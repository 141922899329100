import React, {useEffect} from 'react';
import useForm2 from "../../../../hooks/useForm2";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import RoleSelectField from "./RoleSelectField";
import StatusSelectField from "./UserStatusSelectField";
import KycStatusSelectField from "./KycStatusSelectField";
import FilterFormButton from "../FilterFormButton";
import FilterFormControls from "../FilterFormControls";
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

const useStyle = makeStyles((theme) => ({
    itemSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'var(--charcoal-primary)'
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        },
        "& .MuiFormControl-root.MuiFormControl-fullWidth": {
            marginBottom: '0'
        },
        // Small size
        "& .MuiSelect-select": {
            height: '40px !important',
            padding: '0 16px',
            fontSize: '13px',
            borderRadius: '8px !important',
            lineHeight: '40px',
        },

        '& .MuiSelect-icon': {
            width: '12px',
            height: '12px'
        }
    },
    btnWrapper: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px',
        margin: '0 8px'
    }
}));

const UsersFilter = (props) => {
    const {t} = useTranslation();
    const classes = useStyle();
    const {history, isWhiteLabel, location} = props;
    const {search} = location;
    const query = queryString.parse(search);

    const localFields = ['kyc', 'status', 'roles'];

    const onClearHandler = () => {
        fillFormValues({}); // reset form completely
        const resetQuery = {};

        history.push({
            search: `?${queryString.stringify(resetQuery)}`,
        });

    }

    useEffect(() => {
        // fill data from url

        const dataToFIll = localFields.reduce((obj, key) => {
            if(query[key]){
                obj[key] = query[key]
            }
            return obj
        },{});

        fillFormValues(dataToFIll);

        return () => {
            fillFormValues({});
        }
    },[search]);

    const validate = () => ({});

    const submit = () => {
        const oldQuery = queryString.parse(location.search);
        const newQuery = {...values};

        if(oldQuery["searchValue"]){
            newQuery.searchValue = oldQuery["searchValue"];
        }

        history.push({
            search: `?${queryString.stringify(newQuery)}`,
        });

    }

    const { handleChange, handleSubmit, values, fillFormValues } = useForm2(
        submit,
        validate
    );


    return (
        <Box component={'form'} onSubmit={handleSubmit} className={classes.itemSettings}>
            <FilterFormControls >
                <FormControl
                    fullWidth
                    className={classes.itemFieldFull}
                >
                    <RoleSelectField
                        label={t("formFields.role")}
                        name={"roles"}
                        isWhiteLabel={isWhiteLabel}
                        value={values["roles"]}
                        onSelectChange={(val) => handleChange("roles", val)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    className={classes.itemFieldFull}
                >
                    <StatusSelectField
                        label={t("formFields.status")}
                        name={"status"}
                        value={values["status"]}
                        onSelectChange={(val) => handleChange("status", val)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    className={classes.itemFieldFull}
                >
                    <KycStatusSelectField
                        label={t("formFields.kycStatus")}
                        name={"kyc"}
                        value={values["kyc"]}
                        onSelectChange={(val) => handleChange("kyc", val)}
                    />
                </FormControl>

            </FilterFormControls>
            <Box className={classes.btnWrapper}>
                <FilterFormButton
                    type="submit"
                    addtionalClass={`btn-small`}
                >
                    {t("formFields.submit")}
                </FilterFormButton>
                <FilterFormButton
                    onClick={() => onClearHandler()}
                    addtionalClass={`btn-stroke btn-small`}
                >
                    {t("formFields.reset")}
                </FilterFormButton>
            </Box>
        </Box>
    );
};

export default withRouter(UsersFilter);
