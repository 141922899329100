import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouter as Router,  Route, Switch, Redirect } from "react-router-dom";

import CssBaseline from '@material-ui/core/CssBaseline';

import {connect} from "react-redux";
import {checkUserStatus} from '../User/actions'
import Logout from '../Logout'

import {makeStyles} from "@material-ui/core";
import DrawerMain from "../userSection/DrawerMain";
// import Bar from "../userSection/Bar";
import BarInvesthub from '../userSection/BarInvesthub';
import Box from "@material-ui/core/Box";
import Messages from "../Messages";
import initI18n from "../../initI18n";
import Auth from "../Auth";
import {AbilityContext} from '../../Context/Can'
import {Ability} from "@casl/ability";
import VolveIntercom from "../VolveIntercom";
import UnderBar from "../userSection/UnderBar";
import CircularProgress from '@material-ui/core/CircularProgress'
import * as preLoader from "../../assets/animations/preloader";
import NotyStackProvider from "../NotyStackProvider";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";

import moment from "moment-timezone";
import DrawerWidthProvider from "../DrawerWidthProvider";
import MainWrapper from "../MainWrapper";
import { initPublicSetting } from "../Settings/actions"
// import clsx from 'clsx';

// set globally utc zone
moment.tz.setDefault("Etc/UTC");

const useStyle = makeStyles(theme => ({
    pageRoot: {
        padding: '96px 0 0 340px',
        [theme.breakpoints.down('1339')]: {
            padding: '80px 0 0 300px'
        },
        [theme.breakpoints.down('1259')]: {
            paddingLeft: '96px',
        },
        [theme.breakpoints.down('1023')]: {
            overflow: 'hidden',
            paddingTop: '136px',
        },
        [theme.breakpoints.down('767')]: {
            paddingLeft: '0'
        }
    },
    app:{
        // overflowX:"hidden", // commented because of react-select menu
        '& img' :{
            maxWidth:"100%",
            height:"auto"
        }
    },
    drawerOuter:{
        [theme.breakpoints.up('md')]: {
            display: "flex",
        },
    },
    contentInner: {
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
            width: "calc(100% - 274px) ", // right filter width
        },
        paddingTop:"30px",
    },
}));

const App = (props) => {

    const classes = useStyle();
    const [loaded, setLoaded] = useState(false);


    const initApp = async () => {
        await initI18n();
        await props.checkUserStatus();
        await props.initPublicSetting();
        setLoaded(() => true);
    }

    useEffect(() => {
        initApp();
    }, []);

    const {isAuthenticated, commonRules} = props;

    const drawerOuterRef = useRef(null); // right filter
    const underBarRef = useRef(null); // region for page title, search, top buttons etc.


    if (!loaded) {
        return (
            <Box height={"100vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                {/* <LottieAnimation animationData={preLoader} width={300} height={300}/> */}
                <CircularProgress />
            </Box>
        )
    }

    return (
        <NotyStackProvider>
            <>
                <AbilityContext.Provider value={new Ability(commonRules)}>
                    <CssBaseline/>
                    <Router>
                        <VolveIntercom/>
                        {isAuthenticated ? (
                            <>
                                <Switch>
                                    <Route exact path="/logout" component={Logout}/>
                                    <Route exact path="/" component={() => <Redirect to={`/dashboard`}/>}/>
                                </Switch>
                                <DrawerWidthProvider>
                                    <div className={classes.pageRoot}>
                                        <BarInvesthub />
                                        <DrawerMain />
                                        <MainWrapper>
                                            {/* <Bar/> */}
                                            <UnderBar ref={underBarRef}/>

                                            <PrivateRoutes underBarRef={underBarRef} drawerOuterRef={drawerOuterRef} ref={drawerOuterRef} />
                                        </MainWrapper>
                                    </div>
                                </DrawerWidthProvider>
                            </>

                        ) : (
                            <Auth>
                                <PublicRoutes/>
                            </Auth>
                        )}
                    </Router>
                    <Messages/>
                </AbilityContext.Provider>
            </>
        </NotyStackProvider>
    );
}

const mapStateToProps = ({user}) => {
    return {
        isAuthenticated: user.isAuthenticated,
        commonRules:user.commonRules // need to display menus, or pages with list like users, cards, etc.
    }
}

export default connect(
    mapStateToProps,
    {checkUserStatus, initPublicSetting}
)(App);
