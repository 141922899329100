import React from 'react'
import Groups from "../userSection/content/Groups/Groups";

import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";

const GroupsPage = (props) => {

    const {t} = useTranslation();
    const {underBarRef} = props;

    return (
        <>
            <UserSectionWrapper>
                <Can I="read" a={"group"} passThrough>
                    {(can) => (
                        can ? <Groups underBarRef={underBarRef}/> : <div>{t("group.noPermissionToRead")}</div>
                    )}
                </Can>
            </UserSectionWrapper>
        </>
    )
}



export default GroupsPage
