import React from 'react';
import FormLabel from "@material-ui/core/FormLabel";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormHelperText from "@material-ui/core/FormHelperText";
import {makeStyles} from "@material-ui/core";
import {ReactComponent as CalendarIcon} from "../../../assets/calendarIcon.svg";
import Skeleton from "react-loading-skeleton";


const DateField = (props) => {
    const noHandler = () => {
        console.log(`No handler for ${name} input field`);
    };


    const {value=null, error="", onDateChange=noHandler, label="", name="", disabled=false, dateFormat="", isLoading, ...AdditionalFieldProps} = props;

    const useStyles = makeStyles({
        label:{
            // fontSize: "12px",
            // fontWeight: 400,
            // color: disabled ? "rgba(0, 0, 0, 0.54)" : "#3d4259",
            '&.MuiInputLabel-shrink':{
                transform: "translate(0, 0) scale(1)",
                position: "static"
            },
            '& +.MuiInput-formControl':{
            },
            '& +.MuiFormControl-root':{
                marginTop: "12px"
            }
        },
        picker:{
            '& .MuiPickersToolbar-toolbar':{
                background:"#000"
            }
        },
        root: {
            '& .MuiInputAdornment-positionEnd button': {
                marginRight: "-10px"
            }
        }
    });

    const classes = useStyles();

    return (
        <>
            {label && <FormLabel component="label" htmlFor={`datepicker-${name}`} className={classes.label}>{label}</FormLabel>}
            {isLoading ? <Skeleton width={"100%"} height={"40px"}/> : (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        format={dateFormat}
                        id={name}
                        keyboardIcon={disabled ? null : <CalendarIcon/>}
                        error={!!error}
                        value={value}
                        onChange={(val) => onDateChange(val)}
                        name={name}
                        disabled={disabled}
                        KeyboardButtonProps={{
                            'aria-label': `change ${name}`,
                        }}
                        className={classes.root}
                        {...AdditionalFieldProps}
                    />
                </MuiPickersUtilsProvider>
            )}
            {!!error &&  <FormHelperText>{error}</FormHelperText>}
        </>
    );
};

export default DateField;
