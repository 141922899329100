import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import FormatDate from "../../../../../../FormatDate";
import {useTranslation} from "react-i18next";
import Avatar from "@material-ui/core/Avatar";
import Api from "../../../../../../../services/api";

const useStyles = makeStyles(theme => ({
    notification: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: '12px',
        transition: 'background .2s',

        "&:not(:last-child):before": {
            content: '""',
            position: 'absolute',
            left: '12px',
            right: '12px',
            bottom: '0',
            height: '1px',
            background: 'var(--very-light-pink-secondary)'
        },

        [theme.breakpoints.down('767')]: {
            flexFlow: 'column nowrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '12px',
        }
    },

    notificationContent: {
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '1.6',
        color: 'var(--battelship-grey)',
        transition: 'all .2s',
        display: 'flex',
        flex: '1',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '16px',

        '& svg': {
            width: '24px',
            height: '24px',
            fill: 'var(--battelship-grey)',
            transition: 'fill .2s',
        },
        '& .text-color-faded-green svg': {
            fill: 'var(--faded-green)'
        },
        '& .text-color-orange-pink svg': {
            fill: 'var(--orange-pink)'
        },
        '& .text-color-pale-salmon svg': {
            fill: 'var(--pale-salmon)'
        }
    },

    notificationExtra: {
        "& time": {
            marginLeft: '16px',
            fontSize: '13px',
            fontWeight: '600',
            lineHeight: '1.23077',
            color: 'var(--cool-grey)',

            [theme.breakpoints.down('767')]: {
                marginLeft: '0'
            }
        }
    },
}));
const service =  new Api();
const {_basicPath} = service;

const HistoryCard = (props) => {
    const {item, dateFormat} = props;
    const {t} = useTranslation();
    const {updatedAt, createdAt, diff, user, onlyCreatedAt} = item;
    let avatar = `${_basicPath}/assets/volve-avatar.png`;
    let name = 'Volve';

    if(user){
        const {firstName, lastName, avatarUrl} = user;
        avatar = avatarUrl.startsWith("http") ? avatarUrl : `${window._env_.AWS_PUBLIC_BUCKET_URL}/${avatarUrl}`;
        name = [firstName, lastName].filter((item) =>item).join(" ");
    }

    const [displayedText, setDisplayedText] = useState("");


    const classes = useStyles();


    const getFieldsString = (diffObj) => Object.keys(diffObj).map((field) => `$t(formFields.${field})`).join(", ");




    useEffect(() => {
        if(diff){
            const {status, reportedDate, ...restDiff} = diff;


            const fieldsAndStatus = status && status[1] && Object.keys(restDiff).length;
            const onlyStatus = status && status[1] && Object.keys(restDiff).length === 0;
            const created = Object.keys(diff).length === 0;
            const savedAfterRejected = (status && status[1] && status[1] === "toSubmit") && (restDiff["rejectReason"]);
            const savedAfterRecalled = (status && status[0] === "recalled" && status[1] === "toSubmit");

            let str = "";
            if(created){
                // created
                str = t("expense.historyItemTextCreated", {name})
            }else if(savedAfterRejected){
                // was rejected and then saved
                const fields = getFieldsString(restDiff);
                str = t("expense.historyItemTextSavedAfterRejected", {name, fields})
            }else if(savedAfterRecalled){
                // was recalled and then
                const fields = getFieldsString(restDiff);
                str = t("expense.historyItemTextSavedAfterRecalled", {name, fields})
            }else if(reportedDate){
                // only reported
                str = t("expense.historyItemTextReported", {name})
            }else if(onlyStatus){
                // only status
                str = t("expense.historyItemTextStatus", {name, status: `$t(expense.${status[1]})`})
            }else if(fieldsAndStatus){
                // status and fields
                const fields = getFieldsString(restDiff);
                str = t("expense.historyItemTextFieldsAndStatus", {name, fields, status: `$t(expense.${status[1]})`})
            }else{
                // only fields
                const fields = getFieldsString(restDiff);
                str = t("expense.historyItemTextFields", {name, fields})
            }

            setDisplayedText(str);
        }

    },[diff]);
    return (
        <>
            <div className={classes.notification}>
                <div className={classes.notificationContent}>
                    {avatar && <Avatar src={avatar} />}
                    {displayedText}
                </div>
                <div className={classes.notificationExtra}>
                    <FormatDate dateFormat={`${dateFormat} hh:mm A`}>{onlyCreatedAt ? createdAt : updatedAt}</FormatDate>
                </div>
            </div>
        </>
    )
};

export default HistoryCard;
