import React, {useEffect, useState} from 'react';
import {Prompt, withRouter} from "react-router-dom";
import Api from "../../../../../../services/api";
import {compose} from "redux";
import {connect} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import {makeStyles} from "@material-ui/core";
import useForm from "../../../../../../hooks/useForm";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import stringAsNumber from "../../../../../../helpers/stringAsNumber";

import SelectField from "../../../../../formElements/SelectField";
import InputField from "../../../../../formElements/InputField";
import {useTranslation} from "react-i18next";
import BackButton from "../../../../../BackButton";
import WarningMessage from "../../../../../WarningMessage";
import Box from "@material-ui/core/Box";
import useBeforeUnload from "../../../../../../hooks/useBeforeUnload";
import Portal from "@material-ui/core/Portal";
const service = new Api();

const useStyles = makeStyles(theme => ({
    inputWrapper:{
        [theme.breakpoints.up('md')]: {
            paddingRight: "20px",
        },
    },
    submitBtn:{
         marginRight:"20px"
    },
    backButton:{
        fontWeight: 400,
        paddingLeft: "5px",
        paddingRight: "5px"
    }
}));
const BatchEdit = (props) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();

	const {handleErrorMessage, setMessage, location, history, underBarRef} = props;


	const [isLoading, setIsLoading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
    const [isMount, setIsMount] = useState(false);

	// static data for form
    const [currencies, setCurrencies] = useState([]);
    const [categories, setCategories] = useState([]);

    const expenseIds = (location.state && location.state.selected) ? location.state.selected : [];



	useEffect(() => {
		setIsLoading(true);
        setIsMount(true);
		const promises = [
			// static fields
			service.getServiceData({dataFor:"currencies-all"}),
			service.getCategoriesAll(),

		];

		Promise.all(promises).then((response) => {
			// static fields
			const [currencies, categories] = response;
            const mappedCurrencies = currencies.data.map(({value}) => ({label:value, value}));
            setCurrencies(mappedCurrencies);
			const mappedCategories = categories.data.categories.map(({_id, name}) => ({label:name, value:_id}));
			setCurrencies(mappedCurrencies);
			setCategories(mappedCategories);
			setIsLoading(false);

		}).catch((e) => {
			setIsLoading(false);
			handleErrorMessage(e);
		});

	},[]);

    const submit = async () => {
        setIsSubmitting(true);
        try {
            const response = await service.updateBatchExpense({
                ...values,
                expenseIds
            });

            const {data} = response;
            const {modified, doNotModified} = data;
            setIsSubmitting(false);
            const message = t("expense.updatedExpenses", {modified:modified, all:modified+doNotModified});
            setMessage(message, 'success');
            setValuesWasChanged(false);
            history.push("/expenses");

        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const validate = () => ({});

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate
    );




    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }


    if(!location.state || location.state.selected.length < 1){
        return <div>{t("expense.noInRequest")}</div>
    }

    return (
        <div>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <BackButton/>
                </Portal>
            )}
            <Prompt
                when={valuesWasChanged}
                message={t("unsavedChanges")}
            />
            <Typography variant="h6" gutterBottom>
                {t("expense.editFewExpenses", {count:expenseIds.length})}
            </Typography>
            <Box mb={5}>
                <WarningMessage message={t("expense.updateFewMessage", {count:expenseIds.length})}/>
            </Box>
            <form onSubmit={handleSubmit}  encType="multipart/form-data" noValidate className={classes.form}>
                <Grid container spacing={5}>
                    <Grid item md={6} xs={12}>
                        <FormControl className={classes.inputWrapper} fullWidth error={!!errors.merchant}>
                            <InputField
                                isLoading={isLoading}
                                name={"merchant"}
                                label={t("formFields.merchant")}
                                value={values["merchant"]}
                                error={errors["merchant"]}
                                onInputChange={(val) => handleChangeWithDetectChanges("merchant", val)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl className={classes.inputWrapper} fullWidth>
                            <InputField
                                isLoading={isLoading}
                                name="comment"
                                label={t("formFields.comment")}
                                placeholder="Add a comment"
                                value={values["comment"]}
                                onInputChange={(val) => handleChangeWithDetectChanges("comment", val)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl className={classes.inputWrapper} fullWidth>
                            <InputField
                                isLoading={isLoading}
                                name="tax"
                                label={t("formFields.tax")}
                                value={values["tax"]}
                                onInputChange={(val) => handleChangeWithDetectChanges("tax", stringAsNumber(val))}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl className={classes.inputWrapper} fullWidth>
                            <SelectField
                                label={t("formFields.category")}
                                name={"category"}
                                isLoading={isLoading}
                                value={values["category"]}
                                error={errors["category"]}
                                options={categories}
                                onSelectChange={(val) => handleChangeWithDetectChanges("category", val)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl className={classes.inputWrapper} fullWidth error={!!errors.currency}>
                            <SelectField
                                isLoading={isLoading}
                                label={t("formFields.currency")}
                                name={"currency"}
                                value={values["currency"]}
                                error={errors["currency"]}
                                options={currencies}
                                onSelectChange={(val) => handleChangeWithDetectChanges("currency", val)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color="secondary"
                            type="submit"
                            variant="contained"
                            className={classes.submitBtn}
                            disabled={isSubmitting}
                        >
                            {t("formFields.submit")}
                        </Button>
                        <Button
                            className={classes.backButton}
                            onClick={() => history.goBack()}
                        >
                            {t("formFields.back")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default compose(
    connect(null, {handleErrorMessage, setMessage}),
    withRouter,
)(BatchEdit);
