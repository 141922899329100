import React, {useContext, useState} from 'react';
import PlusButton from "../../../../../../PlusButton"
import ActionMenu from "../../../../../../ActionMenu";
import MenuOption from "../../../../../../MenuOption";
import ImportFromCsvOption from "../../../../../../ImportFromCsvOption";
import {AbilityContext} from "../../../../../../../Context/Can";
import EmailForm from "../EmailForm";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {ReactComponent as PlusIconSVG} from "../../../../../../../assets/plusCircleInvesthub.svg";

const useStyles = makeStyles(theme => ({
    customClass: {
        "&.MuiButton-root": {
            width: '40px',
            height: '40px',
            padding: '0',
            background: 'none',
            boxShadow: '0 0 0 2px #EFEFEF inset',
            transition: 'all .2s',
            minWidth: 'initial',
            borderRadius: '8px',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'all .2s',
                fill: 'var(--battelship-grey)'
            },

            "&:hover": {
                background: 'var(--default-button-hover)',
                boxShadow: 'var(--default-button-hover-shadow)',

                "& svg": {
                    fill: 'var(--pale-grey)'
                }
            },

            "& .MuiButton-startIcon": {
                display: 'none'
            },
            "& .MuiButton-endIcon": {
                display: 'none'
            }
        }
    },

    customMenu: {
        "& .MuiListItemIcon-root": {
            position: 'relative',
            top: '-1px',

            "& svg path": {
                transition: 'all .2s'
            }
        },
        "& .MuiMenu-paper .MuiListItem-root:hover svg path": {
            stroke: 'var(--dark-secondary)'
        },
    }

}));

const AddUsersBtn = (props) => {
    const ability = useContext(AbilityContext);
    const {t} = useTranslation();
    const {onImport, setShowForm, showForm} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose =() => {
        setAnchorEl(null);
    }

    const onInvite = () => {
        setShowForm(true);
        handleClose();
    }

    return (
        <>
            {!showForm ? (
                <>
                    <Tooltip title={t("user.actions")}>
                        <PlusButton
                            onClickHandler={handleClick}
                            customClass={classes.customClass}
                        >
                            <PlusIconSVG />
                        </PlusButton>
                    </Tooltip>
                </>
            ) : (
                <EmailForm setShowForm={setShowForm} />
            )}

            <ActionMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.customMenu}
            >
                <MenuOption onClick={onInvite}>{t("inviteUser")}</MenuOption>
                <ImportFromCsvOption
                    btnLabel={t("formFields.importUsers")}
                    popupTitle={t("titleImportUsers")}
                    disabled={!ability.can("invite", "user")}
                    popupText={t("textImportUsers")}
                    templatePath={"/assets/import-template-users.csv"}
                    onImport={onImport}
                    closeParent={handleClose}
                />
            </ActionMenu>
        </>
    )
}

export default AddUsersBtn

