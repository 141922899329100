import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import {ReactComponent as ExpensePhoto} from "../../../../../assets/expensePhoto.svg";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
// import Box from "@material-ui/core/Box";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useDispatch} from "react-redux";
import compressImage from "../../../../../helpers/compressImage";
import MenuOption from "../../../../MenuOption/MenuOption";
import MenuItem from "@material-ui/core/MenuItem";
import useExpenseQueues from "../../../../../hooks/useExpenseQueues";
const service = new Api();

const useStyles = makeStyles(theme => ({
    button: {
        padding:0,
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-start",
        '&.MuiButton-root:hover':{
            background:"none"
        },
        '& .MuiButton-startIcon':{
            margin:0,
            display:"block"
        },
        '&.Mui-disabled':{
            opacity:0.5
        }
    },
    menuItem:{
        padding:0,
        '& .MuiListItem-button':{
            width:"100%"
        }
    }
}));

const UploadImage = (props) => {
    const {handleClose} = props;
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    const [, setExpensesQueue] = useExpenseQueues();

    const acceptedDocuments = ".png,.jpeg,.jpg,.pdf";

    const handleUpload = async (e) => {
        if(e.target.files && e.target.files[0]){
            try {
                setIsDisabled(true);
                const formData = new FormData();

                const inputFile = e.target.files[0];

                const file = (inputFile.type === "application/pdf") ? inputFile : await compressImage(inputFile); // compress only image

                formData.append("receipt", file);
                const createdExpenseQueue = await service.createExpenseQueue(formData);
                dispatch(setMessage(t("success"), 'success'));

                setExpensesQueue((items) => {
                    return [
                        createdExpenseQueue.data,
                        ...items
                    ]
                });
                handleClose();
            }catch (e) {
                console.log(e);
                dispatch(handleErrorMessage({message:t('errors.cantHandleFile')}));
            }finally {
                setIsDisabled(false)
            }
        }
    }

    return (
        <MenuItem className={classes.menuItem}>
            <Button
                disabled={isDisabled}
                component="label"
                className={classes.button}
                disableTouchRipple={true}
            >
                <MenuOption Icon={ExpensePhoto} component={"span"}>
                    {t("expense.uploadImage")}
                </MenuOption>
                <input type="file"
                       accept={acceptedDocuments}
                       onChange={handleUpload}
                       style={{display: "none"}}
                />
            </Button>
        </MenuItem>
    );
};

export default UploadImage;
