import React, {useState} from 'react'
import AppBar from "@material-ui/core/AppBar";
import {makeStyles, Button} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TopTabs from '../Bar/TopTabs/TopTabs'
import ButtonMenu from './ButtonMenu';
import {connect} from "react-redux";
import { drawerMainToggle } from '../DrawerMain/actions';
import { Link } from "react-router-dom";

// Inner-Component
import User from './User';
import Notifications from './Notifications';

const redirectUrl = `https://dev-crypto.paladis.com/`
const BarInvesthub = (props) => {
    const theme = useTheme();
    const isDown1023 = useMediaQuery(theme.breakpoints.down('1023'));
    const { isOpen, tenantHost } = props;


    const handleNavigate = () => {
        window.location.href = `${process.env.REDIRECT_PALADIS_URL || redirectUrl}`;
    }
    const useStyles = makeStyles({
        header: {
            flexFlow: 'row wrap',
            [theme.breakpoints.down('1339')]: {
                left: '300px',
                padding: '16px 24px'
            },
            [theme.breakpoints.down('1259')]: {
                left: '96px'
            },
            [theme.breakpoints.down('767')]: {
                left: '0',
                padding: '16px 24px 16px 16px'
            }
        },

        headerControl: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
            gap: '20px',
            [theme.breakpoints.down('767')]: {
                marginLeft: 'auto'
            }
        },
    });
    const classes = useStyles();

    console.log('tenantHost', tenantHost)
    return (
        <AppBar color="default" className={`${classes.header} ${isOpen ? "open" : "not open"}`}>
            {isDown1023 ? <><ButtonMenu /></> : null}

            <TopTabs />

            <div className={classes.headerControl}>
                <Button className="btn-small" onClick={handleNavigate}>
                    Paladis
                </Button>
                <Notifications />
                <User />
            </div>
        </AppBar>
    )
}

const mapStateToProps = (state) => {
	const { userData = {} } = state.user;

	const { tenantHost } = userData;
	return {
		tenantHost
	}
}

export default connect(
    mapStateToProps,
    {drawerMainToggle}
)(BarInvesthub)
