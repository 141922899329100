import React, {useEffect, useState} from 'react';
import {compose} from "redux";
// import TopComponent from "../TopComponent";
import TopComponentInvesthub from '../TopComponentInvesthub';
import {connect} from "react-redux";
import checkClick from "../../../../../helpers/checkClick";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import Pagination from "../../../../Pagination";
import getDisplayedTaxRate from "../../../../../helpers/getDisplayedTaxRate";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    tableTaxRate: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    }
}))

const TaxRateTable = (props) => {
    const classes = useStyle();
    const {t} = useTranslation();
    const {handleErrorMessage, setMessage, decimal, history, match, countries} = props;
    const [taxRates, setTaxRates] = useState([]);
    const [info, setInfo] = useState({
        page: 0,
        total: 0,
        pageSize: 0
    });
    const {page, total, pageSize} = info;

    const [isLoading, setIsLoading] = useState(false);


    const fetchItems = async (query) => {
        try{
            setIsLoading(true);
            const response = await service.getTaxRates(query);
            const {result, info} = response.data;
            setInfo(info);
            setTaxRates((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchItems();
    },[]);


    const handleChangePage = (event, newPage) => {
        return fetchItems({page:newPage});
    }


    const rowHandlerClick = async (e, rowData) => {
        if(['LABEL​', 'INPUT'].includes(e.target.tagName)) return;

        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`);
        }
    }


    const onImport = async (formData) => {
        const response = await service.importTaxRate(formData);
        const {data} = response;
        const {modified, doNotModified} = data;
        const message = t("taxRate.importInfo", {modified:modified, all:modified+doNotModified});
        setMessage(message, "success");
        await fetchItems();
    }


    const columns = [
        {
            title: t('thead.name'),
            field: 'name',
        },
        {
            title: t('thead.taxRate'),
            field: 'taxRate',
            render: ({taxRate}) => getDisplayedTaxRate(taxRate, decimal),
        },
        {
            title: t('thead.countries'),
            field: 'countries',
            render: (row) => {
                return row.countries.map((code) => {
                    const foundCountry = countries.find((country) => country.value === code);
                    return foundCountry ? foundCountry.label : code;
                }).join(", ");
            },
        },
        {
            title: t('thead.categories'),
            field: 'categories',
            render: ({categories}) => categories.map(({name, _id}) => name).join(", ")
        },
    ];

    return (
        <div className={`card ${classes.tableTaxRate}`}>
            <TopComponentInvesthub history={history} onImport={onImport} />
            <>
                <VolveMaterialTable
                    columns={columns}
                    isLoading={isLoading}
                    data={taxRates}
                    onRowClick={rowHandlerClick}
                />
                <Pagination
                    total={total}
                    pageSize={pageSize}
                    page={page}
                    isLoading={isLoading}
                    onChangePage={handleChangePage}
                    loadMore
                />
            </>
        </div>
    );
};

const mapStateToProps = ({user}) => {
    const {settings} = user;
    const {decimal} = settings.regional;
    return {
        decimal
    }
}

export default compose(
    connect(mapStateToProps, {handleErrorMessage, setMessage})
)(TaxRateTable);
