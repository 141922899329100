import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import makeStyles from "@material-ui/core/styles/makeStyles";

import CardHeader from "@material-ui/core/CardHeader";

const useStyles = makeStyles({
    card: {
        height:"100%",
        width:"100%"
    },
    header: {
        padding: '34px 30px 10px 30px',
        '& .MuiTypography-root':{
            color: '#354052',
            fontWeight: 300,
            fontSize:'20px',
        },
        '& .MuiCardHeader-action':{
            margin:0,
        }
    },
});

const DashboardPane = (props) => {
    const {title, action, cardContentProps} = props;
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardHeader title={title} action={action} className={classes.header}/>
            <CardContent {...cardContentProps}>
                {props.children}
            </CardContent>
        </Card>
    )
};

export default DashboardPane;
