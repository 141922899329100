import React from 'react'
import List from "@material-ui/core/List";

import {withRouter} from "react-router-dom";
// import {ReactComponent as AccountsIcon} from "../../../../assets/accounts.svg";
import {ReactComponent as AccountsIcon} from "../../../../assets/balanceInvesthub.svg"
import {ReactComponent as HomeSVG} from "../../../../assets/homeInvesthub.svg"
import {ReactComponent as PieChartSVG} from "../../../../assets/piechartInvesthub.svg"
import {ReactComponent as UserSVG} from "../../../../assets/userCircleInvesthub.svg"
import {ReactComponent as ticketSVG} from "../../../../assets/ticketInvesthub.svg"
import {ReactComponent as SettingSVG} from "../../../../assets/settingsInvesthub.svg"
import {ReactComponent as DonutSVG} from "../../../../assets/donutChartInvesthub.svg"
import {ReactComponent as SmileSVG} from "../../../../assets/smileInvesthub.svg"
import {ReactComponent as FileAddSVG} from "../../../../assets/fileAddInvesthub.svg"

import MenuItem from "./MenuItem";
import {useTranslation} from "react-i18next";
import MenuItemChild from "./MenuItemChild";
import {Can} from "../../../../Context/Can";
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    menu: {
        padding: '0'
    },
}))

const Menu = (props) => {
    const {isOpen, viewPermissions} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <List component="nav" className={classes.menu}>
            <MenuItem Icon={HomeSVG} to={"dashboard"} isOpen={isOpen} name={t("menu.dashboard")} />

            <Can I="read" a="card">
                <MenuItem Icon={PieChartSVG} to={"transactions"} isOpen={isOpen} name={t("menu.transactions")}>
                    {/* NEED TRANSLATION */}
                    <MenuItemChild to={"transactions/make-payment"} name={t("Make Payment")} />
                </MenuItem>
            </Can>
            <Can I="read" a="expense">
                <MenuItem Icon={DonutSVG} to={"expenses"} isOpen={isOpen} name={t("menu.expenses")}/>
            </Can>
            {viewPermissions && viewPermissions.account !== "no" && (
                <Can I="read" a="account">
                    <MenuItem Icon={SmileSVG} to={"accounts"} isOpen={isOpen} name={t("menu.accounts")}/>
                </Can>
            )}
            <Can I="read" a="balance">
                <MenuItem Icon={AccountsIcon} to={"balances"} isOpen={isOpen} name={t("menu.balance")}/>
            </Can>
            <Can I="read" a="card">
                <MenuItem Icon={ticketSVG} to={"cards"} isOpen={isOpen} name={t("menu.cards")}/>
            </Can>
            <Can I="read" a="report">
                <MenuItem Icon={FileAddSVG} to={"reports"} isOpen={isOpen} name={t("menu.reports")}>
                    <Can I="create" a="expenseReportTemplate">
                        <MenuItemChild to={"reports/templates"} name={t("menu.expenseReportTemplates")}/>
                    </Can>
                </MenuItem>
            </Can>
            {viewPermissions && viewPermissions.user !== "no" && (
                <Can I="read" a="user">
                    <MenuItem Icon={UserSVG} to={"users"} isOpen={isOpen} name={t("menu.users")}/>
                </Can>
            )}
            {viewPermissions && viewPermissions.businessManagement !== "no" && (
                <MenuItem Icon={SettingSVG} to={"settings"} isOpen={isOpen} name={t("menu.settings")}>
                    <Can I="read" a="company">
                        <MenuItemChild to={"settings/company"} name={t("menu.company")}/>
                    </Can>
                    <Can I="read" a="category">
                        <MenuItemChild to={"settings/categories"} name={t("menu.categories")}/>
                    </Can>
                    <Can I="read" a="project">
                        <MenuItemChild to={"settings/projects"} name={t("menu.projects")}/>
                    </Can>
                    <Can I="read" a="group">
                        <MenuItemChild to={"settings/groups"} name={t("menu.groups")}/>
                    </Can>
                    <Can I="read" a="branch">
                        <MenuItemChild to={"settings/branches"} name={t("menu.branches")}/>
                    </Can>
                    <Can I="read" a="taxRate">
                        <MenuItemChild to={"settings/tax-rates"} name={t("menu.taxRates")}/>
                    </Can>
                    <Can I="read" a="department">
                        <MenuItemChild to={"settings/departments"} name={t("menu.departments")}/>
                    </Can>
                    <MenuItemChild to={"settings/roles"} name={t("menu.roles")}/>
                    <Can I="read" a="rejectReason">
                        <MenuItemChild to={"settings/reject-reasons"} name={t("menu.rejectReasons")}/>
                    </Can>
                    <Can I="read" a="company">
                        <MenuItemChild to={"settings/advanced"} name={t("menu.settingsAdvanced")}/>
                    </Can>
                    <Can I="update:permissions" a="company">
                        <MenuItemChild to={"settings/permissions"} name={t("menu.userPermissions")}/>
                    </Can>
                </MenuItem>
            )}
        </List>
    )

}

export default withRouter(Menu)
