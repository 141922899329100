import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link, Redirect, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import clsx from 'clsx';
const blogUri = window._env_.BLOG_URI;

const useStyles = makeStyles((theme) => ({
    buttonPrefix:{
        marginRight:"20px"
    },
    pageTitle: {
        fontSize: "35px",
        lineHeight: "49px"
    },

    page: {
        padding: '96px 0 0 340px',
        [theme.breakpoints.down('1339')]: {
            padding: '80px 0 0 300px',
        },
        [theme.breakpoints.down('1259')]: {
            paddingLeft: '96px'
        },
        [theme.breakpoints.down('1023')]: {
            overflow: 'hidden'
        },
        [theme.breakpoints.down('767')]: {
            paddingLeft: '0'
        }
    },
    pageSimple: {
        padding: '0',
        [theme.breakpoints.down('1339')]: {
            padding: '0',
        },
        [theme.breakpoints.down('1259')]: {
            paddingLeft: '0'
        }
    },
}));

const Auth = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {location} = props;

    let rightComponent = null;

    if(location.pathname === "/"){
        rightComponent = (
            <>
                <span className={classes.buttonPrefix}>{t("noAccountYet")}</span>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    component={Link}
                    to={`/register`}
                >
                    {t("tryForFree")}
                </Button>
            </>
        )
    }else if (location.pathname === "/register"){
        rightComponent = (
            <>
                <span className={classes.buttonPrefix}>{t("hasAccount")}</span>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    component={Link}
                    to={`/`}
                >
                    {t("logIn")}
                </Button>
            </>
        )
    }

    return (
        <div
            className={clsx(
                classes.page,
                classes.pageSimple
            )}
        >
            {props.title && <Typography variant="h1" gutterBottom className={classes.pageTitle}>{props.title}</Typography>}
            {props.children}
        </div>
    );
}


export default withRouter(Auth);
