import React, {useEffect, useState} from 'react';
import {withRouter, Route, Switch} from 'react-router-dom'
import CategoriesTable from "./CategoriesTable";
import AddCategory from "./AddCategory";
import Api from "../../../../services/api";
import {handleErrorMessage} from "../../../Messages/actions";
import {useDispatch} from "react-redux";
import Category from "./Category";
import BatchEdit from "./BatchEdit";
const service = new Api();

const Categories = (props) => {
    const {match, underBarRef} = props;
    const {url} = match;
    const dispatch = useDispatch();
    const [branches, setBranches] = useState([]);

    const fetchBranches = async () => {
        try {
            const resp = await service.getBranchesAll();
            const mappedBranches = resp.data.branches.map(({_id, name}) => ({value: _id, label: name}));
            setBranches(mappedBranches);
        } catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }
    }

    useEffect(() => {
        fetchBranches();
    },[]);

    return (
        <>
            <Switch>
                <Route exact path={url} render={(props) => <CategoriesTable {...props} underBarRef={underBarRef}/>}/>
                <Route exact path={`${url}/add`} render={(props) => <AddCategory {...props} branches={branches} underBarRef={underBarRef}/>}/>
                <Route exact path={`${url}/batch-edit`} render={(props) => <BatchEdit {...props} branches={branches} underBarRef={underBarRef}/>}/>
                <Route exact path={`${url}/:id`} render={(props) => <Category {...props} branches={branches} underBarRef={underBarRef}/>}/>
            </Switch>
        </>
    );
};

export default withRouter(Categories);
