import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import reducers from './store/reducers'
import { composeWithDevTools } from 'redux-devtools-extension';
import themeConfiguration from "./shared/themeConfiguration";
import themeConfigurationPaladis from "./shared/themeConfigurationPaladis"




const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));

const currentHost = window.location.host;
const isPaladis = currentHost === 'crypto.paladis.com' || currentHost === 'dev-crypto.paladis.com' || currentHost === 'localhost:3000';

let setThemeConfiguration = null;
if (isPaladis) {
    setThemeConfiguration = themeConfigurationPaladis;
} else {
    setThemeConfiguration = themeConfiguration;
}

ReactDOM.render(
        <MuiThemeProvider theme={setThemeConfiguration}>
            <Provider store={store}>
                <App />
            </Provider>
        </MuiThemeProvider>
    , document.getElementById('root'));

serviceWorker.unregister();
