import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import PlusButton from "../../../../../PlusButton"
import ActionMenu from "../../../../../ActionMenu";
import MenuOption from "../../../../../MenuOption";
import {ReactComponent as PlusIconSVG} from "../../../../../../assets/plusCircleInvesthub.svg";
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
    customClass: {
        "&.MuiButton-root": {
            width: '40px',
            height: '40px',
            padding: '0',
            background: 'none',
            boxShadow: '0 0 0 2px #EFEFEF inset',
            transition: 'all .2s',
            minWidth: 'initial',
            borderRadius: '8px',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'all .2s',
                fill: 'var(--battelship-grey)'
            },

            "&:hover": {
                background: 'var(--default-button-hover)',
                boxShadow: 'var(--default-button-hover-shadow)',

                "& svg": {
                    fill: 'var(--pale-grey)'
                }
            },

            "& .MuiButton-startIcon": {
                display: 'none'
            },
            "& .MuiButton-endIcon": {
                display: 'none'
            }
        }
    },

    customMenu: {
        "& .MuiListItemIcon-root": {
            position: 'relative',
            top: '-1px',

            "& svg path": {
                transition: 'all .2s'
            }
        },
        "& .MuiMenu-paper .MuiListItem-root:hover svg path": {
            stroke: 'var(--dark-secondary)'
        },
    }
}));

const AddDepartmentBtn = (props) => {
    const {t} = useTranslation();
    const {history} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose =() => {
        setAnchorEl(null);
    }

    return (
        <>
            <Tooltip
                title={t("department.addNew")}
            >
                <PlusButton onClickHandler={handleClick} hasDropdown customClass={classes.customClass}>
                    <PlusIconSVG />
                </PlusButton>
            </Tooltip>

            <ActionMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuOption onClick={() => history.push("/settings/departments/add")}>{t("department.addManually")}</MenuOption>
            </ActionMenu>
        </>
    )
}

export default AddDepartmentBtn

