import React from 'react'
import Categories from "../userSection/content/Categories";

import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";

const CategoriesPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef} = props;
    return (
            <UserSectionWrapper>
                <Can I="read" a={"category"} passThrough>
                    {(can) => (
                        can ? <Categories underBarRef={underBarRef}/> : <div>{t("category.noPermissionToRead")}</div>
                    )}
                </Can>
            </UserSectionWrapper>
    )

}



export default CategoriesPage
