import React from 'react';
import {FormControl, Grid} from "@material-ui/core";
import {Can} from "../../Context/Can";
import InputField from "../formElements/InputField";
import {useTranslation} from "react-i18next";
import CheckBoxField from "../formElements/CheckBoxField";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    itemFields: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemField: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0',
        },

        // placeholder
        "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder": {
            opacity: "1 !important"
        }
    },
    itemFieldCheckbox: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    }
}))

const GroupForm = (props) => {
    const {handleSubmit, handleChange, values, errors, loading} = props;
    const {t} = useTranslation();
    const classes = useStyle();

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Can I="update" a={"group"} passThrough>
                {(can) => (
                    <div className={classes.itemFields}>
                        <FormControl fullWidth error={!!errors.name} className={classes.itemField}>
                            <InputField
                                label={t("formFields.name")}
                                placeholder={t("formFields.name")}
                                name="name"
                                value={values["name"]}
                                onInputChange={(val) => handleChange("name", val)}
                                error={errors["name"]}
                                isLoading={loading}
                                disabled={!can}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.accountingCode} className={classes.itemField}>
                            <InputField
                                label={t("formFields.accountingCode")}
                                placeholder={t("formFields.accountingCode")}
                                name="accountingCode"
                                isLoading={loading}
                                disabled={!can}
                                value={values["accountingCode"]}
                                onInputChange={(val) => handleChange("accountingCode", val)}
                                error={errors["accountingCode"]}
                            />
                        </FormControl>

                        <FormControl error={!!errors.excludeFromApprovalFlow} className={`${classes.itemField} ${classes.itemFieldCheckbox}`}>
                            <CheckBoxField
                                onCheckboxChange={(val) => handleChange("excludeFromApprovalFlow", !!val)}
                                value={values["excludeFromApprovalFlow"]}
                                label={t("formFields.excludeFromApprovalFlow")}
                                name={"excludeFromApprovalFlow"}
                                error={errors["excludeFromApprovalFlow"]}
                                disabled={!can}
                            />
                        </FormControl>
                    </div>
                )}
            </Can>
        </form>
    );
};

export default GroupForm;
