import React from 'react';
import NewCard from "../NewCard";
import Search from "../../../../Bar/Search";
import {makeStyles} from "@material-ui/core";
import FilterButton from "../../../../../FilterButton/FilterButton";
import {useTranslation} from "react-i18next";
import {Can} from "../../../../../../Context/Can";

const useStyles = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',

        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },

        // placeholder
        "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder": {
            opacity: "1 !important"
        }
    },
    searchWrapper: {
        position: 'relative',
        width: '360px',
        marginRight: 'auto',
        [theme.breakpoints.down('1023')]: {
            width: '250px',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 16px',
        },
        '& .MuiInputLabel-root': {
            display: 'none'
        },
        '& .MuiInputBase-root': {
            position: 'relative',

            "& input[type:'text']": {
                paddingRight: '44px',
            },

            "& button": {
                position: 'absolute',
                top: '0',
                right: '12px',
                bottom: '0',
                width: '44px',
                paddingLeft: '4px',
                margin: '0',
                padding: '0',

                "& svg path": {
                    transition: 'fill .2s',
                },

                "&:hover": {
                    backgroundColor: 'transparent',

                    "& svg path": {
                        stroke: 'var(--clear-blue-primary)'
                    }
                },

                "& .MuiTouchRipple-root": {
                    display: 'none'
                }
            }
        },
        '& .MuiFormControl-root': {
            width: '100%'
        }
    },
    control: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '8px'
    }
}));


const TopComponent = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {onFilterOpen, isFilterOpen, canShowFilter} = props;

    const canShowFilterBtn = canShowFilter && !isFilterOpen;

    return (
        <div className={classes.head}>
            <div className={classes.searchWrapper}>
                {/* NEED TRANSLATION */}
                <Search type="cards" placeholderText={t('Search by description')}/>
            </div>

            <div className={classes.control}>
                <Can I="create" a="card">
                    <NewCard/>
                </Can>

                {canShowFilterBtn && <FilterButton onClick={onFilterOpen}/>}
            </div>
        </div>
    );
};

export default TopComponent;
