import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import moment from 'moment-timezone';

import ChartFilter from "./ChartFilter";
import ChartViewByDay from "./ChartViewByDay";
import useForm2 from "../../../../../hooks/useForm2";
import Api from "../../../../../services/api";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {handleErrorMessage} from '../../../../Messages/actions'
import ChartViewByCategory from "./ChartViewByCategory";
import LastExpenses from "../LastExpenses";
import Portal from "@material-ui/core/Portal";
import DrawerFilter from "../../../DrawerFilter";
import DashboardFilter from "../../../DrawerFilter/DashboardFilter";
import useMounted from "../../../../../hooks/useMounted";
import TopComponent from "../TopComponent";
import useActiveTab from "../../../../../hooks/useActiveTab";

const service = new Api();

const getMonths = () => {
    return [
        // past months
        moment().startOf("month").startOf("day").subtract(11, 'months').format(),
        moment().startOf("month").startOf("day").subtract(10, 'months').format(),
        moment().startOf("month").startOf("day").subtract(9, 'months').format(),
        moment().startOf("month").startOf("day").subtract(8, 'months').format(),
        moment().startOf("month").startOf("day").subtract(7, 'months').format(),
        moment().startOf("month").startOf("day").subtract(6, 'months').format(),
        moment().startOf("month").startOf("day").subtract(5, 'months').format(),
        moment().startOf("month").startOf("day").subtract(4, 'months').format(),
        moment().startOf("month").startOf("day").subtract(3, 'months').format(),
        moment().startOf("month").startOf("day").subtract(2, 'months').format(),
        moment().startOf("month").startOf("day").subtract(1, 'months').format(),

        // current month
        moment().startOf("month").startOf("day").format(),
    ];
}

const ExpensesInfo = (props) => {

    const {handleErrorMessage, decimal, drawerOuterRef, dashboardTableMinHeight, underBarRef} = props;

    const [expensesByDay, setExpensesByDay] = useState([]);
    const [expensesByCategory, setExpenseByCategory] = useState([]);
    const [currency, setCurrency] = useState("");
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [totalAmount, setTotalAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const isMounted = useMounted();
    const activeTab = useActiveTab('dashboard');


    const [months, setMonths] = useState(() => {
        const months = getMonths();
        return months.map((date) => ({value: date, label: moment(date).format('MMMM YYYY')}))
    });

    const submit = async  () => {

        setIsLoading(true);

        try{
            const query = {...values, fromTab: activeTab};

            const amountByDayPromise = service.getDashboardAmountsByDay(query);
            const amountByCategoryPromise = service.getDashboardAmountsByCategory(query);

            const [amountByDayResult, amountByCategoryResult] = await Promise.all([
                amountByDayPromise,
                amountByCategoryPromise
            ]);

            setExpensesByDay(amountByDayResult.data.result);
            setTotalAmount(amountByDayResult.data.info.total);
            setCurrency(amountByDayResult.data.info.displayCurrency);

            setExpenseByCategory(amountByCategoryResult.data.result);


        }catch (e) {
            console.log(e);
            handleErrorMessage(e);

            setExpensesByDay([]);
            setTotalAmount(0);
            setCurrency("");

            setExpenseByCategory([]);

        }finally {
            setIsLoading(false);
        }

    }


    const validate = () => ({});

    const {handleChange, values, fillFormValues, setServerErrors, handleSubmit} = useForm2(
        submit,
        validate
    );

    useEffect(() => {
        const dataForFilter = {
            "date[gte]": moment().startOf("month").startOf("day").format(),
        };
        fillFormValues(dataForFilter);
    },[]);

    useEffect(() => {
        // if date change or tab
        if(values['date[gte]'] && activeTab){
            submit();
        }

    },[values['date[gte]'], activeTab]);

    return (
        <>
            {isMounted && (
                <>
                    <Portal container={drawerOuterRef.current}>
                        <DrawerFilter isOpen={isFilterOpen} onDrawerFilterClose={() => setIsFilterOpen(false)}>
                            <DashboardFilter
                                fillFormValues={fillFormValues}
                                handleSubmit={handleSubmit}
                                values={values}
                                handleChange={handleChange}/>
                        </DrawerFilter>
                    </Portal>
                    <Portal container={underBarRef.current}>
                        <TopComponent onFilterOpen={() => setIsFilterOpen(true)} isFilterOpen={isFilterOpen}/>
                    </Portal>
                </>
            )}

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <ChartFilter
                        handleChange={handleChange}
                        values={values}
                        months={months}
                        totalAmount={totalAmount}
                        decimal={decimal}
                        currency={currency}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ChartViewByDay
                        expenses={expensesByDay}
                        currency={currency}
                        dateLte={values["date[gte]"]}
                        decimal={decimal}
                        isLoading={isLoading}
                    />
                </Grid>
                <Box component={Grid} item flexBasis={"500px"} flexGrow={1}>
                    <LastExpenses dashboardTableMinHeight={dashboardTableMinHeight}/>
                </Box>
                <Box component={Grid} item maxWidth={"100%"} flexBasis={"500px"} flexGrow={1}>
                    <ChartViewByCategory
                        expenses={expensesByCategory}
                        decimal={decimal}
                        currency={currency}
                        isLoading={isLoading}
                        isFilterOpen={isFilterOpen}
                    />
                </Box>
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    const {user} = state;
    const {settings} = user;
    const {decimal} = settings.regional;
    return {
        decimal,
    };
}

export default connect(
    mapStateToProps, {handleErrorMessage}
)(ExpensesInfo);
