import React from 'react';
import SelectField from "../formElements/SelectField";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";

const SelectTab = (props) => {
    const {value,options, onSelectChange } = props;

    const useStyles = makeStyles(theme => ({
        wrapper: {
            position: 'relative',
            flexShrink: '0',
            width: '145px',
            [theme.breakpoints.down('1023')]: {
                maxWidth: '145px',
                width: '100%'
            },
            "& .MuiInputBase-root": {
                width: '100%',
            },
            "& svg": {
                // width: "20px",
                // height: "10px"
            },
            "& .MuiSelect-select": {
                height: '40px !important',
                padding: '0 32px 0 16px !important',
                lineHeight: '40px',
                background: 'none',
                boxShadow: 'inset 0 0 0 2px var(--very-light-pink-secondary)',
                borderRadius: '12px',
                border: 'none',
                opacity: '1',
                fontSize: '14px',
                fontWeight: '600',
            },

            "& .MuiSelect-icon": {
                top: 'calc(50% - 4px)',
                right: '14px',
                width: '10px',
                height: '10px',
            },


            "& .MuiInput-underline:before": {
                display: "none"
            },
            "& .MuiInput-underline::after": {
                display: "none"
            }
        }
    }));


    const classes = useStyles();


    return (
        <Box className={classes.wrapper}>
            <SelectField
                value={value}
                options={options}
                onSelectChange={(val) => onSelectChange(val)}
                hideEmpty
                smallStyle={true}
            />
        </Box>
    );
};

export default SelectTab;
