import React, {useContext, useEffect, useState} from 'react';
import {withRouter, Switch, Route} from "react-router-dom";
import AccountTransactionsTable from "./AccountTransactionsTable";
import Cards from "./AccountCardsTable";
import Details from "./Details";
import {compose} from "redux";
import {connect} from "react-redux";
import {fetchAccount} from "./actions";
import {useTranslation} from "react-i18next";
import {Can} from "../../../../../../Context/Can";
import AccountTabs from "./AccountTabs";
import Portal from "@material-ui/core/Portal";
import useMounted from '../../../../../../hooks/useMounted';

const Account = (props) => {
    const {match: {params, url, path}, userAccount, fetchAccount, drawerOuterRef, underBarRef, name, location} = props;
    const {id} = params;
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const [backPath, setBackPath] = useState(null);
    const {account} = userAccount;
    const isMounted = useMounted();

    useEffect(() => {
        (async() => {
            setIsLoading(true);
            await fetchAccount(id);
            setIsLoading(false);
        })();
    },[fetchAccount]);

    useEffect(() => {
        if(location.state && location.state.backPath){
            setBackPath(location.state.backPath);
        }
        return () => {
            setBackPath(null);
        }
    },[]);


    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("account.mainTitle")}
                    </div>
                </Portal>
            )}

            <div className="card">
                {/* <AccountTabs url={url} account={account}/> */}

                <Switch>
                    <Route exact path={path} render={
                        () => (
                            <Can I="read" a={"account"} passThrough>
                                {(can) => (
                                    can ? <AccountTransactionsTable id={id} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} backPath={backPath} url={url} account={account} /> : <div>{t("account.noPermissionToRead")}</div>
                                )}
                            </Can>
                        )
                    }
                    />
                    <Route exact path={`${path}/cards`} render={
                        () => (
                                <Can I="read" a={"card"} passThrough>
                                    {(can) => (
                                        can ? <Cards id={id} underBarRef={underBarRef} backPath={backPath} accountTabs={<AccountTabs url={url} account={account} />} /> : <div>{t("card.noPermissionToRead")}</div>
                                    )}
                                </Can>
                            )
                    }
                    />
                    <Route exact path={`${path}/details`} render={
                        () => <Details account={account} companyName={name} isLoading={isLoading} underBarRef={underBarRef} backPath={backPath} accountTabs={<AccountTabs url={url} account={account}/>} />
                    }
                    />
                </Switch>
            </div>
        </>
    );
};

const mapStateToProps = ({userAccount, user}) => {
    const {userData} = user;
    const {company} = userData;

    return{
        userAccount,
        name: company.name
    }
}

export default compose(
    connect(mapStateToProps,{fetchAccount}),
    withRouter
)(Account)

