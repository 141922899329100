import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Link, withRouter} from "react-router-dom";

import {fetchUsers} from '../actions'
import {connect} from 'react-redux'
import {makeStyles} from "@material-ui/core";

import TopContentInvesthub from './TopContentInvesthub';
import ResendInvitationEmail from "./ResendInvitationEmail";
import checkClick from "../../../../../helpers/checkClick";
import {compose} from "redux";
import {useTranslation} from "react-i18next";
import canUseCardFunctionality from "../../../../../helpers/canUseCardFunctionality";

import CheckBoxField from "../../../../formElements/CheckBoxField";
import Pagination from "../../../../Pagination";
import queryString from "query-string";
import Portal from "@material-ui/core/Portal";
import DrawerFilter from "../../../DrawerFilter";
import {setMessage} from "../../../../Messages/actions";
import Api from "../../../../../services/api";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import Box from "@material-ui/core/Box";
import CheckBoxFieldMemo from "../../../../formElements/CheckBoxFieldMemo";
import {AbilityContext} from "../../../../../Context/Can";
import {subject} from "@casl/ability";
import UsersFilter from "../../../DrawerFilter/UsersFilter";

const service = new Api();

const useStyles = makeStyles(theme => ({
    tableUser: {
        "& .MuiPaper-root": {
            backgroundColor: 'transparent',
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            borderBottom: '1px solid var(--very-light-pink-secondary)',
            transition: "color .2s"
        },
        "& tbody tr": {
            "&:hover td": {
                backgroundColor: 'var(--very-light-pink-primary)'
            }
        }
    },
    link:{
        textDecoration:'none',
        transition: 'all .2s',
        '&:hover':{
            background: 'var(--pale-salmon)',
            color: 'var(--pale-grey)',
        }
    },
}));


const UsersTable = (props) => {
    const {userSectionUsers, fetchUsers, isAuthenticated, history, setMessage, match, companyCountry, location, drawerOuterRef, underBarRef} = props;

    const {search} = location;
    const queryObject = queryString.parse(search);
    const [selected, setSelected] = useState([]);
    const ability = useContext(AbilityContext);

    const {users:{result, info={}}} = userSectionUsers;
    const {page, total, pageSize, fields} = info;
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [isMount, setIsMount] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const {t} = useTranslation();

    const canUpdateUser = (userId, companyId) => {
        return ability.can("update", subject("user", {_id: userId, company: companyId }));
    }


    const fetchUserUsers = async (dataQuery) => {
        setIsLoading(true);
        await fetchUsers({...dataQuery});
        setIsLoading(false);
    }

    useEffect(() => {
        fetchUserUsers({...queryObject})
    },[search]);

    useEffect(() => {
        setIsMount(true);
    },[]);

    const handleChangePage = async (event, newPage) => {
        fetchUserUsers({
            ...queryObject,
            page:newPage
        })
    }

    const rowHandlerClick = (e, rowData) => {
        if(['LABEL​', 'INPUT', 'A'].includes(e.target.tagName)) return;

        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`)
        }
    }

    const canUseCards = canUseCardFunctionality(companyCountry);

    const onImport = async (formData) => {
        const response = await service.importUsers(formData);
        const {data} = response;
        const {modified, doNotModified} = data;

        const message = t("user.importInfo", {modified:modified, all:modified+doNotModified});

        setMessage(message, 'success');
        await fetchUserUsers({...queryObject})
    }


    const onCheckAll = (value) => {
        if(result.length){
            if(value){
                const usersToSelect = result.reduce((accum, user) => {
                    const {_id, company={} } = user;
                    const canCheck = canUpdateUser(_id, company._id);
                    if(canCheck){
                        accum.push(_id)
                    }
                    return accum
                },[]);
                setSelected(usersToSelect);
            }else{
                setSelected([]);
            }
        }
    }

    const onChange = (e ,value) => {
        setSelected((items) => {

            if(value){
                const copy = [...items];
                copy.push(e.target.id);
                return copy;
            }else{
                return items.filter((item) => item !== e.target.id)
            }
        })
    }


    const memoizedOnChange = useCallback(onChange , []);

    const columns = [
        ...(ability.can("update", "user") ? [
            {
                headerStyle: { padding:' 0 0 0 16px'},
                cellStyle: { padding:' 0 0 0 16px'},
                title: result.length ? (
                    <Box paddingLeft={"6px"}>
                        <CheckBoxField
                            onCheckboxChange={(val) => onCheckAll(!!val)}
                            value={!!(result.length && (selected.length === result.length))}
                            label={''}
                        />
                    </Box>
                ) : '',
                maxWidth: 60,
                render: (row) => {
                    const {_id, company={}} = row;
                    const canCheck = canUpdateUser(_id, company._id);

                    return (
                        <CheckBoxFieldMemo
                            onChange={memoizedOnChange}
                            checked={selected.includes(String(_id))}
                            disabled={!canCheck}
                            label={''}
                            id={String(_id)}
                            name={""}
                        />
                    )
                }
            },
        ] : []),

        {
            title: t('thead.email'),
            field: 'email',
        },
        ...(canUseCards ? [
            {
                title: t('thead.kyc'),
                field: 'kyc',
                render: (row) => {
                    const {status, _id, kyc, company={}} = row;
                    if(kyc) return <span className={`status status-green-dark`}>kyc</span>;

                    const canVerify = canUpdateUser(_id, company._id);

                    return (
                        <span>{(status === "active" && canVerify) && (
                            <Link to={`/user-verification/${_id}`} className={`status status-red ${classes.link}`}>
                                {t("user.verifyUserLinkName")}
                            </Link>
                        )}</span>
                    )
                }
            }
        ] : []),
        {
            title: t('thead.roles'),
            field: 'roles',
            render: ({roles}) => <Box whiteSpace={"nowrap"}>{roles.map((role) => t(`user.${role}`)).join(", ")}</Box>,
        },
        {
            title: t('thead.approvers'),
            field: 'approvers',
            render: ({approvers}) => approvers ? <Box whiteSpace={"nowrap"}>{approvers.map(({firstName, lastName}) => `${firstName} ${lastName}`).join(", ")}</Box> : "",
        },

        {
            title: t('thead.status'),
            field: 'status',
            render: ({status, _id}) => status === "invited" ? <span className={`status status-red ${classes.link}`}> {status} <ResendInvitationEmail userId={_id}/></span> : <span className={`status status-green-dark`}>{status}</span>
        },
        {
            title: t('thead.firstName'),
            field: 'firstName',
        },
        {
            title: t('thead.lastName'),
            field: 'lastName',
        },
        {
            title: t('thead.group'),
            field: 'group',
            render: ({group}) =>  group && group.name ? group.name : "",
        },
    ];

    return (
        <>
            {isMount && (
               <>
                   <Portal container={drawerOuterRef.current}>
                       <DrawerFilter isOpen={isFilterOpen} onDrawerFilterClose={() => setIsFilterOpen(false)}>
                           <UsersFilter/>
                       </DrawerFilter>
                   </Portal>
                   <Portal container={underBarRef.current}>
                        <div className="page__title h4 text-weight-200">
                            {t("user.mainTitle")}
                        </div>
                   </Portal>

               </>
            )}

            <div className={`card ${classes.tableUser}`}>
                <TopContentInvesthub onFilterOpen={() => setIsFilterOpen(true)} isFilterOpen={isFilterOpen} onImport={onImport} selected={selected} setSelected={setSelected}/>

                <VolveMaterialTable
                    columns={columns}
                    isLoading={isLoading}
                    data={result}
                    onRowClick={rowHandlerClick}
                />
                <Pagination
                    total={total}
                    pageSize={pageSize}
                    page={page}
                    isLoading={isLoading}
                    onChangePage={handleChangePage}
                    loadMore
                />
            </div>
        </>
    )
};

const mapStateToProps = (state) => {
    const {userSectionUsers, user} = state;
    const {userData} = user;
    const {isWhiteLabel} = userData.company;

    return {
        userSectionUsers,
        isAuthenticated:state.user.isAuthenticated,
        companyCountry:state.user.userData && state.user.userData.company && state.user.userData.company.country,
        userEmail:state.user.userData.email,
        isWhiteLabel
    }
};


export default compose(
    connect(mapStateToProps, {fetchUsers, setMessage}),
    withRouter,
)(UsersTable)
