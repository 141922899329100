import React, {useState} from 'react';
import useForm from "../../hooks/useForm";
import {FormControl, makeStyles, InputAdornment} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Redirect, withRouter, Link} from 'react-router-dom'
import {getLoggedUser} from "../User/actions";
import {setMessage} from "../Messages/actions";
import {connect} from "react-redux";
import Api from "../../services/api";
import {compose} from "redux";
import InputField from "../formElements/InputField";
import {useTranslation} from "react-i18next";
import getPasswordRegex from "../../helpers/getPasswordRegex";
// import { ReactComponent as LogoVolve} from '../../assets/logoVolve.svg'
import { ReactComponent as LogoPaladis } from "../../assets/paladis.svg"
import { ReactComponent as LockSVG } from "../../assets/lock.svg"
import { getExternalFileUrl } from "../../helpers/file"

const service = new Api();

const useStyles = makeStyles(theme => ({
    entry: {
        flexFlow: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100dvw',
        height: '100dvh',
        display: 'flex'
    },
    entryWrapper: {
        borderRadius: '8px',
        flexFlow: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        width: '700px',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.down('1023')]: {
            width: 'initial',
            marginLeft: '24px',
            marginRight: '24px'
        }
    },
    entryColFirst: {
        flex: '0 0 300px',
        width: '100%',
        maxWidth: '300px',
    },
    entryColSecond: {
        flex: '0 0 400px',
        width: '100%',
        maxWidth: '400px',
        [theme.breakpoints.down('1023')]: {
            flex: '1',
            width: '100%',
            maxWidth: '375px',
            paddingTop: '80px',
            paddingBottom: '80px',
        }
    },
    entryLogo: {
        display: 'inline-flex',
        maxWidth: '68px',
        '& svg': {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    entrySubtitle: {
        marginBottom: '20px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.71429',
    },
    entryFromControl: {
        marginBottom: '12px',
    },
    entryNote: {
        color: 'var(--battelship-grey)',
        fontSize: '13px',
        lineHeight: '1.23077',
        '& a': {
            color: 'var(--dark-secondary)',
            transition: 'all .2s'
        },
        '& a:hover': {
            color: 'var(--bright-blue)'
        }
    },
}));

const ResetPasswordForm = (props) => {
    const {setMessage, match, history, publicSettings} = props;
    const {params} = match;
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const validate = (values) => {
        const passwordRegex = getPasswordRegex();
        let errors = {};

        if (!values.password) {
            errors.password = t('errors.required', {field: t("formFields.password")});
        }
        if(values.password && !values.password.toString().match(passwordRegex)){
            errors.password = t('errors.passwordNotValid');
        }
        if (!values.password_repeat) {
            errors.password_repeat = t('errors.required', {field: t("formFields.repeatPassword")});
        }

        if((values.password_repeat && values.password) && (values.password_repeat !== values.password)){
            errors.password_repeat = t("errors.passwordsAreDifferent");
        }

        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate
    );

    async function submit(){
        setIsLoading(true);
        try {

            const response = await service.resetPassword(values.password, params.resetToken);
            const {msg} = response.data;
            setMessage(msg, 'success');
            setIsLoading(false);
            history.push('/');

        }catch (e) {
            setServerErrors(e);

        }
        setIsLoading(false);
    }

    const classes = useStyles();


    return (
        <div className={classes.entry}>
            <div className={classes.entryWrapper}>
                <div className={`padding-40 bg-color-black tablet-hide ${classes.entryColFirst}`}>
                    <Link to={"/"} className={classes.entryLogo}>
                        <LogoPaladis />
                    </Link>
                </div>
            </div>

            <div className={`padding-40 bg-color-white ${classes.entryColSecond}`}>
                <div class="width-full height-40 tablet-hide">{' '}</div>
                <div className={`text-align-center margin-bottom-16 desktop-hide tablet-show`}>
                    <Link to={"/"} className={classes.entryLogo}>
                        <LogoPaladis />                       
                    </Link>
                </div>

                <div className="text-weight-200 text-align-center h3 margin-bottom-40">
                    {t("resetPassword")}
                </div>

                <form onSubmit={handleSubmit} noValidate >
                    <FormControl fullWidth error={!!errors.password} className={classes.entryFromControl}>
                        <InputField
                            name="password"
                            type="password"
                            placeholder={t("formFields.newPassword")}
                            error={errors["password"]}
                            value={values["password"]}
                            onInputChange={(val) => handleChange("password", val)}
                            hasIcon={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><LockSVG /></InputAdornment>,
                            }}
                        />
                    </FormControl>

                    <FormControl fullWidth error={!!errors.password_repeat} className={classes.entryFromControl}>
                        <InputField
                            name="password_repeat"
                            type="password"
                            placeholder={t("formFields.repeatPassword")}
                            error={errors["password_repeat"]}
                            value={values["password_repeat"]}
                            onInputChange={(val) => handleChange("password_repeat", val)}
                            hasIcon={true}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><LockSVG /></InputAdornment>,
                            }}
                        />
                    </FormControl>

                    <Button
                        type="submit"
                        disabled={isLoading}
                    >
                        {t("formFields.setNewPassword")}
                    </Button>
                </form>
            </div>
        </div>
    )
};

const mapStateToProps = ({publicSettings}) => {
    return { publicSettings }
}

export default compose(
    connect( mapStateToProps, {getLoggedUser, setMessage}),
    withRouter
)(ResetPasswordForm)
