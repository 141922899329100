import React from 'react';
import Button from "@material-ui/core/Button";
import {ReactComponent as PlusCircle} from "../../assets/plusCircle.svg";
import {ReactComponent as ArrowBottom} from "../../assets/arrowBottom.svg";
import {makeStyles} from "@material-ui/core";
import clsx from 'clsx'
const useStyles = makeStyles({
    button: {
        padding: "6px 20px 6px 9px",
        '& .MuiButton-startIcon': {
            marginRight: "9px",
        },
        '& .MuiButton-endIcon': {
            marginLeft: "27px",
        },
    },
    startIcon: {
        width: "32px",
        height: "32px",
        '& .background': {
            fill: '#1EB3A1'
        },
    },
    endIcon: {
        width: "11px",
        height: "8px",
        '& path': {
            fill: '#fff',
            stroke: "#fff",
            strokeWidth: "0"
        },
    },
});

const PlusButton = (props) => {
    const {children, onClickHandler, hasDropdown, customClass, ...restProps} = props;

    const classes = useStyles();
    return (
        <Button
            variant="contained"
            color="primary"
            onClick={onClickHandler}
            startIcon={<PlusCircle className={classes.startIcon}/>}
            endIcon={hasDropdown ? <ArrowBottom className={classes.endIcon}/> : null}
            {...restProps}
            className={clsx(classes.button, customClass)}
        >
            {children}
        </Button>
    );
};

export default PlusButton;
