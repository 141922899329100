import React from 'react'

import MaterialUiDrawer from "@material-ui/core/Drawer/Drawer";
import {makeStyles, useTheme} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import {ReactComponent as CloseSVG} from '../../assets/closeInvesthub.svg'

// const drawerWidth = 500;

const RightDrawer = ({isOpen, onClose, children, titleDrawer}) => {


	const theme = useTheme();
	// const isPc = useMediaQuery(theme.breakpoints.up('md'));
    // const onMax1339 = useMediaQuery(theme.breakpoints.down('1339'));
    // const onMax1259 = useMediaQuery(theme.breakpoints.down('1259'));
    // const onMax1023 = useMediaQuery(theme.breakpoints.up('1023'));
    const onMax767 = useMediaQuery(theme.breakpoints.down('767'))
	const variant = onMax767 ? 'permanent' : 'temporary';

	const useStyles = makeStyles(theme => ({
		drawer: {
            "& .MuiBackdrop-root": {
                backgroundColor: 'rgba(244, 244, 244, 0.8)'
            }
		},
		drawerOpen: {
            width: '340px',
            boxShadow: 'none',
            borderLeft: '0',
            padding: '24px',
            [theme.breakpoints.down('1339')]: {
                padding: '16px'
            },
            [theme.breakpoints.down('1259')]: {
                alignItems: 'stretch',
                width: '300px',
                boxShadow: '4px 0 32px rgba(17, 19, 21, 0.05)',
            },
            [theme.breakpoints.down('767')]: {
                paddingLeft: '8px',
                paddingRight: '8px'
            },
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: 0,
		},
        filterOverlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            zIndex: '25',
            background: 'rgba(244, 244, 244, 0.8)',
            // visibility: 'hidden',
            // opacity: '0',
            transition: 'all .2s',
        },
        head: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '12px',
            padding: '12px',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',

            "& button": {
                marginLeft: 'auto',

                "& svg": {
                    width: '24px',
                    height: '24px',
                    fill: 'var(--dark-secondary)',
                    transition: 'fill .2s',
                },

                "&:hover svg": {
                    fill: 'var(--clear-blue-primary)'
                }
            }
        }
	}));

	const classes = useStyles();

	const openClose = {
		[classes.drawerOpen]: isOpen,
		[classes.drawerClose]: !isOpen,
	};

	return (
        <>
            <MaterialUiDrawer
                variant={variant}
                className={clsx(classes.drawer, openClose)}
                classes={{ paper: clsx(classes.drawerInner, openClose),}}
                open={isOpen}
                anchor='right'
                ModalProps={{ onBackdropClick: () => onClose() }}
            >
                <Box className={classes.head}>
                    {titleDrawer}
                    <button type="button" onClick={onClose}>
                        <CloseSVG />
                    </button>
                </Box>
                {children}
            </MaterialUiDrawer>

            {onMax767 && (
                <>
                    {(isOpen) && (
                        <Box className={classes.filterOverlay} onClick={onClose} />
                    )}
                </>
            )}
        </>
    )
}

export default RightDrawer
