import React, {useState} from 'react'

import {ReactComponent as IconExpense} from "../../../../../assets/expense.svg";
import {ReactComponent as IconFewExpenses} from "../../../../../assets/manyExpenses.svg";
import {ReactComponent as ExpenseCamera} from "../../../../../assets/expenseCamera.svg";
import {ReactComponent as PlusIconSVG} from "../../../../../assets/plusCircleInvesthub.svg";

import {withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PlusButton from "../../../../PlusButton";
import CameraDialog from "../CameraDialog";

import UploadImage from "../UploadImage";
import ActionMenu from "../../../../ActionMenu";
import MenuOption from "../../../../MenuOption";
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
    customClass: {
        "&.MuiButton-root": {
            width: '40px',
            height: '40px',
            padding: '0',
            background: 'none',
            boxShadow: '0 0 0 2px #EFEFEF inset',
            transition: 'all .2s',
            minWidth: 'initial',
            borderRadius: '8px',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'all .2s',
                fill: 'var(--battelship-grey)'
            },

            "&:hover": {
                background: 'var(--default-button-hover)',
                boxShadow: 'var(--default-button-hover-shadow)',

                "& svg": {
                    fill: 'var(--pale-grey)'
                }
            },

            "& .MuiButton-startIcon": {
                display: 'none'
            },
            "& .MuiButton-endIcon": {
                display: 'none'
            }
        }
    },

    customMenu: {
        "& .MuiListItemIcon-root": {
            position: 'relative',
            top: '-1px',

            "& svg path": {
                transition: 'all .2s'
            }
        },
        "& .MuiMenu-paper .MuiListItem-root:hover svg path": {
            stroke: 'var(--dark-secondary)'
        },
    }
}));

const AddExpenseBtn = (props) => {
    const {history} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [isCameraDialogOpen, setIsCameraDialogOpen] = useState(false);
    const {t} = useTranslation();
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose =() => {
        setAnchorEl(null);
    }

    const handleCloseCamera = () => {
        setIsCameraDialogOpen(false);
        handleClose();
    }

    return (
        <>
            <CameraDialog open={isCameraDialogOpen} handleClose={handleCloseCamera} />
            <Tooltip
                title={t("expense.addNew")}
            >
                <PlusButton onClickHandler={handleClick} hasDropdown customClass={classes.customClass}>
                    <PlusIconSVG />
                </PlusButton>
            </Tooltip>

            <ActionMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.customMenu}
            >
                <MenuOption Icon={IconExpense} onClick={() => history.push(`/expenses/add`)}>{t("expense.addManually")}</MenuOption>
                <UploadImage handleClose={handleClose}/>
                <MenuOption Icon={ExpenseCamera} onClick={() => setIsCameraDialogOpen(true)}>{t("expense.captureWithCamera")}</MenuOption>
                <MenuOption Icon={IconFewExpenses} onClick={() => history.push(`/expenses/batchAdd`)}>{t("expense.addFew")}</MenuOption>

            </ActionMenu>
        </>

    )
}

export default withRouter(AddExpenseBtn)
