import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import InputFieldMemo from "../../formElements/InputFieldMemo";
import FormControl from "@material-ui/core/FormControl";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    textAreaWrapper:{
        '& .MuiInput-formControl':{
            borderRadius: "7px",
            paddingBottom:0,
        },
        '& textarea':{
            borderRadius: "7px",
            lineHeight: "22px",
            fontSize:"15px",
            minHeight:"100px"
        },
        '& textarea:not([disabled])':{
            padding:"10px",
            border:"1px solid #8A96A0",
            "&:hover":{
                borderColor:"#23273b"
            },
            "&:focus":{
                borderColor:"#23273b"
            }
        }
    },

}));

const CommentField = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <FormControl fullWidth error={!!props.error} className={classes.textAreaWrapper}>
            <InputFieldMemo
                {...props}
                multiline
                name={"comment"}
                rows="4"
                label={t("formFields.comment")}
            />
        </FormControl>
    );
};

export default CommentField;
