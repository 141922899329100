import React, {useEffect, useState} from 'react';
import {FormControl, makeStyles} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import Fab from "@material-ui/core/Fab";
import {ReactComponent as Delete} from "../../../../../../../assets/delete.svg";
import SelectField from "../../../../../../formElements/SelectField";
import InputField from "../../../../../../formElements/InputField";
import DateField from "../../../../../../formElements/DateField";
import {useTranslation} from "react-i18next";
import {ReactComponent as CloseThin} from "../../../../../../../assets/closeThin.svg";
import NumericInput from "../../../../../../formElements/NumericInput";

const useStyles = makeStyles(theme => ({
    dateWrapper: {
        minWidth: "145px"
    },
    textAreaWrapper: {
        "& textarea": {
            border:"1px solid #8A96A0",
            borderRadius: "7px",
            padding:"10px"
        }
    },
    fabButton: {
        boxShadow: "none",
        background: "none",
        "& svg path":{
            stroke:"#1e1e25"
        }
    },
}));


const ExpenseRow = (props) => {
	const {handleChange, values, index, errors, currencies=[], categories=[], onDeleteRow, dateFormat, decimal} = props;
	const {t} = useTranslation();



	const classes = useStyles();

	return (
		<>
            <TableCell>
                <FormControl fullWidth error={!!errors[`expenses[${index}][date]`]} className={classes.dateWrapper}>
                    <DateField
                        dateFormat={dateFormat}
                        disableFuture
                        onDateChange={(date) => handleChange(`expenses[${index}][date]`, date)}
                        value={values[`expenses[${index}][date]`]}
                        error={errors[`expenses[${index}][date]`]}
                        name={`expenses[${index}][date]`}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl fullWidth error={!!errors[`expenses[${index}][amount]`]}>
                    <NumericInput
                        name={`expenses[${index}][amount]`}
                        value={values[`expenses[${index}][amount]`]}
                        error={errors[`expenses[${index}][amount]`]}
                        onInputChange={(name, value) => handleChange(`expenses[${index}][amount]`, value)}
                        decimal={decimal}
                    />
                </FormControl>
            </TableCell>
			<TableCell>
				<FormControl fullWidth error={!!errors[`expenses[${index}][currency]`]}>
					<SelectField
						name={`expenses[${index}][currency]`}
						value={values[`expenses[${index}][currency]`]}
						error={errors[`expenses[${index}][currency]`]}
						options={currencies}
						onSelectChange={(val) => handleChange(`expenses[${index}][currency]`, val)}
					/>
				</FormControl>
			</TableCell>
			<TableCell>
				<FormControl fullWidth error={!!errors[`expenses[${index}][merchant]`]}>
					<InputField
						name={`expenses[${index}][merchant]`}
						error={errors[`expenses[${index}][merchant]`]}
						value={values[`expenses[${index}][merchant]`]}
						onInputChange={(val) => handleChange(`expenses[${index}][merchant]`, val)}
					/>
				</FormControl>
			</TableCell>
			<TableCell>
				<FormControl fullWidth error={!!errors[`expenses[${index}][category]`]}>
					<SelectField
						name={`expenses[${index}][category]`}
						value={values[`expenses[${index}][category]`]}
						error={errors[`expenses[${index}][category]`]}
						options={categories}
						onSelectChange={(val) => handleChange(`expenses[${index}][category]`, val)}
					/>
				</FormControl>
			</TableCell>
			<TableCell className={classes.textAreaWrapper}>
				<FormControl fullWidth error={!!errors[`expenses[${index}][comment]`]}>
					<InputField
						multiline
						rows="1"
						name={`expenses[${index}][comment]`}
						value={values[`expenses[${index}][comment]`]}
						onInputChange={(val) => handleChange(`expenses[${index}][comment]`, val)}
					/>
				</FormControl>
			</TableCell>
			<TableCell>
                <Fab className={classes.fabButton}
                     onClick={() => onDeleteRow(index)}
                     size={'small'}
                >
                    <CloseThin/>
                </Fab>
			</TableCell>

		</>
	);
};

export default ExpenseRow;
