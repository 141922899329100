import React from 'react';
import {makeStyles} from "@material-ui/core";
import {ReactComponent as DotSVG } from "../../assets/moreHorizontal.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const ActionCircleInvesthub = (props) => {
    const styles = {
        actions: {
            position: 'relative',
            display: 'inline-block',
            zIndex: '3',
        },
        actionsButton: {
            position: 'relative',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            background: 'var(--white)',
            userSelect: 'none',
            transition: 'background .2s',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'fill .2s',
                fill: 'var(--battelship-grey)',
            }
        },
        actionsBody: {
            position: 'absolute',
            top: 'calc(100% + 12px)',
            right: '-12px',
            width: '264px',
            zIndex: '2',
            padding: '12px',
            borderRadius: '16px',
            border: '1px solid transparent',
            boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
            background: 'var(--white)',
            visibility: 'hidden',
            opacity: '0',
            transform: 'translateY(3px)',
            transition: 'all .2s',
        },
        actionsSmall: {
            '& $actionsButton': {
                width: '32px',
                height: '32px',
            },
            '& $actionsButton svg': {
                width: '20px',
                height: '20px'
            }
        },
        actionsActive: {
            '& $actionsBody': {
                opacity: '1',
                visibility: 'visible',
                transform: 'translateY(0)'
            }
        }
    }

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const {children, small=false, onClick, onClickAway, isActive=false} = props;


    return (
        <>
            <ClickAwayListener onClickAway={onClickAway}>
                <div
                    className={`${classes.actions} ${small ? classes.actionsSmall : ''} ${isActive ? classes.actionsActive : ''}`}
                >
                    <button
                        type="button"
                        className={classes.actionsButton}
                        onClick={onClick}
                    >
                        <DotSVG />
                    </button>

                    <div className={classes.actionsBody}>
                        {children}
                    </div>
                </div>
            </ClickAwayListener>
        </>
    )
}

export default ActionCircleInvesthub;
