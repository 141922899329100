import {useSelector} from 'react-redux'
import {matchPath, useLocation} from "react-router-dom";

const useExpenseTabsQuery = () => {

    const tabsExpenses = useSelector(state => state.tabs["expenses"]);
    const userId = useSelector(state => state.user.userData._id);

    const location = useLocation();

    const matchedPath = matchPath(location.pathname, {
        path: "/expenses/:tab",
        exact: true,
        strict: false
    });

    if(!matchedPath){
        return {}
    }

    switch (tabsExpenses.active) {
        case "administrator":
        case "auditor":
            return matchedPath.params.tab === "todo" ? {status: "submitted"} : {};
        case "approver":
            return matchedPath.params.tab === "todo" ? {approver: userId, status: "submitted"} : {approver: userId};
        default:
            return matchedPath.params.tab === "todo" ? {user: userId, status: ["toSubmit", "rejected"]} : {user: userId};
    }
}

export default useExpenseTabsQuery
