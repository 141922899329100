import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import {setMessage, handleErrorMessage} from '../../../../../../Messages/actions'
import Api from "../../../../../../../services/api";
import {fetchUsers} from "../../../actions";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import ActionMenu from "../../../../../../ActionMenu";
import ActionButton from "../../../../../../ActionButton";
import MenuOption from "../../../../../../MenuOption";
import DialogAssignApprover from "../../DialogAssignApprover";
const service = new Api();



const ActionBtn = (props) => {

    const {t} = useTranslation();
    const {selected, setSelected, handleErrorMessage, setMessage, fetchUsers, location} = props;

    const {search} = location;
    const queryObject = queryString.parse(search);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenDialog, setIsOpenDialog] = useState(false);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const onAssignApprover = async (approver) => {
        await service.assignReporters(approver, {ids: selected});
        await fetchUsers({...queryObject});
        setSelected([]);
    }

    const onHandleAction = async (action) => {

        let serviceAction = null;

        const dataToSend = {userIds: selected};

        switch (action) {
            case "activate" :
                serviceAction = service.actionBatchActivate;
                break;
            default :
                handleErrorMessage({message:`user.noAction`});
                setIsSubmitting(false);
                return;
        }

        try {

            const response = await serviceAction(dataToSend);
            const {data} = response;
            const {modified, doNotModified} = data;
            if(modified  > 0){
                const message = t("user.updatedUsers", {modified:modified, all:modified+doNotModified});
                setMessage(message, 'success');
                setSelected([]);
                await fetchUsers({...queryObject});
            }else{
                handleErrorMessage({message:`user.cantUpdateAny`})
            }
            setIsSubmitting(false);

        }catch (e) {
            setIsSubmitting(false);
            handleErrorMessage(e);
        }
    }

    const onOpenDialog = () => {
        setIsOpenDialog(true);
        handleClose(); // close menu
    }

    const onExportAll = async () => {
        try{
            setIsSubmitting(true);
            await service.exportUsers();
            handleClose();
        }catch (e) {
            handleErrorMessage(e);
        }finally {
            setIsSubmitting(false);
        }
    }

    return (
        <>
            <ActionButton
                onClick={handleClick}
            >
                {t("user.actions")}
            </ActionButton>
            <ActionMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuOption onClick={onExportAll}>
                    {t("user.exportAll")}
                </MenuOption>
                {selected.length > 0 && (
                    <MenuOption disabled={isSubmitting} onClick={() => onHandleAction("activate")}>
                        {t("formFields.activate")}
                    </MenuOption>
                )}
                {selected.length > 0 && (
                    <MenuOption disabled={isSubmitting} onClick={onOpenDialog}>
                        {t("formFields.assignApprover")}
                    </MenuOption>
                )}
            </ActionMenu>
            <DialogAssignApprover
                setOpen={setIsOpenDialog}
                title={t("formFields.assignApprover")}
                open={isOpenDialog}
                setMessage={setMessage}
                handleErrorMessage={handleErrorMessage}
                onAssignApprover={onAssignApprover}
            />
        </>
    );
};

export default compose(
    connect(null, {handleErrorMessage, setMessage, fetchUsers})
)(withRouter(ActionBtn));
