import React from 'react';
import DashboardPane from "../../../../DashboardPane";
import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../../../../CompanyCountryCheck";
import KycInfo from "../KYCInfo";
import KYBInfo from "../KYCBnfo";
import Box from "@material-ui/core/Box";
import ExpensesToDo from "./ExpensesToDo";
import {Can} from "../../../../../Context/Can";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    cardContent:{
        '&.MuiCardContent-root':{
            paddingBottom:"6px",
            paddingTop:"6px",
        }
    }
}));


const PersonalToDo = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {user} = props;

    return (
        <DashboardPane
            title={t("user.toDo")}
            cardContentProps={{className:classes.cardContent}}
        >
            <Box paddingLeft={"15px"} paddingRight={"15px"}>
                <CompanyCountryCheck noText>
                    <KycInfo user={user}/>
                    <Can I="update" a={"company"}>
                        <KYBInfo user={user}/>
                    </Can>
                </CompanyCountryCheck>
                <Can I="read" a={"expense"} passThrough>
                    {(can) => (
                        <ExpensesToDo hasPermissionsToRead={can}/>
                    )}
                </Can>
            </Box>

        </DashboardPane>
    );
};

export default PersonalToDo;
