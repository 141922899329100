import React from 'react';
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import {Can} from "../../../../../Context/Can";
import PlusButton from "../../../../PlusButton"
import {ReactComponent as PlusIconSVG} from "../../../../../assets/plusCircleInvesthub.svg"

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',

        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        }
    },

    customClass: {
        "&.MuiButton-root": {
            width: '40px',
            height: '40px',
            padding: '0',
            background: 'none',
            boxShadow: '0 0 0 2px #EFEFEF inset',
            transition: 'all .2s',
            minWidth: 'initial',
            borderRadius: '8px',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'all .2s',
                fill: 'var(--battelship-grey)'
            },

            "&:hover": {
                background: 'var(--default-button-hover)',
                boxShadow: 'var(--default-button-hover-shadow)',

                "& svg": {
                    fill: 'var(--pale-grey)'
                }
            },

            "& .MuiButton-startIcon": {
                display: 'none'
            },
            "& .MuiButton-endIcon": {
                display: 'none'
            }
        }
    },
}))


const TopComponent = (props) => {
    const {history} = props;
    const classes = useStyle();

    const {t} = useTranslation();
    return (
        <div className={classes.head}>
            <div className={`title-blue text-weight-300 margin-bottom-0`}>
                {t("expenseReportTemplate.mainTitle")}
            </div>

            <div className={classes.control}>
                <Can I="create" a={"expenseReportTemplate"}>
                    <Tooltip title={t("expenseReportTemplate.addNew")}>
                        <PlusButton onClickHandler={() => history.push("/reports/templates/add")} customClass={classes.customClass}>
                            <PlusIconSVG />
                        </PlusButton>
                    </Tooltip>
                </Can>
            </div>
        </div>
    );
};

export default TopComponent;
