import React, {useEffect, useState} from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import {connect} from "react-redux";
import {setMessage, handleErrorMessage} from "../../../../Messages/actions";
import {compose} from "redux";
import {useTranslation} from "react-i18next";
import CardTransactionsTable from "./CardTransactionsTable";
import ErrorBoundary from "../../../../ErrorBoundary/ErrorBoundary";
import Settings from "./Settings";
import CardTabs from "./CardTabs";

const CardView = (props) => {
    const {
        match: {url, path},
        location,
        underBarRef,
        drawerOuterRef
    } = props;

    const {t} = useTranslation();
    const [backPath, setBackPath] = useState(null);
    const [shortInfo, setShortInfo] = useState({});

    useEffect(() => {
        if(location.state && location.state.backPath){
            setBackPath(location.state.backPath);
        }
        return () => {
            setBackPath(null);
        }
    },[]);

    return (
        <>

            <Switch>
                <Route
                    exact
                    path={path}
                    render={(props) => <CardTransactionsTable {...props} underBarRef={underBarRef} drawerOuterRef={drawerOuterRef} backPath={backPath} setShortInfo={setShortInfo} cardTabs={<CardTabs url={url} />} shortInfo={shortInfo} />}
                />
                <Route exact path={`${path}/settings`} render={(props) => (
                    <ErrorBoundary>
                        <Settings {...props} underBarRef={underBarRef} backPath={backPath} setShortInfo={setShortInfo} cardTabs={<CardTabs url={url} />} />
                    </ErrorBoundary>
                )}/>
                <Route render={() => <div>{t("pageNotFound")}</div>}/>
            </Switch>
        </>
    )
}

export default compose(
    connect(null, {setMessage, handleErrorMessage}),
    withRouter
)(CardView)
