import React, {useEffect, useState, memo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CompanyAddress from './CompanyAddress/CompanyAddress';
import CompanyDetails from './CompanyDetails';
import AccountDetails from './AccountDetails';

import SubmitStep from './SubmitStep';

import useForm2 from "../../../../../hooks/useForm2";
import TopComponent from "./TopComponent";
import Api from "../../../../../services/api";
import {useTranslation} from "react-i18next";
import {compose} from "redux";
import {connect} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";

import AdditionalInformation from "./AdditionalInformation";
import stringToMoney from "../../../../../helpers/stringToMoney";
import OfficersAndShareholders from "./OfficersAndShareholders";
import {Prompt, withRouter} from "react-router-dom";
import UserSectionWrapper from "../../../../UserSectionWrapper";
import Box from "@material-ui/core/Box";
import FinishingStep from "./FinishingStep";
import getEmailRegex from "../../../../../helpers/getEmailRegex";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";
import {updateUserCompany} from "../../../../User/actions";
import * as loader from "../../../../../assets/animations/loader";
import CircularProgress from '@material-ui/core/CircularProgress'
import SendingAnimationWrapper from "../../../../SendingAnimationWrapper";
import Portal from "@material-ui/core/Portal";

const service = new Api();

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        fontWeight:400,
        paddingLeft:"10px",
        paddingRight:"10px"
    },
    button:{
        marginRight:"15px"
    },
    stepContentWrapper:{
        [theme.breakpoints.up('md')]: {
            "& .MuiGrid-container > .MuiGrid-item > .MuiFormControl-root":{
                paddingRight:"20px"
            }
        }
    },
}));

const KnowYourBusiness = (props) => {
    const {handleErrorMessage, decimal, dateFormat, setMessage, updateUserCompany, underBarRef} = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const [isInitialized, setIsIsInitialized] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [validSteps, setValidSteps] = useState({});
    const [stepFields, setStepFields] = useState({}); // detect which field belongs to which step
    const [loading, setLoading] = useState(false);
    const [typeOfAction, setTypeOfAction] = useState(null);
    const [fundingBankAccountsIndexes, setFundingBankAccountsIndexes] = useState([]);
    const [officersAndShareholdersIndexes, setOfficersAndShareholdersIndexes] = useState([]);
    const [kybStatus, setKybStatus] = useState(null); // detect if can show component with pdf
    const [isDataSending, setIsDataSending] = useState(false);
    const [isMount, setIsMount] = useState(false);

    // data for selects/radios
    const [types, setTypes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [titles, setTitles] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [numberOfCardsRequired, setNumberOfCardsRequired] = useState([]);
    const [anticipatedPercentOfInternationalTransactions, setAnticipatedPercentOfInternationalTransactions] = useState([]);
    const [averageTransactionSizes, setAverageTransactionSizes] = useState([]);
    const [annualTransactionVolumes, setAnnualTransactionVolumes] = useState([]);
    const [personTypes, setPersonTypes] = useState([]);
    const [proofOfIdentityCategories, setProofOfIdentityCategories] = useState([]);
    const [proofOfAddressCategories, setProofOfAddressCategories] = useState([]);

    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();

    const fetchDataForCompanyAddress = async () => {
        setLoading(true);
        try {
            const countriesPromise = service.getServiceData({dataFor:"countries"});
            const typesPromise = service.getServiceData({dataFor:"typesOfCompany"});

            const responses = await Promise.all([
                countriesPromise,
                typesPromise,
            ]);

            const [
                countries,
                types
            ] = responses;


            const mappedCountries = countries.data.map((country) => ({
                label: country.name,
                value: country["country-code"]
            }));
            setCountries(mappedCountries);

            const mappedTypes = types.data.map(({id, value}) => ({label:value, value:id}));
            setTypes(mappedTypes);
            setLoading(false);

        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
            setLoading(false);
        }
    }


    const fetchDataForCompanyDetails = async () => {
        setLoading(true);

        try {
            const industriesPromise = service.getServiceData({dataFor:"industries"});
            const titlesPromise = service.getServiceData({dataFor: "userPrefixes"});
            const responses = await Promise.all([
                industriesPromise,
                titlesPromise
            ]);
            const [
                industries,
                titles
            ] = responses;

            const mappedIndustries  = industries.data.map(({label}) => ({label, value:label}));

            setTitles(titles.data);
            setIndustries(mappedIndustries);
            setLoading(false);
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
            setLoading(false);
        }
    }

    const fetchDataForAccountDetails = async () => {
        setLoading(true);

        try {

            const currenciesPromise = service.getServiceData({dataFor: "currencies-fba"});
            const responses = await Promise.all([
                currenciesPromise
            ]);

            const [
                currencies,
            ] = responses;

            const mappedCurrencies = currencies.data.map(({code, name}) => ({label: name, value: code}));
            setCurrencies(mappedCurrencies);
            setLoading(false);
        } catch (e) {
            console.log(e);
            handleErrorMessage(e);
            setLoading(false);
        }
    }

    const fetchDataForAdditionalInformation = async () => {
        setLoading(true);

        try {
            const numberOfCardsRequiredPromise = service.getServiceData({dataFor:"numberOfCardsRequired"});
            const anticipatedPercentOfInternationalTransactionsPromise = service.getServiceData({dataFor:"anticipatedPercentOfInternationalTransactions"});
            const averageTransactionSizesPromise = service.getServiceData({dataFor:"averageTransactionSizes"});
            const annualTransactionVolumesPromise = service.getServiceData({dataFor:"annualTransactionVolumes"});

            const responses = await Promise.all([
                numberOfCardsRequiredPromise,
                anticipatedPercentOfInternationalTransactionsPromise,
                averageTransactionSizesPromise,
                annualTransactionVolumesPromise
            ]);

            const [
                numberOfCardsRequiredResult,
                anticipatedPercentOfInternationalTransactionsResult,
                averageTransactionSizesResult,
                annualTransactionVolumesResult
            ] = responses;

            const mappedAnticipatedPercentOfInternationalTransactions = anticipatedPercentOfInternationalTransactionsResult.data.map(({value, label}) => ({label:t(label), value}));
            setAnticipatedPercentOfInternationalTransactions(mappedAnticipatedPercentOfInternationalTransactions);

            const mappedNumberOfCardsRequired = numberOfCardsRequiredResult.data.map(({value, label}) => ({label:t(label), value}));
            setNumberOfCardsRequired(mappedNumberOfCardsRequired);

            const mappedAverageTransactionSizes = averageTransactionSizesResult.data.map(({value, label}) => ({label:stringToMoney(t(label), decimal), value}));
            setAverageTransactionSizes(mappedAverageTransactionSizes);

            const mappedAnnualTransactionVolumesResult = annualTransactionVolumesResult.data.map(({value, label}) => ({label:t(label), value}));
            setAnnualTransactionVolumes(mappedAnnualTransactionVolumesResult);


            setLoading(false);
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
            setLoading(false);
        }

    }


    const fetchDataForOfficersAndShareholders = async () => {
        setLoading(true);

       try {

           const personTypesPromise = service.getServiceData({dataFor:"personTypes"});
           const titlesPromise = service.getServiceData({dataFor: "userPrefixes"});
           const proofOfIdentityCategoriesPromise = service.getServiceData({dataFor: "proofOfIdentityCategories"});
           const proofOfAddressCategoriesPromise = service.getServiceData({dataFor: "proofOfAddressCategories"});

           const responses = await Promise.all([
               personTypesPromise,
               titlesPromise,
               proofOfIdentityCategoriesPromise,
               proofOfAddressCategoriesPromise
           ]);

            const [
                personTypesResult,
                titlesResult,
                proofOfIdentityCategoriesResult,
                proofOfAddressCategoriesResult
            ] = responses;

           const mappedPersonTypes = personTypesResult.data.map(({value}) => ({label:value, value:value}));
           setPersonTypes(mappedPersonTypes);
           setTitles(titlesResult.data);

           setProofOfIdentityCategories(proofOfIdentityCategoriesResult.data);
           setProofOfAddressCategories(proofOfAddressCategoriesResult.data);

           setLoading(false);
       }catch (e) {
           console.log(e);
           handleErrorMessage(e);
           setLoading(false);
       }
    }





    const validateCompanyAddress = (values) => {
        const errors = {};
        if(!values["name"]){
            errors["name"] =  t("errors.required", {field:"$t(formFields.companyName)"});
        }
        if(!values["type"]){
            errors["type"] = t("errors.required", {field:"$t(formFields.type)"});
        }
        if(!values["addressNumber"]){
            errors["addressNumber"] =  t("errors.required", {field:"$t(formFields.addressNumber)"});
        }
        if(!values["addressStreet"]){
            errors["addressStreet"] =  t("errors.required", {field:"$t(formFields.addressStreet)"});
        }
        if(!values["postCode"]){
            errors["postCode"] = t("errors.required", {field:"$t(formFields.postCode)"});
        }
        if(!values["city"]){
            errors["city"] = t("errors.required", {field:"$t(formFields.city)"});
        }
        if(!values["country"]){
            errors["country"] =  t("errors.required", {field:"$t(formFields.country)"});
        }
        if(!values["numberOfYearsInThisLocation"]){
            errors["numberOfYearsInThisLocation"] = t("errors.required", {field:"$t(formFields.numberOfYearsInThisLocation)"});
        }

        return errors
    }

    const validateCompanyDetails = (values) => {
        const emailRegex = getEmailRegex();
        const errors = {};
        if (!values["companyRegistrationNo"]) {
            errors["companyRegistrationNo"] = t("errors.required", {field:"$t(formFields.companyRegistrationNo)"});
        }
        if (!values["dateBusinessEstablished"]) {
            errors["dateBusinessEstablished"] =  t("errors.required", {field:"$t(formFields.dateBusinessEstablished)"});
        }
        if (!values["industry"]) {
            errors["industry"] =  t("errors.required", {field:"$t(formFields.industry)"});
        }
        if (!values["primaryContactTitle"]) {
            errors["primaryContactTitle"] =  t("errors.required", {field:"$t(formFields.industry)"});
        }
        if (!values["primaryContactFirstName"]) {
            errors["primaryContactFirstName"] =  t("errors.required", {field:"$t(formFields.industry)"});
        }
        if (!values["primaryContactLastName"]) {
            errors["primaryContactLastName"] =  t("errors.required", {field:"$t(formFields.industry)"});
        }
        if (!emailRegex.test(values["primaryContactEmail"])) {
            errors["primaryContactEmail"] =  t("errors.notValid", {field:"$t(formFields.primaryContactEmail)"});
        }
        if (!values["primaryContactPhone"]) {
            errors["primaryContactPhone"] =  t("errors.required", {field:"$t(formFields.primaryContactPhone)"});
        }
        if (!isPublicType() && !values["proofOfCompanyRegistration"]) {
            errors["proofOfCompanyRegistration"] =  t("errors.required", {field:"$t(formFields.proofOfCompanyRegistration)"});
        }

        return errors
    }


    const validateAccountDetails = (values) => {

        if(fundingBankAccountsIndexes.length > 0){

            return fundingBankAccountsIndexes.reduce((errors, errorIdx) => {
                if (!values[`fundingBankAccounts[${errorIdx}][bankName]`]) {
                    errors[`fundingBankAccounts[${errorIdx}][bankName]`] = t("errors.required", {field:"$t(formFields.bankName)"});
                }
                if (!values[`fundingBankAccounts[${errorIdx}][accountName]`]) {
                    errors[`fundingBankAccounts[${errorIdx}][accountName]`] = t("errors.required", {field:"$t(formFields.accountName)"});
                }
                if (!values[`fundingBankAccounts[${errorIdx}][accountNo]`]) {
                    errors[`fundingBankAccounts[${errorIdx}][accountNo]`] = t("errors.required", {field:"$t(formFields.accountNo)"});
                }
                if (!values[`fundingBankAccounts[${errorIdx}][currency]`]) {
                    errors[`fundingBankAccounts[${errorIdx}][currency]`] = t("errors.required", {field:"$t(formFields.currency)"});
                }
                if (!values[`fundingBankAccounts[${errorIdx}][proofOfAccount]`]) {
                    errors[`fundingBankAccounts[${errorIdx}][proofOfAccount]`] = t("errors.required", {field:"$t(formFields.proofOfAccount)"});
                }
                return errors;
            }, {});

        }else{
            return {fundingBankAccounts: t("errors.required")}
        }

    }

    const validateAdditionalInformation = () => {

        const errors = {};
        if(!values["numberOfCardsRequired"]){
            errors["numberOfCardsRequired"] = t("errors.required", {field:"$t(formFields.numberOfCardsRequired)"});
        }
        if(!values["anticipatedPercentOfInternationalTransactions"]){
            errors["anticipatedPercentOfInternationalTransactions"] = t("errors.required", {field:"$t(formFields.anticipatedPercentOfInternationalTransactions)"});
        }
        if(!values["averageTransactionSize"]){
            errors["averageTransactionSize"] = t("errors.required", {field:"$t(formFields.averageTransactionSize)"});
        }
        if(!values["annualTransactionVolume"]){
            errors["annualTransactionVolume"] = t("errors.required", {field:"$t(formFields.annualTransactionVolume)"});
        }
        if(!values["annualProposedUseOfCards"]){
            errors["annualProposedUseOfCards"] = t("errors.required", {field:"$t(formFields.annualProposedUseOfCards)"});
        }
        return errors
    }



    const validateOfficersAndShareholders = (values) => {
        if(isPublicType()) return {} // skip step

        if(officersAndShareholdersIndexes.length > 0){

            return officersAndShareholdersIndexes.reduce((errors, errorIdx) => {

                if (!values[`officersAndShareholders[${errorIdx}][personType]`]) {
                    errors[`officersAndShareholders[${errorIdx}][personType]`] = t("errors.required", {field:"$t(formFields.personType)"});
                }

                if(!values[`officersAndShareholders[${errorIdx}][user]`]){
                    if (!values[`officersAndShareholders[${errorIdx}][firstName]`]) {
                        errors[`officersAndShareholders[${errorIdx}][firstName]`] = t("errors.required", {field:"$t(formFields.firstName)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][lastName]`]) {
                        errors[`officersAndShareholders[${errorIdx}][lastName]`] = t("errors.required", {field:"$t(formFields.lastName)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][proofOfIdentity]`]) {
                        errors[`officersAndShareholders[${errorIdx}][proofOfIdentity]`] = t("errors.required", {field:"$t(formFields.proofOfIdentity)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][proofOfAddress]`]) {
                        errors[`officersAndShareholders[${errorIdx}][proofOfAddress]`] = t("errors.required", {field:"$t(formFields.proofOfAddress)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][proofOfAddressCategory]`]) {
                        errors[`officersAndShareholders[${errorIdx}][proofOfAddressCategory]`] = t("errors.required", {field:"$t(formFields.proofOfAddressCategory)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][proofOfIdentityCategory]`]) {
                        errors[`officersAndShareholders[${errorIdx}][proofOfIdentityCategory]`] = t("errors.required", {field:"$t(formFields.proofOfIdentityCategory)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][country]`]) {
                        errors[`officersAndShareholders[${errorIdx}][country]`] = t("errors.required", {field:"$t(formFields.country)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][addrl1]`]) {
                        errors[`officersAndShareholders[${errorIdx}][addrl1]`] = t("errors.required", {field:"$t(formFields.addrl1)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][city]`]) {
                        errors[`officersAndShareholders[${errorIdx}][city]`] = t("errors.required", {field:"$t(formFields.city)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][idNo]`]) {
                        errors[`officersAndShareholders[${errorIdx}][idNo]`] = t("errors.required", {field:"$t(formFields.idNo)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][dob]`]) {
                        errors[`officersAndShareholders[${errorIdx}][dob]`] = t("errors.required", {field:"$t(formFields.dob)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][nationality]`]) {
                        errors[`officersAndShareholders[${errorIdx}][nationality]`] = t("errors.required", {field:"$t(formFields.nationality)"});
                    }
                    if (!values[`officersAndShareholders[${errorIdx}][postalCode]`]) {
                        errors[`officersAndShareholders[${errorIdx}][postalCode]`] = t("errors.required", {field:"$t(formFields.postalCode)"});
                    }
                }

                return errors;
            }, {});

        }else{
            return {officersAndShareholders: t("errors.required")}
        }

    }


    const isPublicType = () => {
        return ["6", "7"].includes(values["type"])
    }

    const skipIfNeedsOfficersAndShareholders = () => {
        if(isPublicType()){
            handleNext()
        }
    }


    const validateCompleted = (values) => {
        const errors = {};
        if (!values["accepted"]) {
            errors["accepted"] = t("errors.required");
        }

        return errors
    }

    const steps = [
        {
            Component: CompanyAddress,
            props: {types, countries, loading, fetchData: fetchDataForCompanyAddress},
            label: t("kybStepLabelCompanyAddress"),
            name: "companyAddress",
            validation: validateCompanyAddress,
        },
        {
            Component: CompanyDetails,
            label: t("kybStepLabelCompanyDetails"),
            props:{loading, industries, titles, fetchData: fetchDataForCompanyDetails},
            name: "companyDetails",
            validation: validateCompanyDetails
        },
        {
            Component: AccountDetails,
            props:{loading, fundingBankAccountsIndexes, currencies, setFundingBankAccountsIndexes, fetchData: fetchDataForAccountDetails},
            label: t("kybStepLabelAccountDetails"),
            name: "accountDetails",
            validation: validateAccountDetails
        },
        {
            Component: AdditionalInformation,
            props:{loading, numberOfCardsRequired, anticipatedPercentOfInternationalTransactions, averageTransactionSizes, annualTransactionVolumes, fetchData: fetchDataForAdditionalInformation},
            label: t("kybStepLabelAdditionalInformation"),
            name: "additionalInformation",
            validation: validateAdditionalInformation
        },
        {
            Component: OfficersAndShareholders,
            props:{
                loading,
                countries,
                dateFormat,
                titles,
                proofOfIdentityCategories,
                proofOfAddressCategories,
                officersAndShareholdersIndexes,
                personTypes,
                setOfficersAndShareholdersIndexes,
                skipIfNeedsOfficersAndShareholders,
                fetchData: fetchDataForOfficersAndShareholders,
                setMessage
            },
            label: t("kybStepLabelOfficersAndShareholders"),
            name: "officersAndShareholders",
            validation: validateOfficersAndShareholders
        },
        {
            Component: SubmitStep,
            label: t("kybStepLabelSubmitStep"),
            props:{},
            name: "submit",
            validation: validateCompleted
        },
    ];


    const { handleChange, handleSubmit, values, errors, fillFormValues, setServerErrors } = useForm2(
        submit,
        validate
    );


    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }


    function getStepContent(step) {
        const {Component, props} = steps[step];
        return <Component handleChange={handleChangeWithDetectChanges} values={values} idx={step} setStepFields={setStepFields} {...props} errors={errors}/>
    }


    function validate(values) {

        if(steps[activeStep].name === "submit"){
            // use all rules for this step
            let errors = {};
            steps.forEach((step, idx) => {
                errors = {...errors, ...steps[idx].validation(values)}
            });
            return errors
        }

        return steps[activeStep].validation ? steps[activeStep].validation(values) : {}
    }

    async function submit() {
        setIsDataSending(true);
        const formValues = new FormData();

        const stepName = steps[activeStep].name;

        Object.keys(values).forEach((field) => {
            formValues.append(field, values[field] === undefined ? "" : values[field])
        });
        if(typeOfAction){
            formValues.append("typeOfAction", typeOfAction)
        }

        try {
            const response = await service.submitKybForm(formValues, stepName !== "submit" ? stepName : undefined);
            updateUserCompany({kyb:response.data.kyb});

            fillFormData();
            setIsDataSending(false);
            setValuesWasChanged(false); // allow to update page or moving to another router without prompt
            setMessage("success", 'success');
        } catch (e) {
            console.log(e)
            setIsDataSending(false);
            setServerErrors(e)
        }
    }

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {

        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    const onSubmit = (e, type) => {
        setTypeOfAction(type);
        handleSubmit(e);
    }


    const onCancel = () => {
        props.history.push(`/settings/company`);
    }


    useEffect(() => {
        steps.forEach((step, idx) => {
            if(Object.keys(step.validation(values)).length > 0){

                // step is not valid
                setValidSteps((vals) => {
                    const newValues = {...vals};
                    delete newValues[idx];
                    return newValues;
                });
                setCompleted((vals) => {
                    const newValues = {...vals};
                    delete newValues[idx];
                    return newValues;
                })
            }else{

                setValidSteps((vals) => {
                    const newValues = {...vals};
                    newValues[idx] = true;
                    return newValues;
                });
                setCompleted((vals) => {
                    const newValues = {...vals};
                    newValues[idx] = true;
                    return newValues;
                })
            }
        });

        if(activeStep !== 0 && !values["addressRegion"]){
            // fill addressRegion automatically from city
            if(values["city"]){
                handleChange("addressRegion", values["city"])
            }
        }

    },[values, activeStep, fundingBankAccountsIndexes, officersAndShareholdersIndexes, kybStatus]);



    const fillFormData = async () => {
        setLoading(true);
        try {
            const company = await service.getCompany(true);
            if(company.data.kyb){
                setKybStatus(company.data.kyb);
                return // do not fill data if company has any kyb status
            }

            const {fundingBankAccounts, officersAndShareholders} = company.data;


            const preFillFields = [
                "name",
                "country",
                "tradingAsOrDBA",
                "type",
                "addressNumber",
                "addressRegion",
                "addressStreet",
                "addrl2",
                "postCode",
                "city",
                "country",
                "numberOfYearsInThisLocation",
                "companyRegistrationNo",
                "businessLicenceNo",
                "companyWebsite",
                "dateBusinessEstablished",
                "industry",
                "primaryContactTitle",
                "primaryContactFirstName",
                "primaryContactMiddleName",
                "primaryContactLastName",
                "primaryContactEmail",
                "primaryContactPhone",
                "proofOfCompanyRegistration",
                "websiteUrl",
                "anticipatedPercentOfInternationalTransactions",
                "numberOfCardsRequired",
                "averageTransactionSize",
                "annualTransactionVolume",
                "annualProposedUseOfCards",
            ];

            const values = {};
            preFillFields.forEach((field) => {
                values[field] = company.data[field];
            });



            if(typeof company.data.owner === "object"){
                const {firstName, email, lastName, title, phone, middleName} = company.data.owner;
                // fill primary data from owner by default
                if(!values["primaryContactTitle"] && title){
                    values["primaryContactTitle"] = title;
                }
                if(!values["primaryContactFirstName"] && firstName){
                    values["primaryContactFirstName"] = firstName;
                }
                if(!values["primaryContactLastName"] && lastName){
                    values["primaryContactLastName"] = lastName;
                }
                if(!values["primaryContactEmail"] && email){
                    values["primaryContactEmail"] = email;
                }
                if(!values["primaryContactMiddleName"] && middleName){
                    values["primaryContactMiddleName"] = middleName;
                }
                if(!values["primaryContactPhone"] && phone){
                    values["primaryContactPhone"] = phone;
                }
            }


            if(fundingBankAccounts && fundingBankAccounts.length > 0){

                setFundingBankAccountsIndexes(Object.keys(fundingBankAccounts).map((idx) => parseInt(idx))); // fill indexes for rows
                fundingBankAccounts.forEach((item, key) => {
                    const {bankName, accountName, accountNo, currency, proofOfAccount, _id} = item;

                    if(bankName) values[`fundingBankAccounts[${key}][bankName]`] = bankName;
                    if(accountName) values[`fundingBankAccounts[${key}][accountName]`] = accountName;
                    if(accountNo) values[`fundingBankAccounts[${key}][accountNo]`] = accountNo;
                    if(currency)  values[`fundingBankAccounts[${key}][currency]`] = currency;
                    if(proofOfAccount) values[`fundingBankAccounts[${key}][proofOfAccount]`] = proofOfAccount;
                    if(_id) values[`fundingBankAccounts[${key}][_id]`] = _id;

                })
            }

            if(officersAndShareholders && officersAndShareholders.length > 0){

                setOfficersAndShareholdersIndexes(Object.keys(officersAndShareholders).map((idx) => parseInt(idx))); // fill indexes for rows
                officersAndShareholders.forEach((item, key) => {
                    const {
                        personType,
                        firstName,
                        lastName,
                        country,
                        addrl1,
                        addrl2,
                        city,
                        idNo,
                        dob,
                        nationality,
                        proofOfAddress,
                        proofOfIdentity,
                        proofOfIdentityCategory,
                        proofOfAddressCategory,
                        _id,
                        postalCode,
                        user,
                        title,
                    } = item;


                    if(personType) values[`officersAndShareholders[${key}][personType]`] = personType;
                    if(firstName) values[`officersAndShareholders[${key}][firstName]`] = firstName;
                    if(lastName) values[`officersAndShareholders[${key}][lastName]`] = lastName;
                    if(country) values[`officersAndShareholders[${key}][country]`] = country;
                    if(addrl1) values[`officersAndShareholders[${key}][addrl1]`] = addrl1;
                    if(addrl2) values[`officersAndShareholders[${key}][addrl2]`] = addrl2;
                    if(city) values[`officersAndShareholders[${key}][city]`] = city;
                    if(idNo) values[`officersAndShareholders[${key}][idNo]`] = idNo;
                    if(dob) values[`officersAndShareholders[${key}][dob]`] = dob;
                    if(nationality) values[`officersAndShareholders[${key}][nationality]`] = nationality;
                    if(proofOfAddress) values[`officersAndShareholders[${key}][proofOfAddress]`] = proofOfAddress;
                    if(proofOfIdentity) values[`officersAndShareholders[${key}][proofOfIdentity]`] = proofOfIdentity;
                    if(proofOfAddressCategory) values[`officersAndShareholders[${key}][proofOfAddressCategory]`] = proofOfAddressCategory;
                    if(proofOfIdentityCategory) values[`officersAndShareholders[${key}][proofOfIdentityCategory]`] = proofOfIdentityCategory;

                    if(_id) values[`officersAndShareholders[${key}][_id]`] = _id;

                    if(postalCode) values[`officersAndShareholders[${key}][postalCode]`] = postalCode;
                    if(title) values[`officersAndShareholders[${key}][title]`] = title;

                    if(user){
                        const {email, firstName, id, lastName, title } = user;
                        if(email) values[`officersAndShareholders[${key}][email]`] = email;
                        if(firstName) values[`officersAndShareholders[${key}][firstName]`] = firstName;
                        if(lastName) values[`officersAndShareholders[${key}][lastName]`] = lastName;
                        if(title) values[`officersAndShareholders[${key}][title]`] = title;
                        if(id) values[`officersAndShareholders[${key}][user]`] = id;
                    }


                })
            }

            fillFormValues(values);
            setLoading(false);
            setIsIsInitialized(true);

        }catch (e) {
            console.log(e)
            setLoading(false);
        }
    }


    useEffect(() => {
        fillFormData();
        setIsMount(true);
    },[]);

    useEffect(() => {
        if(isInitialized){
            //set completed steps
            steps.forEach((step, idx) => {
                if(!Object.keys(step.validation(values)).length){
                    const newCompleted = completed;
                    newCompleted[idx] = true;
                    setCompleted(newCompleted);
                }
            });

            // set first not valid step
            for (let i=0; i <steps.length; i++){
                setTimeout(() => {
                    setActiveStep(i); // we need to open each step to get field names inside
                }, 0);

                if(Object.keys(steps[i].validation(values)).length){
                    break; // stop on first not valid step
                }
            }
        }

    },[isInitialized])

    useEffect(() => {
        if(kybStatus === "pending"){

            // mark all steps as completed
            steps.forEach((step, idx) => {
                const newCompleted = completed;
                newCompleted[idx] = true;
                setCompleted(newCompleted);
            });

        }
    },[kybStatus]);


    return (
        <>
            <Prompt
                when={valuesWasChanged}
                message={t("unsavedChanges")}
            />
            {isMount && (
                <Portal container={underBarRef.current}>
                    <TopComponent stepFields={stepFields} steps={steps} activeStep={activeStep} errors={errors} handleStep={handleStep} completed={completed}/>
                </Portal>
            )}

            <Box paddingLeft={'24px'} paddilgRight={'24px'} position={"relative"} minHeight={"250px"}>
                {kybStatus === "pending" && <FinishingStep handleErrorMessage={handleErrorMessage}/>}
                {kybStatus && (kybStatus !== "pending") && <div>{t("company.kybStatusIs", {status:kybStatus})}</div>}
                {!kybStatus && (
                    <>
                        <div className={classes.stepContentWrapper}>
                            {getStepContent(activeStep)}
                        </div>

                        {steps[activeStep].name === "submit" ? (
                            <>
                                <Button
                                    variant="contained"
                                    color={"primary"}
                                    onClick={(e) => onSubmit(e, "submit")}
                                    className={classes.button}
                                    disabled={!validSteps[activeStep] || isDataSending}
                                >
                                    {t("formFields.submit")}
                                </Button>
                                <Button
                                    onClick={(e) => onSubmit(e, "save")}
                                    variant="contained"
                                    color={"secondary"}
                                    disabled={isDataSending}
                                    className={classes.button}
                                >
                                    {t("formFields.save")}

                                </Button>
                            </>
                        ) : (
                            <Button
                                variant="contained"
                                color={"primary"}
                                onClick={handleNext}
                                disabled={!validSteps[activeStep] || loading}
                                className={classes.button}
                            >
                                {t("formFields.continue")}
                            </Button>
                        )}
                        <Button
                            color={"secondary"}
                            onClick={onCancel}
                            className={classes.cancelButton}
                        >
                            {t("formFields.cancel")}
                        </Button>
                        {isDataSending && (
                            <SendingAnimationWrapper>
                                {/* <CircularProgress /> */}
                                <CircularProgress />
                            </SendingAnimationWrapper>
                        )}
                    </>
                )}

            </Box>
        </>
    );
}

const mapStateToProps = (state) => {
    const {user} = state;
    const {regional={}} = user.settings;
    return {
        decimal:regional.decimal,
        dateFormat:regional.dateFormat
    }
}


export default compose(
    memo,
    withRouter,
    connect(mapStateToProps, {handleErrorMessage, setMessage, updateUserCompany})
)(KnowYourBusiness)
