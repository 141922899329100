import React from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputFieldMemo from "../../../../../../../formElements/InputFieldMemo";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import RadioFields from "../../../../../../../formElements/RadioFields";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";
import * as loader from "../../../../../../../../assets/animations/loader";
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
    formControl: {
        [theme.breakpoints.up('md')]: {
            paddingRight: "20px"
        }
    },
    form:{
        position:"relative"
    },
    sendingAnimationWrapper:{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(255, 255, 255, 0.5)",
        zIndex:1
    }
}));

const ForwardForSignatureForm = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const {handleChange, handleSubmit, values, errors, loading, titles, isSubmitting} = props;
    return (
        <>
            <form action="" onSubmit={handleSubmit} className={classes.form}>
                {isSubmitting && (
                    <Box className={classes.sendingAnimationWrapper}>
                        <CircularProgress />
                    </Box>
                )}
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!errors["title"]} className={classes.formControl}>
                            <RadioFields
                                options={titles}
                                name={"title"}
                                label={t("formFields.title")}
                                value={values["title"]}
                                onRadioChange={(val) => handleChange("title", val)}
                                error={errors["title"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["firstName"]} fullWidth className={classes.formControl}>
                            <InputFieldMemo
                                label={t("formFields.firstName")}
                                placeholder={t("formFields.firstName")}
                                name="firstName"
                                type="text"
                                value={values["firstName"]}
                                onInputChange={handleChange}
                                error={errors["firstName"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["lastName"]} fullWidth className={classes.formControl}>
                            <InputFieldMemo
                                label={t("formFields.lastName")}
                                placeholder={t("formFields.lastName")}
                                name="lastName"
                                type="text"
                                value={values["lastName"]}
                                onInputChange={handleChange}
                                error={errors["lastName"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["email"]} fullWidth className={classes.formControl}>
                            <InputFieldMemo
                                label={t("formFields.email")}
                                placeholder={t("formFields.email")}
                                name="email"
                                type="text"
                                value={values["email"]}
                                onInputChange={handleChange}
                                error={errors["email"]}
                                isLoading={loading}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Box mr={2} component={"span"}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading || isSubmitting}
                            >
                                {t("formFields.forwardForSignature")}
                            </Button>
                        </Box>

                    </Grid>
                </Grid>
            </form>

        </>
    );
};

export default ForwardForSignatureForm;
