import React, {useEffect, useState} from 'react'
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";

import {withRouter} from "react-router-dom";
import {tabChange} from "./actions";

const useStyles = makeStyles(theme => ({
	tab:{
        padding: '0',
        minWidth: 'initial',
        minHeight: 'initial',
        [theme.breakpoints.down('1023')]: {
            flex: '0 0 50%',
            maxWidth: '50%',
            width: "50%"
        },
        "& .MuiTab-wrapper": {
            padding: '8px 16px',
            borderRadius: '8px',
            textAlign: 'left',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--battelship-grey)',
            transition: 'all .2s',
            minWidth: 'initial',
            textTransform: 'capitalize',
            zIndex: 9,
        },
        "& .MuiTouchRipple-root > *": {
            display: "none"
        },
        "&.Mui-selected .MuiTab-wrapper": {
            color: 'var(--dark-secondary)',
        },
		// textTransform:"capitalize",
		// minWidth:"auto",
		// color:"#637280",
		// marginRight:"25px",
		// [theme.breakpoints.up('md')]: {
		// 	padding: "22px 12px",
		// 	fontSize:"15px",
		// 	letterSpacing: "0.1px",
		// },
		// '&.Mui-selected':{
		// 	color:"#24dac4"
		// }
	},
	tabs:{
        minHeight: "24px",
        [theme.breakpoints.down('1023')]:{
            order: '10',
            width: "100%",
            marginTop: '24px'
        },
		// minHeight: "70px",
		'& .MuiTabs-indicator':{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            '& > span': {
                width: '100%',
                backgroundColor: '#24dac4',
                marginLeft:'12px',
                marginRight:'12px',
            },
        },
        "& .MuiTabs-scroller > span:last-child": {
            height: "100%",
            "& span": {
                marginLeft: '0',
                marginRight: '0',
                backgroundColor: 'var(--very-light-pink-secondary)',
                borderRadius: '8px'
            }
        }
    },
}));

const TopTabs = (props) => {
    const {location, tabs, tabChange} = props;

    const displayOnPaths = [
        "/dashboard",
        "/expenses",
        "/expenses/todo",
        "/expenses/all",
        "/transactions",
        "/settings",
        "/settings/company",
        "/users",
        "/accounts",
        "/cards",
        "/cards/physical",
        "/cards/virtual",
        "/reports",
        "/settings",
    ];



    const currentPage = location.pathname.split("/")[1];
    const currentTabs = tabs[currentPage];

    const classes = useStyles();

    const canShowTabs = () => {
        return displayOnPaths.includes(location.pathname) && currentTabs
    }

    return (
        <>
            {canShowTabs() ? (
                <Tabs
                    value={currentTabs["active"]}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="off"
                    className={classes.tabs}
                    onChange={(e, val) => tabChange(currentPage, val)}
                    TabIndicatorProps={{ children: <span /> }}
                >
                    {currentTabs["tabs"].map((tab) => {
                        return <Tab label={tab} key={tab} value={tab} className={classes.tab}/>
                    })}

                </Tabs>
            ) : null}

        </>
    )
}

const mapStateToProps = ({user, tabs}) => {
    const {roles} = user;

    return {
        roles,
        tabs
    }
}
export default connect(
	mapStateToProps, {tabChange}
)(withRouter(TopTabs))
