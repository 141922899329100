import React, {useEffect, useState} from 'react';
import DashboardPane from "../../../../DashboardPane";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Api from "../../../../../services/api";
import {compose} from "redux";
import {connect} from "react-redux";
import {handleErrorMessage} from "../../../../Messages/actions";
import CircularProgress from '@material-ui/core/CircularProgress'
import * as animationData from "../../../../../assets/animations/list";
import FormatMoney from "../../../../FormatMoney/FormatMoney";
import FormatDate from "../../../../FormatDate";
import {makeStyles} from "@material-ui/core";
import getTimeAgo from "../../../../../helpers/getTimeAgo";
import Pagination from "../../../../Pagination";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import useActiveTab from "../../../../../hooks/useActiveTab";
import checkClick from "../../../../../helpers/checkClick";
import {withRouter} from "react-router-dom";
import formatMoney from "../../../../../helpers/formatMoney";
import DashboardPaneTopInfo from "../../../../DashboardPane/DashboardPaneTopInfo";

const service = new Api();

const useStyles = makeStyles(theme => ({
    secondLine: {
        fontSize: 12,
        lineHeight:1
    },
    merchant:{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "200px",
        overflow: "hidden",
    }

}));


const WaitingForApproval = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [info, setInfo] = useState({});


    const {handleErrorMessage, dateFormat, dashboardTableMinHeight, history, decimal} = props;
    const activeTab = useActiveTab('dashboard');


    const fetchWaitingForApprovalExpenses = async (queryData) =>  {
        try {
            setIsLoading(true);
            const response = await service.getDashboardWaitingForApproval(queryData);

            const {result, info} = response.data;
            setExpenses(result);
            setInfo(info);
        } catch (e) {
            console.log(e);
            handleErrorMessage(e);
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        if(activeTab){
            fetchWaitingForApprovalExpenses({fromTab: activeTab})
        }
    },[activeTab]);

    const handleChangePage = async (event, newPage) => {
         await fetchWaitingForApprovalExpenses({page:newPage, fromTab: activeTab});
    }

    const columns = [
        {
            title: t('thead.expense'),
            render: (row) => {
                const {merchant, updatedAt} = row;
                return (
                    <>
                        <Box className={classes.merchant}>{merchant}</Box>
                        <Box className={classes.secondLine}>
                            {t("expense.submitted")} {getTimeAgo(new Date(updatedAt))}
                        </Box>
                    </>
                )
            }
        },
        {
            title: t('thead.date'),
            field: 'date',
            render: ({date}) => <FormatDate dateFormat={dateFormat}>{date}</FormatDate>
        },
        {
            title: t('thead.amount'),
            align:'right',
            render: (row) => {
                const {reportedCurrency, calculatedAmount, currency, amount} = row;
                return (
                    <>
                        <Box>{reportedCurrency} <FormatMoney>{calculatedAmount}</FormatMoney></Box>
                        {(reportedCurrency !== currency) && (
                            <Box className={classes.secondLine}>
                                {currency} <FormatMoney>{amount}</FormatMoney>
                            </Box>
                        )}
                    </>
                )
            }
        },
    ]
    const rowHandlerClick = (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push({ pathname:`/expenses/${rowData._id}`});
        }
    }

    const value = `${info.currency ? info.currency : ""} ${info.totalAmount !== undefined ? formatMoney(info.totalAmount, decimal) : ""} `;
    return (
        <DashboardPane
            title={t("expense.waitingForApproval")}
            action={<DashboardPaneTopInfo value={value} />}
        >
            <VolveMaterialTable
                columns={columns}
                isLoading={isLoading}
                data={expenses}
                options={{
                    header: false,
                    minBodyHeight: dashboardTableMinHeight,
                }}
                onRowClick={rowHandlerClick}
                EmptyComponent={() => <CircularProgress />}
            />
            <Pagination
                total={info.total}
                pageSize={info.pageSize}
                page={info.page}
                isLoading={isLoading}
                onChangePage={handleChangePage}
            />
        </DashboardPane>
    );
};


const mapStateToProps = ({user}) => {
    return {
        dateFormat:user.settings.regional.dateFormat,
        decimal:user.settings.regional.decimal
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {handleErrorMessage})
)(WaitingForApproval);
