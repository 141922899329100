import React from 'react'
import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";
import ExpenseReportTemplates from "../userSection/content/ExpenseReportTemplates";

const ExpenseReportTemplatesPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef} = props;
    return (
        <>
            <UserSectionWrapper>
                <Can I="read" a="expenseReportTemplate" passThrough>
                    {(can) => (
                        can ? <ExpenseReportTemplates underBarRef={underBarRef}/> : <div>{t("expenseReportTemplate.noPermissionToRead")}</div>
                    )}
                </Can>
            </UserSectionWrapper>
        </>
    )

}

export default ExpenseReportTemplatesPage
