import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import TopComponentInvesthub from '../TopComponentInvesthub';
import {connect} from "react-redux";
import checkClick from "../../../../../helpers/checkClick";
import DialogRejectReason from "../DialogRejectReason";
import Api from "../../../../../services/api";
import {handleErrorMessage} from "../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import Pagination from "../../../../Pagination";
import Portal from "@material-ui/core/Portal";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import {makeStyles} from "@material-ui/core";

const service = new Api();

const useStyle = makeStyles((theme) => ({
    tableReject: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    }
}));


const RejectReasonsTable = (props) => {
    const classes = useStyle();
    const {t} = useTranslation();

    const {handleErrorMessage, underBarRef} = props;


    const [isFetching, setIsFetching] = useState(false);

    const [rejectReasons, setRejectReasons] = useState([]);

    const [rejectReason, setRejectReason] = useState({});

    const [info, setInfo] = useState({
        fields: ["name"],
        page: 0,
        total: 0,
        pageSize: 0
    });
    const [isMount, setIsMount] = useState(false);

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const fetchItems = async (query) => {

        try {
            setIsLoading(true);
            const response = await service.getRejectReasons(query);
            const {result, info} = response.data;
            setInfo(info);
            setRejectReasons((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }

    }


    useEffect(() => {
        setIsMount(true);
        fetchItems();
    },[]);

    const handleChangePage = async (event, newPage) => {
        await fetchItems({page:newPage});
    }

    const rowHandlerClick = async (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            setOpen(true);
            setIsFetching(true);
            try {
                const response = await service.getRejectReason(rowData._id);
                setRejectReason(response.data);
            }catch (e) {
                handleErrorMessage(e);
                setOpen(false);
            }finally {
                setIsFetching(false);
            }
        }
    }


    const onExited = () => {
        setRejectReason({});
    }
    const columns = [
        {
            title: t('thead.name'),
            field: 'name',
        },
    ];


    return (
        <>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>
                    {/* <TopComponent setOpen={setOpen}/> */}
                </Portal>
            )}
            <DialogRejectReason
                open={open}
                setOpen={setOpen}
                loading={isFetching}
                rejectReason={rejectReason}
                onExited={onExited}
                fetchItems={fetchItems}
            />
            <div className={`card ${classes.tableReject}`}>
                <TopComponentInvesthub setOpen={setOpen} />
                <VolveMaterialTable
                    columns={columns}
                    isLoading={isLoading}
                    data={rejectReasons}
                    onRowClick={rowHandlerClick}
                    className={classes.table}
                />
                <Pagination
                    total={info.total}
                    pageSize={info.pageSize}
                    page={info.page}
                    isLoading={isLoading}
                    onChangePage={handleChangePage}
                    loadMore
                />
            </div>
        </>
    );
};

export default compose(
    connect(null, {handleErrorMessage})
)(RejectReasonsTable);
