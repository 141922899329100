import React from 'react';
import Box from "@material-ui/core/Box"
import {makeStyles} from "@material-ui/core";
import {NavLink} from "react-router-dom";

const useStyles = makeStyles(theme => {

    return {
        headNav: {
            display: 'flex',
            marginLeft: 'auto',
            marginTop: '4px',
            [theme.breakpoints.down('767')]: {
                width: '100%',
                margin: '4px 0'
            },

            "& button":{
                marginLeft: '16px'
            }
        },

        headNavLink: {
            padding: '8px 16px',
            borderRadius: '8px',
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--battelship-grey)',
            transition: 'all .2s',
            [theme.breakpoints.down('767')]: {
                flexGrow: '1'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '&:not(:last-child)': {
                marginRight: '8px',
                [theme.breakpoints.down('767')]: {
                    marginRight: '0'
                }
            },
            '&.active': {
                color: 'var(--dark-secondary)',
                backgroundColor: 'var(--very-light-pink-secondary)'
            },
            '&:hover': {
                color: 'var(--dark-secondary)',
            },
        }
    };

});


const TabsContent = (props) => {
    const {routes=[], rightContent} = props;
    const classes = useStyles();

    return (
        <Box className={classes.headNav}>
            {routes.map((item, key) => {
                return (
                    <NavLink key={key} to={item.url} exact className={classes.headNavLink}>{item.name}</NavLink>
                )
            })}

            {rightContent}
        </Box>
    );
};

export default TabsContent;
