import React from 'react';

import RightDrawer from '../../../components/RightDrawer'
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    contentDrawer: {
        marginBottom: 'auto',
        padding: '24px 12px',
        borderTop: '1px solid var(--very-light-pink-secondary)',
    }
}));

const DrawerFilter = (props) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const {onDrawerFilterClose, isOpen, children} = props;

    return (
        <RightDrawer
            isOpen={isOpen}
            onClose={onDrawerFilterClose}
            titleDrawer={t("formFields.filter")}
        >
            <Box className={classes.contentDrawer}>{children}</Box>
        </RightDrawer>
    );
}

export default DrawerFilter
