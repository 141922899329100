import React from 'react';
import Portal from "@material-ui/core/Portal";
import {makeStyles, useTheme, Button, FormControl} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTranslation} from "react-i18next";
import useMounted from "../../../../hooks/useMounted"
import SelectField from "../../../formElements/SelectField"
import InputField from "../../../formElements/InputField"
import UploadField from "../../../formElements/FileUploaderInlineInvesthub/FileUploaderInlineInvesthub"
import InnerNav from "./Navigation/Navigation"

const useStyle = makeStyles((theme) => ({
    makePayment: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '24px',
        borderRadius: '8px',
        background: 'var(--pale-grey)',
        [theme.breakpoints.down('1023')]: {
            display: 'block'
        },
        [theme.breakpoints.down('767')]: {
            padding: '24px 16px'
        }
    },

    makePaymentWrapper: {
        flexGrow: '1',
        paddingLeft: '32px',

        [theme.breakpoints.down('1023')]: {
            paddingLeft: '0'
        }
    },

    makePaymentList: {},

    makePaymentItem: {
        position: 'relative',

        "&:not(:last-child)": {
            marginBottom: '48px',
            paddingBottom: '48px',
            borderBottom: '1px solid var(--very-light-pink-secondary)',

            [theme.breakpoints.down('1023')]: {
                marginBottom: '32px',
                paddingBottom: '32px'
            }
        }
    },

    makePaymentFieldset: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },

    full: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    },

    half: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        }
    },

    fieldSelect:{
        "& .MuiFormLabel-root": {
            lineHeight: '1.71429',
            marginBottom: '0',

            "&.Mui-focused": {
                color: 'var(--charcoal-primary)'
            }
        }
    },

    fieldTextarea: {
        "& .MuiInput-input": {
            height: 'initial',
            paddingTop: '10px',
            paddingBottom: '10px',
        },
        "& .MuiInputBase-root.MuiInput-root": {
            paddingTop: '0'
        },
        "& .MuiFormLabel-root.MuiInputLabel-root": {
            lineHeight: '1.71429',
            marginBottom: '0'
        }
    },

    fieldCurrency: {
        position: 'relative',
        marginTop: '24px',
        [theme.breakpoints.down('767')]: {
            marginTop: '0'
        }
    },
    currencyWrap:{
        position: 'relative',
        width: '100%',
        height: '48px',
        padding: '0 10px',
        borderRadius: '12px',
        border: '2px solid var(--cool-grey)',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight:' 1.6',
        color: 'var(--dark-secondary)',
        transition: 'all .2s',
        paddingLeft: '58px',
        background: 'none',
        // borderColor: ;
    },
    currencySymbol:{
        position: 'absolute',
        top: '0px',
        left: '0px',
        bottom: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '46px',
        paddingTop: '1px',
        borderRadius: '10px 0 0 10px',
        background: 'var(--very-light-pink-primary)',
        color: 'var(--dark-secondary)',
    },
    currencyValue: {
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '46px',
        height: '46px',
    }
}))

const TransactionsMakePayment = (props) => {
    const classes = useStyle();
    const {t} = useTranslation();
    const {underBarRef} = props;
    const isMounted = useMounted();
    const theme = useTheme();
    const onMax1023 = useMediaQuery(theme.breakpoints.up('1023'));

    return (

        <>
            {isMounted && (
                <>
                    <Portal container={underBarRef.current}>
                        <div className="page__title h4 text-weight-200">
                            {/* NEED TRANSLATION */}
                            {t("Make payment")}
                        </div>
                    </Portal>
                </>
            )}

            <div className={`card ${classes.makePayment}`}>
                {onMax1023 && (<InnerNav />)}

                <div className={classes.makePaymentWrapper}>
                    <form className={`${classes.makePaymentForm}`}>
                        <div className={classes.makePaymentList}>
                            <div id="sectionPayingFrom" className={classes.makePaymentItem}>
                                <div className={`title-blue text-weight-300`}>
                                    {/* NEED TRANSLATION */}
                                    {t("Paying From")}
                                </div>

                                <div className={classes.makePaymentFieldset}>

                                    <FormControl fullWidth className={`${classes.full} ${classes.fieldSelect}`}>
                                        {/* NEED TRANSLATION */}
                                        <SelectField
                                            label={t("Select Wallet or Safe")}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div id="sectionPayingTo" className={classes.makePaymentItem}>
                                <div className={`title-blue text-weight-300`}>
                                    {/* NEED TRANSLATION */}
                                    {t("Paying To")}
                                </div>

                                <div className={classes.makePaymentFieldset}>
                                    <FormControl fullWidth className={`${classes.half} ${classes.fieldSelect}`}>
                                        {/* NEED TRANSLATION */}
                                        <SelectField
                                            label={t("Recipients and amount")}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth className={`${classes.half} ${classes.fieldCurrency}`}>
                                        <div className={classes.currencyWrap}>
                                            <div className={classes.currencySymbol}>ETH</div>
                                            <div className={classes.currencyValue}>0.00</div>
                                        </div>
                                    </FormControl>

                                    {/* NEED TRANSLATION */}
                                    <FormControl fullwidth className={classes.full}>
                                        <UploadField
                                            label={t('Import CSV')}
                                            value=""
                                            fieldName={"file"}
                                            error=""
                                            acceptedDocuments={[".csv"]}
                                            buttonLabel={t('Import CSV')}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            <div id="sectionAdditionalDetails" className={classes.makePaymentItem}>
                                <div className={`title-blue text-weight-300`}>
                                    {/* NEED TRANSLATION */}
                                    {t("Additional Details (optional)")}
                                </div>

                                <div className={classes.makePaymentFieldset}>
                                    <FormControl fullWidth className={`${classes.full} ${classes.fieldSelect}`}>
                                        {/* NEED TRANSLATION */}
                                        <SelectField
                                            label={t("Add Account")}
                                        />
                                    </FormControl>

                                    <FormControl fullwidth className={`${classes.full} ${classes.fieldTextarea}`}>
                                        {/* NEED TRANSLATION */}
                                        <InputField
                                            multiline
                                            rows="4"
                                            label={t("Add Notes")}
                                            name={"addNotes"}
                                        />
                                    </FormControl>

                                    <FormControl fullwidth className={classes.full}>
                                        {/* NEED TRANSLATION */}
                                        <UploadField
                                            label={t('Attach File')}
                                            value=""
                                            fieldName={"file"}
                                            error=""
                                            acceptedDocuments={[".csv"]}
                                            buttonLabel={t('Attach File')}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </div>

                        <Button>
                            {/* NEED TRANSLATION */}
                            {t('Save')}
                        </Button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default TransactionsMakePayment
