import React, {useEffect, useState} from 'react';
import Api from "../../../../../../../services/api";
import {handleErrorMessage} from "../../../../../../Messages/actions";
import {useDispatch} from "react-redux";
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
const service = new Api();

const ReportCounter = (props) => {
    const {queryForExport} = props;
    const {xlsxReportType, ext, fileName, markAsReported, notReported, scope, ...restQuery} = queryForExport; // skip some fields


    const restQueryJsonString = JSON.stringify(restQuery); // we need this data as primitive in useEffect dependency
    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const fetchCounter = async () => {
        try{
            setIsLoading(true);
            const {status, ...restQuery} = queryForExport; // skip status. Status is set on backend
            const response = await service.getExpensesExportCount(restQuery);
            const {count} = response.data;
            setCount(count);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        fetchCounter({...restQuery});
    },[restQueryJsonString]);

    return (
        <div>
            {isLoading ? (
                <Skeleton width={"100%"} height={"20px"}/>
            ) : (
                <div>{t("notReportedExpenses", {count:count})}</div>
            )}
        </div>
    );
};

export default ReportCounter;
