import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import useMounted from "../../../../../hooks/useMounted";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import useForm2 from "../../../../../hooks/useForm2";
import Portal from "@material-ui/core/Portal";
import BackButton from "../../../../BackButton";
import {Can} from "../../../../../Context/Can";
import Button from "@material-ui/core/Button";
import CategoryForm from "../../../../CategoryForm";
import getGeneralLedgerAccountRegex from "../../../../../helpers/getGeneralLedgerAccountRegex";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',

        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },
    },
    item: {
        position: 'relative'
    },
}))

const Category = (props) => {
    const {underBarRef, match, history, branches} = props;
    const {params} = match;
    const {id} = params;

    const classes = useStyle();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useMounted();
    const [category, setCategory] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const fetchCategory = async (id) => {
        try {
            setIsLoading(true);
            const response = await service.getCategory(id);
            setCategory(response.data);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        fetchCategory(id);
    },[id]);


    useEffect(() => {
        if(Object.keys(category).length){
            const {name, generalLedgerAccount, branch, active, mcc} = category;
            fillFormValues({name, generalLedgerAccount, branch, active, mcc});
        }
    },[category]);


    async function submit(){

        setIsSubmitting(true);
        try {
            await service.updateCategory(id, values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/categories");
        } catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        const generalLedgerAccountRegex = getGeneralLedgerAccountRegex();

        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }
        if (values["generalLedgerAccount"] && !generalLedgerAccountRegex.test(values["generalLedgerAccount"].toString())) {
            errors.generalLedgerAccount = t("errors.notValid", {field:"$t(formFields.generalLedgerAccount)"});
        }

        return errors;
    };


    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>
                </Portal>
            )}
            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>

                <div className={classes.item}>
                    <CategoryForm
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        values={values}
                        branches={[{value:"", label:t("branch.allOption")}, ...branches]} // replace --none-- option to all
                        errors={errors}
                        loading={loading}
                    />
                </div>

                <Can I="update" a={"category"}>
                    <Button
                        onClick={handleSubmit}
                        disabled={isSubmitting || loading}
                    >
                        {t("formFields.update")}
                    </Button>
                </Can>
            </div>
        </>
    );
};

export default Category;
