import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    panelDock: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        margin: 'auto -40px -40px',
        padding: '60px 40px 20px',
        [theme.breakpoints.down('1339')]: {
            margin: 'auto -24px -32px',
            padding: '44px 24px 20px'
        },
        [theme.breakpoints.down('767')]: {
            display: 'block',
            margin: 'auto -16px -24px',
            padding: '40px 16px 16px',
        },
        "&:before" :{
            content: '""',
            position: 'absolute',
            top: '40px',
            left: '0',
            right: '0',
            bottom: '0',
            zIndex: '-1',
            background: 'var(--pale-grey)',
            boxShadow: 'inset 1px 0px 0px var(--very-light-pink-primary)',

            [theme.breakpoints.down('1339')]: {
                top: '24px'
            }
        }
    },

    panelInfo: {
        marginRight: 'auto',
        color: 'var(--battelship-grey)',
        fontSize: '13px',
        fontWeight: '600',
        [theme.breakpoints.down('767')]: {
            margin: '0 0 24px'
        }
    },

    panelContent: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto'
    }
}));

const UnderCardDockInvesthub = (props) => {
    const classes = useStyles();
    const {children, titleDock, ...restProps} = props;

    return (
        <div
            className={classes.panelDock}
            {...restProps}
        >

            {titleDock && (
                <div className={`${classes.panelInfo}`}>
                    {titleDock}
                </div>
            )}

            <div className={classes.panelContent}>
                {children}
            </div>
        </div>
    )
}

export default UnderCardDockInvesthub
