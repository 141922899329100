import React from 'react';
import FormLabel from "@material-ui/core/FormLabel";
import {makeStyles} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Skeleton from "react-loading-skeleton";


const PhoneField = (props) => {
    const noHandler = () => {
        console.log(`No handler: PhoneField`);
    };

    const {name="", value="", error="", onChangeHandler=noHandler, label="", disabled=false, isLoading} = props;

    let borderColor = "#8A96A0";
    if(error){
        borderColor = "#f44336"
    }else if(disabled){
        borderColor = "#fff"
    }

    const useStyles = makeStyles({
        "& . react-tel-input":{
            "fontFamily": "\"lota_grotesque\"",
        },
        input: {

        },
        label:{
            display:"block",
            lineHeight:"12px"
        },
        phoneWrapper:{
            '& :disabled':{
                cursor:"text",
            },
            '& .flag-dropdown': {
                border: 0,
                background: "none!important",
                paddingLeft: '0',
                width: '48px',
                borderRadius: '0',
                "& .arrow": {
                    display: 'none'
                }
            },
            '& .selected-flag':{
                background:"none!important",
                paddingLeft:"0",
                width: '48px'
            },
            '& .selected-flag .flag': {
                left: '50%',
                marginLeft: '-8px',
            },
            '& .country-list':{
                color: "#354052",
                background: "#FFFFFF",
                boxShadow: "2px 2px 54px rgba(0, 0, 0, 0.176355)",
                borderRadius: "6px",
                padding:"20px 0",
            },
            "& .react-tel-input .country-list": {
                border: 'none',
                marginTop: '2px',
                padding: '8px 0',
                borderRadius: '16px',
                boxShadow: 'inset 0 0 0 2px #EFEFEF, 0 4px 12px rgba(244, 244, 244, 0.1)',
                background: 'var(--white)',
                "& .country": {
                    position:"relative",
                    minHeight: 'auto',
                    padding: '8px 16px',
                    background: 'none',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '1.71429',
                    color: 'var(--battelship-grey)',
                },
                "& .divider": {
                    display: "none"
                },
                "& .country:hover": {
                    color: 'var(--dark-secondary)',
                },
                "& .country.highlight": {
                    color: 'var(--clear-blue-primary)'
                }
            },
            '& .form-control':{
                color: 'var(--dark-secondary)',
                width: '100%',
                border: '2px solid #0000',
                height: '48px',
                padding: '0 10px',
                paddingLeft: '48px',
                fontSize: '15px',
                background: 'var(--very-light-pink-primary)',
                transition: 'all .2s',
                fontFamily: 'var(--font-primary)',
                fontWeight: '600',
                lineHeight: '1.6',
                borderRadius: '12px',
            },
            '& .form-control:focus': {
                background: 'var(--white)',
                borderColor: 'var(--cool-grey)'
            },
            '& .form-control.open': {
                background: 'var(--white)',
                borderColor: 'var(--cool-grey)'
            },
            '& label + .react-tel-input':{
                marginTop: "12px"
            },
        }
    });

    const classes = useStyles();


    return (
        <>
            <div className={classes.phoneWrapper}>
                {label && <FormLabel component="label" htmlFor={`phone-${name}`} className={classes.label}>{label}</FormLabel>}
                {isLoading ? (
                    <Skeleton height={"42px"} width={"100%"}/>
                ) : (
                    <PhoneInput
                        country={'sg'}
                        enableTerritories
                        preferredCountries={['sg']}
                        disableAreaCodes={'false'}
                        value={value}
                        placeholder={""}
                        disabled={disabled}
                        masks={{sg: ' .... ....'}}
                        onChange={(value) => {
                            onChangeHandler(value)
                        }}
                    />
                )}
            </div>
            {!!error &&  <FormHelperText>{error}</FormHelperText>}
        </>
    );
};

export default PhoneField;
