import React, {useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../Messages/actions";
import ExternalLink from "../ExternalLink";
import useForm2 from "../../hooks/useForm2";
import DialogPopUp from "../DialogPopUp";
// import FileUploaderInline from "../formElements/FileUploaderInline";
import FileUploaderInlineInvesthub from "../formElements/FileUploaderInlineInvesthub/FileUploaderInlineInvesthub"
import Api from "../../services/api";
import MenuOption from "../MenuOption";
const service = new Api();

const useStyles = makeStyles(theme => ({
            item: {
                position: 'relative',
                maxWidth: '480px',
                marginLeft:"auto",
                marginRight:"auto"

            },
            notification: {
                width: '100%',
                padding: '12px 16px',
                marginBottom: '24px',
                borderRadius: '12px',
                border: '2px solid transparent',
                background: 'var(--very-light-pink-primary)',
                fontFamily: 'var(--font-primary)',
                fontSize: '15px',
                fontWeight: '600',
                lineHeight: '1.6',
                color: 'var(--dark-secondary)',
            },
            button: {
                marginTop: '24px'
            },
            mediaText: {
                fontWeight: '500',
                color: 'var(--battelship-grey)',
                '& a': {
                    color: 'var(--dark-secondary)',
                    fontWeight: '600',
                    transition: 'color .2s',
                    textDecoration: 'none'
                },
                '& a:hover': {
                    color: 'var(--bright-blue)',
                    textDecoration: 'none'
                }
            },
            field: {
                marginBottom: '32px',
                [theme.breakpoints.down('1259')]: {
                    marginBottom: '24px'
                }
            }
        }
    )
);

const ImportFromCsvOption = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const {btnLabel, popupTitle, popupText, templatePath, onImport, bottom, closeParent} = props;

    const submit = async () => {

        try {
            setIsDisabled(true);
            const formData = new FormData();
            formData.append("file", values["file"]);
            await onImport(formData);
            setOpen(false);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e))
        }finally {
            setIsDisabled(false);
        }
    }

    const validate = (values) =>{
        const errors = {};
        if (!values[`file`]) {
            errors[`file`] = t("errors.required", {field:"$t(formFields.file)"});
        }


        return errors;
    };

    const { handleChange, handleSubmit, values, fillFormValues, errors } = useForm2(
        submit,
        validate
    );

    const onOpenDialog = () => {
        if(typeof closeParent === "function"){
            closeParent();
        }
        setOpen(true)
    }

    const onExited = () => {
        fillFormValues({});
    }

    return (
        <>
            <MenuOption disabled={isDisabled} onClick={onOpenDialog}>
                {btnLabel}
            </MenuOption>
            <DialogPopUp
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="import-title"
                title={popupTitle}
                onExited={onExited}
            >
                <div className={classes.item}>
                    <div className={classes.notification}>
                        {popupText}
                    </div>

                    <div className={classes.field}>
                        <FileUploaderInlineInvesthub
                            value={values[`file`]}
                            fieldName={"file"}
                            error={errors[`file`]}
                            handleChange={handleChange}
                            acceptedDocuments={[".csv"]}
                            buttonLabel={"attachCsv"}
                        />
                    </div>
                    <div className={classes.mediaText}>
                        {t("importFromFileText1")}
                        {' '}
                        <Trans i18nKey="importFromFileText2">
                            <ExternalLink href={`${service._basicPath}${templatePath}`}/>
                        </Trans>

                        {bottom ? bottom : null}
                    </div>
                </div>
                <Button
                    onClick={handleSubmit}
                    disabled={isDisabled}
                    className={classes.button}
                >
                    {t("formFields.submit")}
                </Button>
            </DialogPopUp>

        </>
    );
};

export default ImportFromCsvOption;
