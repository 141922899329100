import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {Can} from "../../../../../Context/Can";
import AddCategoryBtn from './AddCategoryBtn';
import ActionBtn from '../TopComponent/ActionBtn/ActionBtn';

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',

        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },

        // placeholder
        "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder": {
            opacity: "1 !important"
        }
    },
    searchWrapper: {
        position: 'relative',
        width: '360px',
        marginRight: 'auto',
        [theme.breakpoints.down('1023')]: {
            width: '250px',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 16px',
        },
        '& .MuiInputLabel-root': {
            display: 'none'
        },
        '& .MuiInputBase-root': {
            position: 'relative',

            "& input[type:'text']": {
                paddingRight: '44px',
            },

            "& button": {
                position: 'absolute',
                top: '0',
                right: '12px',
                bottom: '0',
                width: '44px',
                paddingLeft: '4px',
                margin: '0',
                padding: '0',

                "& svg path": {
                    transition: 'fill .2s',
                },

                "&:hover": {
                    backgroundColor: 'transparent',

                    "& svg path": {
                        stroke: 'var(--clear-blue-primary)'
                    }
                },

                "& .MuiTouchRipple-root": {
                    display: 'none'
                }
            }
        }
    },
    control: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '8px'
    }
}))

const TopComponentInvesthub = (props) => {
    const {onImport, history, onDisableBatch, selectedCategories} = props;
    const classes = useStyle();
    const {t} = useTranslation();

    return (
        <div className={classes.head}>
            <div className={`title-blue text-weight-300 margin-bottom-0`}>
                {t("category.mainTitle")}
            </div>

            <div className={classes.control}>
                <Can I="create" a={"category"}>
                    <AddCategoryBtn history={history} onImport={onImport}/>
                </Can>

                <Can I="update" a={"category"}>
                    <ActionBtn
                        onDisableBatch={onDisableBatch}
                        selectedCategories={selectedCategories}
                    />
                </Can>
            </div>
        </div>
    )
}

export default TopComponentInvesthub
