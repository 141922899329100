import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import useMounted from "../../../../../hooks/useMounted";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import Api from "../../../../../services/api";
import useForm2 from "../../../../../hooks/useForm2";
import Portal from "@material-ui/core/Portal";
import BackButton from "../../../../BackButton";
import BranchForm from "../../../../BranchForm";
import {Can} from "../../../../../Context/Can";
import Button from "@material-ui/core/Button";
import getAlphaNumericRegex from "../../../../../helpers/getAlphaNumericRegex";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },
    },
    item: {
        position: 'relative'
    },
}))


const Branch = (props) => {
    const {underBarRef, match, history, currencies, countries} = props;
    const {params} = match;
    const {id} = params;

    const {t} = useTranslation();
    const classes = useStyle();
    const dispatch = useDispatch();
    const isMounted = useMounted();

    const [branch, setBranch] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setIsLoading] = useState(false);

    const fetchBranch = async (id) => {
        try {
            setIsLoading(true);
            const response = await service.getBranch(id);
            setBranch(response.data);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchBranch(id);
    },[id]);

    useEffect(() => {
        if(Object.keys(branch).length){
            const {name, currency, country, enterpriseNumber, street, postalCode, city, branchId} = branch;
            fillFormValues({name, currency, country, enterpriseNumber, street, postalCode, city, branchId});
        }
    },[branch]);


    const submit = async () => {
        try{
            setIsSubmitting(true);
            await service.updateBranch(id, values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/branches");
        }catch (e) {
            console.log(e);
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }


    const validate = (values) => {
        const errors = {};

        const regex = getAlphaNumericRegex();

        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }
        if (!values.currency) {
            errors.currency = t("errors.required", {field:"$t(formFields.currency)"});
        }

        if (values["branchId"] && !regex.test(values["branchId"].toString())) {
            errors.branchId = t("errors.notValid", {field:"$t(formFields.branchId)"});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );


    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>
                </Portal>
            )}
            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>
                <div className={classes.item}>
                    <BranchForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        loading={loading}
                        currencies={currencies}
                        countries={countries}
                        isEditMode={id}
                    />

                <Can I="update" a={"branch"}>
                    <Button
                        onClick={handleSubmit}
                        disabled={isSubmitting || loading}
                    >
                        {t("formFields.update")}
                    </Button>
                </Can>
                </div>
            </div>
        </>
    );
};

export default Branch;
