import React from 'react';
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import {makeStyles, MenuItem} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";
import {ReactComponent as SelectArrow} from "../../../assets/selectArrow.svg";


const SelectField = (props) => {

    const noHandler = () => {
        console.log(`No handler for ${name} select field`);
    };

    const {value = "", error = "", onSelectChange = noHandler, options = [], label = "", name = "", disabled = false, isLoading, alignRight, hideEmpty, emptyLabel, smallStyle = false, ...AdditionalProps} = props;

    const useStyles = makeStyles({
        alignRight: {
            justifyContent: "flex-end"
        },
        nativeStyles: {
            marginLeft: "-5px",
            "&.MuiInput-underline:before": {
                left: "5px"
            },
            "& select": {
                paddingLeft: "5px"
            }
        },
        itemComponent: {
            padding: "13px 30px",
            position: "relative",
            fontSize: "15px",
            color:"#354052",
            "&::after": {
                display: "block",
                left: "30px",
                right: "30px",
                content: "''",
                background: "#EDEFF1",
                height: "1px",
                bottom: 0,
                position: "absolute"
            },
        },
        menuWrapper:{
            background:"#ccc!important"
        },
        menu:{
            '& .MuiPopover-paper':{
                boxShadow:"2px 2px 54px rgba(0, 0, 0, 0.176355)"
            },
            "& .MuiMenu-list":{
                paddingBottom:"20px",
                paddingTop:"20px"
            }

        },
        smallStyleCSS: {
            '& .MuiMenu-list': {
                color: 'red'
            }
        },
        smallStyleMenu: {
            '& .MuiMenu-paper': {
                padding: '0',
                borderRadius: '12px',
            },
            '& .MuiMenu-paper .MuiMenu-list': {
                padding: '8px 0',
            },
            '& .MuiMenu-paper .MuiListItem-root': {
                padding: '4px 16px',
                fontWeight: '600',
                borderRadius: '0',
                transition: 'all 0.2s',

                "&:hover": {
                    backgroundColor: 'transparent !important',
                    color: 'var(--clear-blue-primary)',
                },

                "& .MuiTouchRipple-root": {
                    display: 'none'
                },
                "&.Mui-selected": {
                    backgroundColor: 'transparent !important'
                }
            }
        },
    });

    const classes = useStyles();
    const {t} = useTranslation();


    const OptionComponent = props.native ? "option" : MenuItem;

    return (
        <>
            {label && <FormLabel component="label" htmlFor={`select-${name}`}>{label}</FormLabel>}
            {isLoading ? (<Skeleton width={"100%"} height={"50px"}/>) : (
                <Select
                    value={value}
                    name={name}
                    className={`${props.native ? classes.nativeStyles : null} ${smallStyle ? classes.smallStyleCSS : null}`}
                    displayEmpty
                    id={`select-${name}`}
                    onChange={(e) => onSelectChange(e.target.value)}
                    disabled={disabled}
                    IconComponent={(props) => {
                        return disabled ? null : <SelectArrow {...props}/>
                    }}
                    MenuProps={{
                        className:`${classes.menu} ${smallStyle ? classes.smallStyleMenu : null}`
                    }}
                    {...AdditionalProps}
                >
                {!hideEmpty && (<OptionComponent value="" className={!props.native ? classes.itemComponent : null}>{emptyLabel ? emptyLabel : t("formFields.emptyOption")}</OptionComponent>)}
                {(options && options.length > 0) && (
                    options.map((option) => {
                        return <OptionComponent value={option.value} key={option.value} disabled={!!(option.disabled)} className={!props.native ? classes.itemComponent : null}>{option.label}</OptionComponent>
                    })
                )}
            </Select>
            )}

            {!!error && <FormHelperText>{error}</FormHelperText>}
        </>
    );
};

export default SelectField;
