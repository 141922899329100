import React from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import SelectField from "../../../../formElements/SelectField";
import InputField from "../../../../formElements/InputField";
import RadioFields from "../../../../formElements/RadioFields";
import DateField from "../../../../formElements/DateField";
import {useTranslation} from "react-i18next";
import FileUploaderInline from "../../../../formElements/FileUploaderInline";
import Box from "@material-ui/core/Box";
import SendingAnimationWrapper from "../../../../SendingAnimationWrapper";
import CircularProgress from '@material-ui/core/CircularProgress'
import * as loader from "../../../../../assets/animations/loader";

const useStyles = makeStyles(theme => ({
    formWrapper:{
        position:"relative",
        [theme.breakpoints.up('md')]: {
            "& .MuiGrid-container > .MuiGrid-item > .MuiFormControl-root":{
                paddingRight:"20px"
            }
        }
    },
    cancelButton: {
        fontWeight:400,
        paddingLeft:"10px",
        paddingRight:"10px",
        marginRight:"15px"
    },
    button:{
        marginRight:"15px"
    },
    fileUploaderWrapper:{
        "& .MuiFormLabel-root":{
            textTransform:"lowercase"
        }
    }
}));

const KycForm = (props) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const {handleSubmit, values, handleChange, errors, isSubmitting,  dateFormat, isDataFetching, countries, proofOfIdentityCategories, proofOfAddressCategories, titles, onCancel, onSave, disabled, isValidData} = props;

    return (
        <div>
            <form onSubmit={handleSubmit} noValidate className={classes.formWrapper}>
                {isSubmitting && (
                    <SendingAnimationWrapper>
                        <CircularProgress />
                    </SendingAnimationWrapper>
                )}
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <FormControl error={!!errors["title"]} fullWidth>
                            <RadioFields
                                options={titles}
                                name={"title"}
                                label={t("formFields.title")}
                                value={values["title"]}
                                disabled={disabled}
                                onRadioChange={(val) => handleChange("title", val)}
                                error={errors["title"]}
                                isLoading={isDataFetching}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["firstName"]} fullWidth >
                            <InputField
                                label={t("formFields.firstName")}
                                name="firstName"
                                disabled={disabled}
                                value={values["firstName"]}
                                onInputChange={(val) => handleChange("firstName", val)}
                                error={errors["firstName"]}
                                isLoading={isDataFetching}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["firstName"]} fullWidth >
                            <InputField
                                label={`${t("formFields.middleName")} ${t("formFields.optional")}`}
                                name="middleName"
                                disabled={disabled}
                                value={values["middleName"]}
                                onInputChange={(val) => handleChange("middleName", val)}
                                error={errors["middleName"]}
                                isLoading={isDataFetching}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["lastName"]} fullWidth>
                            <InputField
                                label={t("formFields.lastName")}
                                name="lastName"
                                disabled={disabled}
                                value={values["lastName"]}
                                onInputChange={(val) => handleChange("lastName", val)}
                                error={errors["lastName"]}
                                isLoading={isDataFetching}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={4}>
                                <FormControl error={!!errors["dob"]} fullWidth>
                                    <DateField
                                        disabled={disabled}
                                        label={t("formFields.dob")}
                                        placeholder={t("formFields.dob")}
                                        dateFormat={dateFormat}
                                        onDateChange={(date) => handleChange(`dob`, date)}
                                        value={values[`dob`]}
                                        error={errors["dob"]}
                                        name="dob"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change dob',
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["addrl1"]} fullWidth>
                            <InputField
                                label={t("formFields.addrl1")}
                                name="addrl1"
                                autoComplete='address-line1'
                                value={values["addrl1"]}
                                disabled={disabled}
                                onInputChange={(val) => handleChange("addrl1", val)}
                                error={errors["addrl1"]}
                                isLoading={isDataFetching}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["addrl2"]} fullWidth>
                            <InputField
                                label={`${t("formFields.addrl2")} ${t("formFields.optional")}`}
                                name="addrl2"
                                autoComplete='address-line2'
                                disabled={disabled}
                                value={values["addrl2"]}
                                onInputChange={(val) => handleChange("addrl2", val)}
                                error={errors["addrl2"]}
                                isLoading={isDataFetching}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl error={!!errors["postCode"]} fullWidth>
                            <InputField
                                label={t("formFields.postCode")}
                                name="postCode"
                                disabled={disabled}
                                value={values["postCode"]}
                                onInputChange={(val) => handleChange("postCode", val)}
                                error={errors["postCode"]}
                                isLoading={isDataFetching}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={4}>
                                <FormControl error={!!errors["city"]} fullWidth>
                                    <InputField
                                        label={t("formFields.city")}
                                        name="city"
                                        disabled={disabled}
                                        value={values["city"]}
                                        onInputChange={(val) => handleChange("city", val)}
                                        error={errors["city"]}
                                        isLoading={isDataFetching}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl error={!!errors["country"]} fullWidth>
                                    <SelectField
                                        label={t("formFields.country")}
                                        name={"country"}
                                        disabled={disabled}
                                        value={values["country"]}
                                        error={errors["country"]}
                                        options={countries}
                                        onSelectChange={(val) => handleChange("country", val)}
                                        isLoading={isDataFetching}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={4}>
                                <FormControl error={!!errors["proofOfIdentityCategory"]} fullWidth>
                                    <SelectField
                                        label={t("formFields.proofOfIdentityCategory")}
                                        name={"proofOfIdentityCategory"}
                                        disabled={disabled}
                                        value={values["proofOfIdentityCategory"]}
                                        error={errors["proofOfIdentityCategory"]}
                                        options={proofOfIdentityCategories}
                                        onSelectChange={(val) => handleChange("proofOfIdentityCategory", val)}
                                        isLoading={isDataFetching}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box className={classes.fileUploaderWrapper}>
                                    <FileUploaderInline
                                        value={values[`proofOfIdentity`]}
                                        fieldName={"proofOfIdentity"}
                                        error={errors[`proofOfIdentity`]}
                                        handleChange={handleChange}
                                        disabled={disabled}
                                        label={t("formFields.proofOfIdentity")}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={4}>
                                <FormControl error={!!errors["proofOfAddressCategory"]} fullWidth>
                                    <SelectField
                                        label={t("formFields.proofOfAddressCategory")}
                                        name={"proofOfAddressCategory"}
                                        disabled={disabled}
                                        value={values["proofOfAddressCategory"]}
                                        error={errors["proofOfAddressCategory"]}
                                        options={proofOfAddressCategories}
                                        onSelectChange={(val) => handleChange("proofOfAddressCategory", val)}
                                        isLoading={isDataFetching}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box className={classes.fileUploaderWrapper}>
                                    <FileUploaderInline
                                        value={values[`proofOfAddress`]}
                                        fieldName={"proofOfAddress"}
                                        disabled={disabled}
                                        error={errors[`proofOfAddress`]}
                                        handleChange={handleChange}
                                        label={t("formFields.proofOfAddress")}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color={"primary"}
                            onClick={handleSubmit}
                            className={classes.button}
                            disabled={isSubmitting || disabled || isValidData}
                        >
                            {t("formFields.submit")}
                        </Button>
                        {onSave && (
                            <Button
                                onClick={onSave}
                                variant="contained"
                                color={"secondary"}
                                disabled={isSubmitting}
                                className={classes.button}
                            >
                                {t("formFields.save")}

                            </Button>
                        )}

                        <Button
                            color={"secondary"}
                            onClick={onCancel}
                            className={classes.cancelButton}
                        >
                            {t("formFields.cancel")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default KycForm;
