import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import SelectField from "../../../../../formElements/SelectField";
import InputField from "../../../../../formElements/InputField";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {Can} from "../../../../../../Context/Can";
// import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(theme => ({
    itemSetting: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    formControl: {
        flex: '0 0 calc(33.333% - 16px)',
        width: 'calc(33.333% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('1259')]: {
            flex: '0 0 calc(50% - 16px)',
            width: 'calc(50% - 16px)',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        }
    }
}));


const CompanyForm = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const {handleSubmit, values, handleChange, errors, isLoading, isDataFetching, countries = [], types = [], employeesRangeList = [], checkDisabledField, currencies} = props;

    return (
        <>
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.itemSetting}>
                    <FormControl error={!!errors["name"]} fullWidth className={classes.formControl}>
                        <InputField
                            label={t("formFields.companyName")}
                            placeholder={t("formFields.companyName")}
                            name="name"
                            isLoading={isDataFetching}
                            disabled={checkDisabledField("name")}
                            value={values["name"]}
                            onInputChange={(val) => handleChange("name", val)}
                            error={errors["name"]}
                        />
                    </FormControl>

                    <FormControl error={!!errors["employeesRange"]} fullWidth className={classes.formControl}>
                        <SelectField
                            label={t("formFields.employeesRange")}
                            name={"employeesRange"}
                            value={values["employeesRange"]}
                            error={errors["employeesRange"]}
                            options={employeesRangeList}
                            isLoading={isDataFetching}
                            disabled={checkDisabledField("employeesRange")}
                            onSelectChange={(val) => handleChange("employeesRange", val)}
                        />
                    </FormControl>

                    <FormControl error={!!errors["companyRegistrationNo"]} fullWidth className={classes.formControl}>
                        <InputField
                            label={t("formFields.companyRegistrationNo")}
                            placeholder={t("formFields.companyRegistrationNo")}
                            name="companyRegistrationNo"
                            disabled={checkDisabledField("companyRegistrationNo")}
                            fullWidth
                            isLoading={isDataFetching}
                            value={values["companyRegistrationNo"]}
                            onInputChange={(val) => handleChange("companyRegistrationNo", val)}
                            error={errors["companyRegistrationNo"]}
                        />
                    </FormControl>

                    <FormControl error={!!errors["type"]} fullWidth className={classes.formControl}>
                        <SelectField
                            label={t("formFields.typeOfIncorporation")}
                            name={"type"}
                            isLoading={isDataFetching}
                            value={values["type"]}
                            error={errors["type"]}
                            options={types}
                            disabled={checkDisabledField("type")}
                            onSelectChange={(val) => handleChange("type", val)}
                        />
                    </FormControl>

                    <FormControl error={!!errors["addressStreet"]} fullWidth  className={classes.formControl}>
                        <InputField
                            label={t("formFields.addressStreet")}
                            placeholder={t("formFields.addressStreet")}
                            name="addressStreet"
                            isLoading={isDataFetching}
                            disabled={checkDisabledField("addressStreet")}
                            value={values["addressStreet"]}
                            onInputChange={(val) => handleChange("addressStreet", val)}
                            error={errors["addressStreet"]}
                        />
                    </FormControl>

                    <FormControl error={!!errors["addressNumber"]} fullWidth  className={classes.formControl}>
                        <InputField
                            label={t("formFields.addressNumber")}
                            placeholder={t("formFields.addressNumber")}
                            name="addressNumber"
                            isLoading={isDataFetching}
                            disabled={checkDisabledField("addressNumber")}
                            value={values["addressNumber"]}
                            onInputChange={(val) => handleChange("addressNumber", val)}
                            error={errors["addressNumber"]}
                        />
                    </FormControl>

                    <FormControl error={!!errors["addrl2"]} fullWidth className={classes.formControl}>
                        <InputField
                            label={t("formFields.addrl2")}
                            placeholder={t("formFields.addrl2")}
                            name="addrl2"
                            isLoading={isDataFetching}
                            value={values["addrl2"]}
                            onInputChange={(val) => handleChange("addrl2", val)}
                            error={errors["addrl2"]}
                            disabled={checkDisabledField("addrl2")}
                        />
                    </FormControl>

                    <FormControl error={!!errors["postCode"]} fullWidth className={classes.formControl}>
                        <InputField
                            label={t("formFields.postCode")}
                            placeholder={t("formFields.postCode")}
                            name="postCode"
                            isLoading={isDataFetching}
                            value={values["postCode"]}
                            onInputChange={(val) => handleChange("postCode", val)}
                            error={errors["postCode"]}
                            disabled={checkDisabledField("postCode")}
                        />
                    </FormControl>

                    <FormControl error={!!errors["city"]} fullWidth className={classes.formControl}>
                        <InputField
                            label={t("formFields.city")}
                            placeholder={t("formFields.city")}
                            name="city"
                            isLoading={isDataFetching}
                            disabled={checkDisabledField("city")}
                            value={values["city"]}
                            onInputChange={(val) => handleChange("city", val)}
                            error={errors["city"]}
                        />
                    </FormControl>

                    <FormControl error={!!errors["addressRegion"]} fullWidth  className={classes.formControl}>
                        <InputField
                            label={t("formFields.addressRegion")}
                            placeholder={t("formFields.addressRegion")}
                            name="addressRegion"
                            isLoading={isDataFetching}
                            disabled={checkDisabledField("addressRegion")}
                            value={values["addressRegion"]}
                            onInputChange={(val) => handleChange("addressRegion", val)}
                            error={errors["addressRegion"]}
                        />
                    </FormControl>

                    <FormControl fullWidth error={!!errors["country"]} className={classes.formControl}>
                        <SelectField
                            label={t("formFields.country")}
                            name={"country"}
                            isLoading={isDataFetching}
                            value={values["country"]}
                            error={errors["country"]}
                            options={countries}
                            disabled={checkDisabledField("country")}
                            onSelectChange={(val) => handleChange("country", val)}
                        />
                    </FormControl>

                    <FormControl fullWidth error={!!errors["currency"]} className={classes.formControl}>
                        <SelectField
                            label={t("formFields.currency")}
                            name={"currency"}
                            value={values["currency"]}
                            error={errors.currency}
                            options={currencies}
                            disabled={checkDisabledField("currency")}
                            onSelectChange={(val) => handleChange("currency", val)}
                            isLoading={isDataFetching}
                        />
                    </FormControl>
                </div>
                <Can I={"update"} a={"company"}>
                    <Button
                        type="submit"
                        disabled={isLoading}
                    >
                        {t("formFields.save")}
                    </Button>
                </Can>
            </form>
        </>
    );
};

export default CompanyForm;
