import React from 'react'
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import AvatarUploader from "../../../../AvatarUploader";

import PhoneField from "../../../../formElements/PhoneField";
import InputField from "../../../../formElements/InputField";
import FormLabel from "@material-ui/core/FormLabel";
import SelectField from "../../../../formElements/SelectField";
import RadioFields from "../../../../formElements/RadioFields";
import {useTranslation} from "react-i18next";
import CheckBoxField from "../../../../formElements/CheckBoxField";
import FormHelperText from "@material-ui/core/FormHelperText";


const useStyle = makeStyles((theme) => ({
    item: {
        position: 'relative',
    },
    itemForm: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },
    itemFieldImage:{
        width: '280px',
        [theme.breakpoints.down('1023')]: {
            width: '210px'
        }
    },
    itemFields:{
        flexGrow: '1',
        paddingLeft: '32px',
        [theme.breakpoints.down('1023')]: {
            paddingLeft: '0',
            marginTop: '24px'
        }
    },
    itemSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemFieldThird: {
        flex: '0 0 calc(33.333% - 16px)',
        width: 'calc(33.333% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('1259')]: {
            flex: '0 0 calc(50% - 16px)',
            width: 'calc(50% - 16px)',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        }
    },
    itemFieldHalf: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        }
    },
    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    },
    itemRadio: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0'
        },
        '& .MuiFormControlLabel-root:not(:last-child)': {
            marginRight: '16px'
        }
    },
    itemBox: {
        width: '100%',
        height: '48px',
        padding: '0 10px',
        borderRadius: '12px',
        border: '2px solid var(--cool-grey)',
        background: 'var(--white)',
        transition: 'all .2s',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px'
    }
}));

const ProfileForm = (props) => {
    const {
        roles=[],
        countries,
        titles,
        groups,
        branches,
        departments,
        handleChange,
        handleSubmit,
        errors,
        values,
        isDisabled,
        customerNumber,
        ability,
        isDataFetching
    } = props;

    const classes = useStyle();
    const {t} = useTranslation();

    const checkDisabledStatus = (field) => {
        return !ability.can("update", "user", field)
    }

    return (
        <div className={classes.item}>
            <form method="post" encType="multipart/form-data" onSubmit={handleSubmit} className={classes.itemForm}>
                <div className={classes.itemFieldImage}>
                    <AvatarUploader src={values["avatarUrl"]} setFile={(file) => handleChange("avatarUrl", file)}/>
                </div>
                <div className={classes.itemFields}>
                    <div className={classes.itemSettings}>
                        <FormControl fullWidth error={!!errors.title} className={classes.itemFieldFull}>
                            <RadioFields
                                options={titles}
                                name={"title"}
                                label={t("formFields.title")}
                                value={values["title"]}
                                onRadioChange={(val) => handleChange("title", val)}
                                error={errors["title"]}
                                disabled={checkDisabledStatus("title")}
                                isLoading={isDataFetching}
                                className={classes.itemRadio}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["firstName"]} className={classes.itemFieldThird}>
                            <InputField
                                label={t("formFields.firstName")}
                                placeholder={t("formFields.firstName")}
                                name="firstName"
                                error={errors["firstName"]}
                                value={values["firstName"]}
                                onInputChange={(val) => handleChange("firstName", val)}
                                disabled={checkDisabledStatus("firstName")}
                                isLoading={isDataFetching}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["middleName"]} className={classes.itemFieldThird}>
                            <InputField
                                label={t("formFields.middleName")}
                                placeholder={t("formFields.middleName")}
                                name="middleName"
                                error={errors["middleName"]}
                                value={values["middleName"]}
                                onInputChange={(val) => handleChange("middleName", val)}
                                disabled={checkDisabledStatus("middleName")}
                                isLoading={isDataFetching}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["lastName"]} className={classes.itemFieldThird}>
                            <InputField
                                label={t("formFields.lastName")}
                                placeholder={t("formFields.lastName")}
                                name="lastName"
                                error={errors["lastName"]}
                                value={values["lastName"]}
                                onInputChange={(val) => handleChange("lastName", val)}
                                disabled={checkDisabledStatus("lastName")}
                                isLoading={isDataFetching}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldHalf}>
                            <InputField
                                label={t("formFields.email")}
                                placeholder={t("formFields.email")}
                                name="email"
                                value={values["email"]}
                                error={errors["email"]}
                                onInputChange={(val) => handleChange("email", val)}
                                disabled={checkDisabledStatus("email")}
                                isLoading={isDataFetching}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldHalf}>
                            <PhoneField
                                label={t("formFields.phone")}
                                name={"phone"}
                                value={values["phone"]}
                                onChangeHandler={(val) => handleChange("phone", val)}
                                disabled={checkDisabledStatus("phone")}
                                error={errors["phone"]}
                                isLoading={isDataFetching}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldHalf}>
                            <SelectField
                                label={t("formFields.country")}
                                name={"country"}
                                value={values["country"]}
                                error={errors["country"]}
                                options={countries}
                                onSelectChange={(val) => handleChange("country", val)}
                                disabled={checkDisabledStatus("country")}
                                isLoading={isDataFetching}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldHalf}>
                            <InputField
                                label={t("formFields.volveUserId")}
                                value={customerNumber}
                                disabled={true}
                                isLoading={isDataFetching}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldFull}>
                            <FormLabel component="label">{t("formFields.role")}</FormLabel>
                            <div className={classes.itemBox}>
                                {roles.map((role) => {
                                    return  (
                                        <div className='status-default' key={role}>
                                            {role}
                                        </div>
                                    )
                                })}
                            </div>
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldHalf}>
                            <SelectField
                                label={t("formFields.group")}
                                name={"group"}
                                value={values["group"]}
                                error={errors["group"]}
                                options={groups}
                                onSelectChange={(val) => handleChange("group", val)}
                                disabled={checkDisabledStatus("group")}
                                isLoading={isDataFetching}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldHalf}>
                            <SelectField
                                label={t("formFields.branch")}
                                name={"branch"}
                                value={values["branch"]}
                                error={errors["branch"]}
                                options={branches}
                                onSelectChange={(val) => handleChange("branch", val)}
                                disabled={checkDisabledStatus("branch")}
                                isLoading={isDataFetching}
                            />
                            <FormHelperText>{t("important")}: {t("helpTextUserBranch")}</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth error={!!errors["position"]} className={classes.itemFieldHalf}>
                            <InputField
                                label={t("formFields.position")}
                                placeholder={t("formFields.position")}
                                name="position"
                                error={errors["position"]}
                                value={values["position"]}
                                onInputChange={(val) => handleChange("position", val)}
                                disabled={checkDisabledStatus("position")}
                                isLoading={isDataFetching}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["department"]} className={classes.itemFieldHalf}>
                            <SelectField
                                label={t("formFields.department")}
                                name={"department"}
                                value={values["department"]}
                                error={errors["department"]}
                                options={departments}
                                onSelectChange={(val) => handleChange("department", val)}
                                disabled={checkDisabledStatus("department")}
                                isLoading={isDataFetching}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["expenseReminder"]} className={classes.itemFieldFull}>
                            <CheckBoxField
                                onCheckboxChange={(val) => handleChange("expenseReminder", val)}
                                value={!!(values["expenseReminder"])}
                                label={t("expenseReminderText")}
                                name={"expenseReminder"}
                                error={errors["expenseReminder"]}
                                disabled={checkDisabledStatus("expenseReminder") || isDataFetching}
                            />
                        </FormControl>
                    </div>

                    <Button

                        type="submit"
                        disabled={isDisabled || isDataFetching}
                    >
                        {t("formFields.save")}
                    </Button>
                </div>
            </form>
        </div>
    )
}


export default ProfileForm
