import React, {useState, useEffect} from 'react';
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import moment from "moment-timezone";
import getTimeAgo from "../../../../../../helpers/getTimeAgo";
import {ReactComponent as CloseSVG} from "../../../../../../assets/closeInvesthub.svg"
import {ReactComponent as CheckSVG} from "../../../../../../assets/checkInvesthub.svg"
import {ReactComponent as InfoSVG} from "../../../../../../assets/infoInvesthub.svg"

const useStyles = makeStyles(theme => ({
    itemNotif: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: '12px',
        transition: 'background .2s',
        borderRadius: '8px',

        "&:not(:last-child):before": {
            content: '""',
            position: 'absolute',
            left: '12px',
            right: '12px',
            bottom: '0',
            height: '1px',
            background: 'var(--very-light-pink-secondary)'
        }
    },
    itemContent: {
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '1.6',
        color: 'var(--battelship-grey)',
        transition: 'all .2s',

        '& svg': {
            width: '24px',
            height: '24px',
            marginRight: '12px',
            fill: 'var(--battelship-grey)',
            transition: 'fill .2s',
        },
        '& .text-color-faded-green svg': {
            fill: 'var(--faded-green)'
        },
        '& .text-color-orange-pink svg': {
            fill: 'var(--orange-pink)'
        },
        '& .text-color-pale-salmon svg': {
            fill: 'var(--pale-salmon)'
        }
    },
    timeAgo: {
        marginLeft: '16px',
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '1.23077',
        color: 'var(--cool-grey)',
    }
}))

const Item = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {notification, deleteMessage, isDisabled, onClose} = props;

    const {type, translateKey, _id, payload, textProperty, createdAt, status} = notification;

    let link = null;
    let text = t(translateKey, {textProperty});

    if(type === "expenses"){
        if(payload){
            const [role, expenseTabOrId] = payload.split(":");
            link = `/${type}/${expenseTabOrId}`;
        }
    }else if(type === "kyc"){
        if(payload){
            const [role, userId] = payload.split(":");
            link = `/users/${userId}`;
        }
    }else if(type === "card"){
        const [role, cardId] = payload.split(":");
        link = `/cards/${cardId}`;
    }

    const [timeAgo, setTimeAgo] = useState(null);

    useEffect(() => {

        if(createdAt){
            const date = moment(createdAt).toDate();
            setTimeAgo(date);
            setInterval(() => {
                setTimeAgo(date); // update seconds counter 1 time per minute
            }, 1000*60);
        }

    },[createdAt]);

    let choosenIcon = null;
    if(status === "failure"){
        choosenIcon = <span className="text-color-orange-pink"><CloseSVG /></span>
    }else if(status === "success"){
        choosenIcon = <span className="text-color-faded-green"><CheckSVG /></span>
    } else {
        choosenIcon = <span className="text-color-pale-salmon"><InfoSVG /></span>
    }

    const timeAgoBlock = timeAgo ? <span className={classes.timeAgo}>{getTimeAgo(timeAgo)}</span> : null;

    return (
        <div className={`${classes.itemNotif} ${isDisabled ? 'disabled' : ''}`}>
            <div className={classes.itemContent}>
                {choosenIcon}
                {text}

                {link ? (
                    <Link to={link} className={classes.itemLink} onClick={onClose}>{' '}</Link>
                ) : ''}
            </div>
            <div className={classes.itemExtra}>
                {timeAgoBlock}
            </div>
        </div>
    )
}

export default Item
