import React, {useEffect, useState} from 'react';
import FormLabel from "@material-ui/core/FormLabel";
import Skeleton from "react-loading-skeleton";
import Box from "@material-ui/core/Box";
import FormatMoney from "../../FormatMoney/FormatMoney";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    calculatedAmountLabel:{
        position: 'relative',
        marginBottom: '0'
    },

    currencyWrap: {
        position: 'relative',
        width: '100%',
        height: '48px',
        padding: '0 10px',
        borderRadius: '12px',
        border: '2px solid var(--very-light-pink-primary)',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight:' 1.6',
        color: 'var(--dark-secondary)',
        transition: 'all .2s',
        paddingLeft: '58px',
        background: 'var(--very-light-pink-primary)',
    },

    currencySymbol: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        bottom: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '46px',
        paddingTop: '1px',
        borderRadius: '10px 0 0 10px',
        backgroundColor: 'var(--white)',
        color: 'var(--dark-secondary)',
    },

    currencyValue: {
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '46px',
        height: '46px',
        color: 'var(--dark-secondary)',
    }
}));

const ReportedAmount = (props) => {
    const classes = useStyles();

    const {isLoading, reportedExchangeRate, reportedCurrency, amount} = props;


    const {t} = useTranslation();
    const [displayedAmount, setDisplayedAmount] = useState("");


    useEffect(() => {
        if(reportedExchangeRate && amount){
            setDisplayedAmount(reportedExchangeRate*amount)
        }else{
            setDisplayedAmount(amount);
        }

    },[reportedExchangeRate, amount]);

    return (
        <>
            <FormLabel className={classes.calculatedAmountLabel}>{t("formFields.amountInReportingCurrency")}</FormLabel>
            {(isLoading) ? (
                <Skeleton height={"47px"} width={"100%"}/>
            ) : (
                <Box className={classes.currencyWrap} >
                    <Box className={classes.currencySymbol}>{reportedCurrency}</Box>
                    <Box className={classes.currencyValue}>
                        <FormatMoney>{displayedAmount}</FormatMoney>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ReportedAmount;
