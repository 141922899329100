import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Link, withRouter} from "react-router-dom";
import {Avatar, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {ReactComponent as Logo} from "../../../../../assets/logoInvesthub.svg";
import VolveSnackbar from "../../../../VolveSnackbar";
import Icon from '@material-ui/core/Icon';


const useStyles = makeStyles(theme => ({
    link:{
        color: "#24DAC4",
        fontSize: "15px",
        lineHeight: "26px",
        textDecoration:"none",
        "&:hover":{
            textDecoration:"none",
            color: "#1EB3A1",
            background: "none"
        }
    },


}));


const KYCInfo = ({user}) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {userData} = user;
    const {_id} = userData;

   const canShowMessage = userData.status === "active" && !userData.kyc;

    return (
        canShowMessage ? (
                <VolveSnackbar
                    message={<>{t("verifyKyc")}</>}
                    action={<><Link to={`/user-verification/${_id}`} className={classes.link}>{t("formFields.verify")}</Link></>}
                    icon={<Logo width={"27px"} height={"27px"}/>}
                />
        ) : null

    );
};

export default KYCInfo;
