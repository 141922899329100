import React from 'react';
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {ReactComponent as FilterSVG} from "../../assets/filterInvesthub.svg"
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next"

const useStyles = makeStyles((theme) => (
    {
        button:{
            width: '40px',
            height: '40px',
            padding: '0',
            background: 'none',
            boxShadow: '0 0 0 2px #EFEFEF inset',
            transition: 'all .2s',
            minWidth: 'initial',
            borderRadius: '8px',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'all .2s',
                fill: 'var(--battelship-grey)'
            },

            "&:hover": {
                background: 'var(--default-button-hover)',
                boxShadow: 'var(--default-button-hover-shadow)',

                "& svg": {
                    fill: 'var(--pale-grey)'
                }
            }
        }
    }
));


const FilterButton = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <Tooltip
                title={t("formFields.filter")}
            >
                <Button className={classes.button} {...props}>
                    <FilterSVG />
                </Button>
            </Tooltip>
        </>
    );
};

export default FilterButton
