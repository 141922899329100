import React from 'react';
import {withRouter, Route, Switch} from 'react-router-dom'
import AccountsTable from "./AccountsTable";
import Account from "./AccountsTable/Account";
import {compose} from "redux";
import {useTranslation} from "react-i18next";


const Accounts = (props) => {
    const {match, drawerOuterRef, underBarRef} = props;
    const {path} = match;

	const {t} = useTranslation();


	return (
        <>
			<Switch>
				<Route exact path={path} render={
					() => {
							return <AccountsTable underBarRef={underBarRef}/>
						}
					}
				/>
				<Route path={`${path}/:id`} render={
					() => {
							return <Account underBarRef={underBarRef} drawerOuterRef={drawerOuterRef}/>
						}
					}
				/>
				<Route render={
					() => {
							return <div>{t("pageNotFound")}</div>
						}
					}
				/>
			</Switch>
		</>
	);
};


export default compose(
	withRouter,
)(Accounts);
