import React from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import Advanced from "./Advanced";

const AdvancedSettings = (props) => {
    const {match, ...restProps} = props;

    return (
        <>
            <Switch>
                <Route exact path={`${match.path}`} render={() => <Advanced {...restProps}/>}/>
            </Switch>
        </>
    );
};

export default withRouter(AdvancedSettings);
