import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import {Grid, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Chart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import moment from "moment-timezone";
import {useTranslation} from "react-i18next";
import formatMoney from "../../../../../../helpers/formatMoney";
import * as loader from "../../../../../../assets/animations/loader";
import CircularProgress from '@material-ui/core/CircularProgress'
import useActiveTab from "../../../../../../hooks/useActiveTab";
import DashboardPane from "../../../../../DashboardPane";
import DashboardPaneTopInfo from "../../../../../DashboardPane/DashboardPaneTopInfo";


const useStyles = makeStyles(theme => ({
    spinnerWrapper: {
        position:"absolute",
        top:0,
        left:0,
        right:0,
        bottom:0,
        zIndex:1
    },
    wrapper: {
        overflowY:"hidden",
        overflowX:"auto",
    },
    cardContent:{
        '&.MuiCardContent-root':{
            paddingBottom:"8px",
            paddingTop:0,
        }
    }
}));

const ChartViewByDay = (props) => {
    const {expenses, currency, dateLte, decimal, isLoading} = props;

    const {t} = useTranslation();
    const classes = useStyles();
    const momentDate = moment(dateLte);

    const [amounts, setAmounts] = useState([]);
    const [days, setDays] = useState([]);
    const [max, setMax] = useState(300);

    const [series, setSeries] = useState([{
            name: "",
            data: amounts,
        }]);

    useEffect(() => {
        setSeries([{
            name: "",
            data: amounts,
        }]);

    },[amounts]);


    useEffect(() => {

        const daysInMonth = momentDate.daysInMonth();

        const days = Array.from({length: daysInMonth}, (v, i) => i+1);

        setDays(days);

        const amounts = days.map((day) => {


            const foundItem = expenses.find((expense) => {
                const {_id} = expense; // _id is day number
                return day === _id;
            });

            return foundItem ? foundItem.totalAmountExchanged : 0;
        });

        const max = Math.max(...amounts);
        setMax(Math.ceil(max));

        setAmounts(amounts);

    }, [props]);


    function getColor({ value, seriesIndex, dataPointIndex, w }) {
        const day = dataPointIndex+1;
        const weekDay = moment(dateLte).set("date",day).weekday();
        return weekDay === 1 ? "#1EB3A1" : "#24DAC4";
    }

    const activeTab = useActiveTab('dashboard');

    const getTitle = () => {
        switch (activeTab) {
            case "personal" :
                return t("dashboard.expensesChartTitleMy");
            case "approver" :
            case "manager" :
                return t("dashboard.expensesChartTitleTeam");
            default :  return t("dashboard.expensesChartTitle");
        }
    };

    const title = getTitle();

    return (
        <DashboardPane cardContentProps={{className:classes.cardContent}} title={title} action={<DashboardPaneTopInfo value={t("dashboard.expensesChartCurrency", {currency})}/>}>
            <Box className={classes.wrapper}>
                <Box minWidth={"800px"}>
                    <Grid container justify="space-between">
                        <Grid item xs={12}>
                            {isLoading && (
                                <Box className={classes.spinnerWrapper} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <CircularProgress />
                                </Box>
                            )}
                            <Chart
                                options={{
                                    tooltip: {
                                        x: {formatter: (value) => value},
                                        y: {formatter: (value) => formatMoney(value, decimal)},
                                        marker: {show: false,}
                                    },
                                    colors:[getColor],
                                    plotOptions: {
                                        bar: {
                                            columnWidth: 34,
                                            barHeight: "100%",
                                            backgroundBarRadius: 10
                                        },
                                    },
                                    dataLabels: {
                                        enabled: false,
                                    },
                                    chart: {
                                        redrawOnParentResize: true,
                                        foreColor: "#8A96A0",
                                        toolbar: {show: false},
                                    },
                                    xaxis: {
                                        axisBorder: {show: false},
                                        axisTicks: {show: false},
                                        labels: {
                                            style:{fontSize: "14px"},
                                        },
                                        categories: days
                                    },
                                    yaxis: {
                                        opposite: true,
                                        tickAmount: 3,
                                        min: 0,
                                        max: max,
                                        labels: {
                                            offsetX: 0,
                                            style: {fontSize: "14px"},
                                            formatter: (value) => (value === max) ? Math.ceil(value) :  Math.round(value),
                                        },
                                    },
                                }}
                                series={series}
                                type="bar"
                                width="100%"
                                height="290px"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </DashboardPane>
    )
};

export default ChartViewByDay;
