import React from 'react';
import RadioFields from "../../../../../formElements/RadioFields";
import {useTranslation} from "react-i18next";
import {Box} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',
        paddingBottom: '16px',
        marginBottom: '32px',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('767')]: {
            display: 'block'
        }
    },
    itemCard: {
        paddingBottom: '32px',
        position: 'relative',
        [theme.breakpoints.down('767')]: {
            paddingBottom: '16px'
        },

        '& .card_helper': {
            position: "absolute",
            bottom: "4px",
            right: "0",
            whiteSpace: "nowrap",
            [theme.breakpoints.down('767')]: {
                position: "relative",
                bottom: "0",
                right: "initial",
                left: '0',
                display: 'block',
                whiteSpace: "initial"
            }
        }
    },
    itemTitle: {
        marginRight: '24px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.71429',
        color: 'var(--charcoal-primary)',
        [theme.breakpoints.down('767')]: {
            marginRight: '0',
            marginBottom: '12px',
            display: "block"
        }
    },
    itemContent: {
        marginLeft: 'auto',
        [theme.breakpoints.down('767')]: {
            marginLeft: '0',
            marginRight: 'auto'
        }
    },
    itemRadio: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0',
            '&:last-child': {
                marginRight: '0'
            }
        }
    }
}))

const Permissions = (props) => {
    const {role, handleChange, values, disabled} = props;

    const classes = useStyles();
    const {t} = useTranslation();
    const permissions = ['account', 'card', 'user', 'businessManagement'];

    const getOptions = () => ['no', 'view', 'viewAndEdit'].map((option) => ( {label: t(`userPermissions.${option}`), value: option}));

    return (
        <Box>
            {permissions.map((permission) => {
                const fieldName = `configurablePermissions[${role}][${permission}]`;

                return (
                    <Box className={`${classes.item} ${permission === "card" ? classes.itemCard : ""}`} key={permission}>
                        <Box className={classes.itemTitle}>{t(`userPermissions.${permission}`)}</Box>
                        <Box className={classes.itemContent}>
                            <RadioFields
                                disabled={disabled}
                                options={getOptions()}
                                name={fieldName}
                                value={values[fieldName]}
                                onRadioChange={(val) => handleChange(fieldName, val)}
                                className={classes.itemRadio}

                            />
                            {permission === "card" && (
                                <Box className="card_helper">
                                    <FormHelperText>{t("userPermissionsAccountText")}</FormHelperText>
                                </Box>
                            )}
                        </Box>

                    </Box>
                )
            })}
        </Box>
    );
};

export default Permissions;
