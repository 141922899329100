import React from 'react';
import {withRouter, Route, Switch} from 'react-router-dom'
import GroupsTable from "./GroupsTable";
import Group from "./Group";
import AddGroup from "./AddGroup";

const Groups = (props) => {
    const {match, underBarRef} = props;
    const {url} = match;

    return (
        <>
            <Switch>
                <Route exact path={url} render={(props) => <GroupsTable {...props} underBarRef={underBarRef}/>}/>
                <Route exact path={`${url}/add`} render={(props) => <AddGroup {...props} underBarRef={underBarRef}/>}/>
                <Route exact path={`${url}/:id`} render={(props) => <Group {...props} underBarRef={underBarRef}/>}/>
            </Switch>
        </>
    );
};

export default withRouter(Groups);
