import React, {useEffect, useState} from 'react';
import IMask from 'imask';
import InputField from "../InputField";
import isNumeric from "../../../helpers/isNumeric";



const NumericInput = (props) => {
    const {decimal, onInputChange, value, maskProps, ...restProps} = props;

    const [displayVal, setDisplayVal] = useState("");
    const [isFocused, setIsFocused] = useState(false);

    const imaskRef = React.useRef(IMask.createMask({
        mask: Number,
        mapToRadix: ['.', ','],
        thousandsSeparator: ' ',
        padFractionalZeros: true,
        normalizeZeros: false,
        radix:".",
        scale: 2,
        ...maskProps
    }));

    useEffect(() => {
        if(decimal){
            imaskRef.current.updateOptions({radix: decimal});
        }
    },[decimal, maskProps]);


    const getDisplayValue = () => {
        const intValue = parseFloat(value);
        if(isNumeric(intValue)){
            const maskedValue = imaskRef.current.resolve(value.toString());
            setDisplayVal(maskedValue);
        }else{
            setDisplayVal("");
        }
    }

    useEffect(() => {
        getDisplayValue();
    },[value]);


    const onChange = (value) => {

        const maskedValue = imaskRef.current.resolve(value);
        const {unmaskedValue} = imaskRef.current;

        setDisplayVal(maskedValue);

        const intValue = parseFloat(unmaskedValue);

        onInputChange(restProps.name, isNumeric(intValue) ? intValue : undefined);
    }

    useEffect(() => {
        if(isFocused){
            imaskRef.current.updateOptions({padFractionalZeros: false});
        }else{
            imaskRef.current.updateOptions({padFractionalZeros: true});
        }

        getDisplayValue();

    },[isFocused]);

    return <InputField {...restProps} onInputChange={onChange} value={displayVal} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}/>
};

export default NumericInput;
