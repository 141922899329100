import React from 'react'

import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";
import Transactions from "../userSection/content/Transactions";

const TransactionsPage = (props) => {
    const {t} = useTranslation();
    const {drawerOuterRef, underBarRef} = props;

    return (
        <>
            <UserSectionWrapper>
                <CompanyCountryCheck>
                    <Can I="read" a={"card"} passThrough>
                        {(can) => (
                            can ? <Transactions drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/> : <div>{t("card.noPermissionToRead")}</div>
                        )}
                    </Can>
                </CompanyCountryCheck>
            </UserSectionWrapper>
        </>
    )
}



export default TransactionsPage
