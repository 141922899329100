import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import axios from 'axios';
import { groupBy, filter } from 'lodash'

import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import Pagination from "../../../../Pagination";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import {ReactComponent as CloseThin} from "../../../../../assets/closeThin.svg";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as Pen} from "../../../../../assets/pen.svg";
import {ReactComponent as Download} from "../../../../../assets/download.svg";
import {makeStyles, CircularProgress} from "@material-ui/core";
import FormatDate from "../../../../FormatDate";
import Box from "@material-ui/core/Box";
import downloadFile from "../../../../../helpers/downloadFile";
import {Can} from "../../../../../Context/Can";
import {subject} from "@casl/ability";
import PlusButton from "../../../../PlusButton";
import Portal from "@material-ui/core/Portal";
import {updateUserState} from "../../../../User/actions";

import {confirm} from '../../../../../helpers/confirm'
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import ShortenText from '../../../../../shared/ShortenText'
import DialogSendWallet from '../DialogSendWallet'
import * as loader from "../../../../../assets/animations/loader";

const service = new Api();

const useStyles = makeStyles((theme) => (
    {
        head: {
            display: 'flex',
            position: 'relative',
            minHeight: '40px',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid var(--very-light-pink-secondary)',
            marginBottom: '56px',
            paddingBottom: '32px',
            [theme.breakpoints.down('1023')]: {
                marginBottom: '34px',
                paddingBottom: '24px',

            },
            [theme.breakpoints.down('767')]: {
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                gap: '16px'
            },
        },

       items: {
            position: 'relative',
       },

       item: {
            display: 'flex',
            paddingBottom: '16px',
            [theme.breakpoints.down('767')]: {
                flexFlow: 'column nowrap',
                gap: "16px"
            },
            "&:not(:last-child)": {
                marginBottom: '32px',
                borderBottom: '1px solid var(--very-light-pink-secondary)',

                [theme.breakpoints.down('1023')]: {
                    marginBottom: '24px'
                },
            }
       },

       itemLabel: {
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '1.71429',
            color: 'var(--charcoal-primary)',
            marginRight: '24px',
            [theme.breakpoints.down('767')]: {
                marginRight: '0'
            }
       },

       itemContent: {
            marginLeft: 'auto',
            position: 'relative',
            [theme.breakpoints.down('767')]: {
                marginLeft: '0'
            }
       },

       itemWalletAddress: {
            "& $itemLabel":{
                marginRight: '0',
                marginBottom: '8px'
            },
            "& $itemContent": {
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '1.33333',
                color: 'var(--battelship-grey)',

                [theme.breakpoints.down('sm')]: {
                    maxWidth: '270px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }
            }
       },

       itemLabelCenter: {
            alignItems: 'center',
            [theme.breakpoints.down('767')]: {
                alignItems: 'flex-start'
            }
       },

       currency: {
            position: 'relative'
       },

       currencyTitle: {
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '1.6',
            [theme.breakpoints.down('767')]: {
                fontSize: "18px"
            }
       },

       currencyItems: {
            display: 'flex',
            margin: '24px -24px 0',
            overflow: 'auto',
            overflowX: 'auto',
            scrollbarWidth: 'none',

            "&:before": {
                content: `""`,
                display: 'block',
                flexShrink: '0',
                width: '24px',
                height: '1px'
            },
            "&:after": {
                content: `""`,
                display: 'block',
                flexShrink: '0',
                width: '24px',
                height: '1px'
            }
       },

       currencyCard: {
            position: 'relative',
            flexShrink: '0',
            width: '280px',
            height: '166px',
            borderRadius: '12px',
            overflow: 'hidden',
            display: 'flex',
            flexFlow: 'column noWrap',
            backgroundColor: 'var(--very-light-pink-primary)',

            "&:not(:last-child)": {
                marginRight: "12px"
            }
       },

       currencyCardInner: {
            display: 'flex',
            flexFlow: 'column nowrap',
            flex: '1',
       },

       currencyTitleCard: {
            fontSize: '18px',
            [theme.breakpoints.down('767')]: {
                fontSize: '16px'
            }
       },
       currencyText: {
            fontSize: '13px',
            fontWeight: '600',
            lineHeight: '1.23077',
            color: 'var(--cool-grey)'
       }
    }
));

const TanganyWallet = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {userData, updateUserState, underBarRef} = props;
    const [tanganyWalletBalances, setTanganyWalletBalances] = useState(null)
    const [openModalSendWallet, setOpenModalSendWallet] = useState(false)
    const [currency, setCurrency] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const requestCreateNewWallet = async () => {
        setIsLoading(true);

        try {
            await service.generateWallet();

            const userResult = await service.getCurrentUser();
            const { data } = userResult;
            updateUserState(data);

            setIsLoading(false);
        } catch {
            setIsLoading(false);
        };

    };

    const getTanganyWalletBalances = async () => {
        setIsLoading(true);
        try {
            const host = localStorage?.getItem('host')
            const { data } = await service.getTanganyWalletBalances(host);
            setTanganyWalletBalances(data.wallet)
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        };

    };

    useEffect(() => {
        if (userData && userData.walletAddress) {
            getTanganyWalletBalances()
        }

        // eslint-disable-next-line
    }, [userData]);

    const onSendWallet = () => {
        setCurrency(null);
        setOpenModalSendWallet(true);
    }

    const onSendToken = (currency) => {
        setCurrency(currency);
        setOpenModalSendWallet(true);
    }

    console.log('tanganyWalletBalances', tanganyWalletBalances);

    return (
        <>
            {openModalSendWallet ? (
                <DialogSendWallet
                    setOpen={setOpenModalSendWallet}
                    title={t("Send Wallet")}
                    getTanganyWalletBalances={getTanganyWalletBalances}
                    currency={currency}
                />
            ) : null}
            <div className='card'>
                <div className={classes.head}>
                    <div className={`title-blue text-weight-300 margin-bottom-0`}>
                        {/* NEED TRANSLATION */}
                        {t("Wallet Address")}
                    </div>
                </div>

                <div className={classes.items}>
                    {userData && userData.walletAddress && (
                        <div className={`${classes.item} ${classes.itemWalletAddress}`}>
                            <div className={classes.itemLabel}>
                                {/* NEED TRANSLATION */}
                                {t('Your Wallet')}
                            </div>

                            <div className={classes.itemContent}>
                                {userData.walletAddress}
                            </div>
                        </div>
                    )}

                    {!userData.walletAddress && (
                        <>
                            <div className={`${classes.item} ${classes.itemLabelCenter}`}>
                                <div className={classes.itemLabel}>
                                    {t("Please generate the new wallet")}
                                </div>

                                <div className={classes.itemContent}>
                                    {isLoading && (
                                        <>
                                            {/* <LottieAnimation animationData={loader} width={32} height={32}/> */}
                                            <CircularProgress />
                                        </>
                                    )}

                                    {!isLoading && (
                                        <Button
                                            className={`btn-small btn-stroke`}
                                            onClick={requestCreateNewWallet}
                                        >
                                            {/* NEED TRANSLATION */}
                                            {t("Generate one")}
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    { tanganyWalletBalances && (
                        <>
                            <div className={`${classes.item} ${classes.itemLabelCenter}`}>
                                <div className={classes.itemLabel}>
                                    {`${t("Balance")}: ${
                                        tanganyWalletBalances.balance
                                    }`}
                                </div>

                                <div className={classes.itemContent}>
                                    <Button
                                        className={`btn-small btn-stroke`}
                                        onClick={onSendWallet}
                                    >
                                        {t("Send")}
                                    </Button>
                                </div>
                            </div>

                            <div className="width-full height-32">{' '}</div>
                            <div className={classes.currency}>
                                <div className={classes.currencyTitle}>
                                    {`${t("Currency")}: ${
                                        tanganyWalletBalances.currency
                                    }`}
                                </div>

                                <div className={classes.currencyItems}>
                                    {tanganyWalletBalances.tokenBalances && tanganyWalletBalances.tokenBalances.map((balance, index) => {
                                        return (
                                            <div className={classes.currencyCard} key={index}>
                                                <div className={classes.currencyCardInner}>
                                                    <div className={`text-weight-300 margin-bottom-8 ${classes.currencyTitleCard}`}>
                                                        {balance.currency}
                                                    </div>

                                                    <div className={classes.currencyText}>
                                                        {balance.balance}
                                                    </div>
                                                </div>

                                                <Button
                                                    className={`btn-small`}
                                                    onClick={() => onSendToken(balance.currency)}
                                                >
                                                    {t("Transfer")}
                                                </Button>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    const {user, tabs} = state;
    const {userData} = user;
    const {regional={}} = user.settings;
    const {dateFormat} = regional;


    return {
        dateFormat,
        tabs: tabs["reports"],
        userId: userData._id,
        userData,
    }
}


export default compose(
    withRouter,
    connect(mapStateToProps, {setMessage, updateUserState})
)(TanganyWallet);
