import React, {useState} from 'react';
import {connect} from "react-redux";
import {Grid, Button, FormControl} from '@material-ui/core';
import {makeStyles} from "@material-ui/core";
import Api from "../../../../../../../services/api";
import {setMessage} from "../../../../../../Messages/actions";
import {fetchUsers} from "../../../actions";
import useForm from "../../../../../../../hooks/useForm";
import InputField from "../../../../../../formElements/InputField";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import getEmailRegex from "../../../../../../../helpers/getEmailRegex";
import Box from "@material-ui/core/Box";
const service = new Api();

const useStyles = makeStyles(theme => ({
        inputWrapper: {
            paddingRight: 20,
        },
    }
));

const EmailForm = ({setShowForm, setMessage, fetchUsers, location}) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();

    const validate = values => {
        const emailRegex = getEmailRegex();
        const errors = {};
        if (!values.email) {
            errors.email = t('errors.required', {field: t("formFields.email")});
        }
        if (!values.firstName) {
            errors.firstName = t('errors.required', {field: t("formFields.firstName")});
        }
        if (!values.lastName) {
            errors.lastName = t('errors.required', {field: t("formFields.lastName")});
        }
        if (values.email && !emailRegex.test(values.email)) {
            errors.email = t('errors.notValid', {field: t("formFields.email")});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );

    async function submit(){
        setIsLoading(true);
        const {email, firstName, lastName} = values;

        const {search} = location;
        const queryObject = queryString.parse(search);

        try {
            const response = await service.createInvitedUser({email, firstName, lastName});
            setIsLoading(false);
            setMessage(t("emailWasSentTo", {email:response.data.user.email}), 'success');

            setShowForm(false); // hide form, and show Invite uer button
            fetchUsers({...queryObject});

        }catch (e) {
            setServerErrors(e);
            setIsLoading(false);
        }
    }

    return (
        <Grid item>
            <form onSubmit={handleSubmit} noValidate>
                <Grid container alignItems={"flex-end"}>
                    <Grid item>
                        <Box className={classes.inputWrapper}>
                            <FormControl fullWidth error={!!errors.firstName}>
                                <InputField
                                    name="firstName"
                                    label={t("formFields.firstName")}
                                    value={values["firstName"]}
                                    onInputChange={(val) => handleChange("firstName", val)}
                                    error={errors["firstName"]}
                                />
                            </FormControl>
                        </Box>

                    </Grid>
                    <Grid item>
                        <Box className={classes.inputWrapper}>
                            <FormControl fullWidth error={!!errors.lastName}>
                                <InputField
                                    name="lastName"
                                    label={t("formFields.lastName")}
                                    value={values["lastName"]}
                                    onInputChange={(val) => handleChange("lastName", val)}
                                    error={errors["lastName"]}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box className={classes.inputWrapper}>
                            <FormControl fullWidth error={!!errors.email}>
                                <InputField
                                    name="email"
                                    label={t("formFields.email")}
                                    type="email"
                                    value={values["email"]}
                                    onInputChange={(val) => handleChange("email", val)}
                                    error={errors["email"]}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.button}
                            disabled={isLoading}
                        >
                            {t("formFields.sendInvitationEmail")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};



export default connect(
    null,
    {setMessage, fetchUsers}
)(withRouter(EmailForm))



