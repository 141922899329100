import React, {useEffect, useState} from 'react'
import Settings from '../userSection/content/Settings'
import {useTranslation} from "react-i18next";
import Portal from "@material-ui/core/Portal";


const UserPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef} = props;
    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        setIsMount(true);
    },[]);


    return (
        <>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">{t("settingsTitle")}</div>
                </Portal>
            )}
            <Settings/>
        </>
    )

}



export default UserPage
