import React, {useState} from 'react';
// import Greeting from "./Greeting";
import {compose} from "redux";
import {connect} from "react-redux";
import AccountsInfo from "./AccountsInfo";
import Grid from "@material-ui/core/Grid";

import ExpensesInfo from "./ExpensesInfo";
import WaitingForApproval from "./WaitingForApproval";
import PersonalToDo from "./PersonalToDo";
import {Can} from "../../../../Context/Can";
import {Box} from "@material-ui/core";
import useActiveTab from "../../../../hooks/useActiveTab";

const dashboardTableMinHeight = '320px';

const Dashboard = (props) => {
    const {user, underBarRef, drawerOuterRef} = props;

    const activeTab = useActiveTab('dashboard');

    return (
        <Box paddingLeft={'24px'} paddingRight={'24px'}>
            <Grid container spacing={4}>
                {/*<Greeting user={user}/>*/}
                <Can I="read" a="expense">
                    <Grid item xs={12}>
                        <ExpensesInfo drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/>
                    </Grid>
                </Can>
                <Can I="read" a="expense">
                    <Box component={Grid} item flexBasis={"500px"} flexGrow={1}>
                        <WaitingForApproval dashboardTableMinHeight={dashboardTableMinHeight}/>
                    </Box>
                    {activeTab === 'approver' && <Box component={Grid} item flexBasis={"500px"} flexGrow={1}/> /* show empty block for approver tab to fix block width */}
                </Can>
                <Can I="read" a="account">
                    {["auditor", "administrator"].includes(activeTab) && (
                        <Box component={Grid} item flexBasis={"500px"} flexGrow={1}>
                            <AccountsInfo dashboardTableMinHeight={dashboardTableMinHeight}/>
                        </Box>
                    )}
                </Can>
                {activeTab === "personal" && (
                    <Box component={Grid} item flexBasis={"500px"} flexGrow={1}>
                        <PersonalToDo user={user}/>
                    </Box>
                )}
            </Grid>
        </Box>
    );
};

const mapStateToProps = ({user}) => {
    return {
        user,
    }
}
export default compose(
    connect(mapStateToProps)
)(Dashboard);
