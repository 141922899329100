import React, {useEffect, useRef, useState} from 'react';
import Chart from "react-apexcharts";
import {makeStyles} from "@material-ui/core";

import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import formatMoney from "../../../../../../helpers/formatMoney";
import DashboardPane from "../../../../../DashboardPane";
import CircularProgress from '@material-ui/core/CircularProgress'

import * as animationData from '../../../../../../assets/animations/list'
import * as loader from "../../../../../../assets/animations/loader";
import useActiveTab from "../../../../../../hooks/useActiveTab";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    spinnerWrapper: {
        position:"absolute",
        top:0,
        left:0,
        right:0,
        bottom:0,
        zIndex:1
    },
    chartWrapper: {
        overflowY:"hidden",
        overflowX:"auto",
        position:"relative",
        minHeight: "320px",
        display: "flex",
        alignItems: "center",
        "& .apexcharts-canvas":{
            margin:"0 auto"
        },
        "& .apexcharts-legend.position-right":{
            justifyContent:"center",
            maxWidth:"50%"
        },
        "& .apexcharts-legend-series":{
            justifyContent:"space-between",
            // alignItems:"center",
            display:"flex"
        },
        "& .apexcharts-legend-text":{
            flexGrow:1,
            display: "flex",
            justifyContent: "space-between",
            fontSize:"15px!important",
            color:"#8A96A0!important"
        },
        "& .apexcharts-legend-text-amount":{
            flexShrink: 0,
            paddingLeft:"15px"
        },
        "& .apexcharts-legend-text-category":{
            // paddingLeft:"10px",
            // paddingRight:"10px",
            // maxWidth:"200px",

            // whiteSpace: "nowrap",
            // width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        "& .apexcharts-legend-marker":{
            alignSelf: "center",
            flexShrink: 0
        }
    },
    chartInner:{
        flexGrow:1,
        width:'500px'
    }
}));



const ChartViewByCategory = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {expenses, isLoading, decimal, currency, isFilterOpen} = props;

    // const apexRef = useRef(null);

    // const [chartWidth, setChartWidth] = useState(0);
    // const isMenuOpen =  useSelector(({drawerMain}) => {
    //     return drawerMain.isOpen;
    //     return false
    // });

    // useEffect(() => {
    //     if(chartWidth && apexRef.current){
    //
    //         if(chartWidth < 430){
    //             apexRef.current.updateOptions({
    //                 legend:{
    //                     position:"bottom"
    //                 }
    //             });
    //         }else{
    //             apexRef.current.updateOptions({
    //                 legend:{
    //                     position:"right"
    //                 }
    //             });
    //         }
    //     }
    //
    // },[chartWidth, isFilterOpen, isMenuOpen]);

    // const getChartRef = (e) => {
    //     apexRef.current = e;
    //     // setChartWidth(e.el.offsetWidth);
    // }

    const activeTab = useActiveTab('dashboard');

    const getTitle = () => {
        switch (activeTab) {
            case "personal" :
                return t("dashboard.expensesChartTitleMy");
            case "approver" :
            case "manager" :
                return t("dashboard.expensesChartTitleTeam");
            default :  return t("dashboard.expensesChartTitle");
        }
    };

    const title = getTitle();

    return (
        <DashboardPane title={title}>
            <Box className={classes.chartWrapper}>
                <Box className={classes.chartInner}>
                    {isLoading ? (
                        <Box className={classes.spinnerWrapper} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {expenses.length > 0 ? (
                                <Chart
                                    options={
                                        {
                                            tooltip: {
                                                y: {
                                                    formatter: (value) => formatMoney(value, decimal),
                                                },
                                            },
                                            chart: {
                                                type: 'donut',
                                                // events: {
                                                //     updated: getChartRef,
                                                //     mounted: getChartRef
                                                // }
                                            },
                                            colors:['#24DAC4', '#5097D4', '#7A5FDB', '#32C5FF'],
                                            stroke: {
                                                width: 0
                                            },
                                            labels: expenses.map(({name}) => name)
                                            // formatter: (value) => formatMoney(value, decimal),
                                            ,
                                            plotOptions: {
                                                pie: {
                                                    customScale: 0.8,
                                                    expandOnClick: false,
                                                    donut: {
                                                        size: '60%',
                                                    }
                                                },

                                            },
                                            dataLabels: {
                                                enabled: false
                                            },
                                            legend: {
                                                formatter: function(val, opts) {
                                                    const amount = opts.w.globals.series[opts.seriesIndex];
                                                    return `<span class="apexcharts-legend-text-category">${val}</span><span class="apexcharts-legend-text-amount">${currency} ${formatMoney(amount, decimal)}</span>`
                                                },
                                                markers: {
                                                    width: 8,
                                                    height: 8,
                                                    radius: 4,
                                                },
                                                itemMargin: {
                                                    vertical: 5
                                                },
                                                offsetY: -20,
                                                position: "right"
                                            },
                                        }
                                    }
                                    series={expenses.map(({totalAmountExchanged}) => parseFloat(totalAmountExchanged))}
                                    type="donut"
                                />
                            ) : (
                                <Box>
                                    <CircularProgress />
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </DashboardPane>

    );
};

export default ChartViewByCategory;
