import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import useMounted from "../../../../../hooks/useMounted";
import Portal from "@material-ui/core/Portal";
// import TopComponent from "../TopComponent";
import TopComponentInvesthub from '../TopComponentInvesthub';
import VolveMaterialTable from "../../../../VolveMaterialTable";
import Pagination from "../../../../Pagination";
import {useTranslation} from "react-i18next";
import checkClick from "../../../../../helpers/checkClick";
import Api from "../../../../../services/api";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    tableDepartments: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    }
}))


const DepartmentsTable = (props) => {
    const classes = useStyle();
    const isMounted = useMounted();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [departments, setDepartments] = useState([]);

    const [info, setInfo] = useState({
        page: 0,
        total: 0,
        pageSize: 0
    });

    const {page, total, pageSize } = info;

    const {history, underBarRef, match, handleErrorMessage} = props;

    const fetchItems = async (query) => {

        try {
            setIsLoading(true);
            const response = await service.getDepartments(query);
            const {result, info} = response.data;
            setInfo(info);
            setDepartments((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        fetchItems()
    },[]);


    const columns = [
        {
            title: t('thead.name'),
            field: 'name',
        },
    ];

    const handleChangePage = (event, newPage) => {
        return fetchItems({page:newPage});
    }


    const rowHandlerClick = async (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`);
        }
    }

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>
                </Portal>
            )}

            <div className={`card ${classes.tableDepartments}`}>
                <TopComponentInvesthub history={history}/>

                <VolveMaterialTable
                    columns={columns}
                    isLoading={isLoading}
                    data={departments}
                    onRowClick={rowHandlerClick}
                />
                <Pagination
                    total={total}
                    pageSize={pageSize}
                    page={page}
                    isLoading={isLoading}
                    onChangePage={handleChangePage}
                    loadMore
                />
            </div>
        </>
    );
};

export default compose(
    connect(null, {handleErrorMessage, setMessage})
)(DepartmentsTable);
