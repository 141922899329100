import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import PhoneField from "../formElements/PhoneField";
import InputField from "../formElements/InputField";
import SelectField from "../formElements/SelectField";
import RadioFields from "../formElements/RadioFields";
import {useTranslation} from "react-i18next";
import SelectUsersForApproval from "./SelectUsersForApproval";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import {Link} from "react-router-dom";
import CheckBoxField from "../formElements/CheckBoxField";
import {subject} from "@casl/ability";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(theme => ({
    itemSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemFieldThird: {
        flex: '0 0 calc(33.333% - 16px)',
        width: 'calc(33.333% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('1259')]: {
            flex: '0 0 calc(50% - 16px)',
            width: 'calc(50% - 16px)',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        }
    },
    itemFieldHalf: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        }
    },
    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    },
    itemRadio: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0'
        },
        '& .MuiFormControlLabel-root:not(:last-child)': {
            marginRight: '16px'
        }
    },

    itemFieldCards: {
        '& .MuiFormLabel-root': {
            color: 'var(--charcoal-primary)',
            padding: '0',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '1.71429',
            marginBottom: '6px'
        },
        '& .MuiPaper-root': {
            marginBottom: '0',
            gap: '8px',
            backgroundColor: 'transparent'
        },
    },

    btnLast: {
        marginLeft: '12px'
    },

    groupCheckbox: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0'
        },
        '& .MuiFormControlLabel-root:not(:last-child)': {
            marginRight: '16px'
        }
    },
    inputWrapper: {
        [theme.breakpoints.up('md')]: {
            paddingRight: "20px",
        },
    },
    textAreaWrapper: {
        '& .MuiInput-formControl':{
            paddingBottom:0,
        },
        '& textarea:not([disabled])':{
            padding:"10px 16px",
            transition: 'all .2s'
        },
        '& .Mui-focused textarea:not([disabled])': {
            background: 'var(--white)',
            borderColor: 'var(--cool-grey)'
        }
    },
    cards: {
        display: 'flex',
        // justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 0,
        margin: "0 0 20px 0",
    },
}));


const UserForm = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        roles,
        titles,
        countries,
        groups,
        branches,
        departments,
        handleChange,
        handleSubmit,
        values,
        errors,
        isLoading,
        isDataFetching,
        user,
        match,
        ability,
        onRemoveUser
    } = props;


    const checkDisabledField = (field) => {
        return !ability.can("update", subject("user", user), field)
    }

    const onRolesChange = (val) => {
        const copyRoles = [...values.roles];
        if(values.roles.includes(val)){
            const newRoles = copyRoles.filter((role) => role !== val);
            handleChange("roles", newRoles);
        }else{
            handleChange("roles", [...copyRoles, val]);
        }
    }

    const onActive = (val) => {
        const notActiveStatus = user.status === "invited" ? user.status : "notActive";
        handleChange("status", val ? "active" : notActiveStatus)
    }

    return (
        <form onSubmit={handleSubmit} noValidate>
            <div className={classes.itemSettings}>
                <FormControl fullWidth error={!!errors.email} className={`${classes.itemFieldFull} ${classes.itemRadio}`}>
                    <RadioFields
                        isLoading={isDataFetching}
                        options={titles}
                        name={"title"}
                        label={t("formFields.title")}
                        value={values["title"]}
                        onRadioChange={(val) => handleChange("title", val)}
                        error={errors["title"]}
                        disabled={checkDisabledField("title")}
                    />
                </FormControl>

                <FormControl fullWidth error={!!errors.firstName} className={classes.itemFieldThird}>
                    <InputField
                        label={t("formFields.firstName")}
                        placeholder={t("formFields.firstName")}
                        isLoading={isDataFetching}
                        name="firstName"
                        value={values["firstName"] ? values["firstName"] : ""}
                        onInputChange={(val) => handleChange("firstName", val)}
                        error={errors["firstName"]}
                        disabled={checkDisabledField("firstName")}
                    />
                </FormControl>

                <FormControl fullWidth error={!!errors["middleName"]} className={classes.itemFieldThird}>
                    <InputField
                        isLoading={isDataFetching}
                        label={t("formFields.middleName")}
                        placeholder={t("formFields.middleName")}
                        name="middleName"
                        value={values["middleName"]}
                        onInputChange={(val) => handleChange("middleName", val)}
                        disabled={checkDisabledField("middleName")}
                    />
                </FormControl>

                <FormControl fullWidth error={!!errors["lastName"]} className={classes.itemFieldThird}>
                    <InputField
                        isLoading={isDataFetching}
                        label={t("formFields.lastName")}
                        placeholder={t("formFields.lastName")}
                        name="lastName"
                        type="text"
                        value={values["lastName"]}
                        onInputChange={(val) => handleChange("lastName", val)}
                        error={errors["lastName"]}
                        disabled={checkDisabledField("lastName")}
                    />
                </FormControl>

                <FormControl error={!!errors["addrl1"]} fullWidth className={classes.itemFieldHalf}>
                    <InputField
                        label={t("formFields.addrl1")}
                        placeholder={t("formFields.addrl1")}
                        name="addrl1"
                        value={values["addrl1"]}
                        onInputChange={(val) => handleChange("addrl1", val)}
                        error={errors["addrl1"]}
                        isLoading={isDataFetching}
                        disabled={checkDisabledField("addrl1")}
                    />
                </FormControl>

                <FormControl error={!!errors["addrl2"]} fullWidth className={classes.itemFieldHalf}>
                    <InputField
                        label={t("formFields.addrl2")}
                        placeholder={t("formFields.addrl2")}
                        name="addrl2"
                        value={values["addrl2"]}
                        onInputChange={(val) => handleChange("addrl2", val)}
                        error={errors["addrl2"]}
                        disabled={checkDisabledField("addrl2")}
                    />
                </FormControl>

                <FormControl error={!!errors["postCode"]} fullWidth className={classes.itemFieldThird}>
                    <InputField
                        label={t("formFields.postCode")}
                        placeholder={t("formFields.postCode")}
                        name="postCode"
                        value={values["postCode"]}
                        onInputChange={(val) => handleChange("postCode", val)}
                        error={errors["postCode"]}
                        isLoading={isDataFetching}
                        disabled={checkDisabledField("postCode")}
                    />
                </FormControl>

                <FormControl error={!!errors["city"]} fullWidth className={classes.itemFieldThird}>
                    <InputField
                        label={t("formFields.city")}
                        placeholder={t("formFields.city")}
                        name="city"
                        value={values["city"]}
                        onInputChange={(val) => handleChange("city", val)}
                        error={errors["city"]}
                        isLoading={isDataFetching}
                        disabled={checkDisabledField("city")}
                    />
                </FormControl>

                <FormControl fullWidth className={classes.itemFieldThird}>
                    <SelectField
                        label={t("formFields.country")}
                        name={"country"}
                        isLoading={isDataFetching}
                        value={values["country"]}
                        error={errors["country"]}
                        options={countries}
                        onSelectChange={(val) => handleChange("country", val)}
                        disabled={checkDisabledField("country")}
                    />
                </FormControl>

                <FormControl fullWidth error={!!errors.email} className={classes.itemFieldThird}>
                    <InputField
                        isLoading={isDataFetching}
                        label={t("formFields.email")}
                        name="email"
                        type="email"
                        value={values["email"]}
                        onInputChange={(val) => handleChange("email", val)}
                        error={errors["email"]}
                        disabled={checkDisabledField("email")}
                    />
                </FormControl>

                <FormControl fullWidth error={!!errors.phone} className={classes.itemFieldThird}>
                    <PhoneField
                        label={t("formFields.phone")}
                        isLoading={isDataFetching}
                        name="phone"
                        type="text"
                        id="phone"
                        disabled={checkDisabledField("phone")}
                        value={values["phone"] ? values["phone"] : ""}
                        onChangeHandler={(val) => handleChange("phone", val)}
                        error={errors["phone"]}
                    />
                </FormControl>

                <FormControl fullWidth className={classes.itemFieldThird}>
                    <InputField
                        label={t("formFields.volveUserId")}
                        value={user.customerNumber}
                        disabled={true}
                        isLoading={isDataFetching}
                    />
                </FormControl>

                <FormControl fullWidth className={`${classes.itemFieldFull} ${classes.groupCheckbox}`}>
                    <FormGroup row={true}>
                        {roles.map((role) => {
                            const {value, label} = role;
                            return (
                                <CheckBoxField
                                    key={value}
                                    onCheckboxChange={() => onRolesChange(value)}
                                    value={values.roles ? values.roles.includes(value) : false}
                                    label={t(label)}
                                    disabled={checkDisabledField("roles") || value === "personal"}
                                />
                            )
                        })}
                    </FormGroup>
                    {errors["roles"] && <FormHelperText error>{errors["roles"]}</FormHelperText>}
                </FormControl>

                {(values["roles"] && values["roles"].includes("approver")) && (
                    <FormControl className={classes.itemFieldFull}>
                        <SelectUsersForApproval
                            isLoading={isDataFetching}
                            handleChange={handleChange}
                            values={values["usersForApproval"]}
                            disabled={checkDisabledField("usersForApproval")}
                        />
                    </FormControl>
                )}

                <FormControl fullWidth className={classes.itemFieldFull}>
                    <CheckBoxField
                        onCheckboxChange={(val) => onActive(val)}
                        value={values["status"] === "active"}
                        name={"status"}
                        label={t("formFields.isActive")}
                        disabled={checkDisabledField("status")}
                    />
                </FormControl>

                <FormControl fullWidth className={classes.itemFieldHalf}>
                    <SelectField
                        label={t("formFields.group")}
                        name={"group"}
                        value={values["group"]}
                        error={errors["group"]}
                        options={groups}
                        onSelectChange={(val) => handleChange("group", val)}
                        disabled={checkDisabledField("group")}
                        isLoading={isDataFetching}
                    />
                </FormControl>

                <FormControl fullWidth className={classes.itemFieldHalf}>
                    <SelectField
                        label={t("formFields.branch")}
                        name={"branch"}
                        value={values["branch"]}
                        error={errors["branch"]}
                        options={branches}
                        isLoading={isDataFetching}
                        onSelectChange={(val) => handleChange("branch", val)}
                        disabled={checkDisabledField("branch")}
                    />
                    <FormHelperText>{t("important")}: {t("helpTextUserBranch")}</FormHelperText>
                </FormControl>

                <FormControl fullWidth error={!!errors["position"]} className={classes.itemFieldHalf}>
                    <InputField
                        label={t("formFields.position")}
                        placeholder={t("formFields.position")}
                        name="position"
                        isLoading={isDataFetching}
                        value={values["position"]}
                        onInputChange={(val) => handleChange("position", val)}
                        error={errors["position"]}
                        disabled={checkDisabledField("position")}
                    />
                </FormControl>

                <FormControl fullWidth error={!!errors["department"]} className={classes.itemFieldHalf}>
                    <SelectField
                        label={t("formFields.department")}
                        name={"department"}
                        value={values["department"]}
                        error={errors["department"]}
                        options={departments}
                        isLoading={isDataFetching}
                        onSelectChange={(val) => handleChange("department", val)}
                        disabled={checkDisabledField("department")}
                    />
                </FormControl>

                <FormControl fullWidth error={!!errors["personnelNumber"]} className={classes.itemFieldFull}>
                    <InputField
                        label={t("formFields.personnelNumber")}
                        placeholder={t("formFields.personnelNumber")}
                        name="personnelNumber"
                        value={values["personnelNumber"]}
                        onInputChange={(val) => handleChange("personnelNumber", val)}
                        error={errors["personnelNumber"]}
                        isLoading={isDataFetching}
                        disabled={checkDisabledField("personnelNumber")}
                    />
                </FormControl>

                <FormControl fullWidth className={`${classes.itemFieldFull} ${classes.inputWrapper} ${classes.textAreaWrapper}`}>
                    <InputField
                        name={"comment"}
                        multiline
                        rows="4"
                        label={t("formFields.comment")}
                        value={values["comment"]}
                        error={errors["comment"]}
                        disabled={checkDisabledField("comment")}
                        isLoading={isDataFetching}
                        onInputChange={(val) => handleChange("comment", val)}
                    />
                </FormControl>

                {(user.cards && user.cards.length > 0) && (
                    <FormControl fullWidth className={`${classes.itemFieldFull} ${classes.itemFieldCards}`}>
                        <FormLabel component="div" className={classes.cardsLabel}>{t("formFields.cards")}</FormLabel>
                        <Paper elevation={0} component="ul" className={classes.cards}>
                            {user.cards.map((card) => {
                                const {id, maskedPAN} = card;
                                return <li key={id}>
                                        <Chip
                                            label={maskedPAN}
                                            className={`status status-default ${classes.cardChip}`}
                                            component={Link} to={`/cards/${id}`}
                                            clickable
                                        />
                                </li>
                            })}
                        </Paper>
                    </FormControl>
                )}
            </div>

            {ability.can("update", subject("user", user)) && (
                <>
                    <Button
                        type="submit"
                        disabled={isLoading}
                    >
                        {t("formFields.save")}
                    </Button>
                    {ability.can("delete", subject("user", user)) && (
                        <Button
                            onClick={() => onRemoveUser(user._id)}
                            className={`btn-stroke ${classes.btnLast}`}
                        >
                            {t("formFields.delete")}
                        </Button>
                    )}
                </>
            )}
        </form>
    );
};


export default UserForm
