import React, {useEffect, useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Api from "../../../../../services/api";
import checkClick from "../../../../../helpers/checkClick";
import queryString from "query-string";
import Portal from "@material-ui/core/Portal";
import {useTranslation} from "react-i18next";
import useMounted from "../../../../../hooks/useMounted";
import VolveMaterialTable from "../../../../VolveMaterialTable";

const service = new Api();

const useStyles = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',
        },
    },
    tableRoles: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    }
}));

const RolesTable = (props) => {
    const {history, underBarRef} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const isMounted = useMounted();

    const [roles, setRoles] = useState([]);

    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const response = await service.getGroupedRoles();
                const {data} = response;
                const {result} = data;
                setRoles(result);
                setIsLoading(false);
            }catch (e) {
                setRoles([]);
                setIsLoading(false);
            }
        })();
    },[]);

    const rowHandlerClick = (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push({
                pathname:`/users`,
                search: `?${queryString.stringify({roles:rowData.role})}`,
            })
        }
    }

    const columns = [
        {
            title: t('thead.role'),
            field: 'role',
        },
        {
            title: t('thead.qtyUsers'),
            field: 'qty',
        },
    ];

    return (
        <div className={classes.wrapper}>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>
                    {/* <Typography variant="h1"></Typography> */}
                </Portal>
            )}
           <div className={`card ${classes.tableRoles}`}>
                <div className={classes.head}>
                    <div className="title-blue text-weight-300 margin-bottom-0">
                        {t("roles.mainTitle")}
                    </div>
                </div>
               <VolveMaterialTable
                   columns={columns}
                   isLoading={isLoading}
                   data={roles}
                   onRowClick={rowHandlerClick}
               />
           </div>
        </div>
    );
};

export default RolesTable
