import React, {useState} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Popper from "@material-ui/core/Popper";
import SearchInput from "../SearchInput";
import {handleErrorMessage} from "../../../../Messages/actions";
import {useDebouncedCallback} from "use-debounce";
import {useDispatch} from "react-redux";
const minSymbols = 1;


const SearchAutocomplete = (props) => {
    const {getOptions, onOptionClick, renderOption, getOptionLabel, role, inputValue, handleChange, handleSubmit, getOptionPlaceholder} = props;

    const dispatch = useDispatch();

    const [autocompleteIsSearching, setAutocompleteIsSearching] = useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);


    const onGetOptions = async (searchValue) => {
        if(searchValue.length < minSymbols){
            setAutocompleteOptions([]);
        }else{
            try {
                setAutocompleteIsSearching(true);

                const response = await getOptions({searchValue, role});
                setAutocompleteOptions(response.data.result);
            } catch (e) {
                console.log(e);
                dispatch(handleErrorMessage(e));
            } finally {
                setAutocompleteIsSearching(false);
            }
        }

    }

    const debounced = useDebouncedCallback(onGetOptions, 1000);
    const onInputChange = (e, val) => {
        handleChange("searchValue", val);
        debounced(val);
    };

    const onHandleSubmit = (e) => {
        handleSubmit(e);
        setAutocompleteOptions([]);

    }

    return (
        <Autocomplete
            freeSolo={true}
            inputValue={inputValue ? inputValue : "" }
            loading={autocompleteIsSearching}
            options={autocompleteOptions}
            onChange={onOptionClick}
            onInputChange={onInputChange}
            PopperComponent={(props) => <Popper {...props} style={{ width: 250 }} placement='bottom-start' />}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => {
                const {endAdornment, className, ...restInputProps} = params.InputProps;
                return <SearchInput {...params} InputProps={restInputProps} handleSubmit={onHandleSubmit} searchInputPlaceholder={getOptionPlaceholder} />
            }}
        />

    );
};

export default SearchAutocomplete;
