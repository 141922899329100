import React, {useContext, useEffect, useState} from 'react';
import useMounted from "../../../../../hooks/useMounted";
import Portal from "@material-ui/core/Portal";
import {useTranslation} from "react-i18next";
import AdvancedSettingsForm from "./AdvancedSettingsForm";

import useForm2 from "../../../../../hooks/useForm2";
import {AbilityContext, Can} from "../../../../../Context/Can";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {updateAdvancedSettings} from "../../../../User/actions";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";
import {Prompt} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const service = new Api();

const useStyles = makeStyles(theme => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '33px',
            paddingBottom: '24px',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },
    },
    button: {
        marginTop: '24px'
    }
}))

const Advanced = (props) => {
    const classes = useStyles();
    const {underBarRef} = props;
    const {t} = useTranslation();
    const isMounted = useMounted();
    const dispatch = useDispatch();
    const ability = useContext(AbilityContext);

    const [isLoading, setIsLoading] = useState(false);
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();

    const advancedSettings = useSelector(state => {
        const {userData} = state.user;
        const {company={}} = userData;
        const {advancedSettings = {}} = company;
        return advancedSettings
    });


    useEffect(() => {
        if(advancedSettings){
            const {
                expenseCategoryRequired,
                expenseCommentRequired,
                expenseCountryRequired,
                expenseMerchantIsRequired,
                expenseProjectRequired,
                expenseProjectShow,
                expenseReimbursableActive
            } = advancedSettings;

            fillFormValues({
                expenseCategoryRequired,
                expenseCommentRequired,
                expenseCountryRequired,
                expenseMerchantIsRequired,
                expenseProjectRequired,
                expenseProjectShow,
                expenseReimbursableActive
            });
        }


    },[advancedSettings]);



    const submit = async () => {
          try{
              setIsLoading(true);
              const response = await service.updateCompanyAdvancedSettings(values);
              const {advancedSettings} = response.data;
              dispatch(updateAdvancedSettings(advancedSettings));
              setValuesWasChanged(false);
              dispatch(setMessage(t("success"), 'success'));
          }catch (e) {
              console.log(e);
              dispatch(handleErrorMessage(e));
          }finally {
              setIsLoading(false);
          }

    }

    const validate = () => ({});

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );


    const handleChangeWithDetectChanges = (name, value) => {

        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true);
        }
        handleChange(name, value)
    }

    const checkDisabledField = (field) => {
        const hasAbility = ability.can("update", "company");

        if(field === "expenseProjectRequired" && values["expenseProjectShow"] === false){
            // disable field
            return true;
        }

        return !hasAbility;
    }

    return (
        <>
            <Prompt
                when={valuesWasChanged}
                message={t("unsavedChanges")}
            />
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>
                </Portal>
            )}

            <div className="card">
                <div className={classes.head}>
                    <div className="title-blue text-weight-300 margin-bottom-0">
                        {t("advancedSettings.mainTitle")}
                    </div>
                </div>
                <AdvancedSettingsForm
                    handleChange={handleChangeWithDetectChanges}
                    handleSubmit={handleSubmit}
                    values={values}
                    errors={errors}
                    checkDisabledField={checkDisabledField}
                />
                <Can I={"update"} a={"company"}>
                    <Button
                        className={classes.button}
                        disabled={isLoading}
                        onClick={handleSubmit}
                    >
                        {t("formFields.save")}
                    </Button>
                </Can>
            </div>

        </>
    );
};

export default Advanced;
