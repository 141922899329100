import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import Api from "../../../../../../services/api";
import {connect} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import useForm from "../../../../../../hooks/useForm";
import {makeStyles} from "@material-ui/core";
import ExpenseRow from "./ExpenseRow";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Skeleton from "react-loading-skeleton";
import {Prompt, withRouter} from "react-router-dom";
import {useTranslation} from "react-i18next";
import BackButton from "../../../../../BackButton";
import isDateValid from "../../../../../../helpers/idValidDate";
import useBeforeUnload from "../../../../../../hooks/useBeforeUnload";
import Portal from "@material-ui/core/Portal";
import Box from "@material-ui/core/Box";
const service = new Api();

const useStyles = makeStyles(theme => ({
    fieldsWrapper: {
        overflowX: "auto",
        marginBottom: "20px"
    },
    submitBtn: {
        marginRight: "20px"
    }
}));


const BatchAdd = (props) => {
    const classes = useStyles();
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();
    const {t} = useTranslation();
    const {handleErrorMessage, setMessage, history, dateFormat, userId, underBarRef, decimal} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [rowIndexes, setRowIndexes] = useState([0,1]);

    // static data for form
    const [currencies, setCurrencies] = useState([]);
    const [categories, setCategories] = useState([]);

    const [validationHandler, setValidationHandler] = useState(() => () => ({}));

    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        setIsMount(true);
    },[]);

    useEffect(() => {

        const validateAllItems = () => {

            return (values) => {

                return rowIndexes.reduce((errors, errorIdx) => {

                    if(!isDateValid(values[`expenses[${errorIdx}][date]`])){
                        errors[`expenses[${errorIdx}][date]`] =  t('errors.notValid', {field: "$t(formFields.date)"});
                    }
                    if (!values[`expenses[${errorIdx}][amount]`]) {
                        errors[`expenses[${errorIdx}][amount]`] = t('errors.required', {field: "$t(formFields.amount)"});
                    }

                    return errors;
                }, {});

            }

        };

        setValidationHandler(() => validateAllItems())

    },[rowIndexes]);


    useEffect(() => {
        setIsLoading(true);
        const promises = [
            // static fields
            service.getServiceData({dataFor:"currencies-all"}),
            service.getCategoriesAll(),
            service.getMyCategories(),
        ];

        Promise.all(promises).then((response) => {
            // static fields
            const [currencies, categoriesResult, userCategories] = response;

            const mappedCurrencies = currencies.data.map(({value}) => ({label:value, value}));
            setCurrencies(mappedCurrencies);

            const categories = categoriesResult.data.categories.map(({name, _id}) => {
                const option = {
                    label:name,
                    value:_id
                };

                const isDisabled = !(~userCategories.data.findIndex(item => item._id === _id));
                if(isDisabled){
                    option.disabled=true
                }

                return option
            });

            setCategories(categories);

            setIsLoading(false);

        }).catch((e) => {
            setIsLoading(false);
            handleErrorMessage(e);
        });

    },[]);

	const validate = (values) => {
		return validationHandler(values);
	}


    const submit = async () => {
        setIsSubmitting(true);
        const formValues = new FormData();

        Object.keys(values).map((key) => formValues.append(key, values[key]));



        try {
            await service.addBatchExpense(formValues);
            setIsSubmitting(false);
            setMessage("success", 'success');
            setValuesWasChanged(false);
            history.push("/expenses");
        }catch (e) {
            setServerErrors(e);

            setIsSubmitting(false);
        }


	}

	const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
		submit,
		validate
	);

	const onAddNewRow = () => {
		if(rowIndexes.length){
			const lastIndex = rowIndexes[rowIndexes.length-1]; // get last index value
			const newIndex = lastIndex+1;
			setRowIndexes((rowIdxes) => ([...rowIdxes, newIndex]));
		}else{
			setRowIndexes(() => ([0]));
		}

	}

	const onDeleteRow = (index) => {
		// remove values
		setRowIndexes((rowIndexes) => rowIndexes.filter((idx) => idx !== index));
		handleChange(`expenses[${index}][date]`);
		handleChange(`expenses[${index}][amount]`);
		handleChange(`expenses[${index}][currency]`);
		handleChange(`expenses[${index}][merchant]`);
		handleChange(`expenses[${index}][category]`);
		handleChange(`expenses[${index}][comment]`);

	}

    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }


    return (
        <div>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <BackButton/>
                </Portal>
            )}
            <Prompt
                when={valuesWasChanged}
                message={t("unsavedChanges")}
            />
			<form onSubmit={handleSubmit} noValidate className={classes.form}>
				<div className={classes.fieldsWrapper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>{t("thead.date")}</TableCell>
								<TableCell>{t("thead.amount")}</TableCell>
								<TableCell>{t("thead.currency")}</TableCell>
								<TableCell>{t("thead.merchant")}</TableCell>
								<TableCell>{t("thead.category")}</TableCell>
								<TableCell>{t("thead.comment")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading ? (
								<>
									<TableRow><TableCell colSpan={7}><Skeleton height={"40px"}/></TableCell></TableRow>
									<TableRow><TableCell colSpan={7}><Skeleton height={"40px"}/></TableCell></TableRow>
								</>
							) : (
								rowIndexes.map((rowIndex) => {
									return (
										<TableRow key={rowIndex}>
											<ExpenseRow
												index={rowIndex}
												handleChange={handleChangeWithDetectChanges}
												values={values}
												errors={errors}
												currencies={currencies}
												categories={categories}
												onDeleteRow={onDeleteRow}
                                                decimal={decimal}
												dateFormat={dateFormat}
											/>
										</TableRow>
									)
								})
							)}
						</TableBody>
					</Table>
				</div>
                <Box paddingLeft={'10px'} paddingRight={'10px'}>
                    <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        className={classes.submitBtn}
                        disabled={isSubmitting || !rowIndexes.length}
                    >
                        {t("formFields.save")}
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => onAddNewRow()}
                    >
                        {t("formFields.newLine")}
                    </Button>
                </Box>
            </form>
        </div>
    );
};

const mapStateToProps = ({user}) => {
    const {settings, userData} = user;
    const {regional} = settings;
    const {dateFormat, decimal} = regional;
    return {
        dateFormat,
        decimal,
        userId: userData._id
    }
}
export default compose(
    connect(mapStateToProps, {handleErrorMessage, setMessage}),
    withRouter
)(BatchAdd);
