import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import FormatMoney from "../../../../../../FormatMoney";
import TransactionDescription from "../../../../../../TransactionDescription";
import {compose} from "redux";
import {handleErrorMessage} from "../../../../../../Messages/actions";
import Pagination from "../../../../../../Pagination";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import Api from "../../../../../../../services/api";
import DrawerFilter from "../../../../../DrawerFilter";
import VolveMaterialTable from "../../../../../../VolveMaterialTable";
import FormatDate from "../../../../../../FormatDate";
import {useTranslation} from "react-i18next";
import TransactionsFilter from "../../../../../DrawerFilter/TransactionsFilter";
import getCardName from "../../../../../../../helpers/getCardName";
import CellTransactionAmount from "../../../../../../CellTransactionAmount";
import CellCardAmount from "../../../../../../CellCardAmount";
import checkClick from "../../../../../../../helpers/checkClick";
import {makeStyles} from "@material-ui/core";
import TabsContent from '../../../../../../TabsContent';
import FilterButton from '../../../../../../FilterButton';
import BackButton from '../../../../../../BackButton';
import GetStatement from "./TopComponent/GetStatement"
const service = new Api();

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1129')]: {
            marginBottom: '33px',
            paddingBottom: '24px',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },

        "& > .MuiBox-root ": {
            [theme.breakpoints.down('1129')]: {
                width: '100%',
                flexFlow: 'row wrap',
                justifyContent: 'flex-start',
                marginLeft: '-16px',
                marginRight: '-16px'
            },

            [theme.breakpoints.down(767)]: {
                marginLeft: '-8px',
                marginRight: '-8px'
            },

            "& [href]": {
                [theme.breakpoints.down('1129')]: {
                    flex: '0 0 calc(33.333% - 34px)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    margin: '0 16px',
                },
                [theme.breakpoints.down('767')]: {
                    flex: '0 0 calc(33.333% - 16px)',
                    margin: '0 8px'
                }
            },
            "& button": {
                [theme.breakpoints.down('1129')]: {
                    flex: '0 0 calc(33.333% - 34px)',
                    margin: '16px 16px 0',
                },
                [theme.breakpoints.down('767')]: {
                    flex: '0 0 calc(33.333% - 16px)',
                    margin: '16px 8px 0'
                },

                "& .MuiButton-label": {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }
            },
        }
    },
    tableTransactions: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    }
}));

const AccountTransactionsTable = (props) => {
    const classes = useStyle();

    const { id, dateFormat, handleErrorMessage, location, history, backPath, url, account} = props;
    const {search} = location;
    const [isLoading, setIsLoading] = useState(false);
    const queryObject = queryString.parse(search);

    const [transactions, setTransactions] = useState([]);
    const {t} = useTranslation();

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [info, setInfo] = useState({
        fields: ["date", "description", "amount"],
        page: 0,
        total: 0,
        pageSize: 0
    });

    useEffect(() => {
        fetchTransactionsByAccountId(id, queryObject)
    },[search]);

    const fetchTransactionsByAccountId = async (id, dataQuery) => {
        try {
            setIsLoading(true);
            const response = await service.getAccountTransactions(id, dataQuery);
            const {info, result} = response.data;

            setTransactions((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });
            setInfo(info);

        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }
    }

    const handleChangePage = async (event, newPage) => {
        fetchTransactionsByAccountId(id, {...queryObject, page:newPage});
    }

    const rowHandlerClick = (e, rowData) => {
        const {expense} = rowData;
        if(!expense || !expense._id) return;

        const wasClicked = checkClick();
        if(wasClicked) {
            history.push({
                pathname: `/expenses/${rowData.expense._id}`,
            })
        }
    }


    const {page, total, pageSize} = info;

    const columns = [
        {
            title: t('thead.date'),
            field: 'date',
            render: ({date}) => ( <FormatDate dateFormat={dateFormat}>{date}</FormatDate>)
        },
        {
            title: t('thead.description'),
            field: 'description',
            render: ({description}) => <TransactionDescription description={description}/>
        },
        {
            title: t('thead.employee'),
            render: ({user}) => {
                if(!user) return '';
                const {firstName, lastName} = user;
                return [firstName, lastName].join(" ");
            }
        },
        {
            title: t('thead.card'),
            render: ({card}) => {
                if(!card) return '';
                return getCardName(card)
            }
        },
        {
            title: t('thead.transactionType'),
            field: 'type',
        },
        {
            title: t('thead.statusCode'),
            field: 'statusCode',
        },
        {
            title: t('thead.transactionAmount'),
            align:'right',
            render: (row) => <CellTransactionAmount {...row}/>,
        },
        {
            title: t('thead.cardAmount'),
            align:'right',
            render: (row) => <CellCardAmount {...row}/>,
        },
    ];

    const {balance={}} = account;
    const {toCurrency, sumInCurrency} = balance;

    const routes = [
        {url: url, name:t("account.transactionsTab")},
        {url:`${url}/cards`, name:t("account.cardsTab")},
        {url:`${url}/details`, name:t("account.detailTab")},
    ];


    return (
        <>
            <div className={classes.head}>
                {account.isLoading ? '...' : (
                    <div className={`title-blue text-weight-300 margin-bottom-0`}>
                        {t('account.totalBalance')}
                        {' '}
                        <span className='text-weight-600'>
                            {toCurrency} <FormatMoney>{sumInCurrency}</FormatMoney>
                        </span>
                    </div>
                )}
                <TabsContent routes={routes} rightContent={
                    <>
                        <DrawerFilter isOpen={isFilterOpen} onDrawerFilterClose={() => setIsFilterOpen(false)}>
                            <TransactionsFilter dateFormat={dateFormat}/>
                        </DrawerFilter>

                        <GetStatement />

                        <BackButton backUrl={backPath} />

                        {!isFilterOpen && <FilterButton onClick={() => setIsFilterOpen(true)} />}
                    </>
                }/>
            </div>

            <div className={`${classes.tableTransactions}`}>
                <VolveMaterialTable
                    columns={columns}
                    isLoading={isLoading}
                    data={transactions}
                    onRowClick={rowHandlerClick}
                />
                <Pagination
                    total={total}
                    pageSize={pageSize}
                    page={page}
                    isLoading={isLoading}
                    onChangePage={handleChangePage}
                    loadMore
                />
            </div>
        </>
    );
};


const mapStateToProps = ({user}) => {
    const {regional={}} = user.settings;
    const {dateFormat} = regional;

    return {
        dateFormat,
    }
}

export default compose(
    connect(mapStateToProps,{handleErrorMessage}),
    withRouter
)(AccountTransactionsTable)
