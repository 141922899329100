import React from 'react';
import {Route, Switch} from "react-router-dom";
import LoginForm from "../../LoginForm";
import RegisterForm from "../../RegisterForm";
import ForgotPasswordForm from "../../ForgotPasswordForm";
import ResetPasswordForm from "../../ResetPasswordForm";
import InvitationForm from "../../InvitationForm/InvitationForm";
import EmailConfirmation from "../../EmailConfirmation";
import SsoLogin from "../../SsoLogin";

const PublicRoutes = () => {
    return (
        <Switch>
            <Route exact path="/" component={LoginForm}/>
            <Route exact path="/register" component={RegisterForm}/>
            <Route exact path="/register-white-label" component={RegisterForm}/>
            <Route exact path="/forgotpassword" component={ForgotPasswordForm}/>
            <Route exact path="/resetpassword/:resetToken" component={ResetPasswordForm}/>
            <Route exact path="/invitation/:hash" component={InvitationForm}/>
            <Route exact path="/email-confirmation/:hash" component={EmailConfirmation}/>
            <Route exact path="/sso" component={SsoLogin}/>
            <Route component={LoginForm}/>
        </Switch>
    );
};

export default PublicRoutes;
