import React, {useEffect, useState} from 'react';
import PlusButton from "../../../../../PlusButton";
import DialogPopUp from "../../../../../DialogPopUp";
import {useTranslation} from "react-i18next";
import StartStep from "./StartStep";
import LinkAccountStep from "./LinkAccountStep";
import LimitStep from "./LimitStep";
import {connect} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import FinishStep from "./FinishStep";
import {fetchCards} from "../../actions";
import DeliveryStep from "./DeliveryStep";
import Tooltip from "@material-ui/core/Tooltip";
import {makeStyles} from "@material-ui/core";
import {ReactComponent as PlusIconSVG} from "../../../../../../assets/plusCircleInvesthub.svg";

const useStyles = makeStyles(theme => ({
    customClass: {
        "&.MuiButton-root": {
            width: '40px',
            height: '40px',
            padding: '0',
            background: 'none',
            boxShadow: '0 0 0 2px #EFEFEF inset',
            transition: 'all .2s',
            minWidth: 'initial',
            borderRadius: '8px',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'all .2s',
                fill: 'var(--battelship-grey)'
            },

            "&:hover": {
                background: 'var(--clear-blue-primary)',
                boxShadow: '0 0 0 2px var(--clear-blue-primary) inset',

                "& svg": {
                    fill: 'var(--pale-grey)'
                }
            },

            "& .MuiButton-startIcon": {
                display: 'none'
            },
            "& .MuiButton-endIcon": {
                display: 'none'
            }
        }
    },

    customMenu: {
        "& .MuiListItemIcon-root": {
            position: 'relative',
            top: '-1px',

            "& svg path": {
                transition: 'all .2s'
            }
        },
        "& .MuiMenu-paper .MuiListItem-root:hover svg path": {
            stroke: 'var(--dark-secondary)'
        },
    },

}))

const NewCard = (props) => {
    const classes = useStyles();
    const {company, handleErrorMessage, fetchCards, setMessage, decimal, tabs, userId} = props;

    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null); // use for limit step (only display)
    const [cardData, setCardData] = useState({});
    const [title, setTitle] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setCurrentStep(0);
        setCardData({});
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const [currentStep, setCurrentStep] = useState(0);




    const allSteps = [
        {
            component: <StartStep
                handleErrorMessage={handleErrorMessage}
                setCurrentStep={setCurrentStep}
                setCardData={setCardData}
                cardData={cardData}
                company={company}
                setTitle={setTitle}
            />,
        },
        {
            component: <LinkAccountStep
                handleErrorMessage={handleErrorMessage}
                setCurrentStep={setCurrentStep}
                setCardData={setCardData}
                cardData={cardData}
                setTitle={setTitle}
                setSelectedCurrency={setSelectedCurrency}
            />,
        },
        {
            component:
                <LimitStep
                    handleErrorMessage={handleErrorMessage}
                    setCurrentStep={setCurrentStep}
                    setCardData={setCardData}
                    cardData={cardData}
                    setTitle={setTitle}
                    selectedCurrency={selectedCurrency}
                    decimal={decimal}
                />,
        },
        {
            component:
                <DeliveryStep
                    setCurrentStep={setCurrentStep}
                    setCardData={setCardData}
                    cardData={cardData}
                    setTitle={setTitle}
                />,
            for:["physical"]
        },
        {
            component:
                <FinishStep
                    handleErrorMessage={handleErrorMessage}
                    setCurrentStep={setCurrentStep}
                    setCardData={setCardData}
                    cardData={cardData}
                    setMessage={setMessage}
                    handleClose={handleClose}
                    fetchCards={fetchCards}
                    setTitle={setTitle}
                    tabs={tabs}
                    userId={userId}
                />,
        }
    ];

    const [steps, setSteps] = useState(allSteps);

    const {type} = cardData;

    useEffect(() => {
        if(type){
           const virtualSteps = allSteps.filter((step) => !step["for"] || step["for"].includes(type));
            setSteps(virtualSteps);
        }else{
            setSteps(allSteps);
        }
    },[cardData, currentStep]);



    return (
        <>
            <Tooltip
                title={t("card.addNew")}
            >
                <PlusButton onClickHandler={handleClickOpen} customClass={classes.customClass}>
                    <PlusIconSVG />
                </PlusButton>
            </Tooltip>

            <DialogPopUp
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                disableBackdropClick
                title={title}
            >
                <>
                    {steps[currentStep] ? steps[currentStep].component : null}
                </>
            </DialogPopUp>
        </>
    );
};

const mapStateToProps = (state) => {
    const {user, tabs} = state;

    const {userData, settings} = user;
    const {company} = userData;
    return {
        company,
        decimal: settings.regional.decimal,
        tabs:tabs["cards"],
        userId:user.userData._id
    }
}


export default connect(
    mapStateToProps,
    {handleErrorMessage, setMessage, fetchCards}
)(NewCard);
