import React from 'react';
import {FormControl} from "@material-ui/core";
import {Can} from "../../Context/Can";
import InputField from "../formElements/InputField";
import {useTranslation} from "react-i18next";
import SelectField from "../formElements/SelectField";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    itemFields: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemField: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0',
        },
    },
    itemFieldCheckbox: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    }
}))

const BranchForm = (props) => {
    const {handleSubmit, handleChange, values, errors, loading, currencies, countries, isEditMode} = props;
    const {t} = useTranslation();
    const classes = useStyle();

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Can I="update" a={"branch"} passThrough>
                {(can) => (
                    <div className={classes.itemFields}>
                        <FormControl fullWidth error={!!errors["name"]} className={classes.itemField}>
                            <InputField
                                label={t("formFields.name")}
                                placeholder={t("formFields.name")}
                                name="name"
                                value={values["name"]}
                                onInputChange={(val) => handleChange("name", val)}
                                error={errors["name"]}
                                isLoading={loading}
                                disabled={!can}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["enterpriseNumber"]} className={classes.itemField}>
                            <InputField
                                label={t("formFields.enterpriseNumber")}
                                placeholder={t("formFields.enterpriseNumber")}
                                name="enterpriseNumber"
                                value={values["enterpriseNumber"]}
                                onInputChange={(val) => handleChange("enterpriseNumber", val)}
                                error={errors["enterpriseNumber"]}
                                isLoading={loading}
                                disabled={!can}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.currency} className={classes.itemField}>
                            <SelectField
                                label={t("formFields.currency")}
                                name={"currency"}
                                value={values["currency"]}
                                error={errors.currency}
                                options={currencies}
                                disabled={!can || !!isEditMode}
                                onSelectChange={(val) => handleChange("currency", val ? val : undefined)}
                                isLoading={loading}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["country"]} className={classes.itemField}>
                            <SelectField
                                label={t("formFields.country")}
                                placeholder={t("formFields.country")}
                                name={"country"}
                                value={values["country"]}
                                error={errors["country"]}
                                options={countries}
                                disabled={!can}
                                onSelectChange={(val) => handleChange("country", val)}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["street"]} className={classes.itemField}>
                            <InputField
                                label={t("formFields.street")}
                                placeholder={t("formFields.street")}
                                name="street"
                                value={values["street"]}
                                onInputChange={(val) => handleChange("street", val)}
                                error={errors["street"]}
                                isLoading={loading}
                                disabled={!can}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["postalCode"]} className={classes.itemField}>
                            <InputField
                                label={t("formFields.postalCode")}
                                placeholder={t("formFields.postalCode")}
                                name="postalCode"
                                value={values["postalCode"]}
                                onInputChange={(val) => handleChange("postalCode", val)}
                                error={errors["postalCode"]}
                                isLoading={loading}
                                disabled={!can}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["city"]} className={classes.itemField}>
                            <InputField
                                label={t("formFields.city")}
                                placeholder={t("formFields.city")}
                                name="city"
                                value={values["city"]}
                                onInputChange={(val) => handleChange("city", val)}
                                error={errors["city"]}
                                isLoading={loading}
                                disabled={!can}
                            />
                        </FormControl>
                        <FormControl fullWidth error={!!errors["branchId"]} className={classes.itemField}>
                            <InputField
                                label={t("formFields.branchId")}
                                placeholder={t("formFields.branchId")}
                                name="branchId"
                                value={values["branchId"]}
                                onInputChange={(val) => handleChange("branchId", val)}
                                error={errors["branchId"]}
                                isLoading={loading}
                                disabled={!can}
                            />
                        </FormControl>
                    </div>
                )}
            </Can>
        </form>
    );
};

export default BranchForm;
