import React, {useState} from 'react';
import useMounted from "../../../../../hooks/useMounted";
import Portal from "@material-ui/core/Portal";
import BackButton from "../../../../BackButton";
import Box from "@material-ui/core/Box";
import CategoryForm from "../../../../CategoryForm";
import {setMessage} from "../../../../Messages/actions";
import getGeneralLedgerAccountRegex from "../../../../../helpers/getGeneralLedgerAccountRegex";
import useForm2 from "../../../../../hooks/useForm2";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Can} from "../../../../../Context/Can";
import Button from "@material-ui/core/Button";
import Api from "../../../../../services/api";
import Typography from "@material-ui/core/Typography";
import WarningMessage from "../../../../WarningMessage";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },
    },
    item: {
        position: 'relative'
    },
}))

const BatchEdit = (props) => {
    const {location, history, underBarRef, branches} = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isMounted = useMounted();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyle();


    const hasIdsInRequest = !!(location.state && location.state.ids);


    async function submit(){
        setIsSubmitting(true);
        try {
            await service.updateBatchCategories({
                ids:location.state.ids,
                ...values
            });
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/categories");
        } catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        const generalLedgerAccountRegex = getGeneralLedgerAccountRegex();

        if (values["generalLedgerAccount"] && !generalLedgerAccountRegex.test(values["generalLedgerAccount"].toString())) {
            errors.generalLedgerAccount = t("errors.notValid", {field:"$t(formFields.generalLedgerAccount)"});
        }

        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>

                </Portal>
            )}
            {hasIdsInRequest ? (
                <div className="card">
                    <div className={classes.head}>
                        <BackButton/>
                    </div>

                    <div className={classes.item}>
                        <Typography variant="h6" gutterBottom>
                            {t("category.editFewCategories", {count:location.state.ids.length})}
                        </Typography>
                        <Box mb={5}>
                            <WarningMessage message={t("category.updateFewMessage")}/>
                        </Box>

                        <CategoryForm
                            handleChange={handleChange}
                            handleSubmit={handleSubmit}
                            values={values}
                            branches={branches}
                            errors={errors}
                            isBatchEdit
                        />

                        <Can I="update" a={"category"}>
                            <Button
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                            >
                                {t("formFields.update")}
                            </Button>
                        </Can>
                    </div>
                </div>
            ): (
                <>{t("category.noInRequest")}</>
            )}

        </>
    );
};

export default BatchEdit;
