import React from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import CheckBoxField from "../../../../../formElements/CheckBoxField";
import {useTranslation} from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    fieldset: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
        "& .MuiFormControlLabel-root": {
            margin: '0'
        }
    },
    fieldHalf: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        "& .MuiFormControlLabel-root": {
            margin: '0'
        }
    }
}))

const AdvancedSettingsForm = (props) => {
    const {handleChange, handleSubmit, values, errors, checkDisabledField} = props;
    const {t} = useTranslation();
    const classes = useStyles();

    const onExpenseProjectShow = (val) => {
        handleChange("expenseProjectShow", val);
        if(val === false){
            handleChange("expenseProjectRequired", false);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.fieldset}>
                    <FormControl error={!!errors["expenseMerchantIsRequired"]} fullWidth className={classes.fieldHalf}>
                        <CheckBoxField
                            onCheckboxChange={(val) => handleChange("expenseMerchantIsRequired", val)}
                            value={values["expenseMerchantIsRequired"]}
                            label={t("formFields.advancedSettingsExpenseMerchantRequired")}
                            name={"expenseMerchantIsRequired"}
                            error={errors["expenseMerchantIsRequired"]}
                            disabled={checkDisabledField("expenseMerchantIsRequired")}
                        />
                    </FormControl>

                    <FormControl error={!!errors["expenseCategoryRequired"]} fullWidth className={classes.fieldHalf}>
                        <CheckBoxField
                            onCheckboxChange={(val) => handleChange("expenseCategoryRequired", val)}
                            value={values["expenseCategoryRequired"]}
                            label={t("formFields.advancedSettingsExpenseCategoryRequired")}
                            name={"expenseCategoryRequired"}
                            error={errors["expenseCategoryRequired"]}
                            disabled={checkDisabledField("expenseCategoryRequired")}
                        />
                    </FormControl>

                    <FormControl error={!!errors["expenseProjectShow"]} fullWidth className={classes.fieldHalf}>
                        <CheckBoxField
                            onCheckboxChange={(val) => onExpenseProjectShow(val)}
                            value={values["expenseProjectShow"]}
                            label={t("formFields.advancedSettingsExpenseProjectShow")}
                            name={"expenseProjectShow"}
                            error={errors["expenseProjectShow"]}
                            disabled={checkDisabledField("expenseProjectShow")}
                        />
                    </FormControl>

                    <FormControl error={!!errors["expenseProjectRequired"]} fullWidth className={classes.fieldHalf}>
                        <CheckBoxField
                            onCheckboxChange={(val) => handleChange("expenseProjectRequired", val)}
                            value={values["expenseProjectRequired"]}
                            label={t("formFields.advancedSettingsExpenseProjectRequired")}
                            name={"expenseProjectRequired"}
                            error={errors["expenseProjectRequired"]}
                            disabled={checkDisabledField("expenseProjectRequired")}
                        />
                    </FormControl>

                    <FormControl error={!!errors["expenseCommentRequired"]} fullWidth className={classes.fieldHalf}>
                        <CheckBoxField
                            onCheckboxChange={(val) => handleChange("expenseCommentRequired", val)}
                            value={values["expenseCommentRequired"]}
                            label={t("formFields.advancedSettingsExpenseCommentRequired")}
                            name={"expenseCommentRequired"}
                            error={errors["expenseCommentRequired"]}
                            disabled={checkDisabledField("expenseCommentRequired")}
                        />
                    </FormControl>

                    <FormControl error={!!errors["expenseCountryRequired"]} fullWidth className={classes.fieldHalf}>
                        <CheckBoxField
                            onCheckboxChange={(val) => handleChange("expenseCountryRequired", val)}
                            value={values["expenseCountryRequired"]}
                            label={t("formFields.advancedSettingsExpenseCountryRequired")}
                            name={"expenseCountryRequired"}
                            error={errors["expenseCountryRequired"]}
                            disabled={checkDisabledField("expenseCountryRequired")}
                        />
                    </FormControl>

                    <FormControl error={!!errors["expenseReimbursableActive"]} fullWidth className={classes.fieldHalf}>
                        <CheckBoxField
                            onCheckboxChange={(val) => handleChange("expenseReimbursableActive", val)}
                            value={values["expenseReimbursableActive"]}
                            label={t("formFields.advancedSettingsExpenseReimbursableActive")}
                            name={"expenseReimbursableActive"}
                            error={errors["expenseReimbursableActive"]}
                            disabled={checkDisabledField("expenseReimbursableActive")}
                        />
                    </FormControl>
                </div>
                <Grid container spacing={5}>
                    <Grid item md={6} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>

                            </Grid>
                            <Grid item xs={12}>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default AdvancedSettingsForm;
