import React from 'react'
import { Link } from 'react-scroll'
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
// import Folder from './Folder';

const useStyle = makeStyles((theme) => ({

    makePaymentMenu: {
        position: 'sticky',
        top: '116px',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: '0',
        width: '280px',
        [theme.breakpoints.down('1259')]: {
            width: '320px'
        },
    },

    makePaymentLink: {
        padding: '8px 16px',
        borderRadius: '8px',
        textAlign: 'left',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '1.6',
        color: 'var(--battelship-grey)',
        transition: 'all .2s',
        cursor: 'pointer',

        "&:not(:last-child)": {
            marginBottom: '8px',
        },

        "&.active": {
            color: 'var(--dark-secondary)',
            backgroundColor: 'var(--very-light-pink-secondary)'
        },
        "&:hover": {
            color: 'var(--dark-secondary)',
        }
    },
}))

const Navigation = () => {
    const classes = useStyle();
    const {t} = useTranslation();

    const sections = [
        // NEED TRANSLATION
        { id: 0, title: t("Paying From"), link: "sectionPayingFrom" },
        { id: 1, title: t("Paying To"), link: "sectionPayingTo" },
        { id: 2, title: t("Additional Details (optional)"), link: "sectionAdditionalDetails" }
    ]

    return (
        <div className={classes.makePaymentMenu}>
            {sections.map((section) => (
                <Link
                    to={section.link}
                    className={`${classes.makePaymentLink}`}
                    activeClass="active"
                    offset={-120}
                    duration={500}
                    smooth
                    spy
                    key={section.id}
                >
                    {/* NEED TRANSLATION */}
                    {section.title}
                </Link>
            ))}
        </div>
    )
}

export default Navigation
