import React from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import DepartmentsTable from "./DepartmentsTable";
import AddDepartment from "./AddDepartment";
import Department from "./Department";

const Departments = (props) => {
    const {match, underBarRef} = props;
    const {url} = match;

    return (
        <Switch>
            <Route exact path={url} render={(props) => <DepartmentsTable {...props} underBarRef={underBarRef}/>}/>
            <Route exact path={`${url}/add`} render={(props) => <AddDepartment {...props} underBarRef={underBarRef}/>}/>
            <Route exact path={`${url}/:id`} render={(props) => <Department {...props} underBarRef={underBarRef}/>}/>
        </Switch>
    );
};

export default withRouter(Departments);
