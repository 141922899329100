import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {fetchAccounts} from "../actions";
import TopComponentInvesthub from "../TopComponentInvesthub";
import {withRouter} from "react-router-dom";
import FormatMoney from "../../../../FormatMoney";
import checkClick from "../../../../../helpers/checkClick";
import {compose} from "redux";
import moment from "moment-timezone";
import queryString from "query-string";
import Pagination from "../../../../Pagination";
import Portal from "@material-ui/core/Portal";
import {useTranslation} from "react-i18next";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import HeadContentInvesthub from '../HeadContentInvesthub';
import ShortInfoItem from "../../../../ShortInfoItem";
import useMounted from "../../../../../hooks/useMounted";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    tableCategories: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    },
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',

        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            gap: '16px'
        },
    },

    control: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '8px'
    }
}))

const AccountsTable = (props) => {
    const classes = useStyle();
    const {userAccounts, fetchAccounts, history, underBarRef} = props;
    const {accounts:{result, info={}}} = userAccounts;
    const {page, total, pageSize, totalBalance={}} = info;
    const [isLoading, setIsLoading] = useState(true);
    const isMounted = useMounted();
    const {t} = useTranslation();

    const fetchData = async () => {
        await fetchAccounts();
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    },[]);


    const handleChangePage = async (event, newPage) => {
        setIsLoading(true);
        await fetchAccounts({
            page:newPage,
        });
        setIsLoading(false);
    }

    const rowHandlerClick = (e, rowData) => {
        const monthStart = moment.utc().startOf('month').format();
        const wasClicked = checkClick();

        if(wasClicked){
            history.push({
                pathname:`${props.location.pathname}/${rowData._id}`,
                search: `?${queryString.stringify({'date[gte]': monthStart})}`,
                state: {backPath: props.match.url}
            })
        }
    }

    const columns = [
        {
            title: t('thead.currency'),
            field: 'curCode',
        },
        {
            title: t('thead.avlBal'),
            field: 'avlBal',
            align:'right',
            render: ({avlBal}) => <FormatMoney>{avlBal}</FormatMoney>
        },
        {
            title: t('thead.linkedCards'),
            field: 'card',
            align:'right',
            render: ({card}) =>  card && card.linkedCards ? card && card.linkedCards.length : 0
        },
    ];

   const {toCurrency, sumInCurrency} = totalBalance;

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("account.mainTitle")}
                    </div>
                </Portal>
            )}

            <div className={`card ${classes.tableCategories}`}>
                <div className={classes.head}>
                    <HeadContentInvesthub
                        routes={[]}
                        rightContent={(
                            <ShortInfoItem
                                isLoading={isLoading}
                                label={t('account.totalAmount')}
                                value={<>{toCurrency} <FormatMoney>{sumInCurrency}</FormatMoney></>}
                            />
                        )}
                    />

                    <div className={classes.control}>
                        <TopComponentInvesthub />
                    </div>
                </div>

                <VolveMaterialTable
                    columns={columns}
                    isLoading={isLoading}
                    data={result}
                    onRowClick={rowHandlerClick}
                />
                <Pagination
                    total={total}
                    pageSize={pageSize}
                    page={page}
                    isLoading={isLoading}
                    onChangePage={handleChangePage}
                    loadMore
                />
            </div>
        </>
    );
};


const mapStateToProps = (state) => {

    const {user} = state;
    const {regional={}} = user.settings;
    const {dateFormat} = regional;

    return {
        userAccounts:state.userAccounts,
        isAuthenticated:user.isAuthenticated,
        dateFormat
    }
}

export default compose(
    connect(mapStateToProps,{fetchAccounts}),
    withRouter
)(AccountsTable)
