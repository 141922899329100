import React, {useState} from 'react'

import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";

import {setMessage} from "../../../../Messages/actions";
import {useTranslation} from "react-i18next";

import DialogExport from "./DialogExport";
import MenuOption from "../../../../MenuOption";
import ActionButton from "../../../../ActionButton";
import ActionMenu from "../../../../ActionMenu";


const ExportBtn = (props) => {
    const {dateFormat, setMessage, selected, setSelected} = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpenDialog, setIsOpenDialog] = useState(false);

    const [exportFormat, setExportFormat] = useState("");

    const {t} = useTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const onItemClick = (format) => {
        setExportFormat(format);
        setIsOpenDialog(true);
        handleClose();
    }


    return (
        <>
            <ActionButton
                onClick={handleClick}
            >
                {selected.length ? t("formFields.createReportFromSelected") : t("formFields.createReport")}
            </ActionButton>
            <ActionMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuOption onClick={() => onItemClick("pdf")}>
                    {t("formFields.exportToPdf")}
                </MenuOption>
                <MenuOption onClick={() => onItemClick("xlsx")}>
                    {t("formFields.exportToExcel")}
                </MenuOption>
            </ActionMenu>
            <DialogExport
                setOpen={setIsOpenDialog}
                title={t("formFields.createReport")}
                open={isOpenDialog}
                exportFormat={exportFormat}
                selected={selected}
                setSelected={setSelected}
                dateFormat={dateFormat}
                setMessage={setMessage}
            />
        </>

    )
}

const mapStateToProps = ({user}) => {
    const {regional={}} = user.settings;
    const {dateFormat} = regional;

    return {
        dateFormat
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {setMessage})
)(ExportBtn)
