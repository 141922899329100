import React, {useState} from 'react';
import {setMessage} from "../../../../Messages/actions";
import useForm2 from "../../../../../hooks/useForm2";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import useMounted from "../../../../../hooks/useMounted";
import Portal from "@material-ui/core/Portal";
import BackButton from "../../../../BackButton";
import {Can} from "../../../../../Context/Can";
import Button from "@material-ui/core/Button";
import Api from "../../../../../services/api";
import BranchForm from "../../../../BranchForm";
import getAlphaNumericRegex from "../../../../../helpers/getAlphaNumericRegex";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',
        },
        [theme.breakpoints.down('767')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },
    },
    item: {
        position: 'relative'
    },
}))

const AddBranch = (props) => {
    const {underBarRef, history, currencies, countries} = props;

    const classes = useStyle();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMounted = useMounted();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submit = async () => {
        try{
            setIsSubmitting(true);
            await service.createBranch(values);
            dispatch(setMessage("success", 'success'));
            setIsSubmitting(false);
            history.push("/settings/branches");
        }catch (e) {
            console.log(e);
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }


    const validate = (values) => {
        const regex = getAlphaNumericRegex();

        const errors = {};

        if (!values.name) {
            errors.name = t("errors.required", {field:"$t(formFields.name)"});
        }
        if (!values.currency) {
            errors.currency = t("errors.required", {field:"$t(formFields.currency)"});
        }
        if (values["branchId"] && !regex.test(values["branchId"].toString())) {
            errors.branchId = t("errors.notValid", {field:"$t(formFields.branchId)"});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm2(
        submit,
        validate,
    );


    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>
                </Portal>
            )}
            <div className="card">
                <div className={classes.head}>
                    <BackButton/>
                </div>

                <div className={classes.item}>
                    <BranchForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        currencies={currencies}
                        countries={countries}
                    />

                    <Can I="create" a={"group"}>
                        <Button
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >
                            {t("formFields.create")}
                        </Button>
                    </Can>
                </div>
            </div>
        </>
    );
};

export default AddBranch;
