import React from 'react'
// import {Can} from "../../Context/Can";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import TransactionsMakePayment from "../userSection/content/TransactionsMakePayment/TransactionsMakePayment"

const TransactionsMakePaymentPage = (props) => {
    const {drawerOuterRef, underBarRef} = props;

    return (
        <>
            <UserSectionWrapper>
                <CompanyCountryCheck>
                    <TransactionsMakePayment drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/>
                </CompanyCountryCheck>
            </UserSectionWrapper>
        </>
    )
}

export default TransactionsMakePaymentPage
