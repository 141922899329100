import Api from '../../services/api';
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {FormControl, makeStyles, InputAdornment} from "@material-ui/core";
import {connect} from "react-redux";

import useForm from "../../hooks/useForm";
import InputField from "../formElements/InputField";
import {useTranslation} from "react-i18next";
import getEmailRegex from "../../helpers/getEmailRegex";
// import { ReactComponent as LogoVolve} from '../../assets/logoVolve.svg'
import { ReactComponent as LogoPaladis } from "../../assets/paladis.svg"
import { ReactComponent as MailSVG } from "../../assets/mail.svg"
import { getExternalFileUrl } from "../../helpers/file"

const service = new Api();

const useStyles = makeStyles(theme => ({
    entry: {
        flexFlow: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100dvw',
        height: '100dvh',
        display: 'flex'
    },
    entryWrapper: {
        borderRadius: '8px',
        flexFlow: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        width: '700px',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.down('1023')]: {
            width: 'initial',
            marginLeft: '24px',
            marginRight: '24px'
        }
    },
    entryColFirst: {
        flex: '0 0 300px',
        width: '100%',
        maxWidth: '300px',
    },
    entryColSecond: {
        flex: '0 0 400px',
        width: '100%',
        maxWidth: '400px',
        [theme.breakpoints.down('1023')]: {
            flex: '1',
            width: '100%',
            maxWidth: '375px',
            paddingTop: '80px',
            paddingBottom: '80px',
        }
    },
    entryLogo: {
        display: 'inline-flex',
        maxWidth: '68px',
        '& svg': {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    entrySubtitle: {
        marginBottom: '20px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.71429',
    },
    entryFromControl: {
        marginBottom: '12px',
    },
    entryNote: {
        color: 'var(--battelship-grey)',
        fontSize: '13px',
        lineHeight: '1.23077',
        '& a': {
            color: 'var(--dark-secondary)',
            transition: 'all .2s'
        },
        '& a:hover': {
            color: 'var(--bright-blue)'
        }
    },
}));

const ForgotPasswordForm = (props) => {
    const { publicSettings } = props;
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const [successMessage, setSuccessMessage] = useState();
    const validate = (values) => {
        let errors = {};
        const emailRegex = getEmailRegex();
        if (!values.email) {
            errors.email = t('errors.required', {field: t("formFields.email")});
        }
        if (!emailRegex.test(values.email)) {
            errors.email = t('errors.notValid', {field: t("formFields.email")});
        }

        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate
    );

    async function submit(){
        setIsLoading(true);
        try {
            const response = await service.forgotPassword(values.email);
            const {msg} = response.data;
            setSuccessMessage(t(msg, {email:values.email}));
            setIsLoading(false);

        }catch (e) {
            setIsLoading(false);
            setServerErrors(e);
        }
    }

    const classes = useStyles();


    return (
        <div className={classes.entry}>
            <div className={classes.entryWrapper}>
                <div className={`padding-40 bg-color-black tablet-hide ${classes.entryColFirst}`}>
                    <Link to={"/"} className={classes.entryLogo}>
                        <LogoPaladis />   
                    </Link>
                </div>
                <div className={`padding-40 bg-color-white ${classes.entryColSecond}`}>
                    <div class="width-full height-40 tablet-hide">{' '}</div>
                    <div className={`text-align-center margin-bottom-16 desktop-hide tablet-show`}>
                        <Link to={"/"} className={classes.entryLogo}>
                            <LogoPaladis />                            
                        </Link>
                    </div>

                    <div className="text-weight-200 text-align-center h3 margin-bottom-40">
                        {t("forgotPassword")}
                    </div>

                    <form onSubmit={handleSubmit} noValidate>

                        {successMessage ? (
                            <div className={classes.entrySubtitle}>
                                {successMessage}
                            </div>
                        ) : (
                            <>
                                <FormControl fullWidth error={!!errors.email} className={classes.entryFromControl}>
                                    <InputField
                                        name="email"
                                        type="email"
                                        placeholder={t("formFields.YourEmail")}
                                        error={errors["email"]}
                                        value={values["email"]}
                                        onInputChange={(val) => handleChange("email", val)}
                                        hasIcon={true}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><MailSVG /></InputAdornment>
                                        }}
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                    className="width-full"
                                >
                                    {t("formFields.resetPassword")}
                                </Button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>

    )
};

const mapStateToProps = ({publicSettings}) => {
    return { publicSettings }
}

export default connect(
    mapStateToProps,
    {}
)(ForgotPasswordForm);
