import React, {useContext, useEffect, useState} from 'react';
import Api from "../../../../../../services/api";
import {compose} from "redux";
import {connect} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import VolveSnackbar from "../../../../../VolveSnackbar";
import {Avatar, makeStyles} from "@material-ui/core";
import FormatMoney from "../../../../../FormatMoney/FormatMoney";
import FormatDate from "../../../../../FormatDate";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import Skeleton from "@material-ui/lab/Skeleton";
import canUseCardFunctionality from "../../../../../../helpers/canUseCardFunctionality";
import CircularProgress from '@material-ui/core/CircularProgress'
import * as animationData from "../../../../../../assets/animations/list";
import Box from "@material-ui/core/Box";
import {AbilityContext} from "../../../../../../Context/Can";
import {Link} from "react-router-dom";
import ExpenseStatus from "../../../../../ExpenseStatus";

const service = new Api();


const useStyles = makeStyles(theme => ({
    button:{
        padding: 0,
        color: "#24DAC4",
        fontSize: "15px",
        lineHeight: "26px",
        fontWeight:400,
        minWidth:"auto",
        "&:hover":{
            background:"none",
            color:"#1EB3A1"
        }
    },
    avatar: {
        width:"36px",
        height:"36px"
    }
}));


const ExpensesToDo = (props) => {
    const {userId, dateFormat, handleErrorMessage, firstName, lastName, setMessage, companyCountry, appliedToKyc, appliedToKyb, hasPermissionsToRead} = props;
    const {t} = useTranslation();
    const styles = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const ability = useContext(AbilityContext);

    const [expenses, setExpenses] = useState([]);

    const basicQuery = {
        limit:5,
        status:["toSubmit", "rejected"],
    }


    const fetchExpenses = async (queryData) => {
        if(!hasPermissionsToRead){
            return
        }
        try {
            setIsLoading(true);
            const response = await service.getExpenses({...basicQuery, ...queryData});
            setExpenses(response.data.result)
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);

        }finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        if(userId){
            fetchExpenses({user:userId})
        }
    },[userId]);




    const canUseCards = canUseCardFunctionality(companyCountry);
    const canUpdateCompany = ability.can("update", "company");


    const kybMessageIsShowing = canUseCards && canUpdateCompany && !appliedToKyb;
    const kycMessageIsShowing = canUseCards && !appliedToKyc;

    const showEmptyBlock = !kybMessageIsShowing && !kycMessageIsShowing && (expenses.length === 0);


    return (
        <>
            {isLoading ? (
                <>
                    <Skeleton width={"100%"} height={"60px"}/>
                    <Skeleton width={"100%"} height={"60px"}/>
                    <Skeleton width={"100%"} height={"60px"}/>
                    <Skeleton width={"100%"} height={"60px"}/>
                    <Skeleton width={"100%"} height={"60px"}/>
                </>
            ) : (
                <>
                    {showEmptyBlock ? (
                        <>
                            <Box height={"100%"} display={"flex"} justifyContent={"center"}  alignItems={"center"}>
                            <CircularProgress />
                            </Box>
                        </>
                    ) : (
                        <>
                            {expenses.map((expense) => {
                                const {reportedCurrency, calculatedAmount, date, merchant, status} = expense;

                                return (
                                    <VolveSnackbar
                                        key={expense._id}
                                        message={<><FormatDate dateFormat={dateFormat}>{date}</FormatDate><Box fontSize={"12px"} paddingLeft={"5px"} paddingRight={"5px"} component={"span"}><ExpenseStatus status={status}/></Box>{reportedCurrency} <FormatMoney>{calculatedAmount}</FormatMoney> {merchant}</>}
                                        action={(
                                            <Button
                                                component={Link}
                                                to={`/expenses/${expense._id}`}
                                                size={"small"}
                                                disabled={isSubmitting}
                                                disableRipple
                                                className={styles.button}
                                            >
                                                {t("formFields.complete")}
                                            </Button>
                                        )}

                                        icon={<Avatar className={styles.avatar}>{firstName[0]} {lastName[0]}</Avatar>}
                                    />
                                )
                            })}
                        </>
                    )}

                </>
            )}

        </>
    );
};

const mapStateToProps = ({user}) => {
    const {regional={}} = user.settings;
    const {dateFormat} = regional;
    const {_id, firstName, lastName, company, kyc, role} = user.userData;

    return {
        userId:_id,
        firstName,
        lastName,
        dateFormat,
        companyCountry:company.country,
        appliedToKyb:!!company.kyb,
        appliedToKyc:!!kyc,
        role,
    }
}


export default compose(
    connect(mapStateToProps, {handleErrorMessage, setMessage})
)(ExpensesToDo);
