import React, {useEffect, useState} from 'react';
import useForm2 from "../../../../hooks/useForm2";
import queryString from "query-string";
import {withRouter, matchPath} from "react-router-dom";
import DateField from "../../../formElements/DateField";
import {useTranslation} from "react-i18next";
import Api from "../../../../services/api";
import SelectField from "../../../formElements/SelectField";
import Box from "@material-ui/core/Box";
import moment from 'moment-timezone';
import FilterFormButton from "../FilterFormButton";
import FilterFormControls from "../FilterFormControls";
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

const service = new Api();

const useStyle = makeStyles((theme) => ({
    itemSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'var(--charcoal-primary)'
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        },
        "& .MuiFormControl-root.MuiFormControl-fullWidth": {
            marginBottom: '0'
        },
        // Small size
        "& .MuiSelect-select": {
            height: '40px !important',
            padding: '0 16px',
            fontSize: '13px',
            borderRadius: '8px !important',
            lineHeight: '40px',
        },

        '& .MuiSelect-icon': {
            width: '12px',
            height: '12px'
        }
    },
    fieldCalendar: {
        "& .MuiFormLabel-root + .MuiTextField-root": {
            marginTop: '0'
        },

        "& .MuiTextField-root": {
            position: 'relative',

            "& input": {
                position: 'relative',
                width: '100%'
            },

            "& .MuiInputAdornment-positionEnd": {
                position: 'absolute',
                top: '2px',
                right: '2px',
                bottom: '2px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '46px',
                paddingTop: '1px',
                borderRadius: '0px 10px 10px 0px',
                background: 'var(--white)',
                color: 'var(--dark-secondary)',
                maxHeight: 'initial',
                marginLeft: 'initial',
                height: 'initial',
                transition: 'all .2s',

                "& button": {
                    margin: '0',
                    padding: '0',
                    fontSize: '12px',
                    borderRadius: '0',
                },

                "& .MuiTouchRipple-root": {
                    display: 'none'
                }
            },
        },
        "& .Mui-focused .MuiInputAdornment-positionEnd": {
            backgroundColor: 'var(--very-light-pink-primary) !important',
        }
    },
    btnWrapper: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '8px',
        margin: '0 8px'
    }
}));


const ExpensesFilter = (props) => {
    const {t} = useTranslation();
    const classes = useStyle();
    const [expenseStatuses, setExpenseStatuses] = useState([]);

    const {history, dateFormat, location} = props;
    const {search} = location;
    const query = queryString.parse(search);

    const matchedPath = matchPath(location.pathname, {
        path: "/expenses/:tab",
        exact: true,
        strict: false
    });

    const currentTab = matchedPath ? matchedPath.params.tab : "";
    const isToDoTab = currentTab === "todo";

    const localFields = ['date[gte]', 'date[lte]'];
    if(!isToDoTab){
        // for `All` tab we need additionally `status` field
        localFields.push("status");
    }

    useEffect(() => {
        service.getServiceData({dataFor:"expenseStatuses"}).then((expenseStatuses) => {
            const mappedStatuses = expenseStatuses.data.map((status) => ({label:t(`expense.${status}`), value:status}));
            setExpenseStatuses(mappedStatuses)
        }).catch((e) => {
            console.log(e)
        });

    },[]);


    const onClearHandler = () => {
        fillFormValues({}); // reset form completely
        const resetQuery = {};

        history.push({
            search: `?${queryString.stringify(resetQuery)}`,
        });

    }

    useEffect(() => {
        // fill data from url

        const dataToFIll = localFields.reduce((obj, key) => {
            if(query[key]){
                obj[key] = query[key]
            }
            return obj
        },{});

        fillFormValues(dataToFIll);

        return () => {
            fillFormValues({});
        }
    },[search]);

    const onDateChange = (id, date) => {
        let midnight = undefined;
        if (date && date.isValid()) {
            if (id === 'date[gte]') {
                midnight = moment(date).startOf("day");
            } else {
                midnight = moment(date).endOf("day");
            }
        }
        handleChange(id, midnight);
    }

    const validate = () => ({});

    const submit = () => {
        const oldQuery = queryString.parse(location.search);
        const newQuery = {...values};

        if(oldQuery["searchValue"]){
            newQuery.searchValue = oldQuery["searchValue"];
        }

        history.push({
            search: `?${queryString.stringify(newQuery)}`,
        });

    }



    const { handleChange, handleSubmit, values, fillFormValues } = useForm2(
        submit,
        validate
    );

    return (
        <Box component={'form'} onSubmit={handleSubmit} className={classes.itemSettings}>
            <FilterFormControls>
                <FormControl
                    fullWidth
                    className={`${classes.itemFieldFull} ${classes.fieldCalendar}`}
                >
                    <DateField
                        label={t("formFields.dateFrom")}
                        placeholder={t("formFields.date")}
                        dateFormat={dateFormat}
                        onDateChange={(date) => onDateChange(`date[gte]`, date)}
                        value={values[`date[gte]`]}
                        name="date[gte]"
                        KeyboardButtonProps={{
                            'aria-label': 'change from',
                        }}
                    />
                </FormControl>
                <FormControl
                    fullWidth
                    className={`${classes.itemFieldFull} ${classes.fieldCalendar}`}
                >
                    <DateField
                        label={t("formFields.dateTo")}
                        placeholder={t("formFields.date")}
                        dateFormat={dateFormat}
                        onDateChange={(date) => onDateChange(`date[lte]`, date)}
                        value={values[`date[lte]`]}
                        name="date[lte]"
                        KeyboardButtonProps={{
                            'aria-label': 'change to',
                        }}
                    />
                </FormControl>
                {!isToDoTab && (
                    <FormControl
                        fullWidth
                        className={classes.itemFieldFull}
                    >
                        <SelectField
                            label={t("formFields.status")}
                            name={"status"}
                            value={values["status"]}
                            options={expenseStatuses}
                            onSelectChange={(val) => handleChange("status", val)}
                        />
                    </FormControl>
                )}
            </FilterFormControls>
            <Box className={classes.btnWrapper}>
                <FilterFormButton
                    addtionalClass={`btn-small`}
                    type="submit"
                >
                    {t("formFields.submit")}
                </FilterFormButton>
                <FilterFormButton
                    addtionalClass={`btn-small`}
                    onClick={() => onClearHandler()}
                >
                    {t("formFields.reset")}
                </FilterFormButton>
            </Box>
        </Box>
    );
};

export default withRouter(ExpensesFilter);

