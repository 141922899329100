import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {Can} from "../../../../../Context/Can";
import PlusButton from "../../../../PlusButton"
import Tooltip from "@material-ui/core/Tooltip";
import {ReactComponent as PlusIconSVG} from "../../../../../assets/plusCircleInvesthub.svg";

const useStyle = makeStyles((theme) => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '33px',
            paddingBottom: '24px',
        },
        '& .title-blue': {
            marginRight: 'auto',
            marginBottom: '0',
            [theme.breakpoints.down('767')]: {
                // paddingBottom: '6px',
                margin: '0 0 16px'
            }
        },

        // placeholder
        "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder": {
            opacity: "1 !important"
        }
    },
    searchWrapper: {
        position: 'relative',
        width: '360px',
        marginRight: 'auto',
        [theme.breakpoints.down('1023')]: {
            width: '250px',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 16px',
        },
        '& .MuiInputLabel-root': {
            display: 'none'
        },
        '& .MuiInputBase-root': {
            position: 'relative',

            "& input[type:'text']": {
                paddingRight: '44px',
            },

            "& button": {
                position: 'absolute',
                top: '0',
                right: '12px',
                bottom: '0',
                width: '44px',
                paddingLeft: '4px',
                margin: '0',
                padding: '0',

                "& svg path": {
                    transition: 'fill .2s',
                },

                "&:hover": {
                    backgroundColor: 'transparent',

                    "& svg path": {
                        stroke: 'var(--clear-blue-primary)'
                    }
                },

                "& .MuiTouchRipple-root": {
                    display: 'none'
                }
            }
        }
    },
    control: {
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '8px'
    },
    customClass: {
        "&.MuiButton-root": {
            width: '40px',
            height: '40px',
            padding: '0',
            background: 'none',
            boxShadow: '0 0 0 2px #EFEFEF inset',
            transition: 'all .2s',
            minWidth: 'initial',
            borderRadius: '8px',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'all .2s',
                fill: 'var(--battelship-grey)'
            },

            "&:hover": {
                background: 'var(--default-button-hover)',
                boxShadow: 'var(--default-button-hover-shadow)',

                "& svg": {
                    fill: 'var(--pale-grey)'
                }
            },

            "& .MuiButton-startIcon": {
                display: 'none'
            },
            "& .MuiButton-endIcon": {
                display: 'none'
            }
        }
    },
}))

const TopComponentInvesthub = (props) => {
    const {t} = useTranslation();
    const classes = useStyle();
    const {setOpen} = props;

    return (
        <div className={classes.head}>
            <div className={`title-blue text-weight-300 margin-bottom-0`}>
                {t("rejectReason.mainTitle")}
            </div>

            <div className={classes.control}>
                <Can I="create" a="rejectReason">
                    <Tooltip title={t("rejectReason.addNew")}>
                        <PlusButton onClickHandler={() => setOpen(true)} customClass={classes.customClass}>
                            <PlusIconSVG />
                        </PlusButton>
                    </Tooltip>
                </Can>
            </div>
        </div>
    )
}

export default TopComponentInvesthub
