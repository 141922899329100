import Api from '../../services/api';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getLoggedUser} from '../../components/User/actions'
import {FormControl, makeStyles, InputAdornment} from "@material-ui/core";
import setAuthToken from "../../helpers/setAuthToken";
import useForm from "../../hooks/useForm";
import InputField from "../formElements/InputField";
import {useTranslation} from "react-i18next";
import getEmailRegex from "../../helpers/getEmailRegex";
// import { ReactComponent as LogoVolve} from '../../assets/iconEvolveWhite.svg'
import { ReactComponent as LogoPaladis} from '../../assets/paladis.svg'
import { ReactComponent as LogoPaladisDark } from "../../assets/paladisDark.svg"
import { ReactComponent as MailSVG } from "../../assets/mail.svg"
import { ReactComponent as LockSVG } from "../../assets/lock.svg"
import { ReactComponent as LinkSVG } from "../../assets/link.svg"
import { getExternalFileUrl } from "../../helpers/file"

const service = new Api();

const useStyles = makeStyles(theme => ({

    entry: {
        flexFlow: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100dvw',
        height: '100dvh',
        display: 'flex'
    },
    entryWrapper: {
        borderRadius: '8px',
        flexFlow: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        width: '700px',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.down('1023')]: {
            width: 'initial',
            marginLeft: '24px',
            marginRight: '24px'
        }
    },
    entryColFirst: {
        flex: '0 0 300px',
        width: '100%',
        maxWidth: '300px',
    },
    entryColSecond: {
        flex: '0 0 400px',
        width: '100%',
        maxWidth: '400px',
        [theme.breakpoints.down('1023')]: {
            flex: '1',
            width: '100%',
            maxWidth: '375px',
            padding: '80px 32px'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '68px 24px'
        }
    },
    entryIcon: {
        display: 'inline-flex',
        maxWidth: '168px',
        '& svg': {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    entryLogo: {
        display: 'inline-flex',
        maxWidth: '68px',
        '& svg': {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    entrySubtitle: {
        marginBottom: '20px',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.71429',
    },
    entryFromControl: {
        marginBottom: '12px',
    },
    entryFormControlPassword: {
        "& .MuiInput-root": {
            marginBottom: '40px'
        },
        "& .MuiInputAdornment-root.end_block a": {
            position: 'absolute',
            bottom: '-24px',
            left: '0',
            fontSize: '13px',
            lineHeight: '1.23077',
            fontWeight: '600',
            height: 'initial'
        }
    },
    entryNote: {
        color: 'var(--battelship-grey)',
        fontSize: '13px',
        lineHeight: '1.23077',
        '& a': {
            color: 'var(--dark-secondary)',
            transition: 'all .2s'
        },
        '& a:hover': {
            color: 'var(--bright-blue)'
        }
    },
}));

const LoginForm = (props) => {
    const { publicSettings } = props;

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [is2fa, setIs2fa] = useState(false);
    const {t} = useTranslation();

    const validate = (values) => {

        const emailRegex = getEmailRegex();
        const errors = {};

        if (!values.email) {
            errors.email = t('errors.required', {field: t("formFields.email")});
        }

        if (values.email && !emailRegex.test(values.email)) {
            errors.email = t('errors.notValid', {field: t("formFields.email")});
        }

        if (!values.password) {
            errors.password = t('errors.required', {field: t("formFields.password")});
        }

        if(is2fa && !values["loginCode"]){
            errors.loginCode = t('errors.required', {field: t("formFields.loginCode")});
        }

        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );

    async function submit(){
        setIsLoading(true);
        const {email, password, loginCode} = values;

        try {
            const formData = {email, password};
            if(loginCode){
                formData.loginCode = loginCode;
            }

            const response = await service.loginUser(formData);
            const {token, expires} = response.data;
            setIsLoading(false);
            localStorage.setItem('token', token);
            localStorage.setItem('expires', expires);
            setIsLoading(false);
            setAuthToken(token);
            props.getLoggedUser();
        } catch (e) {
            if(e.response && e.response.data && e.response.data.is2fa &&  !e.response.data.errors){
                setIs2fa(true);
            }else{
                setServerErrors(e);
            }
            setIsLoading(false);
        }
    }

    return (
        <div className={classes.entry}>
            <div className={classes.entryWrapper}>
                <div className={`padding-40 bg-color-black tablet-hide ${classes.entryColFirst}`}>
                    <Link to={"/"} className={classes.entryIcon}>
                        <LogoPaladis />                       
                    </Link>
                </div>

                <div className={`padding-40 bg-color-white ${classes.entryColSecond}`}>
                    <div className="width-full height-40 tablet-hide">{' '}</div>
                    <div className={`text-align-center margin-bottom-16 desktop-hide tablet-show`}>
                        <Link to={"/"} className={classes.entryLogo}>
                            <LogoPaladisDark />                            
                        </Link>
                    </div>

                    {is2fa ? (
                    <>
                        <div className="text-weight-200 text-align-center h3 margin-bottom-40">
                            {t("checkYourEmail")}
                        </div>
                        <div className={classes.entrySubtitle}>
                            {t("errors.paladisDifferentIp")}
                        </div>
                    </>
                    ) : (
                        <div className={`text-weight-200 text-align-center h3 margin-bottom-40`}>
                            {t('Sign in to Paladis')}
                            {/* NEED TRANSLATION */}
                        </div>
                    )}
                    <form onSubmit={handleSubmit} noValidate>
                        {!is2fa && (
                            <>
                                <FormControl fullWidth error={!!errors.email} className={classes.entryFromControl}>
                                    <InputField
                                        placeholder={t("formFields.emailPlaceholder")}
                                        name="email"
                                        type="email"
                                        value={values["email"]}
                                        onInputChange={(val) => handleChange("email", val)}
                                        error={errors["email"]}
                                        hasIcon={true}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><MailSVG /></InputAdornment>
                                        }}
                                    />
                                </FormControl>

                                <FormControl fullWidth error={!!errors.password} className={`${classes.entryFromControl} ${classes.entryFormControlPassword}`}>
                                    <InputField
                                        name="password"
                                        placeholder={t("formFields.passwordPlaceholder")}
                                        type="password"
                                        error={errors["password"]}
                                        value={values["password"]}
                                        onInputChange={(val) => handleChange("password", val)}
                                        hasIcon={true}
                                        hasEndBlock={true}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><LockSVG /></InputAdornment>,
                                            endAdornment: <InputAdornment posiiton="end" className="end_block"><Link to="/forgotpassword">{t("forgotPassword")}</Link></InputAdornment>
                                        }}
                                    />
                                </FormControl>
                            </>
                        )}
                        {is2fa && (
                            <FormControl fullWidth error={errors["loginCode"]}  className={classes.entryFromControl}>
                                <InputField
                                    name="loginCode"
                                    placeholder={t("formFields.loginCodePlaceholder")}
                                    error={errors["loginCode"]}
                                    value={values["loginCode"]}
                                    onInputChange={(val) => handleChange("loginCode", val)}
                                    hasIcon={true}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><LinkSVG /></InputAdornment>
                                    }}
                                />
                            </FormControl>
                        )}

                        <Button
                            type="submit"
                            disabled={isLoading}
                            data-test="login-html-form-submit"
                            className="width-full"
                            style={{marginTop: '25px'}}
                        >
                            {t('formFields.submit')}
                        </Button>
                    </form>

                    <div className={`margin-top-40 text-align-center text-weight-600 ${classes.entryNote}`}>
                        <div className="width-full height-40 tablet-hide">{' '}</div>
                        <div className="width-full height-40 tablet-hide">{' '}</div>
                        {t('noAccountYet')}
                        {' '}
                        <Link to={`/register`}>
                            {t("tryForFree")}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = ({publicSettings}) => {
    return { publicSettings }
}

export default connect(mapStateToProps, {getLoggedUser})(LoginForm)
