import React, {useEffect, useState} from 'react';
import DialogPopUp from "../../../../../DialogPopUp";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {FormControl, Grid, makeStyles} from "@material-ui/core";
import useForm2 from "../../../../../../hooks/useForm2";
import InputField from "../../../../../formElements/InputField";
import {useTranslation} from "react-i18next";
import Api from "../../../../../../services/api";
import DateField from "../../../../../formElements/DateField";
import Button from "@material-ui/core/Button";
import useExpenseTabsQuery from "../../../../../../hooks/useExpenseTabsQuery";
import RadioFields from "../../../../../formElements/RadioFields";
import ReportCounter from "./ReportCounter";
import moment from "moment-timezone";
import {Can} from "../../../../../../Context/Can";
import CustomReportType from "./CustomReportType";
import ErrorBoundary from "../../../../../ErrorBoundary";
import ScopeFields from "./ScopeFields";
import {useSelector} from "react-redux";

const service = new Api();

const useStyles = makeStyles(theme => ({
    buttonsWrapper:{
        justifyContent: "center",
        flexWrap:"wrap",
        paddingLeft: "45px",
        paddingRight: "45px",
    },
    actionButton:{
        background: "#24dac4",
        fontWeight: "600",
        padding: "5px 15px",
        color: "#fff",
        margin:"10px",
        textTransform: "capitalize",
        boxShadow:"none",
        '&:hover':{
            background:"rgba(0, 0, 0, 0.8)"
        }
    },
    form:{
        width:"350px",
        maxWidth:"100%",
        margin: "0 auto",
        paddingTop:"20px",
        paddingBottom:"20px"
    }
}));

const DialogExport = (props) => {
    const {setOpen, title, open, exportFormat, dateFormat, setMessage, selected, setSelected} = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const tabQuery = useExpenseTabsQuery();
    const tabsExpenses = useSelector(state => state.tabs["expenses"]);

    const xlsxReportTypes = [
        {value: "basic", label: t("formFields.xlsxBasic")},
        {value: "full", label: t("formFields.xlsxFull")},
        {value: "custom", label: t("formFields.xlsxCustom")}
    ];

    const withDateRange = (selected.length === 0);


    const submit = async () => {

        try {
            setIsSubmitting(true);
            await service.getExpensesExport(queryForExport);
            setSelected([]);
            setOpen(false);
            setMessage("report.created", "success");
        }catch (e) {
            setServerErrors(e);
        }finally {
            setIsSubmitting(false);
        }

    }

    const validate = (values) => {
        const errors = {};
        const fileNameRegex = /^[\w]+$/;

        if(!values.fileName){
            errors.fileName = t("errors.reportNameIsNotValid", {field:"$t(formFields.fileName)"});
        }else if (!fileNameRegex.test(values.fileName)) {
            errors.fileName = t("errors.reportNameIsNotValid", {field:"$t(formFields.fileName)"});
        }

        if(withDateRange){
            if (!values['date[gte]']) {
                errors['date[gte]'] = t("errors.required", {field:"$t(formFields.dateFrom)"});
            }
            if (!values['date[lte]']) {
                errors['date[lte]'] = t("errors.required", {field:"$t(formFields.dateTo)"});
            }
        }
        if(values["xlsxReportType"] === "custom"){
            if(!values["fields"] || values["fields"].length < 1){
                errors["fields"] = t("errors.notValid");
            }
        }

        if(values["scope"] === "user" && !values["user"]){
            errors['user'] = t("errors.required", {field:"$t(formFields.user)"});
        }
        if(values["scope"] === "branch" && !values["branch"]){
            errors['branch'] = t("errors.required", {field:"$t(formFields.branch)"});
        }
        if(values["scope"] === "group" && !values["group"]){
            errors['group'] = t("errors.required", {field:"$t(formFields.group)"});
        }

        return errors;
    }

    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    const queryForExport = {
        ...values,
        ...tabQuery,
        ext:exportFormat
    }

    if(selected.length){
        queryForExport.ids = selected;
    }

    const onExitHandler = () => {
        fillFormValues({});
    }

    const onDateChange = (id, date) => {
        let midnight = undefined;
        if (date && date.isValid()) {
            if (id === 'date[gte]') {
                midnight = moment(date).startOf('day');
            } else {
                midnight = moment(date).endOf('day');
            }
        }
        handleChange(id, midnight);
    }


    useEffect(() => {
        if(exportFormat === "xlsx"){
            // select "basic" by default
            handleChange("xlsxReportType", "basic");
            handleChange("notReported", "false");
        }
    },[exportFormat, open]);

    const {xlsxReportType} = values;

    useEffect(() => {
        if(xlsxReportType !== "custom"){
           // reset fields if not custom
            if(values["fields"]){
                handleChange("fields")
            }
        }
    },[xlsxReportType]);

    const onExportAndMarkAsReported = (e) => {
        handleChange("markAsReported", true);
        handleSubmit(e);
    }

    const onOnlyExport = (e) => {
        handleChange("markAsReported", false);
        handleSubmit(e);
    }

    return (
        <DialogPopUp
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="export-dialog-title"
            aria-describedby="export-dialog-description"
            title={title}
            onExited={onExitHandler}
        >
            <DialogContent>
                <ErrorBoundary>
                    <form onSubmit={handleSubmit} noValidate className={classes.form}>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <FormControl fullWidth error={!!errors.fileName}>
                                    <InputField
                                        label={t("formFields.fileName")}
                                        name="fileName"
                                        value={values["fileName"]}
                                        onInputChange={(val) => handleChange("fileName", val)}
                                        error={errors["fileName"]}
                                    />
                                </FormControl>
                            </Grid>

                            {tabsExpenses.active !== "personal" && (
                                // hide for personal
                                <Grid item xs={12}>
                                    <ScopeFields
                                        handleChange={handleChange}
                                        values={values}
                                        errors={errors}
                                    />
                                </Grid>
                            )}

                            {exportFormat === "xlsx" && (
                                <Grid item xs={12}>
                                    <FormControl fullWidth error={!!errors['xlsxReportType']}>
                                        <RadioFields
                                            options={xlsxReportTypes}
                                            name={"xlsxReportType"}
                                            label={t("formFields.xlsxReportType")}
                                            value={values["xlsxReportType"]}
                                            onRadioChange={(val) => handleChange("xlsxReportType", val)}
                                            error={errors["xlsxReportType"]}
                                        />
                                    </FormControl>

                                </Grid>

                            )}
                            {values["xlsxReportType"] === "custom" && (
                                <Grid item xs={12}>
                                    <CustomReportType handleChange={handleChange} fields={values["fields"]} error={errors["fields"]}/>
                                </Grid>
                            )}
                            {withDateRange && (
                                <>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth error={!!errors['date[gte]']}>
                                            <DateField
                                                label={t("formFields.dateFrom")}
                                                placeholder={t("formFields.date")}
                                                dateFormat={dateFormat}
                                                onDateChange={(date) => onDateChange("date[gte]", date)}
                                                value={values["date[gte]"]}
                                                error={errors["date[gte]"]}
                                                name="date[gte]"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'date from',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth error={!!errors['date[lte]']}>
                                            <DateField
                                                label={t("formFields.dateTo")}
                                                placeholder={t("formFields.date")}
                                                dateFormat={dateFormat}
                                                onDateChange={(date) => onDateChange("date[lte]", date)}
                                                value={values["date[lte]"]}
                                                error={errors["date[lte]"]}
                                                name="date[lte]"
                                                KeyboardButtonProps={{
                                                    'aria-label': 'date to',
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <RadioFields
                                                options={[{value:"false", label:t("formFields.allExpenses")}, {value:"true", label:t("formFields.onlyNotReported")}]}
                                                name={"notReported"}
                                                value={values["notReported"]}
                                                onRadioChange={(val) => handleChange("notReported", val)}
                                                error={errors["notReported"]}
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <ReportCounter queryForExport={queryForExport} />
                            </Grid>
                        </Grid>
                    </form>
                </ErrorBoundary>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onOnlyExport}
                    color="secondary"
                    variant="contained"
                    disabled={isSubmitting}
                >
                    {t("expense.export")}
                </Button>
                <Can I="markAsReported" a={"expense"}>
                    <Button
                        onClick={onExportAndMarkAsReported}
                        color="secondary"
                        variant="contained"
                        disabled={isSubmitting}
                    >
                        {t("formFields.markAsReported")}
                    </Button>
                </Can>
            </DialogActions>
        </DialogPopUp>
    );
};

export default DialogExport;
