import React from 'react'
import Cards from "../userSection/content/Cards";

import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";


const CardsPage = (props) => {
    const {drawerOuterRef, underBarRef} = props;

    const {t} = useTranslation();
    return (
        <>
            <UserSectionWrapper>
                <CompanyCountryCheck>
                    <Can I="read" a={"card"} passThrough>
                        {(can) => (
                            can ? <Cards drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/> : <div>{t("card.noPermissionToRead")}</div>
                        )}
                    </Can>
                </CompanyCountryCheck>
            </UserSectionWrapper>
        </>
    )

}

export default CardsPage
