import React from 'react';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {makeStyles} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => (
    {
        snackBar: {
            width: "100%",
            background: "none",
            color: theme.palette.text.primary,
            margin: "8px 0",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.06)",
            borderRadius: "6px",
            padding:"5px 10px 5px 5px",
            border: "1px solid #EDEFF1",
            alignItems: "flex-start",
            [theme.breakpoints.down('sm')]: {
                flexWrap:"wrap"
            },
            "& .MuiListItemText-root":{
                marginTop:"5px"
            },
            "& .MuiSnackbarContent-message":{
                padding:0
            },
            "& .MuiSnackbarContent-action":{
                marginRight:0,
                marginTop: "4px",
                marginBottom: "4px"
            }
        },
        icon: {
            width: "36px",
            minWidth: "36px",
            height:"36px",
            minHeight:"36px",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "10px"
        },
        item: {
            paddingTop: 0,
            paddingBottom: 0
        }
    }
));


const VolveSnackbar = (props) => {
    const {message, action, icon} = props;
    const classes = useStyles();

    return <SnackbarContent className={classes.snackBar} message={
        <ListItem component={"div"} disableGutters className={classes.item}>
            <ListItemIcon className={classes.icon}>{icon ? icon : null}</ListItemIcon>
            <ListItemText primary={message} primaryTypographyProps={{variant:"body2"}}/>
        </ListItem>
    } action={action ? action : null} elevation={0} />;
};

export default VolveSnackbar;
