import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";

import TabsContent from "../../../../../../TabsContent";
import ShortInfoItem from "../../../../../../ShortInfoItem";
import FormatMoney from "../../../../../../FormatMoney";
import BackButton from '../../../../../../BackButton';

const useStyles = makeStyles(theme => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '34px',
            paddingBottom: '24px',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },

        "& > .MuiBox-root ": {
            [theme.breakpoints.down('1023')]: {
                flexFlow: 'row wrap',
                marginLeft: '0',
                width: '100%'
            },
            "& [href]": {
                [theme.breakpoints.down('1023')]: {
                    width: '27%',
                    flex: '0 0 27%'
                },
                [theme.breakpoints.down('767')]: {
                    width: '33.333%',
                    flex: '0 0 33.333%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                },
            },
            "& button": {
                [theme.breakpoints.down('1023')]: {
                    flex: '1',
                },
                [theme.breakpoints.down('767')]: {
                    flex: '0 0 100%',
                    maxWidth: '100%',
                    margin: '16px 0 0'
                }
            }
        }
    },
}))

const AccountTabs = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {url, account, backPath} = props;
    const {accountNumber, balance={}} = account;

    const {toCurrency, sumInCurrency} = balance;

    const routes = [
        {url: url, name:t("account.transactionsTab")},
        {url:`${url}/cards`, name:t("account.cardsTab")},
        {url:`${url}/details`, name:t("account.detailTab")},
    ];

    return (
        <div className={classes.head}>
            <>
                <ShortInfoItem isLoading={account.isLoading} label={t('formFields.accountNo')} value={accountNumber}/>
                <ShortInfoItem isLoading={account.isLoading} label={t('account.totalBalance')} value={(
                    <>{toCurrency} <FormatMoney>{sumInCurrency}</FormatMoney></>
                )}/>
            </>
            <TabsContent routes={routes} rightContent={<BackButton backUrl={backPath} />} />
        </div>
    );
};

export default AccountTabs;
