import * as actionTypes from '../../../../store/actionTypes'


const allTabs = {
    dashboard: ["personal", "approver", "administrator", "auditor"],
    expenses: ["personal", "administrator", "auditor", "approver"],
    transactions: ["personal", "administrator", "auditor", "approver"],
    users: ["administrator", "manager", "auditor"],
    accounts: ["administrator", "manager", "auditor"],
    cards: ["personal", "administrator", "manager", "auditor", "approver"],
    reports: ["personal", "administrator", "auditor", "approver"],
    settings: ["administrator"]
}


const setTabs = (roles, isWhiteLabel) => {

    const tabs = Object.keys(allTabs).reduce((tabs, tab) => {

        if(allTabs[tab].some((tab) => roles.includes(tab))){
            const availableTabs = allTabs[tab].filter((tab) => roles.includes(tab));

            tabs[tab] = {
                tabs: isWhiteLabel ? availableTabs.filter((tab) => tab !== "approver") : availableTabs, // remove approver tab for isWhiteLabel
                active: availableTabs[0]
            };
        }

        return tabs;
    },{});

    return {type:actionTypes.TAB_SET, payload: tabs}
}

const tabChange = (page, tab) => {
    return {type:actionTypes.TAB_CHANGE, payload: {page, tab}}
}


export {
    setTabs,
    tabChange
}
