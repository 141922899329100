import React, {useRef} from 'react';
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as CloseThin} from "../../../assets/closeThin.svg";
import Box from "@material-ui/core/Box";
import ReceiptBlob from "../../ReceiptBlob";
import * as loader from "../../../assets/animations/loader";
import CircularProgress from '@material-ui/core/CircularProgress'
import compressImage from "../../../helpers/compressImage";

const useStyles = makeStyles(theme => ({
    wrapper:{
        position:"relative",
        display: 'flex',
        flexFlow: "column nowrap",
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '16px',
    },

    imgWrapper: {
        position: 'relative',
        order: "-1",
        "& img": {
            width: '100%',
            borderRadius: '12px',
        }

    },

    ocrLoading:{
        position:"absolute",
        top:0,
        left:0,
        right:0,
        bottom:0,
        backgroundColor:"rgba(255,255,255,0.5)",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        zIndex:1
    }

}));




const ReceiptUploader = (props) => {
    const {t} = useTranslation();
    const {value, handleChange, disabled=false, handleErrorMessage, isLoading} = props;
    const classes = useStyles();


    const handleDelete = () => {
        handleChange("receiptUri");
    }
    const handleUpload = async (e) => {
        if(e.target.files && e.target.files[0]){
            try{
                const inputFile = e.target.files[0];

                const file = (inputFile.type === "application/pdf") ? inputFile : await compressImage(inputFile); // compress only image

                handleChange("receiptUri", file);
            }catch (e) {
                console.log(e);
                handleErrorMessage({message:t('errors.cantHandleFile')})
            }
        }else{
            handleDelete();
        }
    };

    const fileInput = useRef(null);

    return (
        <div className={classes.wrapper}>
            {isLoading && (
                <Box className={classes.ocrLoading}>
                    {/* <LottieAnimation animationData={loader} width={300} height={300}/> */}
                    <CircularProgress />
                </Box>
            )}
            {!disabled && (
                value && (
                    <Button onClick={handleDelete} className={`btn-stroke btn-small btn-stroke-red width-full`}>
                        {/* NEED TRANSLATION */}
                        {t('Delete')}
                    </Button>
                )
            )}
            <span className={classes.imgWrapper}>
                <ReceiptBlob src={value} skeletonHeight={"260px"}/>
            </span>
            {!disabled && (
                !value && (
                    <Button
                        component="label"
                        className={`btn-small width-full`}
                    >
                        {t("formFields.uploadReceipt")}
                        <input type="file"
                               name="file"
                               accept=".png,.jpeg,.jpg,.pdf"
                               className={classes.file}
                               ref={fileInput}
                               style={{display: "none"}}
                               onChange={handleUpload}
                        />
                    </Button>
                )
            )}

        </div>
    );
};

export default ReceiptUploader;
