import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import InputField from "../../../../formElements/InputField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as SearchIcon} from "../../../../../assets/search.svg";
import {useTranslation} from "react-i18next";
import {Box} from "@material-ui/core";

const SearchInput = (props) => {

    const useStyles = makeStyles({
        input: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        btnWrapper: {
            marginRight: '-13px',
            marginBottom: '-7px'
        },
        icon: {
            width: "17px",
            height: "17px",
            "& path": {
                stroke: "#3D4259"
            }
        },
        searchWrapper:{
            '& .MuiInput-underline::before':{
                borderColor:'#3D4259'
            },
            '& .MuiTextField-root':{
                marginTop:0,
            },
            '& .MuiInput-formControl':{
                marginTop: '0px'
            },
            '& .MuiInputLabel-root.MuiInputLabel-formControl':{
                top: '-10px',
                fontSize:'14px',
                "&.MuiInputLabel-shrink": {
                    transform: "translate(0, -6px) scale(0.8)"
                },
            },

        }
    });

    const classes = useStyles();

    const {t} = useTranslation();

    const {InputProps, handleSubmit, searchInputPlaceholder, ...restProps} = props;

    return (
        <Box className={classes.searchWrapper}>
            <InputField
                {...restProps}
                onInputChange={() => {}} // do nothing. Handler is in params.inputProps.onChange
                label={t("formFields.search")}
                InputProps={{
                    endAdornment: (
                        <React.Fragment>
                            <InputAdornment position="end" onClick={handleSubmit ? handleSubmit : () => {}}>
                                <IconButton aria-label="Searching" className={classes.btnWrapper}>
                                    <SearchIcon className={classes.icon}/>
                                </IconButton>
                            </InputAdornment>
                        </React.Fragment>
                    ),
                    ...InputProps,
                }}
                placeholder={searchInputPlaceholder}
            />
        </Box>
    );
};

export default SearchInput;
