import React, {useEffect, useState} from 'react';
import {withRouter, Route, Switch} from 'react-router-dom'
import BranchesTable from "./BranchesTable";
import Branch from "./Branch";
import AddBranch from "./AddBranch";
import {handleErrorMessage} from "../../../Messages/actions";
import {useDispatch} from "react-redux";
import Api from "../../../../services/api";

const service = new Api();

const Branches = (props) => {
    const {match, underBarRef} = props;
    const {url} = match;
    const dispatch = useDispatch();

    const [currencies, setCurrencies] = useState([]);
    const [countries, setCountries] = useState([]);

    const fetchStaticData = async () => {
        try {
            const currenciesPromise = service.getServiceData({dataFor: "currencies-all"});
            const countriesPromise = service.getServiceData({dataFor:"countries"});
            const [currenciesResult, countriesResult] = await Promise.all([currenciesPromise, countriesPromise]);
            const mappedCurrencies = currenciesResult.data.map(({value}) => ({label:value, value}));
            const mappedCountries = countriesResult.data.map((country) => ({label: country.name, value: country["country-code"]}));

            setCurrencies(mappedCurrencies);
            setCountries(mappedCountries);
        }catch (e) {
            dispatch(handleErrorMessage(e));
        }
    }

    useEffect(() => {
        fetchStaticData();
    },[]);

    return (
        <>
            <Switch>
                <Route exact path={url} render={(props) => <BranchesTable {...props} underBarRef={underBarRef} countries={countries}/>}/>
                <Route exact path={`${url}/add`} render={(props) => <AddBranch {...props} underBarRef={underBarRef} currencies={currencies} countries={countries}/>}/>
                <Route exact path={`${url}/:id`} render={(props) => <Branch {...props} underBarRef={underBarRef} currencies={currencies} countries={countries}/>}/>
            </Switch>
        </>
    );
};

export default withRouter(Branches);
