import React, {useEffect, useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { ReactComponent as NotificationSVG } from "../../../../assets/notification.svg"
import Api from "../../../../services/api";
import {handleErrorMessage} from "../../../Messages/actions";
import {useDispatch} from "react-redux";
import HeadNotification from './Head';
import ContentNotification from './Items';

const service = new Api();
const useStyle = makeStyles(theme =>({
    notification: {
        position: 'relative',
        // marginRight: '24px',
        [theme.breakpoints.down('767')]: {
            position: 'static'
        },
        '&.active $body': {
            visibility: 'visible',
            opacity: '1',
            transform: 'translateY(0)'
        }
    },
    head: {
        width: '48px',
        height: '48px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: '10px',
            right: '10px',
            width: '14px',
            height: '14px',
            borderRadius: '50%',
            border: '2px solid var(--white)',
            background: 'var(--orange-pink)',
            opacity: '0',
            visibility: 'hidden',
        },
        '& svg': {
            width: '24px',
            height: '24px',
            fill: 'var(--battelship-grey)',
            transition: 'fill .2s',
        },
    },
    headCursor: {
        cursor: 'pointer',

        '&:before': {
            opacity: '1',
            visibility: 'visible'
        },
        '&:hover svg': {
            fill: 'var(--dark-secondary)'
        }
    },
    body: {
        position: 'absolute',
        top: 'calc(100% + 18px)',
        border: '1px solid transparent',
        borderRadius: '16px',
        boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
        background: 'var(--white)',
        visibility: 'hidden',
        opacity: '0',
        transform: 'translateY(3px)',
        transition: 'all .2s',
        padding: '12px 24px 24px',
        width: '392px',
        right: '-92px',
        [theme.breakpoints.down('1339')]:{
            top: 'calc(100% + 15px)',
            right: '-73px'
        },
        [theme.breakpoints.down('767')]:{
            top: '100%',
            left: '16px',
            right: '16px',
            width: 'auto',
            padding: '12px 16px 20px'
        }
    },
}));

const Notifications = (props) => {
    const classes = useStyle();

    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [open, setOpen] = useState({
        isOpenActive: false
    });

    const handleOpenNotification = async () => {
        setOpen({ ...open, isOpenActive: !open.isOpenActive })
    }

    const handleOpenNotificationAway = async () => {
        setOpen({ ...open, isOpenActive: false })
    }

    const fetchMessages = async () => {
        setIsDisabled(true);
        try{
            const response = await service.getNotifications();
            setNotifications(response.data);
            setIsDisabled(false);
        }catch (e) {
            console.log(e);
            setIsDisabled(false);
        }
    }

    const onDeleteMessage = async (id) => {
        setIsDisabled(true);
        try {
            await service.deleteNotification(id);

            const notificationsCopy = [...notifications];

            const deleteIndex = notificationsCopy.findIndex((item) => item._id === id);

            if(deleteIndex !== -1){
                notificationsCopy.splice(deleteIndex, 1);
                setNotifications(notificationsCopy);
            }
            setIsDisabled(false);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
            setIsDisabled(false);
        }
    }

    useEffect(() => {
        (async() => {
            await fetchMessages();
        })();

        setInterval(fetchMessages, 1000*60);

    },[]);

    let notifComponent = null;
    if ( notifications.length > 0 ) {
        notifComponent = (
            <>
                <ClickAwayListener
                    onClickAway={handleOpenNotificationAway}
                >
                    <div className={`${classes.notification} ${open.isOpenActive ? 'active' : null } `}>
                        <div
                            className={`${classes.head} ${notifications.length > 0 ? classes.headCursor : null }`}
                            onClick={handleOpenNotification}
                        >
                            <NotificationSVG />
                        </div>

                        <div className={classes.body}>
                            <HeadNotification
                                counter={notifications.length}
                                setNotifications={setNotifications}
                                setIsDisabled={setIsDisabled}
                            />

                            <ContentNotification
                                notifications={notifications}
                                deleteMessage={onDeleteMessage}
                                onClose={() => setOpen({ ...open, isOpenActive: false })}
                                isDisabled={isDisabled}
                                setIsDisabled={setIsDisabled}
                                setNotifications={setNotifications}
                            />
                        </div>
                    </div>
                </ClickAwayListener>
            </>
        )
    }


    return (
        <>
            {notifComponent}
        </>
    )
}

export default Notifications
