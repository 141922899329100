import React from 'react';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import {makeStyles} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import RadioField from "../RadioField";

const RadioFields = (props) => {

    const noHandler = () => {
        console.log(`No handler for ${name} radio field`);
    };

    const {value = "", error = "", onRadioChange = noHandler, options = [], label = "", name = "", disabled = false, isLoading, ...AdditionalProps} = props;

    const useStyles = makeStyles({
        radioSkeleton: {
            display: "inline-flex",
            alignItems: "center"
        },
        formControlLabel:{
            "& .MuiFormControlLabel-label":{
                fontSize:"15px",
                marginTop:"2px",
                paddingLeft:"2px"
            },
        }
    });

    const classes = useStyles();


    return (
        <>
            {label && <FormLabel component="label" htmlFor={`radio-${name}`}>{label}</FormLabel>}

            <RadioGroup
                aria-label={`radio-${name}`}
                name={name}
                value={value}
                onChange={(e) => onRadioChange(e.target.value)}
                row
                {...AdditionalProps}
            >
                {
                    isLoading ? (
                        <>
                            <span className={classes.radioSkeleton}>
                                <Skeleton circle={true} width={"25px"} height={"25px"}/>
                                <Skeleton width={"50px"} height={"10px"}/>
                            </span>
                            <span className={classes.radioSkeleton}>
                                <Skeleton circle={true} width={"25px"} height={"25px"}/>
                                <Skeleton width={"50px"} eight={"10px"}/>
                            </span>
                            <span className={classes.radioSkeleton}>
                                <Skeleton circle={true} width={"25px"} height={"25px"}/>
                                <Skeleton width={"50px"} height={"10px"}/>
                            </span>
                        </>
                    ) : (
                        (options && options.length > 0) && (
                            options.map((option) => {
                                return <FormControlLabel
                                    value={option.value}
                                    id={`radio-${name}`}
                                    key={option.value}
                                    control={<RadioField disabled={disabled} className={classes.option}/>}
                                    label={option.label}
                                    className={classes.formControlLabel}
                                />
                            })
                        )
                    )
                }

            </RadioGroup>
            {error && <FormHelperText>{error}</FormHelperText>}
        </>
    );
};

export default RadioFields;
