import * as actionTypes from '../../store/actionTypes'
import Api from '../../services/api';
import {handleErrorMessage} from "../Messages/actions";
import setAuthToken from "../../helpers/setAuthToken";
import {setTabs} from "../userSection/Bar/TopTabs/actions";
const service = new Api();


export const userLogout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('expires');
	return {
		type: actionTypes.USER_LOGOUT
	}
};

const updateUserState = (user) => dispatch => {
	// update (rewrite) user state
	const {settings, roles, commonRules, ...restUserData} = user;
	dispatch({
		type:actionTypes.USER_UPDATE,
		payload:{userData:{...restUserData}, settings, roles, commonRules}
	})
};

const updateUserData = (data) => dispatch => {
	// update only userData
	dispatch({
		type:actionTypes.USER_UPDATE_DATA,
		payload:data
	})
};

const updateUserCompany = (data) => dispatch => {
	dispatch({
		type:actionTypes.USER_UPDATE_COMPANY,
		payload:data
	})
};

const updateAdvancedSettings = (data) => dispatch => {
    dispatch({
        type:actionTypes.USER_UPDATE_COMPANY_ADVANCED_SETTINGS,
        payload:data
    })
}
const updateConfigurablePermissions = (data) => dispatch => {
    dispatch({
        type:actionTypes.USER_UPDATE_CONFIGURABLE_PERMISSIONS,
        payload:data
    })
}

const getLoggedUser = () => async dispatch => {
    try {
        const user = await service.getCurrentUser();
        const {settings, roles, commonRules,  ...restUserData} = user.data;

        dispatch({
            type:actionTypes.USER_LOGIN_SUCCESS,
            payload:{userData:{...restUserData}, settings, roles, commonRules}
        });

        const {isWhiteLabel} = user.data.company;

        dispatch(setTabs(roles, isWhiteLabel));

    }catch (e) {
        dispatch(handleErrorMessage(e, 'error'));
        dispatch({
            type:actionTypes.USER_LOGIN_FAIL
        });
        dispatch(userLogout());

    }
}

const checkUserStatus = () => {
	return async (dispatch)  => {
		const token = localStorage.getItem('token');
		if(!token){
			dispatch(userLogout())
		}else{
			const expirationDate = new Date(localStorage.getItem('expires'));
			if(expirationDate <= new Date()){
				dispatch(userLogout());
				dispatch(handleErrorMessage({message:"Session expired, please log back in."}));
			}else{
				setAuthToken(token);
				dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime())/1000));
				await dispatch(getLoggedUser()) // load user from db
			}
		}
	}
};

const checkAuthTimeout = (expirationTime) => {
	return dispatch => {
		setTimeout(() => {
			dispatch(handleErrorMessage({message:"Session expired, please log back in."}));
			dispatch(userLogout());
		}, expirationTime *1000)
	}
}

export {
    getLoggedUser,
    checkUserStatus,
    updateUserState,
    updateUserData,
    updateUserCompany,
    updateAdvancedSettings,
    updateConfigurablePermissions
}
