import React, {useEffect, useState} from 'react';
import SelectField from "../../../../formElements/SelectField";
import Api from "../../../../../services/api";
import {handleErrorMessage} from "../../../../Messages/actions";
import {useDispatch} from "react-redux";
import FilterFormControl from "../../FilterFormControl";
const service = new Api();


const UserSelectField = (props) => {
    const dispatch = useDispatch();

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchUsers = async () => {
       try{
           setIsLoading(true);
           const usersDataResponse = await service.getUsersAll();
           const mappedUsers = usersDataResponse.data.map((user) => ({label:`${user.firstName} ${user.lastName}`, value:user._id}));
           setUsers(mappedUsers);
       }catch (e) {
           dispatch(handleErrorMessage(e));
       }finally {
           setIsLoading(false);
       }
    }

    useEffect(() => {
        fetchUsers();
    },[]);

    return (
            <FilterFormControl>
                <SelectField
                    {...props}
                    options={users}
                    isLoading={isLoading}
                />
            </FilterFormControl>
    );
};

export default UserSelectField;
