import React, {useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import ActionMenu from "../../../../../ActionMenu";
import MenuOption from "../../../../../MenuOption";
import ExternalLink from "../../../../../ExternalLink";
import PlusButtonTitle from "../../../../../PlusButtonTitle/PlusButtonTitle"
import PlusButton from "../../../../../PlusButton";
import ImportFromCsvOption from "../../../../../ImportFromCsvOption";
import {ReactComponent as PlusIconSVG} from "../../../../../../assets/plusCircleInvesthub.svg";
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
    customClass: {
        "&.MuiButton-root": {
            width: '40px',
            height: '40px',
            padding: '0',
            background: 'none',
            boxShadow: '0 0 0 2px #EFEFEF inset',
            transition: 'all .2s',
            minWidth: 'initial',
            borderRadius: '8px',
            "& svg": {
                width: '24px',
                height: '24px',
                transition: 'all .2s',
                fill: 'var(--battelship-grey)'
            },

            "&:hover": {
                background: 'var(--default-button-hover)',
                boxShadow: 'var(--default-button-hover-shadow)',

                "& svg": {
                    fill: 'var(--pale-grey)'
                }
            },

            "& .MuiButton-startIcon": {
                display: 'none'
            },
            "& .MuiButton-endIcon": {
                display: 'none'
            }
        }
    },

    customMenu: {
        "& .MuiListItemIcon-root": {
            position: 'relative',
            top: '-1px',

            "& svg path": {
                transition: 'all .2s'
            }
        },
        "& .MuiMenu-paper .MuiListItem-root:hover svg path": {
            stroke: 'var(--dark-secondary)'
        },
    }
}));

const AddTaxRateBtn = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {history, onImport} = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose =() => {
        setAnchorEl(null);
    }

    const bottom = (
        <>
            <Trans i18nKey="helpCountryCode">
                <ExternalLink href={'https://en.wikipedia.org/wiki/ISO_3166-1'} target={"_blank"}/>
            </Trans>
        </>
    )

    return (
        <>
            <Tooltip
                title={t("taxRate.addNew")}
            >
                <PlusButton onClickHandler={handleClick} hasDropdown customClass={classes.customClass}>
                    <PlusIconSVG />
                </PlusButton>
            </Tooltip>

            <ActionMenu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.customMenu}
            >
                <MenuOption onClick={() => history.push("/settings/tax-rates/add")}>{t("taxRate.addManually")}</MenuOption>
                <ImportFromCsvOption
                    btnLabel={t("formFields.importTaxRates")}
                    popupTitle={t("titleImportTaxRates")}
                    popupText={t("textImportTaxRates")}
                    templatePath={"/assets/import-template-tax-rates.csv"}
                    onImport={onImport}
                    closeParent={handleClose}
                    bottom={bottom}
                />
            </ActionMenu>
        </>
    )
}

export default AddTaxRateBtn
