import React, {Fragment, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {ReactComponent as PaymentLogo} from "../../../../../../../assets/paymentInvesthub.svg"
import {ReactComponent as ClockLogoSVG} from "../../../../../../../assets/clockInvesthub.svg"
import {ReactComponent as ActivitySVG} from "../../../../../../../assets/activityInvesthub.svg"
import {useTranslation} from "react-i18next";
import {ReactComponent as Logo} from "../../../../../../../assets/logo.svg";
import Portal from "@material-ui/core/Portal";
// import {ReactComponent as Logo} from "../../../../../../../assets/logoInvesthub.svg";
import VolveSnackbar from "../../../../../../VolveSnackbar";

const useStyles = makeStyles(theme => ({
    list: {
        position: 'relative'
    },

    item: {
        display: 'flex',
        paddingBottom: '16px',
        gap: "24px",
        alignItems: 'center',
        [theme.breakpoints.down('1023')]: {
            gap: '16px'
        },
        "&:not(:last-child)": {
            marginBottom: '32px',
            borderBottom: '1px solid var(--very-light-pink-secondary)',

            [theme.breakpoints.down('1023')]: {
                marginBottom: '24px'
            }
        }
    },

    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '48px',
        height: '48px',
        borderRadius: '50%',
        backgroundColor: 'var(--powder-blue)',

        "& svg": {
            width: '24px',
            height: '24px',
            fill: 'var(--dark-secondary)'
        }
    },

    content: {
        flex: "1",
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '1.71429',
        color: 'var(--charcoal-primary)'
    },

    inner: {
        "& span": {
            lineHeight: '1.33333',
            color: 'var(--battelship-grey)'
        }
    }

}));


const Details = (props) => {
    const classes = useStyles();
    const {account, isLoading, companyName, accountTabs} = props;
    const {t} = useTranslation();

    const {bicSwift, accountNumber, ledgerId, createdCompletely} = account;
    const accountCreationIsNotFinished = !isLoading && !createdCompletely;


    return (
        <>
            {accountTabs}

            {accountCreationIsNotFinished && (
                <>
                    <VolveSnackbar
                        message={<>{t("account.isNotCreatedCompletely")}</>}
                        icon={<Logo/>}
                    />

                    <div className="width-full height-24">{' '}</div>
                </>
            )}

            <div className={classes.list}>
                <div className={classes.item}>
                    <div className={classes.icon}>
                        <PaymentLogo />
                    </div>

                    <div className={classes.content}>
                        {t("account.accountDetailText1")}
                    </div>
                </div>

                <div className={classes.item}>
                    <div className={classes.icon}>
                        <ClockLogoSVG/>
                    </div>

                    <div className={classes.content}>
                        {t("account.accountDetailText1")}
                    </div>
                </div>

                <div className={classes.item}>
                    <div className={classes.icon}>
                        <ActivitySVG/>
                    </div>

                    <div className={classes.content}>
                        <div className={classes.inner}>
                            {t("account.accountDetailHolder")}
                            <span>{companyName}</span>
                        </div>

                        <div className={classes.inner}>
                            {t("account.accountDetailNumber")}
                            <span>{accountNumber}</span>
                        </div>

                        <div className={classes.inner}>
                            {t("account.accountDetailBank")}
                        </div>

                        <div className={classes.inner}>
                            {t("account.accountDetailSwift")}
                            <span>{bicSwift}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Details;
