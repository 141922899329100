import { createMuiTheme } from "@material-ui/core";

const secondaryColor = "#354052";
const primaryColor = "#24dac4";
const themeConfiguration = createMuiTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                '1339': 1339,
                '1259': 1259,
                '1129': 1129,
                '1023': 1023,
                '767': 767,

            },
        },
        palette: {
            secondary: {
                main: secondaryColor
            },
            primary: {
                main: primaryColor,
            },
            text:{
                primary: secondaryColor,
            }
        },
        typography: {
            "fontFamily": "\"lota_grotesque\"",
            "fontSize": 14,
            "fontWeightRegular": 400,
        },
        overrides: {
            MuiTooltip:{
                tooltip:{
                    backgroundColor: "#354052",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)"
                },
                arrow:{
                    color:"#354052"
                }
            },
            // Select / Dropdown field
            MuiSelect:{
                select:{
                    userSelect: "text",
                    height: '48px !important',
                    padding: '0 48px 0 16px',
                    paddingRight: '48px !important',
                    minHeight: 'initial',
                    boxShadow: 'inset 0 0 0 2px #0000',
                    borderRadius: '12px !important',
                    border: 'none',
                    fontFamily: 'var(--font-primary)',
                    fontSize: '15px',
                    fontWeight: '600',
                    lineHeight: '48px',
                    color: 'var(--dark-secondary)'
                },
                icon:{
                    top: "calc(50% - 6px)",
                    width: '14px',
                    height: '14px',
                    right: '16px',
                    color: 'var(--battelship-grey)',
                    '& path': {
                        fill: 'var(--battelship-grey)'
                    }
                }
            },
            MuiInputBase:{
                input:{
                    height:"26px",
                    padding: "4px 0 2px",
                    color: "#354052",
                    '&.Mui-disabled':{
                        color:"#354052",
                        "-webkit-text-fill-color": "#354052",
                        opacity:1
                    }
                },
                root:{
                    fontSize:"15px",
                    lineHeight:"26px",
                    color:"#354052",
                    '&.Mui-disabled':{
                        color:"#354052",
                        "-webkit-text-fill-color": "#354052",
                        opacity:1
                    }
                }
            },
            MuiPaper: {
                elevation1: {
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)"
                },
                rounded:{
                    borderRadius:"12px"
                }
            },
            // Dropdown / Select Menu
            MuiMenu: {
                paper: {
                    width: '264px',
                    padding: '12px',
                    borderRadius: '16px',
                    border: '1px solid transparent',
                    boxShadow: '0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)',
                    background: 'var(--white)',

                    '& .MuiMenu-list': {
                        padding: '0',
                        margin: '0',
                        width: 'initial',
                    },
                    '& .MuiListItem-root': {
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        padding: '12px',
                        borderRadius: '12px',
                        transition: 'all .2s',
                        cursor: 'pointer',
                        "& .MuiListItemText-root": {
                            margin: '0',
                        },
                        "& span": {
                            fontSize: '15px',
                            fontWeight: '600',
                            lineHeight: '1.6',
                            color: 'var(--battelship-grey)',
                            transition: 'all .2s',
                        }
                    },
                    '& .MuiListItem-root::after': {
                        display: 'none'
                    },
                    '& .MuiListItem-root:hover': {
                        background: 'var(--very-light-pink-secondary)',
                    },
                    '& .MuiListItem-root:hover span': {
                        color: 'var(--dark-secondary)'
                    },
                    '& .MuiListItem-root.Mui-selected': {
                        background: 'var(--very-light-pink-secondary)',
                    },
                    '& .MuiListItem-root.Mui-selected span': {
                        color: 'var(--dark-secondary)'
                    }
                },
            },
            MuiAppBar:{
                colorDefault:{
                    top: '0',
                    left: '340px',
                    right: '0',
                    zIndex: '20',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '24px 40px',
                    background: 'var(--pale-grey) !important',
                    boxShadow: 'inset 1px 0px 0px var(--very-light-pink-primary), inset 0 -1px 0px var(--very-light-pink-secondary)',
                    width: 'initial'
                }
            },
            // Button
            MuiButton:{
                text:{
                    padding: '0 20px', // in filter
                    textTransform:"none",
                },
                root:{
                    background: 'var(--clear-blue-primary)',
                    textAlign: 'center',
                    color: 'var(--pale-grey)',
                    borderRadius: '12px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '48px',
                    padding: '0 20px',
                    fontSize: '15px',
                    fontWeight: '700',
                    lineHeight: '1.6',
                    transition: 'all .2s',
                    display: 'inline-flex',
                    // '&.MuiButton-text':{
                    // 	padding: "9px 33px", // removed because it applies everywhere
                    // },
                    '&:hover': {
                        backgroundColor: 'var(--bright-blue)',
                        color: 'var(--white)',
                    },

                    // VARIANT: STROKE
                    '&.btn-stroke': {
                        background: 'none',
                        boxShadow: '0 0 0 2px var(--very-light-pink-secondary) inset',
                        color: 'var(--dark-secondary)',

                        '&:hover': {
                            background: 'none',
                            boxShadow: '0 0 0 2px var(--dark-secondary) inset'
                        }
                    },
                    // VARIANT: SMALL
                    '&.btn-small': {
                        height: '40px',
                        padding: '0 16px',
                        borderRadius: '8px',
                        fontSize: '13px',
                    }
                },
                containedSecondary:{
                    color:"#fff",
                    backgroundColor: "#3D4259",
                    "&:hover":{
                        backgroundColor:"#23273B",
                        boxShadow:"none"
                    },
                    "&.Mui-disabled":{
                        backgroundColor: "#3D4259",
                        opacity:.4,
                        color:"#fff",
                    }
                },
                containedPrimary:{
                    color:"#fff",
                    background: "#24DAC4",
                    "&:hover":{
                        backgroundColor:"#1EB3A1",
                        boxShadow:"none"
                    },
                    "&.Mui-disabled":{
                        backgroundColor: "#24DAC4",
                        opacity:.4,
                        color:"#fff",
                    }
                },
                contained:{
                    boxShadow:"none"
                },
            },
            // Text Field
            MuiInput:{
                root: {
                    '&.Mui-focused input': {
                        borderColor: 'var(--cool-grey)',
                        background: 'var(--white)',
                    },
                },
                // Wrapper Text Field
                formControl: {
                    marginTop: '0',
                },
                input: {
                    background: 'var(--very-light-pink-primary)',
                    fontFamily: 'var(--font-primary)',
                    color: 'var(--dark-secondary)',
                    border: '2px solid #0000',
                    borderRadius: '12px',
                    width: '100%',
                    height: '44px',
                    padding: '0 10px',
                    fontSize: '15px',
                    fontWeight: '600',
                    lineHeight: '1.6',
                    transition: 'all .2s',
                    "&::-webkit-input-placeholder": {
                        opacity: 1,
                        color: 'var(--battelship-grey)',
                    },
                    "&::-moz-placeholder": {
                        opacity: 1,
                        color: 'var(--battelship-grey)',
                    },
                    "&:-moz-placeholder": {
                        opacity: 1,
                        color: 'var(--battelship-grey)',
                    },
                    "&:-ms-input-placeholder": {
                        opacity: 1,
                        color: 'var(--battelship-grey)',
                    },
                },
                underline:{
                    '&.Mui-disabled':{
                        '&:before':{
                            borderBottomStyle:"hidden"
                        },
                    },
                    '&::after':{
                        display: 'none',
                    },
                    '&::before':{
                        display: 'none'
                    },
                    '&:hover:not(.Mui-disabled):before':{
                        borderBottom:"1px solid #23273b"
                    }
                },
            },
            // Field Label
            MuiFormLabel:{
                root:{
                    marginBottom: '6px',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '1.71429',
                    color: 'var(--charcoal-primary)',
                    // '&.Mui-focused':{
                    //     color:"#8A96A0",
                    // }
                }
            },
            // Text Field 2
            MuiTextField: {
                root: {
                    '& .MuiFormLabel-root': {
                        transform: 'none',
                        position: 'relative',
                        color: 'var(--charcoal-primary)',
                    }
                }
            },
            // Autocomplete
            MuiAutocomplete:{
                root: {
                    '&.MuiAutocomplete-hasClearIcon': {
                        '& .MuiInput-root': {
                            paddingRight: '0'
                        },
                        '& .MuiInput-root input': {
                            padding: '0 !important',
                            paddingLeft: '10px !important'
                        },

                        '& .MuiAutocomplete-endAdornment': {
                            right: '12px'
                        },

                        '& .MuiInput-root.MuiAutocomplete-inputRoot': {
                            paddingRight: '0'
                        }
                    }
                }
            },
            // Chip
            MuiChip:{
                root: {
                    "&.MuiChip-sizeSmall": {
                        display: "inline-block",
                        padding: '0 8px',
                        borderRadius: '6px',
                        lineHeight: '24px',
                        fontWeight: '600'
                    }
                }
            },
            // Table
            MuiTable: {
                root: {
                    '& tbody tr:first-child > td': {
                        paddingTop: '16px'
                    },
                    '& tr td:first-child': {
                        paddingLeft: '0'
                    },
                    '& tr th:first-child': {
                        paddingLeft: '0'
                    },
                    '& tr td:last-child': {
                        paddingRight: '0'
                    },
                    '& tr th:last-child': {
                        paddingRight: '0'
                    },
                    '& .MuiTableBody-root .MuiTableCell-root': {
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '1.71429',
                        color: 'var(--dark-secondary)'
                    }
                }
            },
            MuiTableCell: {
                root: {
                    borderBottom: "none",
                    padding: "10px 16px",
                },
                head: {
                    borderColor: "transparent",
                    backgroundColor: "transparent !important",
                    padding: '10px 16px',
                    paddingTop: '12px',
                    paddingBottom: '16px',
                    fontSize: '12px',
                    lineHeight: '1.33333',
                    fontWeight: '500',
                    color: 'var(--battelship-grey)',
                    borderBottom: '1px solid var(--very-light-pink-secondary)'
                }
            },
            MuiNativeSelect:{
                select:{
                    background:"#f7f7f7",
                }
            },
            MuiDialog:{
                paper:{
                    // borderTop:"4px solid #24dac4"
                },
                root:{
                    backdropFilter: 'blur(2px)'
                }
            },
            MuiDialogTitle:{
                root:{
                    textAlign:"center",
                    color: "#4a4a4a",
                    fontSize:"15px",
                    padding:"50px 16px 10px 16px"
                }
            },
            MuiDialogContent:{
                root:{
                    padding:"0px 50px 10px 50px"
                }
            },
            MuiDialogActions:{
                root:{
                    paddingBottom: "50px",
                    justifyContent:"center"
                }
            },
            // Radio
            MuiRadio:{
                root: {
                    padding: '0',
                    "& svg": {
                        display: 'none !important'
                    },
                    "& .MuiIconButton-label": {
                        position: 'relative',
                        flexShrink: '0',
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        border: '2px solid rgba(111, 118, 126, 0.4)',
                        transition: 'all .2s',
                        display: "inline-block"
                    },
                    "& + .MuiTypography-root.MuiFormControlLabel-label": {
                        color: 'var(--cool-grey)',
                        fontSize: '15px',
                        fontWeight: '600',
                        lineHeight: '1.71429',
                        paddingTop: '0',
                        paddingLeft: '12px',
                        alignSelf: 'center',
                        position: 'relative',
                    },
                    "&.Mui-checked": {
                        "& .MuiIconButton-label": {
                            background: 'var(--clear-blue-primary)',
                            borderColor: 'var(--clear-blue-primary)'
                        },
                        "& + .MuiTypography-root.MuiFormControlLabel-label": {
                            color: 'var(--dark-secondary)'
                        }
                    },
                    "&.Mui-disabled": {
                        cursor: "not-allowed",
                        "& .MuiIconButton-label": {
                            cursor: "not-allowed",
                        },
                        "& + .MuiTypography-root.MuiFormControlLabel-label": {
                            cursor: "not-allowed"
                        }

                    }
                }
            },
            // Checkbox
            MuiCheckbox:{
                root:{
                    padding: '0',
                    "& svg": {
                        display: 'none !important'
                    },
                    "& .MuiIconButton-label": {
                        position: "relative",
                        width: '24px',
                        height: '24px',
                        borderRadius: '6px',
                        border: '2px solid rgba(111, 118, 126, 0.4)',
                        transition: 'all .2s',
                        display: "inline-block",
                    },
                    "& + .MuiTypography-root.MuiFormControlLabel-label": {
                        color: 'var(--cool-grey)',
                        fontSize: '15px',
                        fontWeight: '600',
                        lineHeight: '1.71429',
                        paddingTop: '0',
                        paddingLeft: '12px',
                        alignSelf: 'center',
                        position: 'relative',
                    },
                    "&.Mui-checked": {
                        "& .MuiIconButton-label": {
                            background: 'var(--clear-blue-primary)',
                            borderColor: 'var(--clear-blue-primary)'
                        },
                        "& + .MuiTypography-root.MuiFormControlLabel-label": {
                            color: 'var(--dark-secondary)'
                        }
                    }
                },
            },
            MuiSwitch:{
                root: {
                    width: 35,
                    height: 21,
                    padding: 0,
                    margin: 5,
                },
                switchBase:{
                    padding: 2,
                    color: "#fff",
                    '&$checked':{
                        transform: 'translateX(14px)',
                        '& .MuiSwitch-thumb':{
                            // boxShadow:"none"
                        },
                        '&:not(.Mui-disabled) + $track':{
                            opacity: 1,
                            border: 'none',
                        },
                    },
                    '&$focusVisible $thumb': {
                        border: '6px solid #fff',
                    },
                    '&.Mui-checked.Mui-disabled.+.MuiSwitch-track':{
                        opacity:"0.1"
                    },
                },
                thumb:{
                    width: 17,
                    height: 17,
                    boxShadow: "0px 1px 1px #252830"
                },
                track:{
                    borderRadius: 21 / 2,
                    backgroundColor: "#8A969F",
                    opacity: 1,
                },
                checked: {},
                colorPrimary:{
                    '&.Mui-checked':{
                        color:"#fff"
                    },
                    '&.Mui-disabled .MuiSwitch-thumb':{
                        boxShadow:"none"
                    },
                    '&.Mui-disabled': {
                        color:"#fff"
                    }
                },
                colorSecondary:{
                    '&.Mui-checked':{
                        color:"#fff"
                    },
                    '&.Mui-disabled':{
                        color:"#fff"
                    },
                    '&.Mui-disabled .MuiSwitch-thumb':{
                        boxShadow:"none"
                    },
                },
            },
            MuiFormControlLabel:{
                root:{
                    marginLeft: "-6px",
                }
            },
            MuiFormControl:{
                root:{
                    marginBottom: '0',
                }
            },
            MuiFormHelperText: {
                root: {
                    fontSize: '12px',
                    lineHeight: '1.33333',
                    fontWeight: 600,
                    color: 'var(--battelship-grey)',
                    marginTop: '8px'
                }
            },
            MuiAvatar:{
                root:{
                    fontWeight:300
                },
                colorDefault:{
                    backgroundColor:"#EDEFF1",
                    color:"#8A96A0"
                }

            },
            MuiTypography: {
                h1: {
                    fontSize: "30px",
                    fontWeight: "200",
                    lineHeight: "45px"
                },
                h2: {
                    fontSize: "25px",
                    fontWeight: "300",
                    lineHeight: "38px"
                },
                h6: {
                    fontWeight: "300",
                    fontSize: "17px",
                    lineHeight: "30px"
                },
                subtitle1: {
                    fontSize: "20px",
                    lineHeight: "35px",
                    fontWeight: "300",
                },
                body2: {
                    fontSize: "15px",
                    lineHeight: "26px"
                }
            },
            MuiPickersToolbarText:{
                toolbarBtnSelected:{
                    color:"#fff"
                },
                toolbarTxt:{
                    color:"#fff"
                }
            },
            MuiPickersDay:{
                daySelected:{
                    color:"#fff"
                }
            },
            MuiSnackbarContent:{
                root:{
                    flexWrap:"nowrap"
                }
            },
            MuiTableRow: {
                root: {
                    "&:hover": {
                        color:"#354052",
                    },
                    '& td':{
                        width:"auto!important"
                    }
                },
                head:{
                    '& th':{
                        width:"auto!important"
                    },
                },
                hover: {
                    "&:hover": {
                        color:"#354052",
                        backgroundColor: "transparent !important",
                        "& .MuiTableCell-body":{
                            color:"#354052"
                        }
                    }
                }
            }
        }
    },
)

export default themeConfiguration
