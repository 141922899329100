import React, {useEffect, useState} from 'react';
import Api from "../../services/api";
import axios from 'axios';
import Skeleton from "react-loading-skeleton";
import Box from "@material-ui/core/Box";
import {ReactComponent as Rect} from '../../assets/broken.svg'
import PdfPlaceholder from "./PdfPlaceholder";
import {makeStyles} from "@material-ui/core/styles";

const service = new Api();

const useStyles = makeStyles(theme => ({
    wrapper: {
        cursor: "pointer",
        display: "block"
    }
}));

const ReceiptBlob = (props) => {
    const {src, skeletonHeight} = props;
    const [blobUrl, setBlobUrl] = useState();
    const [type, setType] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [notFound, isNotFound] = useState(false);
    const classes = useStyles();


    useEffect(() => {
        if(src instanceof Blob){
            setBlobUrl(window.URL.createObjectURL(src));
            setType(src.type);
        }else if(src && (typeof src === "string")){
            setIsLoading(true);

            axios.get(`${service._basicPath}/files/${src}`, {responseType:"blob"}).then((resp) => {
                setBlobUrl(window.URL.createObjectURL(resp.data));
                setType(resp.data.type);
            }).catch((e) => {
                console.log(e);
                isNotFound(true)
            }).finally(() => {
                setIsLoading(false);
            })
        }else{
            setBlobUrl("");
            setType(null);
        }
    },[src]);

    const onOpen = () => {
        // we use  window.open instead of <a href="blob:..." target="_blank">
        // because of Safari ( a href blob url doesn't work)
        window.open(blobUrl, '_blank');
    }

    return (
        <>
            {isLoading ? (
                <Skeleton width={"100%"} height={skeletonHeight ? skeletonHeight : "200px"}/>
            ) : (
                <>
                    {blobUrl ? (
                        <Box component={"span"} onClick={onOpen} className={classes.wrapper}>
                            {type === "application/pdf" ? (
                                <PdfPlaceholder/>
                            ) : (
                                <img src={blobUrl} alt=""/>
                            )}
                        </Box>
                    ) : (
                        <>
                            {notFound && <Rect/>}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default ReceiptBlob;
