import React, {useEffect, useState} from 'react';

import {withRouter, Route, Switch, Link} from 'react-router-dom'
import CompanyProfile from "./CompanyProfile";
import Api from "../../../../services/api";
import {compose} from "redux";
import {connect} from "react-redux";
import {handleErrorMessage, setMessage} from "../../../Messages/actions";
import FundingBankAccounts from "../FundingBankAccounts";
import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../../../CompanyCountryCheck";
// import TabsContent from "../../../TabsContent";
import HeadContentInvesthub from './HeadContentInvesthub';
import { Portal } from '@material-ui/core';
const service = new Api();

const Company = (props) => {
    const {t} = useTranslation();

    const {match, handleErrorMessage, setMessage, userData, underBarRef} = props;
    const {company:userCompany={}} = userData;

    const tabs = [
        {url:match.url, name:t("company.profileTab")},
        {url:`${match.url}/funding-bank-accounts`, name:t("company.fabTab")}
    ];

    const [isMount, setIsMount] = useState(false);
    const [company, setCompany] = useState({});
    const [countries, setCountries] = useState([]);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [types, setTypes] = useState([]);
    const [employeesRangeList, setEmployeesRangeList] = useState([]);
    const [currencies, setCurrencies] = useState([]);

    const fetchData = async () => {
        setIsDataFetching(true);
        try {

            const countriesPromise = service.getServiceData({dataFor:"countries"});
            const companyPromise = service.getCompany();
            const typesPromise = service.getServiceData({dataFor:"typesOfCompany"});
            const employeesRangePromise = service.getServiceData({dataFor:"employeesRange"});
            const currenciesPromise = service.getServiceData({dataFor: "currencies-all"});

            const responses = await Promise.all([
                companyPromise,
                countriesPromise,
                typesPromise,
                employeesRangePromise,
                currenciesPromise
            ]);
            const [
                company,
                countries,
                types,
                employeesRange,
                currencies
            ] = responses;

            const mappedCurrencies = currencies.data.map(({value}) => ({label:value, value}));
            setCurrencies(mappedCurrencies);

            setCompany(company.data);

            const mappedCountries = countries.data.map((country) => {
                return {
                    label: country.name,
                    value: country["country-code"]
                }
            });
            setCountries(mappedCountries);

            const mappedTypes = types.data.map(({id, value}) => ({label:value, value:id}));
            setTypes(mappedTypes);

            const mappedEmployeesRange = employeesRange.data.map(({label, value}) => ({label:label, value:label}));

            setEmployeesRangeList(mappedEmployeesRange);

            setIsDataFetching(false);
        }catch (e) {
            handleErrorMessage(e);
            setIsDataFetching(false);
        }
    }

    useEffect(() => {
        fetchData();
	},[]);

    useEffect(() => {
        setIsMount(true);
    },[]);


    return (
       <>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">{t("company.mainTitle")}</div>
                </Portal>
            )}

            <div className="card">
                {userCompany.kyb &&  <HeadContentInvesthub routes={tabs}/>}

                <Switch>
                    <Route exact path={`${match.path}`} render={() => (
                        <CompanyProfile
                            company={company}
                            countries={countries}
                            isDataFetching={isDataFetching}
                            setCompany={setCompany}
                            setMessage={setMessage}
                            types={types}
                            employeesRangeList={employeesRangeList}
                            currencies={currencies}
                            underBarRef={underBarRef}
                        />
                    )}/>
                    <Route exact path={`${match.path}/funding-bank-accounts`} render={() => (
                        <CompanyCountryCheck>
                            {userCompany.kyb ? <FundingBankAccounts underBarRef={underBarRef}/> : <div>{t("company.isNotKyb")}</div>}
                        </CompanyCountryCheck>
                    )}/>
                </Switch>
            </div>
       </>
    );
};

const mapStateToProps = (state) => {
    const {user} = state;
    const {userData} = user;
    const {regional={}} = user.settings;
    return {
        userData,
        decimal:regional.decimal
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, {handleErrorMessage, setMessage})
)(Company);
