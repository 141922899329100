import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {ReactComponent as CloseSVG} from '../../assets/closeInvesthub.svg'


const useStyles = makeStyles(theme => ({
    dialogInner: {
        padding: '26px 24px 24px',
        maxWidth: '480px',
    },
    dialogTitle: {
        marginBottom: '24px'
    },
    dialogClose:{
        position: 'absolute',
        top: '24px',
        right: '24px',
        zIndex: '2',
        width: '36px',
        height: '36px',
        borderRadius: '50%',
        background: 'var(--very-light-pink-secondary)',
        transition: 'background .2s',

        '& svg': {
            width: '20px',
            height: '20px',
            fill: 'var(--charcoal-primary)',
            transition: 'transform .25s',
        },
        '&:hover svg': {
            transform: 'rotate(90deg)'
        }
    },
    backdrop:{
        backgroundColor: "rgba(53, 64, 82, 0.8)"
    }
}));

const DialogPopUp = (props) => {
    const {title, children, ...dialogProps} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const classes = useStyles();

    return (
        <Dialog
            maxWidth={'md'}
            fullScreen={fullScreen}
            PaperProps={{elevation:0}}
            BackdropProps={{className:classes.backdrop}}
            {...dialogProps}
        >
            <div className={classes.dialogInner}>
                {title ? (
                    <>
                        <div className={`title-blue text-weight-300 ${classes.dialogTitle}`}>{title}</div>
                    </>
                ) : null}

                {dialogProps.onClose ? (
                    <button type="button" onClick={dialogProps.onClose} className={classes.dialogClose}>
                        <CloseSVG />
                    </button>
                ) : null}

                {children}
            </div>
        </Dialog>
    );
};

export default DialogPopUp;
