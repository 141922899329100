import React from 'react';
import {Route, Switch} from 'react-router-dom'
import RolesTable from "./RolesTable";

const Roles = (props) => {
    const {match, underBarRef} = props;
    const {path} = match;

    return (
        <>
            <Switch>
                <Route exact path={path} render={
                    (props) => {
                        return <RolesTable {...props} underBarRef={underBarRef}/>
                    }
                }
                />
            </Switch>
        </>
    );
};

export default Roles;
