import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {Link, Prompt, withRouter} from "react-router-dom";
import {compose} from "redux";
import CompanyForm from "./CompanyForm";

import useForm2 from "../../../../../hooks/useForm2";
import Api from "../../../../../services/api";
import {useTranslation} from "react-i18next";
import {getLoggedUser, updateUserCompany} from "../../../../User/actions";
import {useDispatch} from "react-redux";
import CompanyCountryCheck from "../../../../CompanyCountryCheck";
import {ReactComponent as Logo} from "../../../../../assets/logoInvesthub.svg";
import VolveSnackbar from "../../../../VolveSnackbar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";
import {Can} from "../../../../../Context/Can";
import {Ability, subject} from "@casl/ability";

const service = new Api();
const {updateCompany} = service;

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        '&:not(:last-child)': {
            marginBottom: '32px',
            borderBottom: '1px solid var(--very-light-pink-secondary)',

            [theme.breakpoints.down('1023')]: {
                marginBottom: '24px'
            }
        }
    },
	snackbar:{
		width: "100%",
		marginBottom: "30px",
		fontSize: "1rem",
		'& svg path':{
			fill:'#fff'
		},
	},
	warning:{
		background: "#ff9800",
	},
	error:{
		background:"#d32f2f",
	},

    link:{
        color: "#24DAC4",
        fontSize: "15px",
        lineHeight: "26px",
        textDecoration:"none",
        "&:hover":{
            textDecoration:"none",
            color: "#1EB3A1",
            background: "none"
        }
    },
}));


const CompanyProfile = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();
    const dispatch = useDispatch();
    const {match, setMessage, setCompany, isDataFetching, countries, types, employeesRangeList, company, currencies, underBarRef, userData} = props;

    const {url} = match;
    const [isLoading, setIsLoading] = useState(false);

    const [ability, setAbility] = useState(new Ability());

    const fields = ["name", "employeesRange", "companyRegistrationNo", "type", "addressStreet", "addressRegion", "addressNumber", "addrl2", "postCode", "city", "country", "currency"];

	const validate = (values) => {
		const errors = {};
		if(!values["name"]){
			errors["name"] = t("errors.required", {field:"$t(formFields.companyName)"});
		}
		if(!values["companyRegistrationNo"]){
			errors["companyRegistrationNo"] = t("errors.required", {field:"$t(formFields.companyRegistrationNo)"});
		}
		if(!values["city"]){
			errors["city"] =  t("errors.required", {field:"$t(formFields.city)"});
		}
		if(!values["country"]){
			errors["country"] =  t("errors.required", {field:"$t(formFields.country)"});
		}
		if(!values["type"]){
			errors["type"] = t("errors.required", {field:"$t(formFields.typeOfIncorporation)"});
		}
		if(!values["currency"]){
			errors["currency"] = t("errors.required", {field:"$t(formFields.currency)"});
		}
		if(!values["employeesRange"]){
			errors["employeesRange"] =  t("errors.required", {field:"$t(formFields.employeesRange)"});
		}

		return errors
	};

    const submit = async () => {

        const dataToSend = fields.reduce((obj, field) => {
            obj[field] = values[field];
            return obj
        },{});

        try {
            const response = await updateCompany(dataToSend);
            setCompany(response.data);
            setIsLoading(false);
            setValuesWasChanged(false);
            // update some fields in redux
            dispatch(updateUserCompany({country:response.data.country, currency:response.data.currency}));
            dispatch(getLoggedUser()); // we need to update user state because some user fields depends from company fields
            setMessage('success', 'success');
        }catch (e) {
            console.log(e);
            setServerErrors(e);
        } finally {
            setIsLoading(false);
        }
    }


    const { handleChange, handleSubmit, values, errors, setServerErrors, fillFormValues } = useForm2(
        submit,
        validate,
    );

    useEffect(() => {

        const objectToFill = {};
        fields.forEach((field) => {
            objectToFill[field] = company[field];
        });
        fillFormValues(objectToFill);
        const {rules} = company;
        setAbility(new Ability(rules));

    },[company]);

	const checkDisabledField = (field) => {
        return !ability.can("update", subject("company", company), field)
	}

    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }


    return (
        <div className={classes.item}>
            <Prompt
                when={valuesWasChanged}
                message={t("unsavedChanges")}
            />
            <CompanyCountryCheck noText>
                <Can I="update" a={"company"}>
                    {!isDataFetching && (
                        !company.kyb && (
                            <Grid container spacing={5}>
                                <Grid item lg={8} xs={12}>
                                <Box mb={"25px"}>
                                    <VolveSnackbar
                                        message={<>{t("verifyKyb")}</>}
                                        action={<><Link to={`${url}/verify`} className={classes.link}>{t("formFields.verify")}</Link></>}
                                        icon={<Logo/>}
                                    />
                                </Box>
                                </Grid>
                            </Grid>

                        )
                    )}
                </Can>
            </CompanyCountryCheck>
            <CompanyForm
                handleSubmit={handleSubmit}
                handleChange={handleChangeWithDetectChanges}
                values={values}
                errors={errors}
                isLoading={isLoading}
                isDataFetching={isDataFetching}
                countries={countries}
                types={types}
                employeesRangeList={employeesRangeList}
                checkDisabledField={checkDisabledField}
                currencies={currencies}
            />
        </div>
    );
};

export default compose(
    withRouter,
)(CompanyProfile);
