import React, {useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import Pagination from "../../../../Pagination";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import {ReactComponent as CloseThin} from "../../../../../assets/closeThin.svg";
import IconButton from "@material-ui/core/IconButton";
import {ReactComponent as Pen} from "../../../../../assets/pen.svg";
import {ReactComponent as Download} from "../../../../../assets/download.svg";
import {makeStyles} from "@material-ui/core";
import FormatDate from "../../../../FormatDate";
import downloadFile from "../../../../../helpers/downloadFile";
import {Can} from "../../../../../Context/Can";
import {subject} from "@casl/ability";

import DialogReport from "../DialogReport";

import {confirm} from '../../../../../helpers/confirm'
import Portal from "@material-ui/core/Portal";
import VolveMaterialTable from "../../../../VolveMaterialTable";
const service = new Api();

const useStyles = makeStyles((theme) => (
    {
        deleteBtn:{
            '& svg path':{
                stroke:"#2c2c36"
            }
        },
        downloadBtn:{
            '& svg path':{
                fill:"#24dac4"
            }
        },
        tableReportTable: {
            "& .MuiPaper-root": {
                backgroundColor: "transparent",
                marginLeft: '-24px',
                marginRight: '-24px',
                [theme.breakpoints.down('767')]: {
                    marginLeft: '-16px',
                    marginRight: '-16px'
                }
            },
            "& thead tr th": {
                padding: '10px 16px',
                "&:first-child": {
                    paddingLeft: '24px',
                    [theme.breakpoints.down('767')]: {
                        paddingLeft: '16px'
                    }
                },
                "&:last-child": {
                    paddingRight: '24px',
                    [theme.breakpoints.down('767')]: {
                        paddingRight: '16px'
                    }
                }
            },
            "& tbody tr td": {
                fontSize: '14px !important',
                fontWeight: '600 !important',
                lineHeight: '1.71429',
                color: 'var(--battelship-grey) !important',
                padding: '10px 16px !important',
                transition: 'color .2s',
                "&:hover": {
                    color: 'var(--dark-secondary) !important'
                },

                "&:first-child": {
                    paddingLeft: '24px !important',
                    [theme.breakpoints.down('767')]: {
                        paddingLeft: '16px !important'
                    }
                },
                "&:last-child": {
                    paddingRight: '24px !important',
                    [theme.breakpoints.down('767')]: {
                        paddingRight: '16px !important'
                    }
                }

            },
            "& tbody tr:nth-child(2n) td": {
                backgroundColor: 'rgba(239, 239, 239, 0.35)',
            }
        },
        head: {
            display: 'flex',
            position: 'relative',
            minHeight: '40px',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid var(--very-light-pink-secondary)',
            marginBottom: '56px',
            paddingBottom: '32px',
            [theme.breakpoints.down('1023')]: {
                marginBottom: '34px',
                paddingBottom: '24px',

            },
            [theme.breakpoints.down('767')]: {
                flexWrap: 'wrap',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px'
            },
        },
        item: {
            position: 'relative'
        }

    }
));

const ReportsTable = (props) => {
    const classes = useStyles();

    const {handleErrorMessage, location, dateFormat, tabs={}, userId, setMessage, underBarRef} = props;
    const {search} = location;
    const queryObject = queryString.parse(search);

    const [reports, setReports] = useState([]);
    const [isMount, setIsMount] = useState(false);

    const [info, setInfo] = useState({
        fields: ["date", "name", "fileFormat", "reportActions"],
        page: 0,
        total: 0,
        pageSize: 0
    });

    useEffect(() => {
        setIsMount(true);
    },[]);


    const {page, total, pageSize, fields} = info;

    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isOpenReport, setIsOpenReport] = useState(false);
    const [report, setReport] = useState({});
    const [isFetchingReport, setIsFetchingReport] = useState(false);

    const buildTabsQuery = (roleTab) => {
        switch (roleTab) {
            case "administrator":
            case "auditor":
                return {};
            case "approver":
                return {approver: userId};
            default:
                return {user: userId};
        }
    }


    const fetchReports = async (queryObject) => {
        try {
            const tabQuery = tabs ? buildTabsQuery(tabs.active) : {};
            setIsLoading(true);
            const response = await service.getReports({...queryObject, ...tabQuery});
            const {info, result} = response.data;

            setReports((old) => {
                if(info.page > 1){
                    // merge only if it is pagination request
                    return [...old, ...result]
                }
                return result
            });

            setInfo((old) => {
                const fields = [...old.fields]; // do not use fields from request because they are different
                return {
                    ...info,
                    fields
                }
            });

        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }
    }

    const fetchReport = async (id) => {
        try {
            setIsOpenReport(true);
            setIsFetchingReport(true);
            const response = await service.getReport(id);
            setReport(response.data);
        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
            setIsOpenReport(false);
        }finally {
            setIsFetchingReport(false);
        }
    }


    const {active} = tabs;
    useEffect(() => {
        fetchReports(queryObject);
    },[search, active]);


    const handleChangePage = async (event, newPage) => {
        fetchReports({...queryObject, page:newPage})
    }

    const onDownload = async (path) => {
        setIsLoading(true);

        try {
            if(path){
                const url = `${service._basicPath}/files/${path}`;
                await downloadFile(url);
            }else{
                handleErrorMessage({message:"errors.somethingWentWrong"})
            }

        }catch (e) {
            console.log(e);
            handleErrorMessage(e);
        }finally {
            setIsLoading(false);
        }
    }


    const updateReportState = (updatedReport) => {
        setReports((oldReports) => {
            return oldReports.map((report) => {
                if(updatedReport._id === report._id){
                    return updatedReport
                }else{
                    return report
                }
            })
        })
    }

    const onDelete = async (id) => {
            if (await confirm()) {
                try {
                    setIsLoading(true);
                    const response = await service.deleteReport(id);

                    setReports((old) => {
                        return old.filter((report) => report._id !== response.data._id);
                    });

                    setMessage("success", 'success');
                }catch (e) {
                    handleErrorMessage(e);
                } finally {
                    setIsLoading(false)
                }
            }
    }


    const columns = [
        {
            title: t('thead.date'),
            field: 'createdAt',
            render: ({createdAt}) => <FormatDate dateFormat={dateFormat}>{createdAt}</FormatDate>
        },
        {
            title: t('thead.name'),
            field: 'name',
        },
        {
            title: t('thead.fileFormat'),
            field: 'ext',
        },
        {
            title: t('thead.reportActions'),
            align:'right',
            render: (row) => {
                const {path} = row;
                return (
                    <>
                        <Can I="delete" this={subject("report", row)}>
                            <IconButton onClick={() => onDelete(row._id)} className={classes.deleteBtn} title={t("formFields.delete")}>
                                <CloseThin/>
                            </IconButton>
                        </Can>
                        <Can I="update" this={subject("report", row)}>
                            <IconButton onClick={() => fetchReport(row._id)} title={t("formFields.rename")}>
                                <Pen/>
                            </IconButton>
                        </Can>
                        <IconButton onClick={() => onDownload(path)} className={classes.downloadBtn} title={t("formFields.download")}>
                            <Download/>
                        </IconButton>

                    </>
                )
            }
        },
    ];

    return (
        <>
            {isMount && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("report.mainTitle")}
                    </div>
                </Portal>
            )}
            {isOpenReport ? (
                <DialogReport
                    setOpen={setIsOpenReport}
                    loading={isFetchingReport}
                    report={report}
                    title={t("report.editTitle")}
                    updateReportState={updateReportState}
                />
            ) : null}
            <div className={`card ${classes.tableReportTable}`}>
                <div className={classes.head}>
                    <div className={`title-blue text-weight-300 margin-bottom-0`}>
                        {/* NEED TRANSLATION */}
                        {t('Table Report')}
                    </div>
                </div>
                <div className={classes.item}>
                    <VolveMaterialTable
                        columns={columns}
                        isLoading={isLoading}
                        data={reports}
                    />
                    <Pagination
                        total={total}
                        pageSize={pageSize}
                        page={page}
                        isLoading={isLoading}
                        onChangePage={handleChangePage}
                        loadMore
                    />
                </div>
            </div>
        </>
    );
};


const mapStateToProps = (state) => {
    const {user, tabs} = state;
    const {userData} = user;
    const {regional={}} = user.settings;
    const {dateFormat} = regional;

    return {
        dateFormat,
        tabs: tabs["reports"],
        userId: userData._id
    }
}


export default compose(
    withRouter,
    connect(mapStateToProps, {handleErrorMessage, setMessage})
)(ReportsTable);
