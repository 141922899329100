import React, {useEffect, useState} from 'react';
import {withRouter, Route, Switch} from 'react-router-dom'
import ProjectsTable from "./ProjectsTable";
import Project from "./Project/Project";
import {useDispatch} from "react-redux";
import Api from "../../../../services/api";
import {handleErrorMessage} from "../../../Messages/actions";
import AddProject from "./AddProject";
const service = new Api();


const Projects = (props) => {
    const {match, underBarRef} = props;
    const {url} = match;

    const dispatch = useDispatch();
    const [groups, setGroups] = useState([]);

    const fetchGroups = async () => {
        try {
            const response = await service.getGroupsAll();
            const mappedGroups = response.data.groups.map(({_id, name}) => ({label:name, value:_id}));
            setGroups(mappedGroups);

        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }
    }

    useEffect(() => {
        fetchGroups();
    },[]);

    return (
        <>
            <Switch>
                <Route exact path={url} render={(props) =>  <ProjectsTable {...props} underBarRef={underBarRef}/>}/>
                <Route exact path={`${url}/add`} render={(props) => <AddProject {...props} underBarRef={underBarRef} groups={groups}/>}/>
                <Route exact path={`${url}/:id`} render={(props) => <Project {...props} underBarRef={underBarRef} groups={groups}/>}/>
            </Switch>
        </>
    );
};

export default withRouter(Projects);
