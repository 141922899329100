import React from 'react'
import Branches from "../userSection/content/Branches";

import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";


const BranchesPage = (props) => {
    const {t} = useTranslation();
    const {underBarRef} = props;
    return (
        <>
            <UserSectionWrapper>
                <Can I="read" a={"branch"} passThrough>
                    {(can) => (
                        can ? <Branches underBarRef={underBarRef}/> : <div>{t("branch.noPermissionToRead")}</div>
                    )}
                </Can>
            </UserSectionWrapper>
        </>
    )

}



export default BranchesPage
