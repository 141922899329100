import React, {useEffect, useState} from 'react';
import {compose} from "redux";

// import TopComponent from "../TopComponent";
import TopComponentInvesthub from "../TopComponentInvesthub"
import {connect} from "react-redux";
import {fetchBranches} from "../actions";
import checkClick from "../../../../../helpers/checkClick";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import Pagination from "../../../../Pagination";
import Portal from "@material-ui/core/Portal";
import useMounted from "../../../../../hooks/useMounted";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import {makeStyles} from "@material-ui/core";
const service = new Api();

const useStyle = makeStyles((theme) => ({
    tableBranches: {
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: '-24px',
            marginRight: '-24px',
            [theme.breakpoints.down('767')]: {
                marginLeft: '-16px',
                marginRight: '-16px'
            }
        },
        "& thead tr th": {
            padding: '10px 16px',
            "&:first-child": {
                paddingLeft: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px'
                }
            },
            "&:last-child": {
                paddingRight: '24px',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px'
                }
            }
        },
        "& tbody tr td": {
            fontSize: '14px !important',
            fontWeight: '600 !important',
            lineHeight: '1.71429',
            color: 'var(--battelship-grey) !important',
            padding: '10px 16px !important',
            transition: 'color .2s',
            "&:hover": {
                color: 'var(--dark-secondary) !important'
            },

            "&:first-child": {
                paddingLeft: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingLeft: '16px !important'
                }
            },
            "&:last-child": {
                paddingRight: '24px !important',
                [theme.breakpoints.down('767')]: {
                    paddingRight: '16px !important'
                }
            }

        },
        "& tbody tr:nth-child(2n) td": {
            backgroundColor: 'rgba(239, 239, 239, 0.35)',
        }
    }
}))


const BranchesTable = (props) => {
    const {t} = useTranslation();
    const {fetchBranches, branches, handleErrorMessage, countries=[], history, match, setMessage, underBarRef} = props;
    const {result, info} = branches;
    const {page, total, pageSize, fields} = info;
    const classes = useStyle();

    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useMounted();

    useEffect(() => {
        (async() => {
            setIsLoading(true);
            await fetchBranches();
            setIsLoading(false);
        })();
    },[fetchBranches]);



    const handleChangePage = async (event, newPage) => {
        setIsLoading(true);
        await fetchBranches({
            page:newPage,
        });
        setIsLoading(false);
    }

	const rowHandlerClick = async (e, rowData) => {
        const wasClicked = checkClick();
        if(wasClicked){
            history.push(`${match.url}/${rowData._id}`);
        }
    }

    const onImport = async (formData) => {
        const response = await service.importBranches(formData);
        const {data} = response;
        const {modified, doNotModified} = data;
        const message = t("branch.importInfo", {modified:modified, all:modified+doNotModified});
        setMessage(message, 'success');
        fetchBranches();
    }


    const columns = [
        {
            title: t('thead.name'),
            field: 'name',
        },
        {
            title: t('thead.currency'),
            field: 'currency',
        },
        {
            title: t('thead.country'),
            field: 'country',
            render: ({country}) => {
                const foundCountry = country ? countries.find((countryObj) => countryObj["value"] === country) : undefined;
                return foundCountry ? foundCountry.label : ""
            }
        },
        {
            title: t('thead.enterpriseNumber'),
            field: 'enterpriseNumber',
        },
        {
            title: t('thead.id'),
            field: '_id',
        },
    ];

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">
                        {t("settingsTitle")}
                    </div>

                </Portal>
            )}
            <div className={`card ${classes.tableBranches}`}>
                <TopComponentInvesthub history={history} onImport={onImport}/>
                <VolveMaterialTable
                    columns={columns}
                    isLoading={isLoading}
                    data={result}
                    onRowClick={rowHandlerClick}
                />
                <Pagination
                    total={total}
                    pageSize={pageSize}
                    page={page}
                    isLoading={isLoading}
                    onChangePage={handleChangePage}
                    loadMore
                />
            </div>
        </>
    );
};

const mapStateToProps = ({userBranches}) => {
    const {branches} = userBranches;
    return {
        branches
    }
}

export default compose(
    connect(mapStateToProps, {fetchBranches, handleErrorMessage, setMessage})
)(BranchesTable);
