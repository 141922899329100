import React from 'react'
import {makeStyles} from "@material-ui/core"
import {NavLink, matchPath, useLocation} from "react-router-dom"
import {useTranslation} from "react-i18next";
import AddFundingBankAccountInvesthub from '../../FundingBankAccounts/AddFundingBankAccountInvesthub';

const useStyles = makeStyles(theme => {
    return {
        head: {
            display: 'flex',
            position: 'relative',
            minHeight: '40px',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid var(--very-light-pink-secondary)',
            marginBottom: '56px',
            paddingBottom: '32px',
            [theme.breakpoints.down('1023')]: {
                marginBottom: '34px',
                paddingBottom: '24px',
                flexWrap: 'wrap',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px'
            },
            [theme.breakpoints.down('767')]: {
                flexWrap: 'wrap',
                paddingBottom: '24px',
                marginBottom: '24px'
            },
        },
        headNav: {
            display: 'flex',
            marginLeft: 'auto',
            marginTop: '4px',
            [theme.breakpoints.down('767')]: {
                width: '100%',
                // margin: '0 0 16px'
            }
        },
        headHasTitleButton: {
            display: 'flex',
            gap: '12px',
            justifyContent: 'start',
            alignItems: 'center',
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            [theme.breakpoints.down('767')]: {
                width: '100%'
            }
        },
        headLink: {
            padding: '8px 16px',
            borderRadius: '8px',
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--battelship-grey)',
            transition: 'all .2s',
            [theme.breakpoints.down('767')]: {
                flexGrow: '1'
            },
            '&:not(:last-child)': {
                marginRight: '8px',
                [theme.breakpoints.down('767')]: {
                    marginRight: '0'
                }
            },
            '&.active': {
                color: 'var(--dark-secondary)',
                backgroundColor: 'var(--very-light-pink-secondary)'
            },
            '&:hover': {
                color: 'var(--dark-secondary)',
            }
        }

    }
});

const HeadContentInvesthub =(props) => {
    const {t} = useTranslation();
    const {routes=[], rightContent} = props;
    const classes = useStyles();

    const location = useLocation();
    const matchedPathSettingCompany = matchPath(location.pathname, {
        path: "/settings/company",
        exact: true,
        strict: false
    });

    const matchedPathSettingCompanyAccount = matchPath(location.pathname, {
        path: "/settings/company/funding-bank-accounts",
        exact: true,
        strict: false
    });

    let titleName = null;
    let titleButton = null;
    let hasTitleButton = false;
    if (matchedPathSettingCompany) {
        titleName = <>{t("company.profileTab")}</>
    }
    if (matchedPathSettingCompanyAccount) {
        titleName = <>{t("company.fabTab")}</>
        titleButton = <AddFundingBankAccountInvesthub />
        hasTitleButton = true;
    }

    return(
        <div className={classes.head}>
            <div className={`title-blue text-weight-300 margin-bottom-0 ${hasTitleButton ? classes.headHasTitleButton : ''}`}>
                {titleName}
                {titleButton}
            </div>
            <div className={classes.headNav}>
                {routes.map((item, key) => {
                    return (
                        <NavLink
                            key={key}
                            className={classes.headLink}
                            exact
                            to={item.url}
                        >
                            {item.name}
                        </NavLink>
                    )
                })}
            </div>
            {rightContent}
        </div>
    )
}

export default HeadContentInvesthub
