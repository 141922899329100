import React from 'react';
import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";
import Departments from "../userSection/content/Departments";

const DepartmentsPage = (props) => {
    const {t} = useTranslation();
    const {drawerOuterRef, underBarRef} = props;

    return (
        <UserSectionWrapper>
            <Can I="read" a={"department"} passThrough>
                {(can) => (
                    can ? <Departments drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/> : <div>{t("department.noPermissionToRead")}</div>
                )}
            </Can>
        </UserSectionWrapper>
    );
};

export default DepartmentsPage;
