import React, {useEffect, useState} from 'react';
import {NavLink, withRouter} from "react-router-dom";

import {makeStyles, useTheme} from '@material-ui/core/styles';
import MaterialUiDrawer from "@material-ui/core/Drawer";
import Box from '@material-ui/core/Box';
import ListItem from "@material-ui/core/ListItem";
import clsx from 'clsx';

// import {ReactComponent as Logo} from '../../../assets/iconEvolveBlack.svg'
import {ReactComponent as Logo} from "../../../assets/paladis.svg"
import {ReactComponent as ArrowLeftSVG} from '../../../assets/arrowLeftInvesthub.svg'
import {ReactComponent as ArrowRightSVG} from '../../../assets/arrowRightInvesthub.svg'

import {connect} from "react-redux";
import {drawerMainMenuPermissions, drawerMainToggle} from './actions'

import Menu from "./Menu";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useTranslation} from "react-i18next";
// import Notifications from "./Notifications";
import {compose} from "redux";
// import useDrawerWidth from "../../../hooks/useDrawerWidth";
import Tooltip from "@material-ui/core/Tooltip";
import { getExternalFileUrl } from "../../../helpers/file"

const useStyles = makeStyles(theme => ({
    logo: {
        padding: '22px 10px 33px 28px',
        display: 'inline-flex',
        maxWidth: '96px',
        '& svg': {
            width: '100%',
            height: 'auto'
        }
    },
    drawer: {
    },
    drawerInner: {
        "&.MuiDrawer-paper": {
            border: 'none',
            backgroundColor: 'var(--dashboard-bg-sidebar)',
            padding: '24px',
        }
    },
    sidebarLogo: {
        display: "block",
        width: "165px",
        marginBottom: '24px',
        [theme.breakpoints.down('1339')]: {
            marginBottom: '24px'
        },
        [theme.breakpoints.down('1259')]: {
            textAlign: 'center'
        },

        "& svg": {
            width: '100%',
            height: 'auto'
        },
        '& img': {
            width: '100%',
            height: 'auto'
        }
    },
    sidebarMenu: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 'auto'
    },
    sidebarBottom: {
        marginTop: '24px',
        paddingTop: '18px',
        borderTop: '2px solid var(--very-light-pink-primary)',
        textAlign: 'center',
        [theme.breakpoints.down(1259)]: {
            marginTop: '12px',
            paddingTop: '12px'
        }
    },
    sidebarBottomExpand: {
        width: '48px',
        height: '48px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: 'var(--very-light-pink-secondary)',
        "& svg": {
            width: '24px',
            height: '24px',
            fill: 'var(--dark-secondary)',
        }
    },
    sidebarBottomShrink: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 16px',
        borderRadius: '8px',
        fontSize: '15px',
        fontWeight: '600',
        lineHeight: '1.6',
        backgroundColor: 'var(--very-light-pink-secondary)',
        color: 'var(--dark-secondary)',
        transition: 'all .2s',
        "& svg": {
            width: '24px',
            height: '24px',
            fill: 'var(--dark-secondary)',
            marginRight: '8px',
            position: 'relative',
            top: '-1px'
        }
    },
    sidebarOverlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '25',
        background: 'rgba(244, 244, 244, 0.8)',
        // visibility: 'hidden',
        // opacity: '0',
        transition: 'all .2s',
    }

}));

const DrawerMain = (props) => {
    const {drawerMain, drawerMainToggle, isAuthenticated, isWhiteLabel, drawerMainMenuPermissions, publicSettings} = props;
    const {isOpen, viewPermissions} = drawerMain;
    // const drawerWidth = useDrawerWidth();

    const usStylesDrawer = makeStyles((theme) => (
        {
            drawerDefault: {
                width: '340px'
            },
            drawerInnerOnMax1339: {
                width: '300px',
                padding: '16px !important',
            },
            drawerInnerOnMax1259: {
                zIndex: '30',
                width: '96px',
                alignItems: 'center'
            },

            drawerInnerIsOpen: {
                '&$drawerDefault': {

                    [theme.breakpoints.down('1259')]: {
                        alignItems: 'stretch',
                        width: '300px',
                        boxShadow: '4px 0 32px rgba(17, 19, 21, 0.05)',
                    }

                }
            },

            drawerInnerShrink: {
                '&$drawerDefault': {
                    zIndex: '30',
                    width: '96px',
                    alignItems: 'center'
                },
                '&$drawerInnerOnMax1339': {
                    zIndex: '30',
                    width: '96px',
                    alignItems: 'center'
                },
                '&$drawerInnerOnMax1259': {
                    zIndex: '30',
                    width: '96px',
                    alignItems: 'center'
                }

            },
            drawerInnerExpand: {
                '&$drawerDefault': {
                    width: '340px',
                },
                "&$drawerInnerOnMax1339": {
                    width: '300px',
                },
                "&$drawerInnerOnMax1259": {
                    width: '300px',
                    boxShadow: '4px 0 32px rgba(17, 19, 21, 0.05)',
                    alignItems: 'stretch'
                }
            }


            // drawerInnerOpen: {
            //     width: '340px',
            // },

            // drawerClose: {
            //     transition: theme.transitions.create('width', {
            //         easing: theme.transitions.easing.sharp,
            //         duration: theme.transitions.duration.leavingScreen,
            //     }),
            //     overflowX: 'hidden',
            //     width: 0,
            //     border: 0,
            //     [theme.breakpoints.up('md')]: {
            //         width: theme.spacing(11) + 1,
            //     },
            // },

        }
    ));

    const {t} = useTranslation();

    const theme = useTheme();
    // const isPc = useMediaQuery(theme.breakpoints.up('md'), {noSsr: true});
    const onMax1339 = useMediaQuery(theme.breakpoints.down('1339'), {noSsr: true});
    const onMax1259 = useMediaQuery(theme.breakpoints.down('1259'), {noSsr: true});
    const isDown1023 = useMediaQuery(theme.breakpoints.up('1023'), {noSsr: true});
    const onMax767 = useMediaQuery(theme.breakpoints.down('767'), {noSsr: true})
    const variant = !onMax767 ? 'permanent' : 'temporary';

    useEffect(() => {
        drawerMainMenuPermissions();
    },[]);


    useEffect(() => {
        if (isOpen && !isDown1023) {
            // close main drawer by default on 1023 viewport
            drawerMainToggle();
        }
    }, []);


    const classes = useStyles();
    const drawerClasses = usStylesDrawer();
    if (!isAuthenticated) {
        return null;
    }

    return (
        <>
            <MaterialUiDrawer
                variant={variant}
                classes={{
                    paper: clsx(classes.drawerInner, drawerClasses.drawerDefault,
                    {
                        [drawerClasses.drawerInnerOnMax1339]: onMax1339,
                        [drawerClasses.drawerInnerOnMax1259]: onMax1259,
                        [drawerClasses.drawerInnerIsOpen]: isOpen,
                        [drawerClasses.drawerInnerOnVariant]: variant
                    })
                }}
                data-test="drawer-main"
                open={isOpen}
                ModalProps={{onBackdropClick: drawerMainToggle}}
            >
                <NavLink to={`/dashboard`} className={clsx(classes.sidebarLogo)}>
                    { publicSettings && publicSettings.logo_path &&
                        <img
                            className={`${publicSettings.logo_path}`.match(/\.svg/) ? 'svg-logo' : ''}
                            src={getExternalFileUrl(publicSettings.originHost, publicSettings.logo_path)}
                            alt="logo"
                        />
                    }
                    { (!publicSettings || !publicSettings.logo_path) &&
                        <Logo />
                    }
                </NavLink>

                <Box className={clsx(classes.sidebarMenu)}>
                    <Menu isOpen={isOpen} viewPermissions={viewPermissions}/>
                </Box>

                {onMax1259 && (
                    <Box className={classes.sidebarBottom}>
                        {/* NEED TRANSLATION */}
                        {(!isOpen) ? (
                            <>
                                <Tooltip title="Expand Menu">
                                    <ListItem
                                        button
                                        onClick={drawerMainToggle}
                                        className={classes.sidebarBottomExpand}
                                    >
                                        <ArrowRightSVG />
                                    </ListItem>
                                </Tooltip>
                            </>
                        ): (
                            <ListItem
                                button
                                onClick={drawerMainToggle}
                                className={classes.sidebarBottomShrink}
                            >
                                <ArrowLeftSVG />
                                {' '}
                                {t("collapseMenu")}
                            </ListItem>
                        )}
                    </Box>
                )}
            </MaterialUiDrawer>
            {onMax1259 && (
                <>
                    {(isOpen) && (
                        <Box className={classes.sidebarOverlay} onClick={drawerMainToggle}>{' '}</Box>
                    )}
                </>
            )}
        </>
    )
}

const mapStateToProps = ({drawerMain, user, publicSettings}) => {
    const {userData, isAuthenticated, roles} = user;
    const {company} = userData;
    const {isWhiteLabel} = company;

    return {
        drawerMain,
        isAuthenticated,
        roles,
        isWhiteLabel,
        publicSettings,
    }
};

export default compose(
    withRouter,
    connect( mapStateToProps,{drawerMainToggle, drawerMainMenuPermissions})
)(DrawerMain)
