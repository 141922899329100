import React from 'react'
import {useTranslation} from "react-i18next";
import CompanyCountryCheck from "../CompanyCountryCheck";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";
import UserPermissions from "../userSection/content/UserPermissions";


const UserPermissionsPage = (props) => {
    const {t} = useTranslation();
    const {drawerOuterRef, underBarRef} = props;

    return (
        <UserSectionWrapper>
            <CompanyCountryCheck>
                <Can I="update:permissions" a={"company"} passThrough>
                    {(can) => (
                        can ? <UserPermissions drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/> : <div>{t("company.noPermissionToRead")}</div>
                    )}
                </Can>
            </CompanyCountryCheck>
        </UserSectionWrapper>
    )

}

export default UserPermissionsPage
