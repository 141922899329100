import React, {useCallback} from 'react';
import CheckBoxField from "../../../../formElements/CheckBoxField";
import {ReactComponent as ReceiptIcon} from "../../../../../assets/receipt.svg";
import {ReactComponent as AccountsIcon} from "../../../../../assets/accounts.svg";

import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import checkClick from "../../../../../helpers/checkClick";
import {withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import ExclamationToolTip from "../../../../ExclamationToolTip";
import {useTranslation} from "react-i18next";
import FormatDate from "../../../../FormatDate";
import ExpenseStatus from "../../../../ExpenseStatus";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FormatMoney from "../../../../FormatMoney";
import ExpenseRow from "./ExpenseRow";
import CheckBoxFieldMemo from "../../../../formElements/CheckBoxFieldMemo";
import Box from "@material-ui/core/Box";
import VolveMaterialTable from "../../../../VolveMaterialTable";
import getCardName from "../../../../../helpers/getCardName";


const useStyles = makeStyles(theme => ({
    notReimbursable: {
        background: "#F5A623"
    },
    noReceipt:{
        "& path":{
            fill:"#8A96A0"
        }
    },
    exclamation: {
        display: "inline-block",
        verticalAlign: "middle"
    },

}));

const ExpenseTable = (props) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const {expenses=[], isLoading, history, activeRoleTab, dateFormat, selected, setSelected} = props;

    const onCheckAll = (value) => {
        if(expenses.length){
            setSelected(value ? expenses.map((item) => item._id) : []);
        }

    }

    const onChange = (e ,value) => {
        setSelected((items) => {

            if(value){
                const copy = [...items];
                copy.push(e.target.id);
                return copy;
            }else{
                return items.filter((item) => item !== e.target.id)
            }
        })
    }

    const memoizedOnChange = useCallback(onChange , []);

    const columns = [
        {
            headerStyle: { padding:' 0 0 0 16px'},
            cellStyle: { padding:' 0 0 0 16px', whiteSpace:'nowrap'},
            title: expenses.length ? (
                <Box paddingLeft={"6px"}>
                    <CheckBoxField
                        onCheckboxChange={(val) => onCheckAll(!!val)}
                        value={!!(expenses.length && (selected.length === expenses.length))}
                        disabled={activeRoleTab === 'auditor'}
                        label={''}
                    />
                </Box>
            ) : '',
            render: ({_id, reimbursable }) => (
                <>
                    <CheckBoxFieldMemo
                        onChange={memoizedOnChange}
                        checked={selected.includes(String(_id))}
                        label={''}
                        id={String(_id)}
                        name={""}
                        disabled={activeRoleTab === 'auditor'}
                    />
                    {(reimbursable === false) && (
                        <ExclamationToolTip title={t("formFields.nonReimbursable")} className={classes.exclamation}/>
                    )}
                </>)
        },
        {
            title: t('thead.date'),
            cellStyle: { whiteSpace:'nowrap'},
            field: 'date',
            render: (row) => ( <FormatDate dateFormat={dateFormat}>{row.date}</FormatDate>)
        },
        {
            title: t('thead.description'),
            cellStyle: { whiteSpace:'nowrap'},
            field: 'merchant',
        },
        {
            title: t('thead.category'),
            cellStyle: { whiteSpace:'nowrap'},
            field: 'category',
            render : ({category}) => (category && category.name ) ? category.name : " "
        },
        ...(activeRoleTab !== 'personal' ? [{
            title: t('thead.employee'),
            cellStyle: { whiteSpace:'nowrap'},
            field: 'user',
            render : ({user}) => user ? `${user.firstName} ${user.lastName}` : " "
        }] : []),
        {
            title: t('thead.paymentMethod'),
            cellStyle: { whiteSpace:'nowrap'},
            field: 'paidWith',
            render : ({paidWith}) => {
                if (!paidWith) return "";

                if (typeof paidWith === 'string') {
                    return paidWith;
                } else if (typeof paidWith === 'object') {
                    return getCardName(paidWith)
                }
                return "";
            }
        },
        {
            title: t('thead.amount'),
            cellStyle: { whiteSpace:'nowrap'},
            field: 'paidWith',
            align:'right',
            render : ({amount, currency, reportedExchangeRate, reportedCurrency, calculatedAmount}) => (
                <Tooltip title={ <> {currency} {amount}<br/>{currency} 1  = {reportedCurrency} {reportedExchangeRate} </>} placement="right">
                    <span>{reportedCurrency}{" "}<FormatMoney>{calculatedAmount}</FormatMoney></span>
                </Tooltip>
            )
        },
        {
            title: t('thead.status'),
            cellStyle: { whiteSpace:'nowrap'},
            field: 'status',
            render: (row) =>  <ExpenseStatus status={row.status}/>
        },
        {
            cellStyle: { padding:'0 16px 0 16px', whiteSpace:'nowrap'},
            title: '',
            field: 'receiptUri',
            align:'right',
            render: (row) => (
                <>
                    <Tooltip arrow placement={"bottom"} title={row.receiptUri ? t("expense.hasReceipt") : t("expense.noReceipt")}>
                        <IconButton>
                            <ReceiptIcon className={!row.receiptUri ? classes.noReceipt : null}/>
                        </IconButton>
                    </Tooltip>
                    { row.status === 'submitted' &&
                        <Tooltip arrow placement={"bottom"} title={t("Pay")}>
                            <IconButton onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                history.push({
                                    pathname: "/balances",
                                    search: "?" + new URLSearchParams({
                                    expenseId: row.id,
                                    iban: row.merchant,
                                    amount: row.amount,
                                    }).toString()
                                })
                                }}
                            >
                                <AccountsIcon className={classes.noReceipt}/>
                            </IconButton>
                        </Tooltip>
                    }
                </>
            )
        },
    ];

    return (
        <>
            <VolveMaterialTable
                columns={columns}
                isLoading={isLoading}
                data={[
                    ...(activeRoleTab === 'personal' ? [{isExpenseQueueRow:true}] : []),
                    ...expenses
                ]}
                onRowClick={(e, rowData) => {
                    if(['LABEL​', 'INPUT'].includes(e.target.tagName)) return;

                    const wasClicked = checkClick();
                    if(wasClicked){
                        history.push({pathname: `/expenses/${rowData._id}`, state: {backPath: props.match.url}})
                    }
                }}
                components={{
                    Row: ExpenseRow,
                }}
            />
        </>
    );
};

export default withRouter(ExpenseTable);
