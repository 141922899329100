import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {
    AccountsPage, BranchesPage,
    CardsPage, CompanyPage,
    DashboardPage, GroupsPage,
    KYCPage,
    ReportsPage, RolesPage,
    TransactionsPage,
    TransactionsMakePaymentPage,
    UserPage,
    UsersPage,
    ExpensesPage,
    CompanyKybPage,
    ProjectsPage,
    CategoriesPage,
    TaxRatesPage,
    RejectReasonsPage,
    AdvancedSettingsPage,
    ExpenseReportTemplatesPage,
    DepartmentsPage,
    BalancesPage,
} from "../../pages";
import UserPermissionsPage from "../../pages/UserPermissionsPage";

const PrivateRoutes = (props) => {
    const {underBarRef, drawerOuterRef} = props;
    return (
        <Switch>
            <Route path={`/user-verification/:id`} component={(props) => <KYCPage {...props} underBarRef={underBarRef}/>}/>
            <Route exact path={`/dashboard`} component={(props) => <DashboardPage {...props} underBarRef={underBarRef} drawerOuterRef={drawerOuterRef}/>}/>
            <Route exact path={`/expenses`} component={() => <Redirect to={`/expenses/todo`}/>}/>
            <Route path={`/expenses`} component={(props) => <ExpensesPage {...props} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/>}/>
            <Route exact path={`/transactions`} component={(props) => <TransactionsPage {...props} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/>} />
            <Route exact path={`/transactions/make-payment`} component={(props) => <TransactionsMakePaymentPage {...props} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef} />}/>
            <Route exact path={`/cards`} component={() => <Redirect to={`/cards/physical`}/>}/>
            <Route path={`/cards`} component={(props) => <CardsPage {...props} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/>}/>
            <Route path={`/balances`} component={(props) => <BalancesPage {...props} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/>}/>
            <Route path={`/user`} component={(props) => <UserPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/accounts`} component={(props) => <AccountsPage {...props} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/>}/>
            <Route path={`/users`} component={(props) => <UsersPage {...props} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/>}/>
            <Route exact path={`/reports`} component={(props) => <ReportsPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/reports/templates`} component={(props) => <ExpenseReportTemplatesPage {...props} underBarRef={underBarRef}/>}/>
            <Route exact path={`/settings`} component={() => <Redirect to={`/settings/company`}/>}/>
            <Route path={`/settings/company/verify`} component={(props) => <CompanyKybPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/company`} component={(props) => <CompanyPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/projects`} component={(props) => <ProjectsPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/categories`} component={(props) => <CategoriesPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/groups`} component={(props) => <GroupsPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/roles`} component={(props) => <RolesPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/branches`} component={(props) => <BranchesPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/tax-rates`} component={(props) => <TaxRatesPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/departments`} component={(props) => <DepartmentsPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/reject-reasons`} component={(props) => <RejectReasonsPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/advanced`} component={(props) => <AdvancedSettingsPage {...props} underBarRef={underBarRef}/>}/>
            <Route path={`/settings/permissions`} component={(props) => <UserPermissionsPage {...props} underBarRef={underBarRef}/>}/>
            <Route component={() => <Redirect to={`/dashboard`}/>}/>
        </Switch>
    );
};

export default PrivateRoutes;
