import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import Api from "../../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Can} from "../../../../../../Context/Can";

const service = new Api();

const useStyles = makeStyles(theme => ({
    btn:{
        textTransform:"none",
        fontWeight:400,
        fontSize: "15px",
        lineHeight: "26px",
        paddingTop:0,
        paddingBottom:0,
        "&:hover":{
            backgroundColor:"inherit",
            textDecoration:"underline"
        }
    }
}));

const ResendInvitationEmail = (props) => {
	const classes = useStyles();
	const [isBtnDisabled, setIsBtnDisabled] = useState(false);
	const {t, i18n} = useTranslation();

	const {userId, handleErrorMessage, setMessage} = props;

	const resendEmail = async (e) => {
        e.stopPropagation();
        setIsBtnDisabled(true);
        try {
            const response = await service.resendInvitedUser(userId);
            setIsBtnDisabled(false);

            setMessage(t("emailWasSentTo", {email:response.data.user.email}), 'success');
        }catch (e) {
            setIsBtnDisabled(false);
            handleErrorMessage(e);
        }

	}

    return (
        <>
            <Can I="invite" a={"user"}>
                <Button
                    size="small"
                    color="primary"
                    className={classes.btn}
                    disabled={isBtnDisabled}
                    onClick={resendEmail}
                    disableTouchRipple
                >
                    {t("formFields.resendEmail")}
                </Button>
            </Can>
        </>
    );
};

export default connect(
    null,
    {handleErrorMessage, setMessage}
)(ResendInvitationEmail);
