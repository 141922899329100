import React, {useContext, useEffect, useState} from 'react';
import Portal from "@material-ui/core/Portal";

import useMounted from "../../../../../hooks/useMounted";
import {useTranslation} from "react-i18next";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../../../../services/api";
import Permissions from "./Permissions";
import Box from "@material-ui/core/Box";
import useForm2 from "../../../../../hooks/useForm2";
import Button from "@material-ui/core/Button";

import {updateConfigurablePermissions} from "../../../../User/actions";
import Skeleton from "react-loading-skeleton";
import {AbilityContext} from "../../../../../Context/Can";
import { makeStyles } from '@material-ui/core/styles';
const service = new Api();

const useStyles = makeStyles(theme => ({
    head: {
        display: 'flex',
        position: 'relative',
        minHeight: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid var(--very-light-pink-secondary)',
        marginBottom: '56px',
        paddingBottom: '32px',
        [theme.breakpoints.down('1023')]: {
            marginBottom: '33px',
            paddingBottom: '24px',
            flexWrap: 'wrap',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '16px'
        },
    },
    item: {
        "& + $head": {
            marginTop: '48px',
            [theme.breakpoints.down('1023')]: {
                marginTop: '24px'
            },
            [theme.breakpoints.down('767')]: {
                marginTop: '16px'
            }
        }
    },
    button: {
        marginTop: '24px'
    }
}))

const UserPermissionsForm = (props) => {
    const classes = useStyles();

    const {underBarRef} = props;
    const isMounted = useMounted();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const configurablePermissions = useSelector(state => {
        const {userData} = state.user;
        const {company={}} = userData;
        const {configurablePermissions = {}} = company;
        return configurablePermissions;
    });

    const ability = useContext(AbilityContext);
    const canUpdate = ability.can("update:permissions", "company");

    useEffect(() => {
        fetchData()
    },[]);


    const [isFetching, setIsFetching] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [roles, setRoles] = useState([]);


    const fetchData = async () => {
        try{
            setIsFetching(true);
            const rolesResponse = await service.getServiceData({dataFor:"roles"});
            const {roles} = rolesResponse.data;
            const mappedRoles = roles
                .filter((role) => !['personal', 'administrator'].includes(role.id)); // delete 'personal' and 'administrator' role
            setRoles(mappedRoles);

        }catch (e) {
            dispatch(handleErrorMessage(e));
        }finally {
            setIsFetching(false);
        }
    }


    useEffect(() => {

        const fillValues = Object.keys(configurablePermissions).reduce((accum, role) => {
            Object.keys(configurablePermissions[role]).forEach((permission) => {
                const fieldName = `configurablePermissions[${role}][${permission}]`;
                accum[fieldName] = configurablePermissions[role][permission];
            });
            return accum
        },{});

        fillFormValues(fillValues);

    },[configurablePermissions]);

    const submit = async () => {

        try{
            const data = new FormData();
            Object.keys(values).map((field) => {
                data.append(field, values[field]);
            });
            setIsSending(true);
            const response = await service.updateCompanyPermissions(data);
            const {configurablePermissions} = response.data;
            dispatch(updateConfigurablePermissions(configurablePermissions));
            dispatch(setMessage("success", 'success'));
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e));
        }finally {
            setIsSending(false);
        }
    }

    const validate = () => ({});


    const { handleChange, handleSubmit, values, fillFormValues } = useForm2(
        submit,
        validate
    );

    return (
        <>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <div className="page__title h4 text-weight-200">{t("userPermissions.mainTitle")}</div>
                </Portal>
            )}
            <div className="card">
                <form action="#" onSubmit={handleSubmit}>
                    {isFetching ? (
                        <Box paddingBottom={"20px"}>
                            <Skeleton height={"40px"} width={"100px"}/><br/>
                            <Skeleton height={"20px"} width={"400px"}/><br/>
                            <Skeleton height={"20px"} width={"400px"}/><br/>
                            <Skeleton height={"20px"} width={"400px"}/><br/>
                            <Skeleton height={"20px"} width={"400px"}/><br/>
                        </Box>
                    ) : (
                        roles.map((role) => {
                            return (
                                <React.Fragment key={role.id}>
                                    <div className={classes.head}>
                                        <div className="title-blue text-weight-300 margin-bottom-0">
                                            {role.name}
                                        </div>
                                    </div>
                                    <div className={classes.item}>
                                        <Permissions
                                            role={role.id}
                                            handleChange={handleChange}
                                            values={values}
                                            disabled={isSending || !canUpdate}
                                        />
                                    </div>
                                </React.Fragment>
                            )
                        })
                    )}
                    <Button
                        className={classes.button}
                        onClick={handleSubmit}
                        disabled={isFetching || isSending || !canUpdate}
                    >
                        {t("formFields.submit")}
                    </Button>
                </form>
            </div>
        </>
    );
};

export default UserPermissionsForm;
