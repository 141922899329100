import React from 'react'
import Users from "../userSection/content/Users";
import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";


const UsersPage = (props) => {
    const {drawerOuterRef, underBarRef}  = props;
    const {t} = useTranslation();
    return (
        <>
            <UserSectionWrapper>
                 <Can I="read" a="user" passThrough>
                    {can => (
                        can ? <Users drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/> : <div>{t("user.noPermissionToRead")}</div>
                    )}
                </Can>
            </UserSectionWrapper>
        </>
    )
}

export default UsersPage
