import React, {useState, useEffect, useCallback} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import ReceiptUploader from "./ReceiptUploader";
import Api from "../../services/api";
import {compose} from "redux";
import {handleErrorMessage} from "../Messages/actions";
import {connect} from "react-redux";

import SelectField from "../formElements/SelectField";
import DateField from "../formElements/DateField";
import {useTranslation} from "react-i18next";
import CheckBoxField from "../formElements/CheckBoxField";
import NoReceipt from "./NoReceipt";

import InputFieldMemo from "../formElements/InputFieldMemo";

import NumericInput from "../formElements/NumericInput";
import isNumeric from "../../helpers/isNumeric";
import {ReactComponent as Logo} from "../../assets/logoInvesthub.svg";
import VolveSnackbar from "../VolveSnackbar";
import CommentField from "./CommentField";
import CategoryField from "./CategoryField";
import ProjectField from "./ProjectField";
import ReportedAmount from "./ReportedAmount";
import ReportedExchangeRate from "./ReportedExchangeRate";
import EmployeeField from "./EmployeeField";

const service = new Api();

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
    },

    itemForm: {
        display: 'flex',
        alignItems: 'flex-start',
        flexFlow: 'row wrap',
        [theme.breakpoints.down('1023')]: {
            display: "block"
        }
    },

    itemFields: {
        flex: '1',
        paddingRight: '32px',
        [theme.breakpoints.down('1023')]: {
            paddingRight: '0',
            marginTop: '24px'
        }
    },

    itemImages: {
        width: '280px',
        [theme.breakpoints.down('1023')]: {
            width: '210px'
        }
    },

    itemFieldSettings: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },

    itemFieldThird: {
        flex: '0 0 calc(33.333% - 16px)',
        width: 'calc(33.333% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('1259')]: {
            flex: '0 0 calc(50% - 16px)',
            width: 'calc(50% - 16px)',
        },
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        }
    },

    itemFieldHalf: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        }
    },


    itemFieldFull: {
        flex: '0 0 calc(100% - 16px)',
        width: 'calc(100% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        },
        '& > .MuiFormControlLabel-root': {
            marginLeft: '0',
            marginRight: '0'
        }
    },

    fixFieldSpace: {
        // Remove space from label
        "& .MuiFormLabel-root": {
            marginBottom: '0',
            "&.Mui-focused": {
                color: 'var(--charcoal-primary)',
            }
        },

        // Remove space from field to label
        "& .MuiFormControl-root.MuiTextField-root": {
            marginTop: '0'
        },


    },

    fixFieldDate: {
        "& .MuiInput-root.Mui-disabled .MuiInputAdornment-root.MuiInputAdornment-positionEnd": {
            display: 'none'
        }
    },

    fixFieldTextarea: {
        "& textarea": {
            border: '2px solid transparent',
            padding: '12px 10px',
            borderRadius: '12px',
            background: 'var(--very-light-pink-primary)',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '1.6',
            color: 'var(--dark-secondary)',
            transition: 'all .2s',

            "&:not([disabled])": {
                border: '2px solid transparent',

                "&:hover": {
                    borderColor: 'transparent'
                }
            }
        },

        "& .Mui-focused": {
            "& textarea": {
                "&:not([disabled])": {
                    borderColor: 'var(--cool-grey) !important',
                    background: 'var(--white) !important'
                }
            }
        }
    }


}));

const ExpenseForm = (props) => {
    const {
        handleErrorMessage,
        dateFormat,
        handleChange,
        handleSubmit,
        values,
        errors,
        decimal,
        paidWith=[],
        taxRatesByCountry,
        setTaxRatesByCountry,
        checkDisabledField,
        currencies=[],
        countries=[],
        isLoading,
        formMessage,
        advancedSettings,
        onDateChange,
        onCurrencyChange,
        rateIsLoading,
        employee
    } = props;

    const {expenseProjectShow} = advancedSettings;

    const {t} = useTranslation();

    const [taxRates, setTaxRates] = useState([]);
    const [isTaxRateLoading, setIsTaxRateLoading] = useState(false);

    const [isTaxDisabled, setIsTaxDisabled] = useState(false);
    const [isTaxAmountDisabled, setIsTaxAmountDisabled] = useState(false);


    const classes = useStyles();

    const calculateTax = (amount, value) => {
        const intAmount = parseFloat(amount);
        const intTax = parseFloat(value);
        let result; // undefined

        if(isNumeric(intAmount) && isNumeric(intTax)){
            result = (intAmount/(intAmount-intTax)-1)*100;
            result = result.toFixed(1);
            result = parseFloat(result);
        }

        return result;
    };

    const calculateAmountTax = (amount, tax) => {

        let result; // undefined

        const intAmount = parseFloat(amount);
        const intTax = parseFloat(tax);

        if(isNumeric(intAmount) && isNumeric(intTax)){
            result = intAmount - intAmount/(1 + intTax/100);
            result = result.toFixed(2);
            result = parseFloat(result);
        }
        return result;
    };


    const onTaxChange = (name, value) => {
        const newAmountTax = calculateAmountTax(values["amount"], value);
        handleChange("tax", value);
        handleChange("taxAmount", newAmountTax);
    }

    const onTaxAmountChange = (value, amount) => {
        const newTax = calculateTax(amount, value);
        handleChange("taxAmount", value);

        handleChange("tax", newTax);
    }

    const {amount, status, country, category, currency, tax, taxAmount, receiptUri, noReceiptReason} = values;

    useEffect(() => {
        if(receiptUri && noReceiptReason){
            handleChange('noReceiptReason', ); // remove noReceiptReason
        }
    },[receiptUri, noReceiptReason]);

    useEffect(() => {
        if(values["country"] && (values["taxRate"] && values["taxRate"] !== "other")){
            setIsTaxAmountDisabled(true);
            setIsTaxDisabled(true);
        }else{
            setIsTaxAmountDisabled(false);
            setIsTaxDisabled(false);
        }

    },[values["taxRate"], values["country"]]);

    useEffect(() => {
        let taxRates = [];

        if(country){
            if(category){
                // filtered with `category` and `country` field
                taxRates = taxRatesByCountry.filter((taxRate) => {
                    const {categories} = taxRate;
                    return (categories.length === 0) || categories.includes(category)
                });
                setTaxRates(taxRates);

            }else{
                // filtered with `country` field
                taxRates = taxRatesByCountry;
            }
        }

        setTaxRates(taxRates);

    },[category, country, taxRatesByCountry]);


    const selectTaxRateByCategory = (taxRatesByCountry, category) => {

        if(taxRatesByCountry.length){

            // if category is selected - try to find taxRate with this category
            const taxRateByCategory = category ? taxRatesByCountry.find(({categories}) => categories.includes(category)) : undefined;

            // if no taxRateByCategory - try to find first taxRate without any category
            const foundTax = taxRateByCategory ? taxRateByCategory : taxRatesByCountry.find(({categories}) => categories.length === 0);

            if(foundTax){
                handleChange("taxRate", foundTax.value);
                onTaxChange("tax", foundTax.taxRate);
            }else{
                if(values["taxRate"]){
                    handleChange("taxRate", "other");
                }
            }
        }else{
            if(values["taxRate"]){
                handleChange("taxRate", "other");
            }
        }
    }


    const onCategoryChange = (val) => {

        selectTaxRateByCategory(taxRatesByCountry, val);

        handleChange("category", val)
    }


    const onCountryChange = async (value) => {
        try {
            setIsTaxRateLoading(true);
            handleChange("country", value);

            if(value){
                const response = await service.getTaxRates({countries: value, "no-limit": true});
                const mappedTaxRates = response.data.result.map(({name, _id,  taxRate, categories}) => ({label:name, value:_id, taxRate, categories}));
                selectTaxRateByCategory(mappedTaxRates, values["category"]);
                setTaxRatesByCountry(mappedTaxRates);
            }else{
                handleChange("tax", "");
                handleChange("taxAmount", "");
                handleChange("taxRate", "");
                setTaxRatesByCountry([]);
            }

        } catch (e) {
            console.log(e);
            handleErrorMessage(e);
        } finally {
            setIsTaxRateLoading(false);
        }
    }


    const onTaxRateChange = (val) => {
        handleChange("taxRate", val);
        if(val && val !== "other"){
            const foundTaxRate = taxRatesByCountry.find((taxRate) => taxRate.value === val);
            if(foundTaxRate && isNumeric(foundTaxRate.taxRate)){
                onTaxChange("tax", foundTaxRate.taxRate);
            }
        }
    }


    const onAmountChange = (name, value) => {
        handleChange("amount", value);
        const newAmountTax = calculateAmountTax(value, values["tax"]);
        handleChange("taxAmount", newAmountTax);
    }

    const memoizedCallback = useCallback(handleChange , []);
    const memoizedTaxChange = useCallback(onTaxChange, [amount]);
    const memoizedAmountChange = useCallback(onAmountChange, [tax, taxAmount]);

    return (
        <div className={classes.item}>
            <form onSubmit={handleSubmit} encType="multipart/form-data" noValidate className={classes.itemForm}>
                <div className={classes.itemFields}>
                    {formMessage && (
                        <VolveSnackbar message={<>{formMessage}</>} icon={<Logo/>}/>
                    )}

                    <div className={classes.itemFieldSettings}>
                        <FormControl fullWidth className={`${classes.itemFieldHalf} ${classes.fixFieldSpace} ${classes.fixFieldDate}`} error={!!errors.date}>
                            <DateField
                                label={t("formFields.date")}
                                disableFuture
                                placeholder={t("formFields.date")}
                                disabled={checkDisabledField("date")}
                                dateFormat={dateFormat}
                                onDateChange={(date) => onDateChange(date)}
                                value={values["date"]}
                                error={errors["date"]}
                                isLoading={isLoading}
                                name="date"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`} error={!!errors.merchant}>
                            <InputFieldMemo
                                name={"merchant"}
                                label={t("formFields.merchant")}
                                placeholder={t("formFields.merchant")}
                                value={values["merchant"]}
                                error={errors["merchant"]}
                                disabled={checkDisabledField("merchant")}
                                onInputChange={memoizedCallback}
                                isLoading={isLoading}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.amount} className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`}>
                            <NumericInput
                                decimal={decimal}
                                name={"amount"}
                                maskProps={{signed: checkDisabledField("amount"),}}
                                label={t("formFields.amount")}
                                placeholder={t("formFields.amount")}
                                value={values["amount"]}
                                error={errors["amount"]}
                                disabled={checkDisabledField("amount")}
                                onInputChange={memoizedAmountChange}
                                isLoading={isLoading}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors.currency} className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`}>
                            <SelectField
                                label={t("formFields.currency")}
                                name={"currency"}
                                value={values["currency"]}
                                error={errors.currency}
                                options={currencies}
                                disabled={checkDisabledField("currency")}
                                onSelectChange={(val) =>  onCurrencyChange(val)}
                                isLoading={isLoading}
                            />
                        </FormControl>

                        <FormControl fullWidth className={`${classes.itemFieldHalf} ${classes.fixFieldSpace} ${classes.exchangeRateWrapper}`}>
                            <ReportedExchangeRate
                                isLoading={(isLoading || rateIsLoading)}
                                decimal={decimal}
                                value={values["reportedExchangeRate"]}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldHalf}>
                            <ReportedAmount
                                reportedExchangeRate={values["reportedExchangeRate"]}
                                reportedCurrency={values["reportedCurrency"]}
                                amount={values["amount"]}
                                isLoading={isLoading || rateIsLoading}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["category"]} className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`}>
                            <CategoryField
                                value={values["category"]}
                                error={errors["category"]}
                                disabled={checkDisabledField("category")}
                                onSelectChange={(val) => onCategoryChange(val)}
                                isLoading={isLoading}
                            />
                        </FormControl>

                        <FormControl fullWidth error={!!errors["paidWith"]} className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`}>
                            <SelectField
                                label={t("formFields.paidWith")}
                                name={"paidWith"}
                                value={values["paidWith"]}
                                error={errors["paidWith"]}
                                options={paidWith}
                                disabled={checkDisabledField("paidWith")}
                                onSelectChange={(val) => handleChange("paidWith", val)}
                                isLoading={isLoading}
                            />
                        </FormControl>

                        {values["transactionStatus"] && (
                            <FormControl fullWidth className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`} error={!!errors.transactionStatus}>
                                <InputFieldMemo
                                    name={"transactionStatus"}
                                    label={t("formFields.transactionStatus")}
                                    placeholder={t("formFields.transactionStatus")}
                                    value={values["transactionStatus"] && t(`expenseTransactionStatus.${values["transactionStatus"]}`)}
                                    error={errors["transactionStatus"]}
                                    disabled={true}
                                    onInputChange={memoizedCallback}
                                    isLoading={isLoading}
                                />
                            </FormControl>
                        )}

                        {expenseProjectShow && (
                            <FormControl fullWidth error={!!errors["project"]} className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`}>
                                <ProjectField
                                    value={values["project"]}
                                    error={errors["project"]}
                                    disabled={checkDisabledField("project")}
                                    onSelectChange={(val) => handleChange("project", val)}
                                    isLoading={isLoading}
                                />
                            </FormControl>
                        )}

                        {employee && (
                            <FormControl fullWidth className={classes.itemFieldHalf}>
                                <EmployeeField employee={employee}/>
                            </FormControl>
                        )}

                        <FormControl fullWidth error={!!errors["country"]} className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`}>
                            <SelectField
                                label={t("formFields.country")}
                                name={"country"}
                                isLoading={isLoading}
                                value={values["country"]}
                                error={errors["country"]}
                                options={countries}
                                disabled={checkDisabledField("country")}
                                onSelectChange={(val) => onCountryChange(val)}
                            />
                        </FormControl>

                        <FormControl fullWidth className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`}>
                            <SelectField
                                label={t("formFields.taxRate")}
                                name={"taxRate"}
                                value={values["taxRate"]}
                                error={errors["taxRate"]}
                                options={[{value:"other", label:t("expense.other")}, ...taxRates]}
                                disabled={checkDisabledField("taxRate")}
                                onSelectChange={(val) => onTaxRateChange(val)}
                                isLoading={isLoading || isTaxRateLoading}
                            />
                        </FormControl>

                        <FormControl fullWidth className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`}>
                            <NumericInput
                                decimal={decimal}
                                name={"tax"}
                                label={t("formFields.tax")}
                                placeholder={t("formFields.tax")}
                                value={values["tax"]}
                                error={errors["tax"]}
                                maskProps={{scale:1}}
                                disabled={checkDisabledField("tax") || isTaxDisabled}
                                onInputChange={memoizedTaxChange}
                                isLoading={isLoading || isTaxRateLoading}
                            />
                        </FormControl>

                        <FormControl fullWidth className={`${classes.itemFieldHalf} ${classes.fixFieldSpace}`}>
                            <NumericInput
                                decimal={decimal}
                                name={"taxAmount"}
                                label={t("formFields.taxAmount")}
                                placeholder={t("formFields.taxAmount")}
                                value={values["taxAmount"]}
                                error={errors["taxAmount"]}
                                disabled={checkDisabledField("taxAmount") || isTaxAmountDisabled}
                                onInputChange={(name, value) => onTaxAmountChange(value, values["amount"])}
                                isLoading={isLoading || isTaxRateLoading}
                            />
                        </FormControl>

                        <FormControl fullWidth className={`${classes.itemFieldFull} ${classes.fixFieldSpace} ${classes.fixFieldTextarea}`}>
                            <CommentField
                                placeholder={!checkDisabledField("comment") ? t("formFields.addComment") : null}
                                value={values["comment"]}
                                error={errors["comment"]}
                                disabled={checkDisabledField("comment")}
                                onInputChange={memoizedCallback}
                                isLoading={isLoading}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldFull}>
                            <CheckBoxField
                                onCheckboxChange={(val) => handleChange("reimbursable", !!val)}
                                value={values["reimbursable"]}
                                label={t("formFields.reimbursable")}
                                name={"reimbursable"}
                                error={errors["reimbursable"]}
                                disabled={checkDisabledField("reimbursable")}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={classes.itemImages}>
                    <ReceiptUploader
                        handleErrorMessage={handleErrorMessage}
                        value={values.receiptUri}
                        disabled={checkDisabledField("receiptUri")}
                        handleChange={handleChange}
                        isLoading={isLoading}
                    />
                    {!values["receiptUri"] &&
                        <NoReceipt
                            value={values["noReceiptReason"]}
                            disabled={checkDisabledField("noReceiptReason") || isLoading}
                            handleChange={handleChange}
                        />
                    }
                </div>
            </form>
        </div>
    );
};

export default compose(
	connect( null, {handleErrorMessage})
)(ExpenseForm);
