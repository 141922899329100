import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const ExpenseStatus = (props) => {
    const {status} = props;

    let statusClass = null;
    switch (status) {
        case "approved" : statusClass = "status-green-dark"; break;
        case "submitted" : statusClass = "status-green"; break;
        case "rejected" : statusClass = "status-red"; break;
        case "unapproved" : statusClass = "status-red-dark"; break;
        case "recalled" : statusClass = "status-red-dark"; break;

        default:  statusClass = "status-default";
    }

    const useStyles = makeStyles(theme => ({
        fontSize :{
            fontSize: "14px"
        }

    }));

    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <>
            <span className={`text-weight-600 status ${statusClass} ${classes.fontSize}`}>{t(`expense.${status}`)}</span>
        </>
    );
};

export default ExpenseStatus;
