import React from 'react';
import ExpensesView from "./ExpensesView";
import {withRouter, Switch, Route, matchPath} from "react-router-dom";
import Expense from "./Expense";
import AddExpense from "./Expense/AddExpense";
import {compose} from "redux";
import BatchEdit from "./Expense/BatchEdit";
import BatchAdd from "./Expense/BatchAdd";
import {useTranslation} from "react-i18next";
import SelectTab from "../../../SelectTab";
import {Can} from "../../../../Context/Can";
import ExpenseQueuesProvider from "../../../ExpenseQueuesProvider";
import ExpensesProvider from "../../../ExpensesProvider";
import UnderCardDockInvesthub from '../../../UnderCardDockInvesthub/UnderCardDockInvesthub';

const Expenses = (props) => {
    const {match, location, drawerOuterRef, underBarRef} = props;
    const {t} = useTranslation();


    const matchedPath = matchPath(location.pathname, {
        path: "/expenses/:tab",
        exact: true,
        strict: false
    });

    const currentTab = matchedPath ? matchedPath.params.tab : "";

    const types = [
        {id:"todo", name: t("expense.toDoTab")},
        {id:"all", name:  t("expense.allTab")},
    ];


    return (
        <>
            <Switch>
                <Route
                    exact
                    path={[`${match.path}/todo`, `${match.path}/all`]}
                    render={(props) => {
                        const onSelectChange = (val) => {
                            props.history.push(`${match.url}/${val}`)
                        };
                        return (
                            <>
                                <ExpenseQueuesProvider>
                                    <ExpensesProvider>
                                        <ExpensesView type={currentTab} drawerOuterRef={drawerOuterRef} underBarRef={underBarRef}/>
                                    </ExpensesProvider>
                                </ExpenseQueuesProvider>

                                {/* NEED TRANSLATION */}
                                <UnderCardDockInvesthub
                                    titleDock={t('Page Inner')}
                                >
                                    <SelectTab
                                        value={currentTab}
                                        options={types.map(({id, name}) => ({value:id, label:name}))}
                                        onSelectChange={(val) => onSelectChange(val)}
                                    />
                                </UnderCardDockInvesthub>
                            </>
                        )
                    }}
                />

				<Route path={`${match.path}/add`} render={() => {
					return (
							<Can I="create" a={"expense"} passThrough>
								{can => (
									can ? <AddExpense history={props.history} underBarRef={underBarRef}/> : <div>{t("expense.noPermissionToAdd")}</div>
								)}
							</Can>
					)
				}}/>
				<Route path={`${match.path}/batchEdit`} render={() => {
					return (
							<Can I="update" a={"expense"} passThrough>
								{can => (
									can ? <BatchEdit underBarRef={underBarRef}/> : <div>{t("expense.noPermissionToUpdate")}</div>
								)}
							</Can>
					)
				}}/>
				<Route path={`${match.path}/batchAdd`} render={() => {
					return (
							<Can I="create" a={"expense"} passThrough>
								{can => (
									can ? <BatchAdd underBarRef={underBarRef}/> : <div>{t("expense.noPermissionToCreate")}</div>
								)}
							</Can>
					)
				}}/>

				<Route path={`${match.path}/:id`} render={() => {
					return <Expense underBarRef={underBarRef}/>
				}}/>
			</Switch>
		</>
	);
};

export default compose(
	withRouter,
)(Expenses)
