import React from 'react'
import {makeStyles} from "@material-ui/core";
import Item from './Item';

const useStyles = makeStyles(theme => ({
    list: {
        margin: '0 -12px',
    },
}))

const Items = (props) => {
    const classes = useStyles();
    const {notifications, deleteMessage, isDisabled, onClose, setNotifications, setIsDisabled} = props;

    return (
        <div className={classes.list}>
            {notifications.length > 0 ? (
                notifications.map((notification, idx) => {
                    const {_id, type, translateKey, payload, textProperty, createdAt, status} = notification;

                    return <Item
                                key={_id}
                                notification={notification}
                                deleteMessage={deleteMessage}
                                isDisabled={isDisabled}
                                onClose={onClose}
                            />
                })
            ) : (
                <Item notification={{translateKey:"noNotifications"}} />
            )}
        </div>
    )
}

export default Items
