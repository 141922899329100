import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import FilterButton from "../../../../FilterButton";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    search:{
        marginLeft:"auto",
        display:"flex",
    },
});

const TopComponent = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const {onFilterOpen, isFilterOpen} = props;
    return (
        <>
            <Grid item>
                <Typography variant="h1">{t("dashboard.mainTitle")}</Typography>
            </Grid>
            <Grid item className={classes.search}>
                {!isFilterOpen && <Grid item><FilterButton onClick={onFilterOpen}/></Grid>}
            </Grid>
        </>
    );
};

export default TopComponent;
