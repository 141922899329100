import React from 'react'
import Company from "../userSection/content/Company";
import {useTranslation} from "react-i18next";
import UserSectionWrapper from "../UserSectionWrapper";
import {Can} from "../../Context/Can";


const CompanyPage = (props) => {
    const {underBarRef} = props;
    const {t} = useTranslation();
    return (
        <>
            <UserSectionWrapper>
                <Can I="read" a="company" passThrough>
                    {(can) => (
                        can ? <Company underBarRef={underBarRef}/> : <div>{t("company.noPermissionToRead")}</div>
                    )}
                </Can>
            </UserSectionWrapper>
        </>
    )
}

export default CompanyPage
