import React, {useEffect, useState} from 'react'
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";

import {makeStyles} from "@material-ui/core";
import {connect} from "react-redux";
import Api from "../../../../../services/api";
import {handleErrorMessage, setMessage} from "../../../../Messages/actions";
import {updateUserState} from "../../../../User/actions";

import {useTranslation} from "react-i18next";
import SelectField from "../../../../formElements/SelectField";
import useForm from "../../../../../hooks/useForm";
import {Prompt} from "react-router-dom";
import useBeforeUnload from "../../../../../hooks/useBeforeUnload";


const service = new Api();
const {updateCurrentUser} = service;

const useStyles = makeStyles(theme => ({
    item: {
        position: 'relative',
        '&:not(:last-child)': {
            marginBottom: '32px',
            borderBottom: '1px solid var(--very-light-pink-secondary)',

            [theme.breakpoints.down('1023')]: {
                marginBottom: '24px'
            }
        }
    },
    itemSetting: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemFieldHalf: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0'
        }
    },
}));

const SettingsForm = (props) => {
	const {handleErrorMessage, setMessage, settings, updateUserState} = props;
	const classes = useStyles();
	const [isDisabled, setIsDisabled] = useState(false);
	const {t} = useTranslation();
    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();

	const [isLoading, setIsLoading] = useState(false);

	const [currencies, setCurrencies] = useState([]);
	const [decimals, setDecimals] = useState([]);
	const [dateFormats, setDateFormats] = useState([]);
	const [distances, setDistances] = useState([]);


	useEffect(() => {
		if(settings.regional){
			Object.keys(settings.regional).forEach((key) => {
				handleChange(key, settings.regional[key]);
			})
		}
    }, [settings]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {

                const response = await Promise.all([
                    service.getServiceData({dataFor: "currencies-all"}),
                    service.getServiceData({dataFor: "decimals"}),
                    service.getServiceData({dataFor: "dateformat"}),
                    service.getServiceData({dataFor: "distance"}),
                ]);

                const [currenciesResult, decimalsResult, dateFormatsResult, distancesResult] = response;
                const mappedCurrencies = currenciesResult.data.map(({value}) => ({label:value, value}));
                const mappedDecimals = decimalsResult.data.map(({value, name}) => ({label:name, value}));
                const mappedDateFormats = dateFormatsResult.data.map(({value, name}) => ({label:name, value}));
                const mappedDistances = distancesResult.data.map(({value, name}) => ({label:name, value}));

                setCurrencies(mappedCurrencies);
                setDecimals(mappedDecimals);
                setDateFormats(mappedDateFormats);
                setDistances(mappedDistances);

            }catch (e) {

                handleErrorMessage(e);
            }finally {
                setIsLoading(false);
            }

        })();
    },[]);


    const submit = async () => {
        setIsDisabled(true);
        const {currency, dateFormat, decimal, distance} = values;

        try {

            const dataValues = {settings:{regional:{currency, dateFormat, decimal, distance}}}

            const result = await updateCurrentUser(dataValues);
            const {data} = result;

            updateUserState(data);
            setValuesWasChanged(false);
            setMessage("successfullyUpdated", 'success');
            setIsDisabled(false);
        }catch (e) {
            setServerErrors(e);
            setIsDisabled(false);
        }
    }

    const validate = (values) => {
        const errors = {};
        if (!values["decimal"]) {
            errors["decimal"] = t('errors.required', {field: t("formFields.decimal")});
        }
        if (!values["dateFormat"]) {
            errors["dateFormat"] = t('errors.required', {field: t("formFields.dateFormat")});
        }
        if (!values["distance"]) {
            errors["distance"] = t('errors.required', {field: t("formFields.distanceUnit")});
        }
        return errors;
    };

    const { handleChange, handleSubmit, values, errors, setServerErrors } = useForm(
        submit,
        validate,
    );


    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true)
        }
        handleChange(name, value)
    }


	return (
        <>
            <div className={classes.item}>
                <form onSubmit={handleSubmit}>
                    <Prompt
                        when={valuesWasChanged}
                        message={t("unsavedChanges")}
                    />
                    <div className={classes.itemSetting}>
                        <FormControl fullWidth className={classes.itemFieldHalf} error={!!errors["decimal"]}>
                            <SelectField
                                label={t("formFields.decimal")}
                                name={"decimal"}
                                value={values['decimal']}
                                options={decimals}
                                onSelectChange={(val) => handleChangeWithDetectChanges("decimal", val)}
                                isLoading={isLoading}
                                error={errors["decimal"]}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldHalf} error={!!errors["dateFormat"]}>
                            <SelectField
                                label={t("formFields.dateFormat")}
                                name={"dateFormat"}
                                value={values['dateFormat']}
                                options={dateFormats}
                                onSelectChange={(val) => handleChangeWithDetectChanges("dateFormat", val)}
                                isLoading={isLoading}
                                error={errors["dateFormat"]}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldHalf} error={!!errors["currency"]}>
                            <SelectField
                                label={t("formFields.defaultCurrency")}
                                name={"currency"}
                                value={values['currency']}
                                options={currencies}
                                onSelectChange={(val) => handleChangeWithDetectChanges("currency", val)}
                                isLoading={isLoading}
                                error={errors["currency"]}
                            />
                        </FormControl>

                        <FormControl fullWidth className={classes.itemFieldHalf}  error={!!errors["distance"]}>
                            <SelectField
                                label={t("formFields.distanceUnit")}
                                name={"distance"}
                                value={values['distance']}
                                options={distances}
                                onSelectChange={(val) => handleChangeWithDetectChanges("distance", val)}
                                isLoading={isLoading}
                                error={errors["distance"]}
                                hideEmpty
                            />
                        </FormControl>
                    </div>

                    <Button
                        type="submit"
                        disabled={isDisabled}
                    >
                        {t("formFields.save")}
                    </Button>
                </form>
            </div>
        </>
	)
}

const mapStateToProps = ({user}) => {
	return {
		settings:user.settings
	}
}

export default connect(
	mapStateToProps,
	{handleErrorMessage, setMessage, updateUserState}
)(SettingsForm)

