import React from 'react';
import Menu from "@material-ui/core/Menu";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    menu: {
        '& ul': {
            padding: 0,
            width: "300px",
            margin: "15px 0"
        },
    },
}));


export default React.forwardRef((props, ref) => {

    const classes = useStyles();
    const {children} = props;

    return (
        <Menu
            className={classes.menu}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        >
            {children}
        </Menu>
    )
})
