import React from 'react';
import Grid from "@material-ui/core/Grid";
import WarningMessage from "../../../../WarningMessage";
import {Link, withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import {ReactComponent as Logo} from '../../../../../assets/iconEvolveWhite.svg'
import VolveSnackbar from "../../../../VolveSnackbar";

const useStyles = makeStyles(theme => ({
    link:{
        color: "#24DAC4",
        fontSize: "15px",
        lineHeight: "26px",
        textDecoration:"none",
        "&:hover":{
            textDecoration:"none",
            color: "#1EB3A1",
            background: "none"
        }
    },

}));


const KYBInfo = ({user}) => {
    const classes = useStyles();


    const {userData={}} = user;
    const {company={}} = userData;
    const {t} = useTranslation();

    return (
        !company.kyb ? (
                <VolveSnackbar
                    message={<>{t("verifyKyb")}</>}
                    action={<><Link to={`/settings/company/verify`} className={classes.link}>{t("formFields.verify")}</Link></>}
                    icon={<Logo width={"27px"} height={"27px"}/>}
                />
        ) : null
    );
};

export default KYBInfo;
