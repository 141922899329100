import React, {useEffect, useRef, useState} from 'react';
import ExpenseForm from "../../../../../ExpenseForm";
import {compose} from "redux";
import {connect} from "react-redux";
import Api from "../../../../../../services/api";
import Button from "@material-ui/core/Button";
import {handleErrorMessage, setMessage} from "../../../../../Messages/actions";
import {useTranslation} from "react-i18next";
import BackButton from "../../../../../BackButton";
import useBeforeUnload from "../../../../../../hooks/useBeforeUnload";
import {Prompt} from "react-router-dom";
import useForm2 from "../../../../../../hooks/useForm2";
import Portal from "@material-ui/core/Portal";
import Grid from "@material-ui/core/Grid";
import ThinButton from "../../../../../ThinButton";
import useMounted from "../../../../../../hooks/useMounted";
import useExpenseValidation from "../../../../../../hooks/useExpenseValidation";
import moment from "moment-timezone";
import {Box} from "@material-ui/core";
const service = new Api();


const AddExpense = (props) => {
    const {setMessage, regional, history, userId, branch, underBarRef, handleErrorMessage, company} = props;
    const {advancedSettings} = company;

    const validate = useExpenseValidation(advancedSettings);

    const {dateFormat, decimal} = regional;
    const userSettingsCurrency = regional.currency;

    const companyCurrency = company.currency;
    const branchCurrency = branch && branch.currency ? branch.currency : null;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rateIsLoading, setRateIsLoading] = useState(false);

    const [valuesWasChanged, setValuesWasChanged] = useBeforeUnload();
    const isMounted = useMounted();

    const {t} = useTranslation();



    const submit = async () => {

        setIsSubmitting(true);
        const formValues = new FormData();
        Object.keys(values).forEach((field) => {
            formValues.append(field, values[field] !== undefined ? values[field] : "");
        });

        try {
            await service.createExpense(formValues);
            setIsSubmitting(false);
            setValuesWasChanged(false);
            history.push("/expenses");
            setMessage("success", 'success');
        }catch (e) {
            setServerErrors(e);
            setIsSubmitting(false);
        }
    }

    const [currencies, setCurrencies] = useState([]);
    const [countries, setCountries] = useState([]);

    const [paidWith, setPaidWith] = useState([]);
    const [taxRatesByCountry, setTaxRatesByCountry] = useState([]);


    useEffect(() => {
        setIsLoading(true);

        const promises = [
            service.getServiceData({dataFor:"currencies-all"}),
            service.getServiceData({dataFor:"paidWith"}),
            service.getServiceData({dataFor:"countries"}),
        ];

        Promise.all(promises).then((response) => {
            const [currencies, paidWith, countriesResult] = response;

            const mappedCurrencies = currencies.data.map(({value}) => ({label:value, value}));
            setCurrencies(mappedCurrencies);

            const mappedPaidWith = paidWith.data.map(({value, name}) => ({label:name, value:value}));

            setPaidWith(mappedPaidWith);

            const mappedCountries = countriesResult.data.map((country) => {
                return {
                    label: country.name,
                    value: country["country-code"]
                }
            });

            setCountries(mappedCountries);


            setIsLoading(false);
        }).catch((e) => {
            setIsLoading(false);
            handleErrorMessage(e);
        });

    },[]);


    const { handleChange, handleSubmit, values, errors, setServerErrors} = useForm2(
        submit,
        validate,
    );

    const {reportedCurrency, currency, date} = values;


    const getReportedExchangeRate = async (fromCurrency, toCurrency, date) => {

        const theSameCurrency = fromCurrency === toCurrency;

        const doNotCalculate = theSameCurrency || !fromCurrency || !toCurrency;

        if(doNotCalculate){
            handleChange("reportedExchangeRate", 1);
            return;
        }

        try {
            setRateIsLoading(true);
            const response = await service.getExchangeRate(fromCurrency, toCurrency, 1, date);
            handleChange("reportedExchangeRate", response.data.result);
        }catch (e) {
            handleErrorMessage(e);
        }finally {
            setRateIsLoading(false);
        }
    };

    useEffect(() => {
        // do not use fillFormValues() because some data fills in deeper component
        handleChange('paidWith', "cash");

        const reportedCurrency = branchCurrency ? branchCurrency : companyCurrency;

        if(userSettingsCurrency){
            handleChange('currency', userSettingsCurrency);
        }else{
            handleChange('currency', reportedCurrency);
        }

        handleChange('reportedCurrency', reportedCurrency);

    },[]);

    useEffect(() => {
        getReportedExchangeRate(currency, reportedCurrency, date)
    },[reportedCurrency, currency, date]);

    useEffect(() => {
        const {expenseReimbursableActive} = advancedSettings;
        handleChange('reimbursable', expenseReimbursableActive);
    },[advancedSettings]);

    const handleChangeWithDetectChanges = (name, value) => {
        if(values[name] !== value){
            // if prev and current values are different
            if(!valuesWasChanged) setValuesWasChanged(true);
        }
        handleChange(name, value)
    }


    const checkDisabledField = (name) => {
        const readOnlyFields = ["paidWith"];
        return readOnlyFields.includes(name);
    }


    const onSubmit = (e, status) => {
        handleChange("status", status);
        handleSubmit(e);
    }

    const onDateChange = (date) => {
        handleChangeWithDetectChanges("date", date ? moment(date).startOf('day').format() : null);
    }
    const onCurrencyChange = (currency) => {
        handleChangeWithDetectChanges("currency", currency);
    }

    return (
        <Box paddingLeft={'24px'} paddingRight={'24px'}>
            {isMounted && (
                <Portal container={underBarRef.current}>
                    <BackButton/>
                </Portal>
            )}
            <Prompt
                when={valuesWasChanged}
                message={t("unsavedChanges")}
            />
            <ExpenseForm
                isLoading={isLoading}
                dateFormat={dateFormat}
                values={values}
                handleChange={handleChangeWithDetectChanges}
                errors={errors}
                handleSubmit={handleSubmit}
                decimal={decimal}
                advancedSettings={advancedSettings}
                paidWith={paidWith}
                currencies={currencies}
                checkDisabledField={checkDisabledField}
                countries={countries}
                taxRatesByCountry={taxRatesByCountry}
                setTaxRatesByCountry={setTaxRatesByCountry}
                history={history}
                onDateChange={onDateChange}
                onCurrencyChange={onCurrencyChange}
                rateIsLoading={rateIsLoading}
            />
            <Grid container spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={(e) => onSubmit(e, "submitted")}
                    >
                        {t("formFields.saveAndSubmit")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={(e) => onSubmit(e, "toSubmit")}
                    >
                        {t("formFields.save")}
                    </Button>
                </Grid>
                <Grid item>
                    <ThinButton onClick={() => history.push(`/expenses`)}>{t("formFields.cancel")}</ThinButton>
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => {
    const {user} = state;
    const {settings, userData} = user;
    const {regional} = settings;
    const {_id, branch, company} = userData;

    return {
        regional,
        userId: _id,
        branch,
        company,
    }
};
export default compose(
    connect(mapStateToProps, {handleErrorMessage, setMessage}),
)(AddExpense);
