import React, {useEffect, useState} from 'react';

import Select, { components } from 'react-select';
import {
    SortableContainer,
    SortableElement,
    sortableHandle,
} from 'react-sortable-hoc';

import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import Api from "../../../services/api";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import {ReactComponent as Move} from '../../../assets/move.svg'
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {useDispatch} from "react-redux";
import {handleErrorMessage} from "../../Messages/actions";
import Skeleton from "react-loading-skeleton";
const service = new Api();

const useMultiValueLabelStyles = makeStyles({
    multiValueLabel:{
        width:"100%",
    },
    label:{
        marginBottom:"12px"
    },
    moveIcon:{
        fill: "#3d4259"
    },
    listItemIcon:{
        minWidth:"35px"
    }
});


function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
}


const SortableMultiValue = SortableElement(props => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
    };


    const innerProps = { ...props.innerProps, onMouseDown, style:{width:"100%"}};

    return <components.MultiValue {...props} innerProps={innerProps}/>;
});



const SortableMultiValueLabel = sortableHandle(props => {
    const classes = useMultiValueLabelStyles();

    const {innerProps, children, ...restProps} = props;

    const modifiedInnerProps = {...innerProps, className:[innerProps.className, classes.multiValueLabel].join(" ")};

    return <components.MultiValueLabel {...restProps} innerProps={modifiedInnerProps} children={<ListItem component={"div"} disableGutters><ListItemIcon className={classes.listItemIcon}><Move width={"20px"} height={"20px"}/></ListItemIcon>{children}</ListItem>}/>
});

const SortableSelect = SortableContainer((props) => {
    return <Select {...props}/>
});

export default function MultiSelectSort(props) {
    const {handleChange, value, error, label} = props;
    const {t} = useTranslation();
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(value ? value.map((item) => ({value: item, label: t(`formFields.${item}`)})) : []);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useMultiValueLabelStyles();

    const getFields =  async () => {
        try{
            setIsLoading(true);
            const response = await service.getServiceData({dataFor:"reportedFieldsFull"});
            const mappedFields = response.data.fields.map((item) => ( { value: item, label: t(`formFields.${item}`) }));
            setOptions(mappedFields);
        }catch (e) {
            console.log(e);
            dispatch(handleErrorMessage(e))
        }finally {
            setIsLoading(false);
        }

    }


    useEffect(() => {
        getFields()
    },[])

    useEffect(() => {
        if(value && options.length){
            setSelected(() => {
                return value.map((field) => options.find((option) => option.value === field))
            })
        }

    },[value, options])


    useEffect(() => {
        const newValues = selected.map((item) => item.value);
        const oldValuesStringify = JSON.stringify(value);
        const newValuesStringify = JSON.stringify(newValues);

        if(oldValuesStringify !== newValuesStringify){
            handleChange("fields", newValues)
        }

    },[selected])

    const onChange = selectedOptions => {
        setSelected(selectedOptions ? selectedOptions : [])
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(selected, oldIndex, newIndex);
        setSelected(newValue);

    };


    return (
        <>
            {label && <FormLabel className={classes.label}>{label}</FormLabel>}
                <SortableSelect
                    useDragHandle
                    // react-sortable-hoc props:
                    axis="y"
                    onSortEnd={onSortEnd}
                    distance={4}
                    // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
                    getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                    // react-select props:
                    isMulti
                    options={options}
                    value={selected}
                    onChange={onChange}
                    components={{
                        MultiValue: SortableMultiValue,
                        MultiValueLabel: SortableMultiValueLabel,
                    }}
                    updateBeforeSortStart={(node) => { node.node.style.zIndex=1301; }} // fix zIndex inside dialog
                    // closeMenuOnSelect={false}
                    styles={{ menuPortal: base => ({...base, zIndex: 1301}) }}  // fix zIndex inside dialog
                    menuPortalTarget={window.document.body}
                    isLoading={isLoading}
                    className={`field-sortable-select`}
                />

            {error &&  <FormHelperText error>{error}</FormHelperText>}
        </>
    );
}
