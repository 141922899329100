import React from 'react';
import {FormControl, Grid} from "@material-ui/core";
import {Can} from "../../Context/Can";
import InputField from "../formElements/InputField";
import {useTranslation} from "react-i18next";
import SortableMultiSelect from "../formElements/SortableMultiSelect";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import {makeStyles} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    itemFields: {
        display: 'flex',
        flexFlow: 'row wrap',
        margin: '0 -8px',
        padding: '0',

        [theme.breakpoints.down('767')]:{
            display: 'block',
            margin: '0'
        },
    },
    itemField: {
        flex: '0 0 calc(50% - 16px)',
        width: 'calc(50% - 16px)',
        margin: '0 8px 24px',
        [theme.breakpoints.down('767')]: {
            width: '100%',
            margin: '0 0 24px',
        },
        '& .MuiInputBase-root': {
            marginTop: '0',
        },
        // placeholder
        "& label[data-shrink=false] + .MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder": {
            opacity: "1 !important"
        }
    },
    itemFieldDropdown: {
        '& .MuiFormLabel-root': {
            marginBottom: '6px',
        }
    }
}))

const ExpenseReportTemplateForm = (props) => {
    const {handleSubmit, handleChange, values, errors, loading} = props;
    const classes = useStyle();
    const {t} = useTranslation();

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Can I="update" a={"expenseReportTemplate"} passThrough>
                {(can) => (
                    <div className={classes.itemFields}>
                        <FormControl fullWidth error={!!errors["name"]} className={classes.itemField}>
                            <InputField
                                label={t("formFields.name")}
                                placeholder={t("formFields.name")}
                                name="name"
                                disabled={!can}
                                value={values["name"]}
                                isLoading={loading}
                                onInputChange={(val) => handleChange("name", val)}
                                error={errors["name"]}
                            />
                        </FormControl>
                        <FormControl fullWidth error={!!errors["fields"]} className={`${classes.itemField} ${classes.itemFieldDropdown}`}>
                            <ErrorBoundary>
                                <SortableMultiSelect
                                    value={values["fields"]}
                                    handleChange={handleChange}
                                    error={errors["fields"]}
                                    label={t("formFields.fields")}
                                />
                            </ErrorBoundary>
                        </FormControl>

                    </div>
                )}
            </Can>
        </form>
    );
};

export default ExpenseReportTemplateForm;
